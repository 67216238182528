import React, {useState, useEffect, useRef} from 'react'
import { AiOutlineShareAlt } from 'react-icons/ai'
import { BiShare } from 'react-icons/bi'
import { toast, ToastContainer } from 'react-toastify';
import instance from '../../helpers/axios';
import ButtonLoader from '../../helpers/button_loader';
import { useTypedSelector } from '../../hooks/useSelector';
import { facebookSVG, instagramSVG, linkedinSVG, telegramSVG, twitterSVG, whatsappSVG } from '../images'


interface iConfirm {
    title : string;
    description? : string;
    close : any;
    callback : any;
}

function ConfirmModal(data : iConfirm) {
    const [isLoading, setIsLoading] = useState(false)
    return (
        <div>
            <div className='fixed top-0 left-0 w-full z-[999] h-[100vh]'>
                <div className="max-h-[300px] min-h-[100px] w-full flex justify-center">
                    <div onClick={()=>data.close()} className='back_drop backdrop-blur fixed top-0 left-0 w-full bg-blue/30 h-[100vh] bg-blue'></div>
                    <div className='bg-white shadow-lg rounded-[15px] w-[95%] lg:w-[360px] p-3 relative'>
                        <div className="mt-[10px] mb-[15px] px-3 font-primary ">
                            <div className="" id="">
                                <h3 className="text-blue text-center text-uppercase text-[20px] font-bold mb-3">{data.title}</h3>

                                <div className="text-center">
                                    <p className="text-[20px]">{data.description ? data.description : "This action cannot be revert"}</p>
                                </div>
                                   
                                <div className="mt-3 flex items-center justify-content-between">
                                    <button type="button" onClick={()=>data.callback()} className="btn bg-blue text-white close-swl">Confirm</button>
                                    <button type="button" onClick={()=>data.close()} className="btn bg-red-700 text-white ml-10 close-swl">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}


export default ConfirmModal;