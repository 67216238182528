import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useDocumentTitle from '../../helpers/pageTitle';
import { useTypedSelector } from '../../hooks/useSelector';
import { ActionType } from '../../store/actions';
import instance from '../../helpers/axios';
import { toast, ToastContainer } from 'react-toastify';
import ScrollToTop from '../../helpers/scrollToTop';
import Loader from '../../helpers/loader';
import Button from '../../components/button';
import { checkBox, checkBoxChecked, checked, exporticon, notChecked, who } from '../../components/images';
import { graph1, graph2, trendup } from '../../components/super_images';
import { CChart } from '@coreui/react-chartjs';
import { BsCaretDownSquare, BsCircleFill } from 'react-icons/bs';
import { BiCaretDown, BiDotsVertical } from 'react-icons/bi';
import { AiOutlineCheckSquare, AiOutlineEye, AiOutlinePlusCircle } from 'react-icons/ai';
import { formatDate } from '../../helpers/helpers';
import { CoreDropdown } from '../../components';
import { resultSort } from '../../data';
import { FiSearch } from 'react-icons/fi';
import { ChallengeManagement, OthersManagement, SubscriptionManagement, UserManagement } from '../../components/admin';
// import { ShareModal } from '../../components/brand';

const Settings: React.FC = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { search } = useLocation()
    const { acctSideBarActive } = useTypedSelector((state) => state.appReducer);
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        dispatch({
            type: ActionType.UPDATE_LAYOUT,
            payload: "admin"
        })

    }, [])


    useDocumentTitle('ADMIN - Settings')
    // const a = []
    return (
        <div>
            <div className="max-w-[1445px] font-primary">
                <ToastContainer />
                {/* wrapper */}
                {
                    isLoading ? <Loader /> :
                        <div className='container font-primary min-h-[95vh] flex-inital lg:px-[15px] px-[5px] py-[25px] w-full'>
                            <div className="my-[25px] bg-white rounded-[10px] shadow-lg flex flex-col lg:flex-row justify-between p-[15px]">
                                <div className=''>
                                    <h3 className='mb-1 text-[20px] font-500 text-blue font-primary'>Team Member</h3>
                                    <p className='text-blue opacity-80'>Add and manage permissions for member of your organization here  </p>
                                </div>
                                <div>
                                    <Link to={'/admin/users/team-members'} className='bg-yellow rounded-[45px] font-bold font-primary w-[50px] lg:w-[150px] text-blue h-[35px] flex items-center justify-center px-[10px] text-[14px]'> <AiOutlinePlusCircle className='font=bold' /> <div className="hidden lg:block mx-1"></div> <span className='hidden lg:flex'>Add Members</span> </Link >
                                    {/* <img onClick={()=>null} src={eamilNotification ? checked : notChecked} style={{ cursor: 'pointer' }} alt="" /> */}
                                </div>
                            </div>

                            <div className="my-[25px] bg-white rounded-[10px] shadow-lg p-[15px]">
                                <div className=''>
                                    <h3 className='mb-1 text-[20px] font-500 text-blue font-primary'>Owner (Admin)</h3>
                                    <p className='text-blue opacity-80'>Admin have all Permission by default.</p>
                                    <p className='text-blue opacity-80'>Give access to predefined menus and features by assigning  users and members to the different  roles. </p>
                                </div>

                                <div className="flex relative justify-between border-b-2 pb-[5px] border-[#0B01491F] mt-5">
                                    <div className=' mb-[5px] w-[100px] lg:w-[auto]'>
                                        <p className='text-gray2 text-[14px]'>Action</p>
                                        <div className='w-[50px] lg:w-[80px] h-[2px] bg-blue absolute bottom-[-2px]'></div>
                                    </div>
                                    <div className='lg:w-[340px] w-full grid grid-cols-3 '>
                                        <p className='text-blue font-bold text-[12px] lg:text-[14px] text-center'>All Users</p>
                                        <p className='text-blue font-bold text-[12px] lg:text-[14px] text-center'>Super Admin</p>
                                        <p className='text-blue font-bold text-[12px] lg:text-[14px] text-center'>Brand Admin</p>
                                    </div>
                                </div>

                                <ChallengeManagement />
                                <UserManagement />
                                <SubscriptionManagement />
                                <OthersManagement />
                            </div>
                        </div>
                }

            </div>
        </div>
    )
}

export default Settings