import React, {useEffect, useState} from 'react'
import { useDispatch } from 'react-redux';
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import BlogCard from '../../components/admin/blog'
import { blog3 } from '../../components/images'
import { useTypedSelector } from '../../hooks/useSelector'
import { ActionType } from '../../store/actions';

import { iPost } from '../../models/post'
import instance from '../../helpers/axios';
import Pagination from '../../components/pagination';
export const links:iPost[] = [
    {
        title : "BLOG",
        image : blog3,
        slug : "/admin/blog"
    },
    {
        title : "FAQs",
        image : blog3,
        slug : "/admin/faqs"
    }
]
function AdminBlog() {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { search } = useLocation()
    const [allRecords, setAllRecords] = useState(0)
    const [page, setPage] = useState(1)
    const { loggedInAdmin } = useTypedSelector((state:any) => state.appReducer);
    const [posts, setPosts] = useState<any[]>([])
    const headers = {
        'Authorization': `Bearer ${loggedInAdmin}`,
    }

    useEffect(() => {
        if (!loggedInAdmin || loggedInAdmin == "") {
            // nobody is logged in
            navigate("/admin/login");
        }
    }, [loggedInAdmin])

    useEffect(() => {
        dispatch({
            type: ActionType.UPDATE_LAYOUT,
            payload: "admin"
        })

    }, [])
    useEffect(() => {
        instance({
            method: 'get',
            headers : headers,
            url: `/blog/all`
        }).then((response)=>{
            var b = response.data.data.docs
            setPosts(b)
            setAllRecords(response.data.data.totalDocs)
            // console.log(response.data.data.docs)
        }).catch((err)=> {
            navigate("/admin/blog")
        }).finally(()=>{
        })
    }, [])

    useEffect(() => {
        const search_ = (search.replace('?', '')).split("&")
        if (search_.length > 0) {
            search_.forEach(s => {
                if (s.includes('page=') !== false) {
                    const scrollTo = s.replace('page=', '')
                    setPage(parseInt(scrollTo))
                    return false;
                }
            });
        }
    }, [search])

    return (
        <>
            <div>
                <div className="max-w-[1445px] font-primary">
                    <div className='container font-primary min-h-[95vh] flex-inital lg:px-[15px] px-[5px] py-[25px] w-full'>
                        <div className="search flex justify-between flex-col lg:flex-row items-center mb-4">
                            <h3 className="font-primaary font-bold text-blue">Blogs</h3>
                            
                            <Link to={'/admin/blog/post'} className='bg-yellow rounded-[45px] font-bold font-primary w-[50px] lg:w-[150px] text-blue h-[35px] flex items-center justify-center px-[10px] text-[14px]'> <AiOutlinePlusCircle className='font=bold' /> <div className="hidden lg:block mx-1"></div> <span className='hidden lg:flex'>Create Post</span> </Link >
                        </div>

                        <div className="grid grid-cols-1 lg:grid-cols-3 gap-3">
                            {
                                posts.map((item, i)=>(
                                    <BlogCard key={i} source="admin" title={item.title} image={item.blogImage} slug={item._id} />
                                ))
                            }
                        </div>

                        <div className="mt-4">
                            <Pagination limit={10} length={allRecords} page={page} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdminBlog