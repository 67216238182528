import { iChallengeCat, iFaqs } from "./models/model";

export const brandCategories = [
    {id : 1, value : "Personal brand"},
    {id : 2, value : "Business"},
    {id : 3, value : "Government"}
];

export const superAdminUsersTypes = [
  {id : 1, value : "Admin"},
];

export const industries = [
    {id : 1, key : 1, label :"Accounting & Finance",  value : "Accounting & Finance"}, 
    {id : 2, value : "Agriculture", key : 2, label : "Agriculture"}, 
    {id : 3, value : "Business & Consulting", key : 3, label : "Business & Consulting"},
    {id : 4, value : "Communication", key : 4, label : "Communication"}, 
    {id : 5, value : "Construction", key : 5, label : "Construction"}, 
    {id : 6, value : "Education", key : 6, label : "Education"}, 
    {id : 7, value : "Entertainment", key : 7, label : "Entertainment"}
]

export const monthsList = [
    {id : 1, value : "01"}, 
    {id : 2, value : "02"}, 
    {id : 3, value : "03"},
    {id : 4, value : "04"}, 
    {id : 5, value : "05"}, 
    {id : 6, value : "06"}, 
    {id : 6, value : "07"}, 
    {id : 6, value : "08"}, 
    {id : 6, value : "09"}, 
    {id : 6, value : "10"}, 
    {id : 6, value : "11"}, 
    {id : 6, value : "12"}, 
]

export const plans = [
    {id : 1, title : "Beentos Bronze", amount : 100, features : [
        {title : "Access to Admin board"},
        {title : "Challenge Size ", value : "No"},
        {title : "Visual Data ", value : "No"},
        {title : "Download Video", value : "No" },
    ]}, 
      {id : 1, title : "Beentos Silver", amount : 200, features : [
        {title : "Access to Admin board"},
        {title : "Challenge Size ", value : "No"},
        {title : "Visual Data ", value : "No"},
        {title : "Download Video", value : "No"},
    ]}, 
    {id : 1, title : "Beentos Gold", amount : 300, features : [
        {title : "Access to Admin board"},
        {title : "Challenge Size ", value : "No"},
        {title : "Visual Data ", value : "No"},
        {title : "Download Video", value : "No"},
    ]}, 
]

export const votingCategories = [
    {id : 1, value : "Audience Choice"},
    {id : 2, value : "Brand Choice"},
    {id : 3 , value : "Both"},
]



export const rewardTypes = [
    {id : 1, value : "Cash", label : "Cash"},
    {id : 2, value : "Gift card", label : "Gift card"},
    {id : 3, value : "Product discounts", label : "Product discounts"},
    {id : 4, value : "Cash reward", label : "Cash reward"},
    {id : 5, value : "Free Sample Product", label : "Free Sample Product"},
    {id : 6 , value : "Others", label : "Others"},
]

export const locations = [
    {id : 1, value : "Global"},
    {id : 2, value : "Continent"},
]

export const challengeVisibilities = [
  {id : 1, value : "Public"},
  {id : 2, value : "Private"},
]
export const couponTypes = [
  {id : 1, value : "Challenge", label : "Challenge"},
  {id : 2, value : "Cash", label : "Cash"},
]

// export const challengeTypes = [
//     {id : 1, value : "Marketing"},
//     {id : 2, value : "Solve a business problem"},
// ]

export const challengeTypes = [
    {id : 1, value : "Select"},
    {id : 2, value : "Video"},
    {id : 3, value : "Picture"},
]

export const challengeCategories:iChallengeCat[] = [
    {id : 1, value : "Dance", label : "Dance"},
    {id : 2, value : "Art", label : "Art"},
    {id : 2, value : "Sports", label : "Sports"},
    {id : 2, value : "Photography", label : "Photography"},
    {id : 2, value : "Fashion", label : "Fashion"},
    {id : 2, value : "Cinema", label : "Cinema"},
    {id : 2, value : "Comedy", label : "Comedy"},
    {id : 2, value : "Illustration", label : "Illustration"},
    {id : 2, value : "Singing & Music", label : "Singing & Music"},
]


export const resultSort = [
    {id : 1, value : "All"},
    {id : 2, value : "Oldest"},
    {id : 2, value : "Newest"},
    {id : 2, value : "Highest"},
    {id : 2, value : "Lowest"},
]
export const challengePostType = [
  {id : 1, value : "Video"},
  {id : 2, value : "Picture"},
  {id : 3, value : "Text"},
]

export const filterInsight = [
  {id : 1, value : "Day"},
  {id : 2, value : "Week"},
  {id : 3, value : "Months"},
  {id : 3, value : "Lifetime"},
  
]

export const resultSort1 = [
  {id : 1, value : "All"},
  {id : 2, value : "Active"},
  {id : 2, value : "In Active"},
  {id : 2, value : "Oldest"},
  {id : 2, value : "Newest"},
]

export const frequentlyAskedQuestions:iFaqs[] =[
    {
      question: "How many programmers does it take to screw a lightbulb?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed pharetra lorem eu dolor rhoncus, at scelerisque ligula gravida. Sed porta id mi sit amet convallis. Etiam iaculis massa sit amet lacus blandit sodales. Nulla ultrices velit a diam placerat congue. Pellentesque iaculis, ipsum quis eleifend dapibus, est dui eleifend ante, quis fermentum mi ligula quis nisl. Ut et ex dui. Integer id venenatis quam.",
      open: false
    },
    {
      question: "Who is the most awesome person?",
      answer: "You! The viewer!",
      open: false
    },
    {
      question:
        "How many questions does it take to makes a succesful FAQ Page?",
      answer: "This many!",
      open: false
    }
  ]

