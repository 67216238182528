import React from 'react';
import ReactDOM from 'react-dom/client';
import '@coreui/coreui/dist/css/coreui.min.css'
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import {store} from './store'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

