import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useDocumentTitle from '../../helpers/pageTitle';
import { useTypedSelector } from '../../hooks/useSelector';
import { ActionType } from '../../store/actions';
import { toast, ToastContainer } from 'react-toastify'
import Input from '../../components/input';
import Button from '../../components/button';
import { challengeTypes, rewardTypes, votingCategories, locations } from '../../data';
import { Choose } from '../../components/brand';
import { capitalizeFirstLetter, position, sharePrize } from '../../helpers/helpers';
import instance from '../../helpers/axios';
import AddProductModal from '../../components/admin/add_package_modal';
import { BiDotsVertical, BiPencil } from 'react-icons/bi';
import AppLink from '../../components/link';

const AdminFaqAdd: React.FC = () => {
    const params = useParams()
    const { loggedInAdmin } = useTypedSelector((state) => state.appReducer);
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [title, setTitle] = useState('')
    const [challengePerDay, setChallengePerDay] = useState("")
    const [rateperDay, setRateperDay] = useState("")
    const [loading, setLoading] = useState(false)
    const [feature, setFeature] = useState("")
    const [features, setFeatures] = useState<string[]>([])
    const [showAddModal, setShowAddModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [editIndex, setEditIndex] = useState(0)
    const headers = {
        'Authorization': `Bearer ${loggedInAdmin}`
    }
    useEffect(() => {
        if(!params.package){
        }else{
            const slug = params.package;
            
        }
    }, [])


    useEffect(() => {
        if (!loggedInAdmin || loggedInAdmin === "") {
            // nobody is logged in
            navigate("/admin/login");
        }
    }, [loggedInAdmin, navigate])

    useEffect(() => {
        dispatch({
            type: ActionType.UPDATE_LAYOUT,
            payload: "admin"
        })
    }, [])

    const { pathname, search } = useLocation();
    useEffect(() => {
        const headers = {
            'Authorization': `Bearer ${loggedInAdmin}`
        }

    }, []);

    useDocumentTitle('CREATE CHALLENGE ')

    const proceed = () => {
        if(loading) return false;
        // navigate("/brand/challenge/create-step-payment");
        setLoading(true)
        var passed = true;
      
        if(title === "" ){
            passed = false;
        }

        if(rateperDay === "" ){
            passed = false;
        }

        if(!passed){
        setLoading(false)
            toast.error("All fields required")
            return false;
        }
        var data = {
            "subject": title,
            detail: rateperDay,
            status: "published"
        }

        const headers = {
            'Authorization': `Bearer ${loggedInAdmin}`
        }
        instance({
            method: "POST",
            url: `/knowledge-base/create`,
            headers: headers,
            data : data
        }).then((response) => {
            // window.location.reload();
            toast.success("New FAQ created")
            console.log(response)
            setTitle("")
            setFeatures([])
            setRateperDay("")
            setChallengePerDay("")
        }).catch((err) => {
            toast.error("Something went wrong")
        }).finally(() => {
            setLoading(false)
        })
    }
  
    // const a = []
    return (
        <div className='px-[5px] py-[50px]'>
            <div className="container mx-auto">
                <ToastContainer />
                <AppLink href='/admin/faqs' containerClass='font-bold font-primary w-[120px] text-blue flex items-center justify-center px-[10px] text-[14px]' text='go back' />

                <div className="py-[20px] bg-white shadow-lg rounded ">
                    <div className="text-censter font-primary">
                        <div className="mt-3 w-full">
                            <h3 className='text-blue  text-center text-[24px] m-0 font-bold font-primary'>Add Faq</h3>
                        </div>
                      

                        <div className="mt-4 w-[80%] mx-auto">
                            <div className="my-2">
                                <label className='font-primary text-blue' htmlFor="">Subject</label>
                                <Input value={title} setValue={setTitle} containerClass="h-[50px] bg-[#F0F0F4] border-graylight shadow-0 shadow-none border-0" placeholder='Title' />
                            </div>
                            <div className="my-2">
                                <label className='font-primary text-blue' htmlFor="">Description</label>
                                <Input value={rateperDay} type="text" setValue={setRateperDay} containerClass="h-[50px] bg-[#F0F0F4] border-graylight shadow-0 shadow-none border-0" placeholder='Enter rate per day' />
                            </div>
                           
                            <div className="text-center">
                                <Button loading={loading} text="Save" onclick={() => proceed()} containerClass="w-full max-w-[250px] bg-blue text-yellow mt-[30px]" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminFaqAdd