export interface State {
    loggedIn: string | null,
    layoutType: string,
    brandData : any,
    loggedInBrand : string | null,
    loggedInAdmin : string | null,
    challengeData : string | null,
    brandId : string | null,
    
}

export enum ActionType {
    LOGIN = 'LOGIN',
    LOGIN_BRAND = 'LOGIN_BRAND',
    UPDATE_BRANDID = 'UPDATE_BRANDID',
    LOGIN_ADMIN = 'LOGIN_ADMIN',
    LOGOUT = 'LOGOUT',
    GET_LAYOUT = 'GET_LAYOUT',
    UPDATE_LAYOUT = 'UPDATE_LAYOUT',
    GET_BRAND_DATA = 'GET_BRAND_DATA',
    UPDATE_BRAND_DATA = "UPDATE_BRAND_DATA",
    LOGOUT_BRAND = "LOGOUT_BRAND",
    LOGOUT_ADMIN = "LOGOUT_ADMIN",
    UPDATE_CHALLENGE = "UPDATE_CHALLENGE",
    
    ACCT_SIDEBAR_ACTIVE = 'ACCT_SIDEBAR_ACTIVE',
    UNSET_CHALLENGE = "UNSET_CHALLENGE",
    SIDEBAR_TOGGLE = "SIDEBAR_TOGGLE"
}

interface actionLogin {
    type: ActionType.LOGIN;
    payload: string;
}

interface actionLoginBrand {
    type: ActionType.LOGIN_BRAND;
    payload: string;
}
interface actionSetBrandId {
    type: ActionType.LOGIN;
    payload: string;
}

interface actionLoginAdmin {
    type: ActionType.LOGIN_ADMIN;
    payload: string;
}

interface actionLogOutBrand {
    type: ActionType.LOGOUT_BRAND;
}

interface actionLogOutAdmin {
    type: ActionType.LOGOUT_ADMIN;
}

interface actionLogout {
    type: ActionType.LOGOUT;
}

interface actionGetLayout {
    type: ActionType.GET_LAYOUT;
}

interface actionLayout {
    type: ActionType.UPDATE_LAYOUT,
    payload: string,
}

interface actionGetBrandData {
    type : ActionType.GET_BRAND_DATA,
    payload : object,
}

interface actionUpdateBrandData {
    type : ActionType.UPDATE_BRAND_DATA;
    payload : string;
}
interface actionAcctSidebarActive {
    type : ActionType.ACCT_SIDEBAR_ACTIVE;
    payload : string;
}

interface actionUpdateChallengeData {
    type : ActionType.UPDATE_CHALLENGE;
    payload : string;
}
interface actionUnsetChallengeData {
    type : ActionType.UNSET_CHALLENGE;
}

interface actiontoggleSidebar {
    type : ActionType.SIDEBAR_TOGGLE;
    payload : boolean;
}

export type Action = actionSetBrandId | actionLogOutAdmin | actionLoginAdmin | actiontoggleSidebar | actionUnsetChallengeData | actionLayout | actionLogin | actionLogout | actionGetLayout | actionGetBrandData | actionUpdateBrandData | actionLoginBrand | actionLogOutBrand | actionAcctSidebarActive | actionUpdateChallengeData;