import React from 'react'
import { Link } from 'react-router-dom'
import ButtonLoader from '../helpers/button_loader'
import {iButton} from '../models/model'

const AppLink = ({containerClass, text = 'Submit', href, loading = false, h='', img = ""} : iButton) => {
  return (
    <Link to={`${href}`} className={`control flex items-center justify-evenly border border-blue pl-2 h-[50px] rounded-[45px] ${containerClass}`} style={{height: h == '' ? '50px' : h}}>
        {
          !loading && img !== "" ? 
          <span className="mr-3">
          <img src={img} alt="" />
          </span>
           : ""
        }
        {text}
    </Link>
  )
}

export default AppLink