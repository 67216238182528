import React from 'react'
import { PDFDownloadLink, Font, Page, Text, View, Document, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import { Logo } from '../../components/images';
import { iPdf } from '../../models/model';
interface iReport {

}
const ReportTemplate1 = () => {
	Font.register({
		family: 'Oswald',
		src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
	});

	const styles = StyleSheet.create({
		pdf : {
			height : '100vh',
			width : '100%',
		},
		body: {
			paddingTop: 35,
			paddingBottom: 65,
			paddingHorizontal: 35,
		},
		title: {
			fontSize: 24,
			textAlign: 'center',
			fontFamily: 'Oswald'
		},
		author: {
			fontSize: 12,
			textAlign: 'center',
			marginBottom: 15,
		},
		subtitle: {
			fontSize: 18,
			// margin: 12,
			fontFamily: 'Oswald'
		},
		text: {
			margin: 12,
			fontSize: 14,
			textAlign: 'justify',
			fontFamily: 'Times-Roman'
		},
		image: {
			// marginVertical: 15,
			// marginHorizontal: 50,
			width : '90%',
			maxHeight : '300px',
			objectFit : 'contain',
			marginHorizontal : 'auto'
		},
		logo: {
			height: 72,
			width : 72,
			objectFit : 'contain',
			margin : '0 auto'
			// marginHorizontal: 100,
		},
		space1 : {
			marginBottom : 10,
		},
		space2 : {
			marginBottom : 20,
		},
		header: {
			fontSize: 12,
			marginBottom: 20,
			textAlign: 'center',
			color: 'grey',
		},
		pageNumber: {
			position: 'absolute',
			fontSize: 12,
			bottom: 30,
			left: 0,
			right: 0,
			textAlign: 'center',
			color: 'grey',
		},
		table : {
			// display: 'flex',
			width : '100%',
		},
		row : {
			display : 'flex',
			flexDirection : 'row',
		},
		tableHeader : {
			display : 'flex',
			flexDirection : 'row',
			backgroundColor : "#F8F8F8",
			height : '40px',
			textAlign : 'center',
			alignItems : 'center',
			color : "rgb(11 1 73)",
		},
		headerCell : {
			fontWeight : 'bold',
			width : '100%',
			fontSize : 12
		},
		cell : {
			// flex : 1,
			width : '50%',
			flexGrow: 1,
			color : "#0B014980",
			padding : 4,
			fontSize : 12,
			minHeight : 40,
		}

	});

	return (
		<PDFViewer style={styles.pdf}>
		<Document>
			<Page style={styles.body}>
				<Image
					style={styles.logo}
					src={Logo}
				/>
				<Text style={styles.header} fixed>
					~ Brand Report - Beentos ~
				</Text>
				
				<Text style={styles.subtitle} >
					Overview :
				</Text>
				
				<View style={styles.table} >
				{/* Title, start, end, engagement, participants */}
				<View style={styles.tableHeader}>
					<Text style={styles.headerCell}>Title</Text>
					<Text style={styles.headerCell}>Start</Text>
					<Text style={styles.headerCell}>End</Text>
					<Text style={styles.headerCell}>Engagement</Text>
					<Text style={styles.headerCell}>Participants</Text>
				</View>
				{
					[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,1,1,1,1,1,1,1,11,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1].map((item, i)=>(
						<View style={[styles.row, i%2==1 ? {backgroundColor : "rgb(240 240 244)"} : {}]} key={i}>
							<Text style={[styles.cell]}>Hello worldsadfgjakdfgjkadf</Text>
							<Text style={styles.cell}>Hello world</Text>
							<Text style={styles.cell}>Hello world</Text>
							<Text style={styles.cell}>Hello world</Text>
							<Text style={[styles.cell, {textAlign : "center"}]}>Hello world</Text>
						</View>
					))
				}
				</View>
				
				
				<Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
					`${pageNumber} / ${totalPages}`
				)} fixed />
			</Page>
		</Document>
		</PDFViewer>
	);
};

export default ReportTemplate1;