import React from 'react'
import { Link } from 'react-router-dom';
import Image2 from '../../assets/img/image2.png';
import { RxCaretDown } from "react-icons/rx";
import { beentosNew } from '../images';

function Companies() {
  return (
    <div className='container mx-auto py-4 my-[50px]' id='company_section'>
      {/* banner */}
      <div className="flex items-center gap-5 justify-between flex-col lg:flex-row">
        <div className='flex-1'>
          <img src={beentosNew} className='h-[300px]  rounded-[15px] shadow lg:h-[320px] object-cover w-full' alt="" />
        </div>
        <div className='h-100 flex-1 lg:mt-0 flex flex-col text-blue justify-center'>
          <h3 className="font-primarybold leading-tight mb-2 font-bold m-0 p-0 text-blue dark:text-white text-[36px]">
            Get people talking about your business. <br /> Grow your customers.  Gain market insights.
          </h3>
          <p className='text-[16px] lg:text-[18px] font-primary dark:text-white'>
          Amplify your brand message, reward your loyal customers, engage with your community, and promote talent.
            </p>


        </div>

      </div>
    </div>
  )
}

export default Companies