import React, { useState } from 'react'
import { FiSearch } from 'react-icons/fi'
import { VscCloseAll } from 'react-icons/vsc'
import { toast, ToastContainer } from 'react-toastify'
import { brandCategories, superAdminUsersTypes } from '../../data'
import instance from '../../helpers/axios'
import ButtonLoader from '../../helpers/button_loader'
import { useTypedSelector } from '../../hooks/useSelector'
import { iCreate } from '../../models/model'
import Button from '../button'
import CoreDropdown from '../core_dropdown'
import { checkBox, checkBoxChecked, copy_icon1, Logo } from '../images'
import Input from '../input'

function AddGuidelineModal({ close, setFeature, text, isEdit = false, index }: iCreate) {
    const [feature, setF] = useState(text)
    const addPackage = () => {
        if(feature == "") return ;
        if(isEdit){
            setFeature(index,feature)
        }else{
            setFeature(feature)
        }
    }
   
    return (
        <div>
            <ToastContainer />
            <div className='fixed top-0 left-0 w-full flex justify-center z-[999] h-[100vh]'>
                <div className="max-h-[80vh] h-[fit-content] w-full flex justify-center">
                    <div onClick={() => close()} className='back_drop backdrop-blur fixed top-0 left-0 w-full bg-blue/30 h-[100vh] bg-blue'></div>
                    <div className='bg-white shadow-lg rounded-[15px] w-[95%] lg:w-[670px] p-3 relative'>
                        <h3 className='text-[#1E2124] text-[20px]'>{isEdit ? "Edit" : "Add"} Guideline</h3>
                        <div className="h-[1px] my-3 bg-[#CDE1FF] w-full"></div>
                        <div className='bg-[#F0F0F4] rounded-[8px] w-full flex items-center py-1'>
                            <input value={feature} onChange={(e)=> setF(e.target.value)} type="text" className='bg-transparent ml-3 border-0 outline-0 w-full h-[40px]' placeholder='Enter new guideline' />
                        </div>
                        <br />
                            <Button onclick={addPackage} text={ isEdit ? 'Update' : 'Add'} containerClass='bg-blue text-yellow border-0 w-[100px] lg:w-[150px]' h='40px' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddGuidelineModal