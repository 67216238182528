import React, { useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import  useDocumentTitle from '../helpers/pageTitle'
import "react-phone-input-2/lib/bootstrap.css";
import { iUser } from '../models/user';
import instance from '../helpers/axios';
import ButtonLoader from '../helpers/button_loader';
import { validateEmail } from '../helpers/helpers';
import { AppSection } from '../components/home';

function GetBeentos() {
    
 
    return (
        <div className="mx-auto max-w-[1440] bg-graylight py-[10px] dark:bg-black">
            <AppSection source={"download"} />
        </div>
    )
}

export default GetBeentos 