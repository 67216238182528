import React from 'react'
import { Link } from 'react-router-dom'
import { iPost } from '../../models/post'
import { blog3 } from '../images'

function BlogCard(post: iPost) {
    return (
        <div className={`font-primary ${post.source == "admin" ? "bg-white shadow-md border border-gray-200" : ""}  rounded-lg max-w-sm mb-1`}>
            <Link to={`${post.slug}`}>
                <img className="rounded-t-lg max-h-[250px] h-[250px] object-cover w-full" src={post.image ? post.image : blog3} alt="" />
            </Link>
            <div className="py-2 px-2">
                <Link to={`${post.slug}`}>
                    <h5 className="text-blue font-primary font-bold text-lg tracking-tight mb-2">{post.title.substring(0, 70)} {post.title.length > 70 && '...'}</h5>
                </Link>
                {
                    post.shortDescription ?
                        <p className="font-primary text-blue mb-3">
                            {post.shortDescription}
                        </p> : ""
                }
             
            </div>
        </div>
    )
}

export default BlogCard