import React from 'react'
import {AppSection as App, AppStore, PlayStore} from '../images';


function AppSection({source = "app"}) {
  return (
    <div className={`mx-auto ${ source == "app" ? 'bg-graylight dark:bg-black mt-[80px] py-[60px]' : "mt-[40px]"} `}>
        <div className="container mx-auto flex flex-col-reverse lg:flex-row items-center">
            <div className='flex-1'>
                <h3 className="text-[32px] lg:text-[38px] font-primarybold text-blue dark:text-white">Get it now on PlayStore <br /> and App Store!!</h3>
                <div className="mt-[50px]"></div>
                <div className="flex w-full flex-col items-center lg:flex-row justify-between gap-y-3 max-w-[450px]">
                  <a href="https://play.google.com/store/apps/details?id=com.beentosapp"><img src={PlayStore} className="object-contain h-[60px]" alt="" /></a>
                  <a href="https://apps.apple.com/app/beentos/id6450564973">
                  <img src={AppStore} className="object-contain h-[60px]" alt="" />
                  </a>
                </div>
            </div>
            <div className='flex-1'>
                <img src={App} className="h-[245px] mb-4 lg:mb-0 lg:h-[383px] object-contain w-full" alt="" />
            </div>
        </div>
    </div>
  )
}

export default AppSection