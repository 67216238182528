import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Button from '../../components/button';
import { bgWhite, arrowBack, bgYellow } from "../../components/images";
import Input from '../../components/input';
import Steps from '../../components/signup/steps';
import useDocumentTitle from '../../helpers/pageTitle';
import { useTypedSelector } from '../../hooks/useSelector';
import { Country, State }  from 'country-state-city';
import { validateEmail } from '../../helpers/helpers';
import { useDispatch } from 'react-redux';
import { ActionType } from '../../store/actions';
import instance from '../../helpers/axios';
import { toast, ToastContainer } from 'react-toastify';
import DropDown from '../../components/dropdown';

// if you later check this code and you see weird structure, it isn't my fault

const BrandRegister2 = () => {
  const [brandEmail, setBrandEmail] = useState <string>('')
  const [brandEmailErr, setBrandEmailErr] = useState <string>('')
  const [city, setCity] = useState <string>('')
  const [cityErr, setCityErr] = useState <string>('')
  const [stateErr, setStateErr] = useState <string>('')
 const [cityList, setCityList] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();

  const [name, setName] = useState <string>('')
  const [nameErr, setNameErr] = useState <string>('')
  const [phone, setPhone] = useState <string>('')
  const [phoneErr, setPhoneErr] = useState <string>('')
  const [showNameErr, setShowNameErr] = useState(false)
  const [showPhoneErr, setShowPhoneErr] = useState(false)
  const [category, setCategory] = useState("")
  const [gState, setGState] = useState("")

  const [showBrandEmailErr, setShowBrandEmailErr] = useState(false)
  const [showCityErr, setShowCityErr] = useState(false)
  const [showStateErr, setShowStateErr] = useState(false)
  const [showCountryErr, setShowCountryErr] = useState(false)
  const { brandData } : any = useTypedSelector((state) => state.appReducer);
  const dispatch = useDispatch();
  const myCountries= (Country.getAllCountries()).map((item, i) => {
    return {
        id : i,
        key : i,
        label: item.name,
        value : item.name,
        ...item,
    }
  });

  const countryChanged = (value:string) => {
    var selected = myCountries.find(c => c.name === value);
    if(!selected){
      window.alert("invalid country");
      return false;
    }
    const cities = (State.getStatesOfCountry(selected.isoCode)).map((item, i) => {
      return {
          id : i,
          key : i,
          label: item.name,
          value : item.name,
          ...item,
      }
    });

    setCityList(cities);
  }
  useEffect(() => {
    if(!brandData) {
      navigate("/brand/register");
    }else{
      var data = JSON.parse(brandData);
      // setCity(data.city ? data.city : '')
      setName(data.name ? data.name : '')
      setCategory(data.category)
      setPhone(data.phone ? data.phone : '')
      countryChanged(data.country)
    }
  }, [brandData, navigate])
  
  const login = () =>{
    let passed = true;
    setLoading(true)
    setShowCityErr(false)
    setShowNameErr(false)
    setShowPhoneErr(false)
    setShowStateErr(false)
    setTimeout(() => {
   
    // if(city === ""){
    //   if(category === "Government") {
    //     setCityErr("Please enter your city");
    //   }else{
    //     setCityErr("Please select your city");
    //   }
    //   setShowCityErr(true)
    //   passed = false;
    // }

    if(name === ""){
      setNameErr("Please enter contact person's name");
      setShowNameErr(true)
      passed = false;
    }

    if(phone === ""){
      setPhoneErr("Please enter contact person's phone");
      setShowPhoneErr(true)
      passed = false;
    }

      if(!passed){
        setLoading(false)
        return false;
      }
      var data = JSON.parse(brandData);
      data = {...data, name : name, phone : phone}
      dispatch({
        type : ActionType.UPDATE_BRAND_DATA,
        payload : JSON.stringify(data)
      })
      navigate("/brand/register-step-4")
    }, 1000);
      
  }


  const handleChangeCity = (selectedOption : any, actionMeta : any) => {
    // setCountry(selectedOption)
    if(category === "Government") return setGState(selectedOption.label)
    setCity(selectedOption.label)
  };

  const handleInputChange = ()=>{

  }
  useDocumentTitle('BRAND SIGN UP - STEP 2')
  return (
    <div className='h-[100vh] relative'>
      <ToastContainer />
      <img src={bgYellow} className="h-[100vh] w-full object-fill" alt="" />
      <div className="h-[100vh] absolute top-0 left-0 flex w-full justify-center items-center">
        <div className="login-container shadow-lg w-[95%] max-w-[520px] p-[40px] px-[30px] lg:px-[60px] text-center bg-white rounded-[20px] relative">
          <div className="absolute top-[10px] left-[10px]">
            <img src={arrowBack} className="h-[30px] object-contain" style={{cursor:'pointer'}} onClick={()=>navigate("/brand/register-step-1")} alt="back" />
          </div>
          <h3 className="text-blue font-primarybold  text-[26px] ">Create an account</h3>
          <div className="flex items-center justify-center my-[20px]">
            <Steps s={1} iscurrent={false} islast={false} />
            <Steps s={2} iscurrent={true} islast={false} />
            <Steps s={3} iscurrent={false} islast={true} />
          </div>
          <div className='w-full text-left'>
            <h3 className="text-blue font-primarybold text-[16px] ">Contact details</h3>
          </div>
          {/* {
            category === "Government" ?
            <div className='m-0'>
            <DropDown source='signup' value={gState} placeholder='State' showError={showStateErr} err={stateErr}  isMulti={false} options={cityList} handleChange={handleChangeCity} handleInputChange={handleInputChange} />
            <Input showError={showCityErr} err={cityErr} placeholder="City" value={city} setValue={setCity} containerClass="mt-[12px] text-[1rem]" />
            </div>
             :
            <DropDown source='signup' value={city} placeholder='City' showError={showCityErr} err={cityErr}  isMulti={false} options={cityList} handleChange={handleChangeCity} handleInputChange={handleInputChange} />
          } */}
          <Input showError={showNameErr} err={nameErr} placeholder="Contact person's name" value={name} setValue={setName} containerClass={` ${category === "Government" && showCityErr ? 'mt-[30px]' : 'mt-[15px]'}   text-[1rem]`} />
          <Input showError={showPhoneErr} err={phoneErr} placeholder="Contact person's phone" value={phone} setValue={setPhone} containerClass={` ${showNameErr ? 'mt-[30px]' : 'mt-[15px]'}   text-[1rem]`} />
          <div className="lg:flex lg:justify-end pt-[10px]">
          <Button onclick={login} text="Next" loading={loading} containerClass='w-full lg:w-[200px] bg-blue text-yellow font-bold hover:opacity-75 mt-[20px]' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BrandRegister2