import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { FaqCard, SettingsAside, SettingsHeader } from '../../../components/brand';
import Button from '../../../components/button';
import { beentos_help_icon, help_icon, money_help_icon } from '../../../components/images';
import { frequentlyAskedQuestions } from '../../../data';
import instance from '../../../helpers/axios';
import useDocumentTitle from '../../../helpers/pageTitle';
import { useTypedSelector } from '../../../hooks/useSelector';
import { ActionType } from '../../../store/actions';

const Help: React.FC = () => {
    const { loggedInBrand } = useTypedSelector((state) => state.appReducer);
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [faqs, setFaqs] = useState<any[]>([])
    const [allFaqs, setAllFaqs] = useState(0)
    const headers = {
        'Authorization': `Bearer ${loggedInBrand}`,
    }
    // const [faqs, setFaqs] = useState(frequentlyAskedQuestions)
    // get brand profile
    useEffect(() => {
        instance({
            method: "GET",
            url: "/brand/profile",
            headers: headers
        }).then((response) => {
            // console.log(response)
        }).catch((err) => {
            if (err.code == "ERR_NETWORK") {
                alert("No internet connection")
            }
            else if (err.response.data.statusCode === 403) {
                navigate('/brand/login');
                return false;
            }

            toast.error(err.response.data.message)
            console.log(err)
        })
    }, [])

    useEffect(() => {
        dispatch({
            type: ActionType.UPDATE_LAYOUT,
            payload: "brand"
        })
    }, [])

    const toggleFAQ = (index: number) => {
        setFaqs(
            faqs.map((faq, i) => {
                if (i === index) {
                    faq.open = !faq.open;
                } else {
                    faq.open = false;
                }

                return faq;
            })
        );
    };
    useEffect(() => {
        const headers = {
            'Authorization': `Bearer ${loggedInBrand}`
        }
        instance({
            method: "GET",
            url: `/knowledge-base/all?_orderBy=createdAt&_order=desc`,
            headers: headers
        }).then((response) => {
            console.log("response", response)
            var docs = response.data.data.docs;
            setFaqs(docs);
            setAllFaqs(response.data.data.totalDocs)
        }).catch((err) => {

        }).finally(() => {
        })
    }, [])
    useDocumentTitle('HELP SETTINGS')

    return (
        <div className='w-full'>
            <ToastContainer />
            <div className="px-[15px] h-100 relative font-primary flex max-w-[1440]">
                <SettingsAside />
                <div className='lg:container sm:mx-auto md:mx-auto flex-inital lg:px-[15px] px-[5px] py-[25px] w-full'>
                    <SettingsHeader title='Help topics' />

                    <div className="my-3 font-primary">
                        <div className="grid lg:grid-cols-2 gap-4">
                            <div className="p-[15px] rounded-[15px] shadow bg-white">
                                <div className="flex items-start">
                                    <img src={beentos_help_icon} alt="" className='object-fit' />
                                    <div className="pl-3">
                                        <h3 className='text-blue font-bold'>Beentos Basics</h3>
                                        <p className='text-gray2 mt-3'>Basic guide to making the most use of your Beentos account</p>
                                    </div>
                                </div>
                            </div>

                            <div className="p-[15px] rounded-[15px] shadow bg-white">
                                <div className="flex items-start">
                                    <img src={help_icon} alt="" className='object-fit' />
                                    <div className="pl-3">
                                        <h3 className='text-blue font-bold'>Creating Challenge</h3>
                                        <p className='text-gray2 mt-3'>Basic guide to making the most use of your Beentos account</p>
                                    </div>
                                </div>
                            </div>

                            <div className="p-[15px] rounded-[15px] shadow bg-white">
                                <div className="flex items-start">
                                    <img src={money_help_icon} alt="" className='object-fit' />
                                    <div className="pl-3">
                                        <h3 className='text-blue font-bold'>Subscription</h3>
                                        <p className='text-gray2 mt-3'>How to subscribe and what you get on the premium plans.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="p-[15px] rounded-[15px] shadow bg-white">
                                <div className="flex items-start">
                                    <img src={help_icon} alt="" className='object-fit' />
                                    <div className="pl-3">
                                        <h3 className='text-blue font-bold'>Account</h3>
                                        <p className='text-gray2 mt-3'>Learn how to manage your account.</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="mt-[30px]">
                        <div className="font-primary p-4 bg-white rounded-[15px] shadow">
                            <h3 className='text-blue font-primarybold text-[18px]'>Frequently Asked questions</h3>
                            {faqs.map((faq, index) => (
                                <FaqCard isOpen={faq.open} answer={faq.detail} question={faq.subject} index={index} key={index} toggleFAQ={toggleFAQ} />
                            ))}
                        </div>
                    </div>
                    <div className="my-3 mt-[30px] font-primary">
                        <h3 className='text-blue font-bold'>Still need help?</h3>
                        <p className='text-blue'>We are here to help you</p>
                        <div className="mt-3">
                            <Button onclick={() => { }} text='Contact Support' containerClass="w-[150px] lg:w-[200px] bg-blue text-yellow " />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Help;