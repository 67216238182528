import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import useDocumentTitle from '../../../helpers/pageTitle';
import { useTypedSelector } from '../../../hooks/useSelector';
import { ActionType } from '../../../store/actions';
import instance from '../../../helpers/axios';
import { toast, ToastContainer } from 'react-toastify';
import Loader from '../../../helpers/loader';
import "../../../helpers/slick.css";
import { ClipCard } from '../../../components/brand';
import Input from '../../../components/input';
import { FiSearch } from 'react-icons/fi';
import Pagination from '../../../components/pagination';
import { CCarousel, CCarouselItem } from '@coreui/react';
import video from '../../../assets/img/1.mkv'
import { CoreDropdown } from '../../../components';
import { resultSort } from '../../../data';
import { IoIosDocument, IoMdDocument } from 'react-icons/io';
import { editor_url } from '../../../helpers/helpers';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { httpDeleteWithToken, httpGetWithToken } from '../../../helpers/http_util';
import ls from "localstorage-slim"
import EditorCard from '../../../components/brand/editor_video';
import ConfirmModal from '../../../components/brand/ask_modal';

const VideoEditor: React.FC = () => {
    const { loggedInBrand } = useTypedSelector((state) => state.appReducer);
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const {search} = useLocation()
    const [isLoading, setIsLoading] = useState(false)
    const [search1, setSearch] = useState('')
    const [search_, setSearch_] = useState('')
    const [allList, setList] = useState([])
    const [page, setPage] = useState(1)
    const params = useParams()
    const [posts, setPosts] = useState<any[]>([])
    const [totalPosts, setTotalPosts] = useState(0);
    const [editorid, setEditorId] = useState("")
    const [showDelete, setShowDelete] = useState(false)
    const headers = {
        'Authorization': `Bearer ${loggedInBrand}`
    }

    useEffect(() => {
      fetchConstants()
    }, [search])
    const fetchConstants = async () => {
        var brandId = ls.get("app_brand", { decrypt: true })
        const resp:any = await httpGetWithToken(loggedInBrand, `editor?page=${page}`)
         setList(resp.data.data.docs)
         setPosts(resp.data.data.docs)
         setTotalPosts(resp.data.data.totalPages)
    }

    useEffect(() => {
        dispatch({
            type: ActionType.UPDATE_LAYOUT,
            payload: "brand"
        })

    }, [])

 
    useEffect(() => {
        if (!loggedInBrand || loggedInBrand == "") {
            // nobody is logged in
            navigate("/brand/login");
        }
    }, [loggedInBrand])

    var paramsid = params.id
    const deleteEditor = async () => {
        setIsLoading(true)
        const resp:any = await httpDeleteWithToken(loggedInBrand, `editor/${editorid}`)
        setIsLoading(false)
        setShowDelete(false)
        toast.success("Project Deleted")
        fetchConstants()
        
    }

    const searchChanged = (value : string) => {
        if(value === "" || value === undefined || value === null) {
            setSearch_("")
            return setPosts(allList)
        };
      setSearch_(value)
      var filtered:any[] = [];
      allList.map((list : any) => {
        if(list.user){
            var fname:string = list.user.fullName;

            if((fname.toLowerCase()).includes(value.toLowerCase())) filtered = [...filtered, list];
        }
      })

      setPosts(filtered)
    
    }
    
    useDocumentTitle('BRAND Clip')
    // const a = []
    return (
        <div>
            <div className="max-w-[1445px]">
                <div className="bg-white">
                    {
                        showDelete &&
                    <ConfirmModal
                    title='Delete Project'
                    description='Are you sure you want to delete this project?'
                    close={()=> setShowDelete(false)}
                    callback={()=> {
                        deleteEditor()
                    }}
                     />
                    }

               </div>
                <ToastContainer />
                {
                    isLoading ? 
                    <Loader />
                    :
                <div className="flex">

                    <div className="mx-auto container">
                        <div className="rounded mt-5 p-3 pb-[30px] bg-white rounded-[10px] shadow">
                            <div className="mb-2"></div>
                            <div className="flex items-center justify-between">
                            <h3 className='text-blue font-bold font-primary'>Editor</h3>
                            <Link to={editor_url+"?t="+loggedInBrand} className='bg-yellow rounded-[45px] font-bold font-primary w-[50px] lg:w-[120px] text-blue h-[35px] flex items-center justify-center px-[10px] text-[14px]'> 
                            <AiOutlinePlusCircle /> &nbsp;
                             <span className='lg:flex'>Create</span> </Link >
                             </div>
                            <div className="mb-2"></div>
                                
                        </div>

                        <div className="rounded mt-5 p-3 pb-[30px] pb-5 mb-3 bg-white rounded-[10px] shadow">
                            <div className="search flex justify-end items-center mb-4">
                                <div className={`flex items-center control border border-blue pl-2 h-[45px] rounded-[45px] w-[150px]} bg-[#F0F0F4] border-graylight shadow-sm border-2`}>
                                    <FiSearch className='text-[22px] text-[#7E799F]' /> <div className="mr-1"></div>
                                    <input placeholder="Search" type="text" value={search_} onChange={(e)=>searchChanged(e.target.value)} className={`bg-transparent font-primary font-semibold ${search_ === '' ? 'font-medium text-blue opacity-50' : 'dark:text-white  text-blue'} placeholder:font-medium  border-0 outline-0 bg-none w-full px-2 h-[40px] is-dirty peer `} />
                                </div>
                            </div>
                            <div className="mb-2"></div>
                                <div className="grid lg:grid-cols-4 gap-3 gap-y-5">
                                    {
                                         posts.map( (item, i) => (
                                            <EditorCard deleteCallback={(v:string)=> {
                                                setEditorId(v);
                                                setShowDelete(true)
                                            }} loggedInBrand={loggedInBrand} item={item} key={i} />
                                        ) )
                                    }
                                </div>

                                {
                                    posts.length === 0 ?
                                    <p>No Video found </p>
                                    :''
                                }
                        </div>

                        <Pagination length={totalPosts} page={page} />

                    </div>
                </div>
                }

            </div>
        </div>
    )
}

export default VideoEditor