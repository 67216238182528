import React, { useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { BiBriefcase, BiCog, BiLogOut, BiMoney, BiMoneyWithdraw, BiUser } from "react-icons/bi";
import { BsClipboardPlus, BsGridFill, BsLungs } from "react-icons/bs";
import { FiSettings } from "react-icons/fi";
import { MdBlockFlipped, MdQueryStats } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import { useTypedSelector } from "../../hooks/useSelector";
import { sidebarItems } from "../../models/model";
import { Logo, chatFloat, sidebarBg } from '../images';
import Input from "../input";
import Button from "../button";
import instance from "../../helpers/axios";




const BrandAside = () => {
    const path = useLocation().pathname
   
    
    const items:sidebarItems[] = [
        {title : 'Dashboard', icon : <BsGridFill />, link : '/admin'},
        {title : 'Users', icon : <BiUser />, link : '/admin/manage-users'},
        {title : 'Brands', icon : <BiBriefcase />, link : '/admin/manage-brands'},
        {title : 'Content', icon : <BsClipboardPlus />, link : '/admin/manage-contents'},
        {title : 'Campaigns', icon : <MdQueryStats />, link : '/admin/campaign-management'},
        {title : 'Coupons', icon : <MdQueryStats />, link : '/admin/coupons'},
        {title : 'Reported', icon : <MdBlockFlipped />, link : '/admin/reported-contents'},
        {title : 'Subscriptions', icon : <BiMoney />, link : '/admin/subscriptions'},
        {title : 'Payments', icon : <BiMoney />, link : '/admin/payment-management'},
        {title : 'Payouts', icon : <BiMoneyWithdraw />, link : '/admin/payout-management'},
        {title : 'Others', icon : <BiMoneyWithdraw />, link : '/admin/others'},
    ]
    const { loggedInAdmin, sideBarActive } = useTypedSelector((state) => state.appReducer);
    
    
    return (
        <aside className={`font-primary ${sideBarActive ? 'fixed z-[51211] left-0 top-0 h-[100vh]' : 'hidden'} lg:flex lg:relative shadow-lg lg:min-h-[90vh] bg-white w-[85%] lg:p-3 lg:pt-[15px] lg:w-[190px] `}>
         
          <div className="lg:hidden">
            <Link to="/brand/dashboard" className="brand flex justify-center items-center bg-yellow w-full rounded-t-[10px] h-[93px]"> 
                <img src={Logo} className="object-contain h-[83px] lg:h-[79px]" alt="" />
            </Link>
          </div>

          <div className="p-3 lg:p-0 pt-[15px] lg:pt-0 h-[60%] min-h-[350px] overflow-y-auto" >
              {
                items.map((item, i)=> {
                    const isActive = item.link === path;
                    return <Link to={item.link} key={i} className={`w-full flex items-center justify-start my-[15px] p-2 ${isActive ? 'bg-blue text-yellow rounded ' : 'text-blue opacity-75 hover:opacity-100'}`}>
                                <div className="text-[20px]">
                                    {item.icon}
                                </div>
                                <div className="ml-1 text-[13px]">
                                    {item.title}
                                </div>
                            </Link>
                })
              }
          </div>
          <div className="absolute left-0 bottom-0 w-full">
            <div className="p-3 lg:p-0 pt-[15px] lg:pt-0" >
              <Link to={"/admin/settings"} className={`w-full flex items-center justify-start my-[5px] p-2 ${path === "/admin/settings" ? 'bg-blue text-yellow rounded ' : 'text-blue opacity-75 hover:opacity-100'}`}>
                <div className="text-[20px]"> <FiSettings /> </div> <div className="ml-1 text-[13px]"> Settings </div>
              </Link>
              <Link to={"/admin/logout"} className={`w-full flex items-center justify-start my-[5px] p-2 ${path === "/admin/logout" ? 'bg-blue text-yellow rounded ' : 'text-blue opacity-75 hover:opacity-100'}`}>
                <div className="text-[20px]"> <BiLogOut /> </div> <div className="ml-1 text-[13px]"> Logout </div>
              </Link>
            </div>
            <img src={sidebarBg} className="w-full h-[100px]" alt="" />
          </div>
          
      </aside>
    );
}

export default BrandAside;