import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
// import {posts} from '../../blog'
import instance from '../../helpers/axios';
import { iPost } from '../../models/post';


function Blog() {
  const [posts, setPosts] = useState<iPost[]>([])
  useEffect(() => {

    instance({
      method: "GET",
      url: `/blog/all?_limit=3`,
    }).then((response) => {
      console.log("response", response)
      var docs = response.data.data.docs;
      let p:iPost[] = docs.map((doc:any)=> {
        return {
          slug :  doc._id,
          title : doc.title,
          image : doc.blogImage,
          content : doc.content
        }
      })
      setPosts(p)
    }).catch((err) => {

    }).finally(() => {
    })
  }, [])
  return (
    posts.length > 0 ?
    <div className='my-[50px]'>
        <div className="container mx-auto">
            <div className="text-center mb-3">
              <h3 className='text-[36px] font-primarybold text-blue dark:text-white'>Blog</h3>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 ">
              {
                posts.map((post, index) => (
                  <div className='p-2 last:hidden lg:last:block mb-[20px] lg:mb-1' key={index}>
                      <Link to={`/blog/${post.slug}`}>
                        <img src={post.image} className='h-[255px] lg:h-[250px] rounded object-cover w-full' alt="blog 1" />
                      </Link>
                      <div className="mt-2">
                        <Link to={`/blog/${post.slug}`} className='flex flex-wrap'>
                            <h4 className="font-primarybold text-blue leading-tight text-[20px] dark:text-white">{post.title}
                              <span className='text-blue font-primary dark:text-white text-[14px]'>&nbsp; Read more....</span>
                              <span className='text-blue font-primary  dark:text-white text-[14px]'>&nbsp; {post.date}</span>
                            </h4>
                        </Link>
                      </div>
                  </div>
                ))
              }
            </div>
<br />
            <div className="flex justify-end">
              <Link to="/blog" className='flex justify-center h-[50px]  items-center px-5 font-bold rounded-[39px] font-primary bg-graylight text-blue'>Read More... </Link>
            </div>
        </div>
    </div>
    : <></>
  )
}

export default Blog;