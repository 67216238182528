import React, { useState, useEffect } from 'react'
import { FiSearch, FiUpload } from 'react-icons/fi'
import { VscCloseAll } from 'react-icons/vsc'
import { toast, ToastContainer } from 'react-toastify'
import { brandCategories, superAdminUsersTypes } from '../../data'
import instance from '../../helpers/axios'
import ButtonLoader from '../../helpers/button_loader'
import { useTypedSelector } from '../../hooks/useSelector'
import { iCreate } from '../../models/model'
import Button from '../button'
import CoreDropdown from '../core_dropdown'
import { appleEn, checkBox, checkBoxChecked, copy_icon1, googleEn, lastBackground, Logo, successBall } from '../images'
import Input from '../input'
import { Link } from 'react-router-dom'
import { capitalizeFirstLetter } from '../../helpers/helpers'
import { Rating, Typography } from "@material-tailwind/react";


function ClientUserForm({ close, state, challenge, brandColor }: iCreate) {
    const [loading, setLoading] = useState(true)
    const { loggedInAdmin } = useTypedSelector((state) => state.appReducer);
    const [isLoading, setIsLoading] = useState(false)
    const [email, setEmail] = useState("")
    const [lastName, setLastName] = useState("")
    const [instagramID, setInstagramID] = useState("")
    const [content, setContent] = useState("")
    const [firstName, setFirstName] = useState("")
    const [participate, setParticipate] = useState("video")
    const [phone, setPhone] = useState("")
    const [formError, setFormError] = useState("")
    const [contentError, setContentError] = useState("")
    const [formError1, setFormError1] = useState("")
    const [rated, setRated] = React.useState(0);
    const [challengeId, setChallengeId] = useState(challenge || "")
    const [file, setFile] = useState<any>(null)
    const [checked, setChecked] = useState(false)
    const maxlength = 175;
    const [step, setStep] = useState(state)
    const closeModal = () => {
        setStep("form")
        setFile(null)
        close()
    }
    const fileUploadChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormError("")
        setFile(null)
        // console.log(e.target.files )
        let file_ = e.target.files ? e.target.files[0] : null;
        if (file_) {
            // if (!file_.type.includes("video")) {
            // setFormError("Only video file is allowed")
            // } else {
            setFile(file_)
            // }
        } else {
            setFile(null)
        }
    }
    const addMember = (stp = "upload") => {
        // return setStep("last")
        setFormError("")
        if (email == "" || phone == "" || firstName == "" || lastName == "") return setFormError("All fields are required");
        if (stp == "form") {
            return setStep("upload")
        }

        if (participate == "video" || participate == "picture") {
            if (!file) {
                setStep("upload")
                return setFormError(participate == "video" ? "Please upload your video" : "Please upload your image")
            }
        } else {
            if (content == "") {
                setStep("upload")

                return setFormError("Post content cannot be empty")
            }
        }


        if (!checked) return setFormError1("You must grant the permission before you proceed.")

        setIsLoading(true)
        const formdata = new FormData();
        formdata.append("email", email)
        formdata.append("phoneNumber", phone)
        formdata.append("firstName", firstName)
        formdata.append("lastName", lastName)
        formdata.append("challenge", challengeId)
        formdata.append("instagramID", instagramID)
        console.log(instagramID)
        formdata.append("rating", rated.toString())
        if (participate == "video" || participate == "picture") formdata.append("file", file)
        formdata.append("onboardUser", "true")
        const contentType = participate.replace("picture", "image")
        formdata.append("contentType", contentType)
        if (participate == "text" || participate == "picture") formdata.append("contentText", content)

        instance({
            method: "POST",
            url: "/challenge-post/external/create/multi",
            data: formdata
        }).then((response) => {
            // console.log(response)
            toast.success("Your upload has been successfully submitted!");
            setFile("");
            setStep("last")
        }).catch((e) => {
            toast.error(e.response.data.message);
        }).finally(() => {
            setIsLoading(false)
        })
    }
    const nextStep = () => {
        // return setStep("last")
        if (participate == "video" || participate == "picture") {
            if (file) return addMember()
        }
        if (participate == "text") return addMember();
        setFile(null)
        setFormError(participate == "video" ? "Please upload your video" : "Please upload your picture")
    }
    useEffect(() => {
        setStep(step)
    }, [state])

    const contentChange = (value: string) => {
        if (value.length < maxlength) {
            setContent(value)
        } else {
            setContentError("Maximum length reached")
        }
    }

    const dropDownChanged = (value: string) => {
        setParticipate(value)
        setContent("")
        setContentError("")
        setFile(null)
        setFormError("")
        setFormError1("")
    }


    const Step1 = () => {

    }
    const Step3 = () => {
        return <div className='bg-white shadow-lg rounded-[15px]  w-[95%] lg:w-[670px]  relative'>
            <div
                className='w-full p-3 rounded-[15px] rainbow_background h-full'
            >
                <div className='max-w-[316px] text-center flex flex-col items-center mx-auto'>
                    <img src={successBall} className='h-[120px] object-contain' />
                    <div className="mb-3"></div>
                    <p
                        style={{
                            color: brandColor
                        }} >
                        <i>Your upload has been successfully submitted</i> </p>
                    <div className="mb-3"></div>


                    <div className='p-2 text-white' style={{
                        background: brandColor
                    }}>
                        <p>
                            <i>
                                We have sent you a password reset link to your email {email}. Download Beentos APP to stay updated!.
                            </i>
                        </p>
                    </div>
                    <div className="mb-3"></div>


                    <p><i>Download now on</i></p>
                    <div className="mb-3"></div>
                    <a href="https://play.google.com/store/apps/details?id=com.beentosapp">
                        <img src={googleEn} />
                    </a>
                    <div className="mb-3"></div>


                    <p><i>or</i></p>
                    <div className="mb-3"></div>
                    <a href="https://apps.apple.com/app/beentos/id6450564973">

                        <img src={appleEn} />
                    </a>
                    <div className="mb-3"></div>

                    <Button style={{
                        borderColor: brandColor
                    }} onclick={closeModal} containerClass=' rounded-[20x] min-w-[150px]' text='Go Home' />
                </div>
            </div>
        </div>
    }

    return (
        <div>
            <ToastContainer />
            <div className='fixed top-0 left-0 w-full border-2 flex items-center justify-center z-[999] h-[100vh]'>
                <div className=" w-full flex justify-center">

                    <div onClick={() => closeModal()} className='back_drop backdrop-blur fixed top-0 left-0 w-full bg-blue/30 h-[100vh] bg-blue'></div>
                    {
                        step == "form"
                            ?
                            <div className='bg-white shadow-lg rounded-[15px] w-[95%] lg:w-[670px] p-3 relative'>
                                <h3 style={{
                                    color: brandColor
                                }} className='text-[20px]'>
                                    Join Campaign</h3>
                                <p className='text-[#1E2124] mt-2'>Step 1/2 - Fill form </p>
                                <div className="h-[1px] my-3 bg-[#CDE1FF] w-full"></div>

                                <div className=' rounded-[8px] w-full flex items-center flex-col py-1'>
                                    <div className="grid lg:grid-cols-2 grid-cols-2 gap-4 w-full">
                                        <div className='w-full'>
                                            <label>Firstname *</label>
                                            <Input className='h-[20px] py-1' placeholder='E.g. Sam' value={firstName} setValue={setFirstName} />
                                        </div>
                                        <div className='w-full'>
                                            <label>Lastname *</label>
                                            <Input placeholder='E.g. Shawn' value={lastName} setValue={setLastName} />
                                        </div>

                                        <div className='w-full'>
                                            <label>Email *</label>
                                            <Input type='email' placeholder='E.g. someone@example.com' value={email} setValue={setEmail} />
                                        </div>

                                        <div className='w-full'>
                                            <label>Phone *</label>
                                            <Input placeholder='E.g. +16475555678' value={phone} setValue={setPhone} />
                                        </div>
                                        <div className='w-full'>
                                            <label>Instagram handle [optional]</label>
                                            <Input placeholder='E.g. beentos_official' value={instagramID} setValue={setInstagramID} />
                                        </div>


                                    </div>


                                </div>

                                <div className="h-[1px] my-4 bg-[#CDE1FF] w-full"></div>
                                <div className="px-3">
                                    {
                                        formError !== "" && <p className='text-[12px] m-0 p-0 text-center text-red-700'>{formError}</p>
                                    }
                                    <div className="mt-2 flex flex-col lg:flex-row items-center justify-between">
                                        <div className='flex flex-col justify-center lg:items-center lg:flex-row'>
                                            <Button style={{
                                                background: brandColor
                                            }} onclick={() => addMember("form")} loading={isLoading} text='Proceed' containerClass=' text-white border-0 w-[150px] lg:w-[fit-content] px-4' h='40px' />
                                            {
                                                isLoading && <p className='text-success'>Please wait, this may take a while...</p>
                                            }
                                        </div>
                                        <Button text='Back' onclick={() => closeModal()} containerClass='border-blue border-1 w-[120px]' h='40px' />
                                    </div>
                                </div>
                            </div>
                            :
                            <>
                                {
                                    step == "last"
                                        ?
                                        <Step3 />
                                        :
                                        <div className='max-h-[90vh] overflow-auto bg-white shadow-lg rounded-[15px] w-[95%] lg:w-[670px] p-3 relative'>
                                            <h3 style={{
                                                color: brandColor
                                            }} className=' text-[20px]'>
                                                Join Challenge</h3>
                                            <p className='text-[#1E2124] mt-2'>Step 2/2 - Upload Content </p>
                                            <br />
                                            <div>
                                                <div className='mt-[12px]'>
                                                    <label>
                                                        <strong>How do you want to participate?</strong>
                                                    </label>
                                                </div>
                                                <CoreDropdown
                                                    source="challenge"
                                                    showError={false}
                                                    err={""}
                                                    items={[
                                                        { value: "video" }, { value: "picture" }, { value: "text" }
                                                    ]}
                                                    containerClass="control justify-between border border-blue pl-3 h-[50px] rounded-[45px] w-full mt-[7px] bg-[#F0F0F4]"
                                                    placeholder="Select Campaign Type"
                                                    type="text"
                                                    value={participate}
                                                    setValue={(v: string) => dropDownChanged(v)}
                                                />
                                            </div>
                                            <br />
                                            <div>
                                                <label>
                                                    <strong>{participate == "text" ? `Content ${content.length}/${maxlength}` : "Upload Content"} </strong>
                                                </label>
                                            </div>
                                            {
                                                participate == "text" ?
                                                    <>
                                                        <Input textareaH='100px' type='textarea' value={content} setValue={contentChange} placeholder='content' />
                                                        <small className='text-red-700'>{contentError}</small>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="h-[1px] my-3 bg-[#CDE1FF] w-full"></div>

                                                        <div className=' rounded-[8px] w-full flex items-center py-1'>
                                                            <input accept={participate == "video" ? 'video/*' : 'image/*'} onChange={fileUploadChange} type="file" hidden name="uploadVideo" id="uploadFile" />
                                                            <label htmlFor='uploadFile' className='flex w-full h-[100px] justify-center items-center flex-col text-center'>
                                                                <FiUpload style={{ fontSize: "31px", color: participate == "video" ? "#7E799F" : "#000" }} />
                                                                {
                                                                    file ? <>
                                                                        <p
                                                                            style={{
                                                                                color: brandColor
                                                                            }} className='text-[15px] '>{capitalizeFirstLetter(participate)} uploaded, click to change the {participate}</p>
                                                                        <p className='text-[15px] text-success'>{file?.name}</p>

                                                                    </>
                                                                        :
                                                                        <>
                                                                            <p className='text-[15px] text-[#7E799F]'>Click to {participate == "picture" ? "Upload picture" : "Upload Video"}</p>
                                                                            {
                                                                                formError !== "" && <p className='text-[12px] text-red-700'>{formError}</p>
                                                                            }
                                                                            {
                                                                                file && <>
                                                                                </>
                                                                            }
                                                                        </>
                                                                }

                                                            </label>
                                                        </div>

                                                        <div className="h-[1px] my-4 bg-[#CDE1FF] w-full"></div>
                                                    </>
                                            }

                                            {
                                                participate == "picture" &&
                                                <>
                                                    <div>
                                                        <label>
                                                            <strong> Say something about your picture &nbsp; <small className='text-red-700'>[optional]</small> </strong>
                                                        </label>
                                                    </div>
                                                    <Input textareaH='50px' type='textarea' value={content} setValue={contentChange} placeholder='Description' />
                                                    <small className='text-red-700'>{contentError}</small>
                                                </>
                                            }
                                            <div className=" text-center gap-2 font-bold text-blue-gray-500">
                                                <label>Please leave a Rating for us</label>
                                                <div className='flex justify-center  flex-col gap-1 items-center'>

                                                    <Rating  className='flex gap-1' value={0} onChange={(value) => setRated(value)} />
                                                    <Typography color="blue-gray" className="font-medium text-blue-gray-500">
                                                        &nbsp;  {rated > 0 && "(You rated us " + rated + ")"}
                                                    </Typography>
                                                </div>

                                            </div>


                                            <div className="flex w-full justify-start gap-2  p-1 mt-[12px] text-[10px] lg:text-[14px]">
                                                <label htmlFor="remember" className='flex items-center'>
                                                    {
                                                        !checked ?
                                                            <div onClick={() => {
                                                                setChecked(true)
                                                            }} className='border-2 cursor-[pointer] border-blue h-[18px] w-[18px]'></div>
                                                            :
                                                            <div onClick={() => {
                                                                setChecked(false)
                                                            }} className='border-2 cursor-[pointer] flex rounded-[100%] items-center justify-center border-blue  h-[18px] w-[18px] checkedBoss'>
                                                                {/* <div className='bg-blue  h-[8px] w-[8px] checkedBoss'>
                                                       </div>  */}
                                                                <span className='font-bold'> &#10003; </span>
                                                            </div>
                                                    }
                                                </label> &nbsp;
                                                <p>
                                                    By checking this box, you grant Beentos and the sponsor company, along with its affiliates, permission to use your content for marketing and promotion purposes. Additionally, you agree to abide by the terms and conditions, as well as the privacy policy outlined by Beentos. <a href='/terms' className='text-blue' target='_blank'>Read more</a>
                                                </p>
                                            </div>
                                            <div className="px-3">
                                                {
                                                    formError1 !== "" && <p className='text-[12px] m-0 p-0 text-center text-red-700'>{formError1}</p>
                                                }
                                                <div className="mt-5 flex justify-between">
                                                    <Button
                                                        style={{
                                                            background: brandColor
                                                        }} onclick={() => nextStep()} loading={isLoading} text='Submit' containerClass='text-white border-0 w-[150px] lg:w-[fit-content] px-4' h='40px' />

                                                    <Button text='Back' onclick={() => setStep("form")} containerClass='border-blue border-1 w-[120px]' h='40px' />
                                                </div>
                                            </div>
                                        </div>
                                }
                            </>
                    }
                </div>
            </div>
        </div>
    )
}

export default ClientUserForm