import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useDocumentTitle from '../../helpers/pageTitle';
import { useTypedSelector } from '../../hooks/useSelector';
import { ActionType } from '../../store/actions';
import instance from '../../helpers/axios';
import { toast, ToastContainer } from 'react-toastify';
import ScrollToTop from '../../helpers/scrollToTop';
import Loader from '../../helpers/loader';
import Button from '../../components/button';
import { checkBox, checkBoxChecked, checked, exporticon, notChecked, who } from '../../components/images';
import { graph1, graph2, trendup } from '../../components/super_images';
import { CChart } from '@coreui/react-chartjs';
import { BsCaretDownSquare, BsCircleFill } from 'react-icons/bs';
import { BiCaretDown, BiDotsVertical } from 'react-icons/bi';
import { AiOutlineCheckSquare, AiOutlineEye, AiOutlinePlusCircle } from 'react-icons/ai';
import { formatDate } from '../../helpers/helpers';
import { CoreDropdown } from '../../components';
import { resultSort } from '../../data';
import { FiSearch } from 'react-icons/fi';
// import { ShareModal } from '../../components/brand';

const ManageNotification: React.FC = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { search } = useLocation()
    const [user, setUser] = useState('')
    const [selected, setSelected] = useState<number[]>([])
    const { acctSideBarActive } = useTypedSelector((state) => state.appReducer);
    const [isLoading, setIsLoading] = useState(false)
    const [c, setC] = useState([])
    const [search_, setSearch_] = useState("");
    const [allList, setAllList] = useState<any[]>([])
    useEffect(() => {
        dispatch({
            type: ActionType.UPDATE_LAYOUT,
            payload: "admin"
        })

    }, [])
    const chartlegends = [
        { title: "Running", className: "text-[#0B0149]" },
        { title: "Not Running", className: "text-[#0B01491F]" },
    ]

    const addSelected = (value: number) => {
        var selected_ = selected;
        if (selected_.includes(value)) {
            selected_.splice(selected_.indexOf(value), 1)
        } else {
            selected_.push(value)
        }
        setSelected(selected_);
        setTimeout(() => {
            setAllList(c)
        }, 500);
    }
    const [sort, setSort] = useState("All")
    const [page, setPage] = useState(1)
    function dropChanged(value: any) {
        setPage(1)
        setSort(value)
    }
    const searchChanged = (value: string) => {
        if (value === "" || value === undefined || value === null) {
            setSearch_("")
            return setAllList(c)
        };
        console.log("value", value)
        setSearch_(value)
        var filtered: any[] = [];
        allList.map((list: any) => {
            if (list.user) {
                var fname: string = list.user.fullName;

                if ((fname.toLowerCase()).includes(value.toLowerCase())) filtered = [...filtered, list];
            }
        })

        setAllList(filtered)

    }
    useDocumentTitle('ADMIN - Notification Management')
    // const a = []
    return (
        <div>
            <div className="max-w-[1445px] font-primary">
                <ToastContainer />
                {/* wrapper */}
                {
                    isLoading ? <Loader /> :
                        <div className='container font-primary min-h-[95vh] flex-inital lg:px-[15px] px-[5px] py-[25px] w-full'>

                            <div className="mb-[10px]">
                                <h6 className="text-blue mb-[5px] font-primarybold text-[18px]">Manage Notification</h6>
                                <p className="text-blue opacity-75">Choose which notification you would like users to recieve</p>
                            </div>



                            <div className="my-[25px]p-[15px]">
                                <div className="flex relative justify-between border-b-2 pb-[5px] border-[#0B01491F] mt-5">
                                    <div className=' mb-[5px] w-[100px] lg:w-[auto]'>
                                        <p className='text-gray2 text-[14px]'></p>
                                    </div>
                                    <div className='lg:w-[340px] w-full grid grid-cols-2 '>
                                        <p className='text-blue font-bold text-[12px] lg:text-[14px] text-center'>Brands</p>
                                        <p className='text-blue font-bold text-[12px] lg:text-[14px] text-center'>Users</p>
                                    </div>
                                </div>
                            </div>

                            {/* ====================================================================== */}

                            <div className="my-[25px]">
                                <div className="bg-blue rounded p-[10px]">
                                    <div className='text-yellow'>
                                        <h3 className='text-[14px] m-0 font-500'>Campaign Notifications</h3>
                                    </div>
                                </div>
                                <div className="pl-3">
                                    <div className="flex justify-between mt-3">
                                        <div className='w-[100px] lg:w-[auto]'>

                                            <p className='text-gray2 text-[14px]'>New Campaign</p>
                                        </div>
                                        <div className='lg:w-[340px] w-full grid grid-cols-2 '>
                                            <p className='text-blue flex justify-center font-bold text-[14px]'>
                                                <img className='h-[22px]' src={notChecked} style={{ cursor: 'pointer' }} alt="" />
                                            </p>
                                            <p className='text-blue flex justify-center font-bold text-[14px]'>
                                                <img className='h-[22px]' src={checked} style={{ cursor: 'pointer' }} alt="" />
                                            </p>
                                        </div>
                                    </div>


                                    <div className="flex justify-between mt-3">
                                        <div className='w-[100px] lg:w-[auto]'>

                                            <p className='text-gray2 text-[14px]'>Comment on Campaign</p>
                                        </div>
                                        <div className='lg:w-[340px] w-full grid grid-cols-2 '>
                                            <p className='text-blue flex justify-center font-bold text-[14px]'>
                                                <img className='h-[22px]' src={notChecked} style={{ cursor: 'pointer' }} alt="" />
                                            </p>

                                            <p className='text-blue flex justify-center font-bold text-[14px]'>
                                                <img className='h-[22px]' src={checked} style={{ cursor: 'pointer' }} alt="" />
                                            </p>
                                        </div>
                                    </div>


                                    <div className="flex justify-between mt-3">
                                        <div className='w-[100px] lg:w-[auto]'>
                                            <p className='text-gray2 text-[14px]'>Campaign follow</p>
                                        </div>
                                        <div className='lg:w-[340px] w-full grid grid-cols-2 '>
                                            <p className='text-blue flex justify-center font-bold text-[14px]'>
                                                <img className='h-[22px]' src={notChecked} style={{ cursor: 'pointer' }} alt="" />
                                            </p>

                                            <p className='text-blue flex justify-center font-bold text-[14px]'>
                                                <img className='h-[22px]' src={checked} style={{ cursor: 'pointer' }} alt="" />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* ====================================================================== */}
                            <div className="my-[25px]">
                                <div className="bg-blue rounded p-[10px]">
                                    <div className='text-yellow'>

                                        <h3 className='text-[14px] m-0 font-500'>Interactions</h3>
                                    </div>
                                </div>
                                <div className="pl-3">
                                    <div className="flex justify-between mt-3">
                                        <div className='w-[100px] lg:w-[auto]'>

                                            <p className='text-gray2 text-[14px]'>Votes</p>
                                        </div>
                                        <div className='lg:w-[340px] w-full grid grid-cols-2 '>
                                            <p className='text-blue flex justify-center font-bold text-[14px]'>
                                                <img className='h-[22px]' src={notChecked} style={{ cursor: 'pointer' }} alt="" />
                                            </p>
                                            <p className='text-blue flex justify-center font-bold text-[14px]'>
                                                <img className='h-[22px]' src={checked} style={{ cursor: 'pointer' }} alt="" />
                                            </p>
                                        </div>
                                    </div>


                                    <div className="flex justify-between mt-3">
                                        <div className='w-[100px] lg:w-[auto]'>

                                            <p className='text-gray2 text-[14px]'>Comments</p>
                                        </div>
                                        <div className='lg:w-[340px] w-full grid grid-cols-2 '>
                                            <p className='text-blue flex justify-center font-bold text-[14px]'>
                                                <img className='h-[22px]' src={notChecked} style={{ cursor: 'pointer' }} alt="" />
                                            </p>

                                            <p className='text-blue flex justify-center font-bold text-[14px]'>
                                                <img className='h-[22px]' src={checked} style={{ cursor: 'pointer' }} alt="" />
                                            </p>
                                        </div>
                                    </div>


                                    <div className="flex justify-between mt-3">
                                        <div className='w-[100px] lg:w-[auto]'>
                                            <p className='text-gray2 text-[14px]'>Mentions</p>
                                        </div>
                                        <div className='lg:w-[340px] w-full grid grid-cols-2 '>
                                            <p className='text-blue flex justify-center font-bold text-[14px]'>
                                                <img className='h-[22px]' src={notChecked} style={{ cursor: 'pointer' }} alt="" />
                                            </p>

                                            <p className='text-blue flex justify-center font-bold text-[14px]'>
                                                <img className='h-[22px]' src={checked} style={{ cursor: 'pointer' }} alt="" />
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex justify-between mt-3">
                                        <div className='w-[100px] lg:w-[auto]'>
                                            <p className='text-gray2 text-[14px]'>New follow</p>
                                        </div>
                                        <div className='lg:w-[340px] w-full grid grid-cols-2 '>
                                            <p className='text-blue flex justify-center font-bold text-[14px]'>
                                                <img className='h-[22px]' src={notChecked} style={{ cursor: 'pointer' }} alt="" />
                                            </p>

                                            <p className='text-blue flex justify-center font-bold text-[14px]'>
                                                <img className='h-[22px]' src={checked} style={{ cursor: 'pointer' }} alt="" />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* ====================================================================== */}
                            <div className="my-[25px]">
                                <div className="bg-blue rounded p-[10px]">
                                    <div className='text-yellow'>

                                        <h3 className='text-[14px] m-0 font-500'>Suggessions</h3>
                                    </div>
                                </div>
                                <div className="pl-3">
                                    <div className="flex justify-between mt-3">
                                        <div className='w-[100px] lg:w-[auto]'>

                                            <p className='text-gray2 text-[14px]'>Suggest Campaign</p>
                                        </div>
                                        <div className='lg:w-[340px] w-full grid grid-cols-2 '>
                                            <p className='text-blue flex justify-center font-bold text-[14px]'>
                                                <img className='h-[22px]' src={notChecked} style={{ cursor: 'pointer' }} alt="" />
                                            </p>
                                            <p className='text-blue flex justify-center font-bold text-[14px]'>
                                                <img className='h-[22px]' src={checked} style={{ cursor: 'pointer' }} alt="" />
                                            </p>
                                        </div>
                                    </div>


                                    <div className="flex justify-between mt-3">
                                        <div className='w-[100px] lg:w-[auto]'>

                                            <p className='text-gray2 text-[14px]'>Campaign from people you follow</p>
                                        </div>
                                        <div className='lg:w-[340px] w-full grid grid-cols-2 '>
                                            <p className='text-blue flex justify-center font-bold text-[14px]'>
                                                <img className='h-[22px]' src={notChecked} style={{ cursor: 'pointer' }} alt="" />
                                            </p>

                                            <p className='text-blue flex justify-center font-bold text-[14px]'>
                                                <img className='h-[22px]' src={checked} style={{ cursor: 'pointer' }} alt="" />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="my-[25px]">
                                <div className="bg-blue rounded p-[10px]">
                                    <div className='text-yellow'>

                                        <h3 className='text-[14px] m-0 font-500'>Others</h3>
                                    </div>
                                </div>
                                <div className="pl-3">
                                    <div className="flex justify-between mt-3">
                                        <div className='w-[100px] lg:w-[auto]'>

                                            <p className='text-gray2 text-[14px]'>Email Notifications</p>
                                        </div>
                                        <div className='lg:w-[340px] w-full grid grid-cols-2 '>
                                            <p className='text-blue flex justify-center font-bold text-[14px]'>
                                                <img className='h-[22px]' src={notChecked} style={{ cursor: 'pointer' }} alt="" />
                                            </p>
                                            <p className='text-blue flex justify-center font-bold text-[14px]'>
                                                <img className='h-[22px]' src={checked} style={{ cursor: 'pointer' }} alt="" />
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex justify-between mt-3">
                                        <div className='w-[100px] lg:w-[auto]'>

                                            <p className='text-gray2 text-[14px]'>Push Notifications</p>
                                        </div>
                                        <div className='lg:w-[340px] w-full grid grid-cols-2 '>
                                            <p className='text-blue flex justify-center font-bold text-[14px]'>
                                                <img className='h-[22px]' src={notChecked} style={{ cursor: 'pointer' }} alt="" />
                                            </p>

                                            <p className='text-blue flex justify-center font-bold text-[14px]'>
                                                <img className='h-[22px]' src={checked} style={{ cursor: 'pointer' }} alt="" />
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex justify-between mt-3">
                                        <div className='w-[100px] lg:w-[auto]'>

                                            <p className='text-gray2 text-[14px]'>Reminders</p>
                                        </div>
                                        <div className='lg:w-[340px] w-full grid grid-cols-2 '>
                                            <p className='text-blue flex justify-center font-bold text-[14px]'>
                                                <img className='h-[22px]' src={notChecked} style={{ cursor: 'pointer' }} alt="" />
                                            </p>

                                            <p className='text-blue flex justify-center font-bold text-[14px]'>
                                                <img className='h-[22px]' src={checked} style={{ cursor: 'pointer' }} alt="" />
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex justify-between mt-3">
                                        <div className='w-[100px] lg:w-[auto]'>

                                            <p className='text-gray2 text-[14px]'>In-app Notifications</p>
                                        </div>
                                        <div className='lg:w-[340px] w-full grid grid-cols-2 '>
                                            <p className='text-blue flex justify-center font-bold text-[14px]'>
                                                <img className='h-[22px]' src={notChecked} style={{ cursor: 'pointer' }} alt="" />
                                            </p>

                                            <p className='text-blue flex justify-center font-bold text-[14px]'>
                                                <img className='h-[22px]' src={checked} style={{ cursor: 'pointer' }} alt="" />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                }

            </div>
        </div>
    )
}

export default ManageNotification