import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { SettingsAside, SettingsHeader } from '../../../components/brand';
import Button from '../../../components/button';
import { checked, notChecked } from '../../../components/images';
import InputB from '../../../components/input_b';
import instance from '../../../helpers/axios';
import useDocumentTitle from '../../../helpers/pageTitle';
import { useTypedSelector } from '../../../hooks/useSelector';
import { ActionType } from '../../../store/actions';


const Security: React.FC = () => {
    const { loggedInBrand } = useTypedSelector((state) => state.appReducer);
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [current, setCurrent] = useState('123456')
    const [newpassword, setNewpassword] = useState('')
    const [currentType, setCurrentType] = useState('password')
    const [newType, setnewType] = useState('password')
    const [loading, setLoading] = useState(false)
    const [isloading, setIsloading] = useState(true)
    const [brand, setBrand] = useState({})
    const [tweoFactor, setTweoFactor] = useState(false)
    useEffect(() => {
        dispatch({
            type: ActionType.UPDATE_LAYOUT,
            payload: "brand"
        })
    }, [])

    const headers = {
        'Authorization': `Bearer ${loggedInBrand}`,
    }

    useDocumentTitle('SECURITY SETTINGS')
    const toggleEye = (type : string) => {
        if(type == 'newpassword'){
            setnewType(newType === "password" ? "text" : 'password')
        }else{
            setCurrentType(currentType === "password" ? "text" : 'password')
        }
    }

     // get brand profile
     useEffect(() => {
        instance({
            method: "GET",
            url: "/brand/profile",
            headers: headers
        }).then((response) => {
            console.log(response)
            const c = response.data.data.challenges;
            const b = response.data.data.brand;
           setBrand(b)
           setTweoFactor(b.brandSecuritySettings.twoFactorAuthentication)
           console.log(b.brandSecuritySettings.twoFactorAuthentication)
        }).catch((err) => {
            if (err.code == "ERR_NETWORK") {
                alert("No internet connection")
            }
            else if (err.response.data.statusCode === 403) {
                navigate('/brand/login');
                return false;
            }
            toast.error(err.response.data.message)
            console.log(err)
        })
    }, [])

    const updatePassword=()=>{
        
    }

    const updateSecurity = (value : boolean) => {
        let data;
       
        data = {
            twoFactorAuthentication : value
        }
        setTweoFactor(value)

        instance({
            method: "POST",
            url: "/brand-settings/security/update",
            headers: headers,
            data : data,
        }).then((response) => {
            console.log(response)
            toast.success("Settings updated")
        }).catch((err) => {

            if (err.code == "ERR_NETWORK") {
                alert("No internet connection")
            }
            else if (err.response.data.statusCode === 403) {
                navigate('/brand/login');
                return false;
            }
            toast.error(err.response.data.message)
            console.log(err)
        }).finally(()=> {
            // setLoading(false)
        })
    }
    return (
        <div className='w-full'>
            <div className="px-[15px] h-100 relative font-primary flex max-w-[1440]">
                <SettingsAside />
                <ToastContainer />
                <div className='lg:container sm:mx-auto md:mx-auto flex-inital lg:px-[15px] px-[5px] py-[25px] w-full'>
                    <SettingsHeader title='Security' />
                    <div className="my-[25px] bg-blue rounded-[10px] shadow-lg p-[15px]">
                        <div className='text-white'>
                            <p>Include additional security and put on other security like the two factor verification to keep your account and data secure. To do this, you must first confirm your email. </p>
                        </div>
                        <div>
                        </div>
                    </div>

                    <div className="my-[25px] bg-white rounded-[10px] shadow-lg p-[15px]">
                        <h3 className='mb-1 text-[20px] font-500 text-blue font-primary'>Login Activity</h3>
                        <div className="flex justify-between">
                            <div className=''>
                                <p className='text-blue opacity-80'>Keep track of everywhere you've logged in with your Beentos account and remove access from devices you're no longer using with Beentos.</p>
                            </div>
                        </div>
                    </div>

                    <div className="my-[25px] bg-white rounded-[10px] shadow-lg p-[15px]">
                        <h3 className='mb-1 text-[20px] font-500 text-blue font-primary'>Password</h3>
                        <p className='text-blue opacity-80'>Enter your current password to change your password.</p>
                        <div className='my-2'>
                            <InputB passwordType='current' placeholder='Current Password' value={current} toggleEye={toggleEye} setValue={setCurrent} type={currentType} containerClass="shadow-sm border-graylight border-2" isText={currentType == 'text' ? true : false} />
                        </div>

                        <div className='my-2'>
                            <InputB passwordType='newpassword' placeholder='New Password' value={newpassword} toggleEye={toggleEye} setValue={setNewpassword} type={newType} containerClass="shadow-sm border-graylight border-2" isText={newType == 'text' ? true : false} />
                        </div>

                        <div className="my-2 flex justify-end">
                            <Button loading={loading} onclick={() => updatePassword()} containerClass='bg-blue w-[200px] text-yellow' text='Update Password' />
                        </div>
                    </div>

                    <div className="my-[25px] bg-white rounded-[10px] shadow-lg p-[15px]">
                        <h3 className='mb-1 text-[20px] font-500 text-blue font-primary'>Two-Factor Authentication</h3>
                        <p className='text-blue opacity-80'>To keep hackers away from accessing your data, turn on two-factor authentication to secure your account and get alerted when there is any suspicious login attempt on your account.</p>
                        <div className="flex justify-between my-3">
                            <div className=''>
                                <p className='text-blue opacity-80'>Turn on Two-factor authentification.</p>
                            </div>
                            <div>
                                <img src={tweoFactor ? checked : notChecked} onClick={()=>updateSecurity(!tweoFactor)} className='h-[19px] object-contain' style={{ cursor: 'pointer' }} alt="" />
                            </div>
                        </div>
                       
                    </div>

                    
                    <div className="my-[25px] bg-white rounded-[10px] shadow-lg p-[15px]">
                        <h3 className='mb-1 text-[20px] font-500 text-blue font-primary'>Saved Login Info</h3>
                        <p className='text-blue opacity-80 max-w-[620px]'>This is a list of devices that have logged into your account. Revoke access to any device you don't recognize.</p>
                        <div className="flex justify-between my-3">
                            <div className=''>
                                <p className='text-blue opacity-80'>
                                Last accessed: <br />
                                February 26, 2023, 6:08 PM <br />
                                Location: <br />
                                Lagos State, Nigeria (Approximate, based on IP = 105.113.19.163) <br />
                                Device type: <br />
                                Chrome on Mac OS X 10.15.7 <br />
                                </p>
                            </div>
                            <div>
                                <img src={checked} className='h-[19px] object-contain' style={{ cursor: 'pointer' }} alt="" />
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Security;