import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import useDocumentTitle from '../../../helpers/pageTitle';
import { useTypedSelector } from '../../../hooks/useSelector';
import { ActionType } from '../../../store/actions';
import instance from '../../../helpers/axios';
import { toast, ToastContainer } from 'react-toastify';
import Loader from '../../../helpers/loader';
import "../../../helpers/slick.css";
import { ClipCard } from '../../../components/brand';
import Input from '../../../components/input';
import { FiSearch } from 'react-icons/fi';
import Pagination from '../../../components/pagination';
import { CCarousel, CCarouselItem } from '@coreui/react';
import video from '../../../assets/img/1.mkv'
import { CoreDropdown } from '../../../components';
import { challengePostType, resultSort } from '../../../data';
import Button from '../../../components/button';
import QRCode from "react-qr-code";
import { slugify } from '../../../helpers/helpers';
import html2canvas from 'html2canvas';
import { Qr } from '../../../components/QrContainer';
import ClipCaard2 from '../../../components/brand/clip_card2';

const Clip: React.FC = () => {
    const { loggedInBrand, loggedInAdmin } = useTypedSelector((state) => state.appReducer);
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { search } = useLocation()
    const isWinners = window.location.href.includes('winners')
    const [brand, setBrand] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [search1, setSearch] = useState('')
    const [search_, setSearch_] = useState('')
    const [sort, setSort] = useState('All')
    const [allList, setList] = useState([])
    const [page, setPage] = useState(1)
    const [offset, setOffset] = useState(0)
    const [challenge, setChallenge] = useState<any>()
    const [limit, setLimit] = useState(10)
    const params = useParams()
    const [posts, setPosts] = useState<any[]>([])
    const [pinned, setPinned] = useState([])
    const [loadPage, setLoadPage] = useState(false)
    const [qrCode, setQrCode] = useState(null)
    const [brandName, setBrandName] = useState("")
    const [selectWinners, setSelectWinners] = useState(false)
    const [selectedWinners, setSelectedWinners] = useState<any[]>([])
    const [winners, setAllWinners] = useState<any[]>([])
    const [totalPosts, setTotalPosts] = useState(0);
    const [type, setType] = useState("Video");
    var isAdmin = window.location.href.includes("admin")
    var paramsid = params.id

    const headers = {
        'Authorization': `Bearer ${isAdmin ? loggedInAdmin : loggedInBrand}`
    }
    const postTypes:any = [
        "Video",
        "Picture",
        "Text"
    ]
    useEffect(() => {
        const search_ = (search.replace('?', '')).split("&")
        let pageQuery;
        if (search_.length > 0) {
            pageQuery = search_.find((e) => /page=/i.test(e));
        }
        let offset = pageQuery ? Number(pageQuery.trim().replace(/page=/i, "")) : 0;
        offset = offset === 0 ? 1 : offset;
        setOffset(offset);
        // window.alert("here")
        if (!isWinners) fetchConstant(offset);


        instance({
            method: "GET",
            url: `/challenge/all?_id=${paramsid}`,
        }).then((response) => {
            var docs = response.data.data.docs[0]
            if (docs) {
                const { challengeCode } = docs;
                if (challengeCode) {
                    setBrandName(docs.brand.brandName)
                    setQrCode(challengeCode)
                }
            }
        }).catch((e) => {
        })
    }, [search, window.location.href, type])

    useEffect(() => {
        dispatch({
            type: ActionType.UPDATE_LAYOUT,
            payload: isAdmin ? "admin" : "brand"
        })
        allWinners()
    }, [window.location.href])

    const fetchConstant = async (offset: number) => {
        var t = type.toLowerCase();
        t = t == "picture" ? "image" : t
      
        instance({
            method: "GET",
            url: `/challenge-post/all?challenge=${paramsid}&_page=${offset}&_limit=${limit}&_ordderBy=createdAt&_order=DESC`,
            headers: headers
        }).then((response) => {
            setPosts(response.data.data.docs);
            setList(response.data.data.docs);
            setTotalPosts(response.data.data.totalDocs)
            setPage(offset)
            setIsLoading(false);
        }).catch((err) => {
            console.log(err.message)
        }).finally(() => {
            setIsLoading(false);
        })
    }
    var settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2
    };

    useEffect(() => {
        if (isAdmin) {
            if (!loggedInAdmin || loggedInAdmin == "") {
                // nobody is logged in
                navigate("/admin/login");
            }
        } else {
            if (!loggedInBrand || loggedInBrand == "") {
                // nobody is logged in
                navigate("/brand/login");
            }
        }
    }, [loggedInBrand, loggedInAdmin])

    const submitWinners = async () => {
        if (selectedWinners.length == 0) {
            return toast.error("Please select at least one winner")
        }
        instance({
            method: "PUT",
            url: `/challenge-post/update-winning-status`,
            headers: headers,
            data: {
                challengePostIds: selectedWinners,
                wonByBrand: true
            }
        }).then((response) => {
            console.log("response", response)
            return toast.success("Winners updated successfully")

        }).catch((err) => {
            console.log(err.message)
        }).finally(() => {
            setIsLoading(false);
        })
    }
    const allWinners = async () => {

        instance({
            method: "GET",
            url: `/challenge-post/all?challenge=${paramsid}&wonByBrand=true&limit=1000`,
            headers: headers,
        }).then((response) => {
            let c: any[] = response.data.data.docs;
            if (isWinners) {
                setPosts(c);
                setList(response.data.data.docs);
                setTotalPosts(response.data.data.totalDocs)
                setPage(offset)
                setIsLoading(false);
            } else {
            }
            c = c.map((b) => {
                return b._id
            })

            setAllWinners(c)
        }).catch((err) => {
        }).finally(() => {
            setIsLoading(false);
        })
    }
    // GET /api/challenge-post/all?challenge=challenge.id&wonByBrand=true

    const searchChanged = (value: string) => {
        if (value === "" || value === undefined || value === null) {
            setSearch_("")
            return setPosts(allList)
        };
        console.log("value", value)
        setSearch_(value)
        var filtered: any[] = [];
        allList.map((list: any) => {
            if (list.user) {
                var fname: string = list.user.fullName;

                if ((fname.toLowerCase()).includes(value.toLowerCase())) filtered = [...filtered, list];
            }
        })

        setPosts(filtered)

    }

    useDocumentTitle('BRAND Clip')
    // const a = []
    return (
        <div>
            <div className="max-w-[1445px]">
                <div className="bg-white">
                    {/* {challengeCode && <div>
                <Hr />
                <small>

                <label><strong>Link:</strong> <a target='_blank' href={`/${slugify(brandName)}/${challengeCode}`}>{window.location.host.includes("localhost") ? "http://" : "https://"} {window.location.host}{`/${slugify(brandName)}/${challengeCode}`}</a></label>
                </small>
            </div>
            } */}
                </div>
                <ToastContainer />
                {
                    isLoading ?
                        <Loader />
                        :
                        <div className="flex">
                            {isLoading ? <Loader /> : ''}
                            <ToastContainer />


                            <div className="mx-auto container">
                                <br />
                                <div className='flex justify-between'>
                                    {
                                        isWinners ?
                                            <Link to={`${isAdmin ? "/admin/campaign-management/view" : "/brand/challenge/clips"}/${paramsid}`}>go back</Link>
                                            :
                                            <Link to={`${isAdmin ? "/admin/campaign-management/" : "/brand/dashboard"}`}>go back</Link>
                                    }
                                    {
                                        qrCode &&
                                        <Qr qrCode={qrCode} brandName={brandName} />
                                    }
                                </div>
                                <div className="flex flex-row items-center mt-2 justify-between">
                                    <div className='flex items-center'>
                                    <CoreDropdown value={"Filter : " + type} placeholder='Filter : All' extendedClasses='dark:text-white text-yellow' containerClass="mt-[-5px] w-full h-[45px] border-graylight border-2 shadow-sm text-[0.8rem] mt-0 bg-blue text-yellow" showError={false} err={""} items={challengePostType} setValue={setType} />
                                    </div>
                                    {!isWinners && <Link to={`${isAdmin ? "/admin/campaign-management/view/winners" : "/brand/challenge/clips/winners"}/${paramsid}`} >See All Winners ({winners.length})</Link>}
                                </div>
                                <div className="rounded mt-2 p-3 pb-[30px] bg-white rounded-[10px] shadow">
                                    <div className="mb-2"></div>
                                    <div className=" w-full ">
                                        <div>
                                            <h3 className='text-blue font-bold font-primary'>{isWinners ? "All Winners" : "Created Contents/Posts"}</h3>
                                        </div>
                                        {

                                            !isWinners && !isAdmin && <>
                                                <br />
                                                <div>
                                                    {
                                                        selectWinners
                                                            ?
                                                            <div>
                                                                <div className='mb-2'>

                                                                    <h3 className='text-lg font-primarybold'>{selectedWinners.length} selected</h3>
                                                                </div>

                                                                <div className="flex">

                                                                    <Button onclick={() => {
                                                                        setSelectWinners(false)
                                                                        setSelectedWinners([])
                                                                    }} text='Cancel' h='30px' containerClass='text-[12px] pl-0 px-2 ' />
                                                                    <div className="px-2"></div>
                                                                    <Button onclick={() => {
                                                                        submitWinners()
                                                                    }} text='Submit Winners' h='30px' containerClass='text-[12px] bg-yellow text-blue pl-0 px-2 ' />

                                                                </div>
                                                            </div>
                                                            :
                                                            <Button onclick={() => {
                                                                setSelectWinners(true)
                                                            }} text='Select Winners' h='30px' containerClass='text-[12px] bg-yellow text-blue pl-0 px-2 ' />
                                                    }
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className="mb-2"></div>
                                    {
                                        pinned && pinned.length > 0 ?
                                            <div className="grid grid-cols-2 gap-2">
                                                {/* <ClipCard />
                                    <ClipCard /> */}
                                            </div>
                                            :
                                            <p></p>
                                    }

                                </div>

                                <div className="rounded mt-5 p-3 pb-[30px] pb-5 mb-3 bg-white rounded-[10px] shadow">
                                    <div className="search flex justify-end items-center mb-4">
                                        <div className={`flex items-center control border border-blue pl-2 h-[45px] rounded-[45px] w-[150px]} bg-[#F0F0F4] border-graylight shadow-sm border-2`}>
                                            <FiSearch className='text-[22px] text-[#7E799F]' /> <div className="mr-1"></div>
                                            <input placeholder="Search" type="text" value={search_} onChange={(e) => searchChanged(e.target.value)} className={`bg-transparent font-primary font-semibold ${search_ === '' ? 'font-medium text-blue opacity-50' : 'dark:text-white  text-blue'} placeholder:font-medium  border-0 outline-0 bg-none w-full px-2 h-[40px] is-dirty peer `} />
                                        </div>
                                        {!isWinners && <div className="mx-2"></div>}
                                        {!isWinners && <div className="max-w-[150px] w-full">
                                            <CoreDropdown value={"Sort : " + sort} placeholder='Sort : All' extendedClasses='dark:text-white text-yellow' containerClass="mt-[-5px] w-full h-[45px] border-graylight border-2 shadow-sm text-[0.8rem] mt-0 bg-blue text-yellow" showError={false} err={""} items={resultSort} setValue={setSort} />
                                        </div>}
                                    </div>
                                    <div className="mb-2"></div>
                                    <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-3 gap-y-3">
                                        {
                                            posts.map((item, i) => (
                                                <ClipCaard2 setChecked={(v: string, i: boolean) => {
                                                    var s = selectedWinners;
                                                    if (i) {
                                                        setSelectedWinners([...s, v])
                                                    } else {
                                                        var isExist = selectedWinners.find((w) => w === v)
                                                        if (isExist) {
                                                            let news: any[] = [];

                                                            for (let index = 0; index < s.length; index++) {
                                                                const element = s[index];
                                                                if (element == v) {

                                                                } else {
                                                                    news = [...news, element]
                                                                }
                                                            }
                                                            setSelectedWinners(news)
                                                        }
                                                    }
                                                }} winners={winners} selectedWinners={selectedWinners} selectWinners={selectWinners} loggedInBrand={loggedInBrand} item={item} key={i} />
                                            ))
                                        }
                                    </div>
                                    {/* <div className="grid lg:grid-cols-2 gap-3 gap-y-5">
                                        {
                                            posts.map((item, i) => (
                                                <ClipCard setChecked={(v: string, i: boolean) => {
                                                    var s = selectedWinners;
                                                    if (i) {
                                                        setSelectedWinners([...s, v])
                                                    } else {
                                                        var isExist = selectedWinners.find((w) => w === v)
                                                        if (isExist) {
                                                            let news: any[] = [];

                                                            for (let index = 0; index < s.length; index++) {
                                                                const element = s[index];
                                                                if (element == v) {

                                                                } else {
                                                                    news = [...news, element]
                                                                }
                                                            }
                                                            setSelectedWinners(news)
                                                        }
                                                    }
                                                }} winners={winners} selectedWinners={selectedWinners} selectWinners={selectWinners} loggedInBrand={loggedInBrand} item={item} key={i} />
                                            ))
                                        }
                                    </div> */}

                                    {
                                        posts.length === 0 ?
                                            <p>{!isWinners ? "No post found" : "No winners found"} </p>
                                            : ''
                                    }
                                </div>

                                {!isWinners && <Pagination length={totalPosts} page={page} />}

                            </div>
                        </div>
                }

            </div>
        </div>
    )
}

export default Clip