import React from 'react'
import { RxCaretDown } from 'react-icons/rx';
import { iFaqs } from '../../models/model';
import { faq_icon, faq_icon_active } from '../images';

export interface iFaqCard {
    faq?: iFaqs,
    index: number,
    toggleFAQ: any,
    question?: string,
    answer?: string,
    isOpen?: boolean,
    source?: string
}
const FaqCard = ({ question, source = "brand", answer, isOpen, index, toggleFAQ }: iFaqCard) => {
    return (
        <div onClick={() => toggleFAQ(index)} key={index} className='my-4'>
            <div className="w-full">
                <div className="flex items-start justify-between">
                    <img src={isOpen ? faq_icon_active : faq_icon} alt={question} className="object-contain" />
                    <div className='pl-4 flex-1 flex justify-between'>
                        <div className=''>
                            <h3 className={`text-blue font-primary ${source == 'home' ? 'text-[20px]' : 'text-[16px]'}`}>{question}</h3>
                            {
                                isOpen ?
                                    <div className="mt-3">
                                        <p className='text-gray2'>{answer}</p>
                                    </div>
                                    : ""
                            }

                        </div>

                        <div className='px-3'>
                            <RxCaretDown style={{ cursor: 'pointer' }} className={`text-[26px] font-bold ${isOpen ? 'text-blue' : 'text-gray2'}`} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FaqCard