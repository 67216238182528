import React, {useState} from 'react'
import {iInput} from '../models/model'
import "../helpers/dropdown_css.css"
import { BsChevronDown } from 'react-icons/bs';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import DatalistInput from 'react-datalist-input';

const Input = (data : iInput) => {
  const {isDisabled=false, placeholder, items, setValue, value, containerClass, className, type="text", isDropDown, showError = false, err = "", prevErr, hasPt =true, posErr = "", pos="", accept="", multi=false, w='', textareaH="200px"} = data;
  const [initType, setInitType] = useState(type);
  const togglePassword = () => {
    if(initType === "password") {
      setInitType("text")
    }else setInitType("password")
  }
  return (
      isDropDown ? 
      <div className={`relative ${prevErr === true ? (hasPt ? 'pt-[30px]' : 'pt-[10px]') : 'pt-[10px]'} `}>
        <BsChevronDown style={{cursor : 'pointer'}} className={`${type} absolute z-3 right-[15px] ${showError ? posErr : pos}`} />
        <DatalistInput
            value={value}
            placeholder={placeholder}
            label="Select ice cream flavor"
            showLabel={false}
            className={`control border border-blue pl-2 h-[50px] rounded-[45px] ${containerClass} ${type}`}
            inputProps={{
              className:`bg-transparent font-primary font-semibold dark:text-white  text-blue  border-0 outline-0 bg-none w-full px-2 h-[50px] is-dirty peer ${className} ${type}`,
              readOnly: false,
            }}
            onSelect={(item : {id : string, value : string}) => setValue(item.value)}
            items={items}
          />
          {
          showError ? <div className='px-2 w-full flex justify-start'>
                <p className="text-red-700"> <small>{err}</small> </p>
            </div>
            : ''
        }
        </div>
      :
      (type === 'textarea' ?
    <div className={`control w-full relative border border-blue  rounded-[10px] ${containerClass}`}>
        <textarea arial-aria-label={placeholder} style={{height : textareaH}} placeholder={placeholder} onChange={(e)=>setValue(e.target.value)} className={`bg-transparent p-2 font-primary font-semibold dark:text-white  text-blue placeholder:font-medium  border-0 outline-0 bg-none w-full px-2 is-dirty peer ${className}`} value={value} >{ value }</textarea>
        <br />
        {
          showError ? <div className='w-full absolute bottom-[-30px] flex justify-start '>
                <p className="text-red-700"> <small>{err}</small> </p>
            </div>
            : ''
        }
    </div>
    
    :
    (
      type === 'file' ? 
      <div className={`control w-full border relative  flex items-center border-blue pl-2 h-[50px] rounded-[45px] ${containerClass}`}>
        <label style={{cursor : 'pointer'}} htmlFor='inputFile' className={`bg-transparent flex items-center font-primary font-semibold ${value === '' ? 'font-medium text-blue opacity-50' : 'dark:text-white  text-blue'} placeholder:font-medium  border-0 outline-0 bg-none w-full px-2 h-[45px] is-dirty peer ${className}`} >
          {value === "" ? "Click to select a file " : value}
        </label>
        <input accept={accept} type="file" hidden id="inputFile" onChange={(e)=>setValue(e)}/>
        {
          showError ? <div className='w-full absolute bottom-[-28px] flex justify-start '>
                <p className="text-red-700"> <small>{err}</small> </p>
            </div>
            : ''
        }
    </div>
      :
    
    <div className={`control relative border border-blue pl-2 h-[50px] rounded-[45px] ${containerClass} ${w === '' ? 'w-full' : w}`}>
        <input arial-aria-label={placeholder} placeholder={placeholder} disabled={isDisabled} type={initType} value={value} onChange={(e)=>setValue(e.target.value)} className={`bg-transparent font-primary font-semibold ${value === '' || value === "mm/dd/yyyy" ? 'font-medium text-blue opacity-50' : 'dark:text-white  text-blue'} placeholder:font-medium  border-0 outline-0 bg-none w-full px-2 h-[50px] is-dirty peer ${className}`} />
        {
          type === "password" ?
          <div onClick={togglePassword} style={{cursor : 'pointer'}} className="absolute right-[15px] top-[15px] text-blue opacity-80">
            {initType === "password" ?
            <AiOutlineEyeInvisible />
            : 
            <AiOutlineEye />}
          </div>
          : ''
        }
        {
          showError ? <div className='w-full flex justify-start '>
                <p className="m-0 text-red-700"> <small>{err}</small> </p>
            </div>
            : ''
        }
    </div>
    )
    )
  )
}

export default Input