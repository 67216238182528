import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { AiOutlinePlusCircle } from "react-icons/ai";
import { needHelp } from '../../components/images';
import useDocumentTitle from '../../helpers/pageTitle';
import { decryptData } from '../../helpers/security';
import { useTypedSelector } from '../../hooks/useSelector';
import { ActionType } from '../../store/actions';
import { BiDotsVertical } from 'react-icons/bi';
import Button from '../../components/button';
import ChallengeCard from '../../components/brand/challengeCard';
import instance from '../../helpers/axios';
import { toast, ToastContainer } from 'react-toastify';
import Pagination from '../../components/pagination';
import ScrollToTop from '../../helpers/scrollToTop';
import Loader from '../../helpers/loader';
import { getUserLocation } from '../../helpers/helpers';
// import { ShareModal } from '../../components/brand';

const GenerateInvoice: React.FC = () => {
    const params = useParams();
    const { loggedInBrand } = useTypedSelector((state) => state.appReducer);
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { search } = useLocation()
    const [user, setUser] = useState('')
    const { acctSideBarActive } = useTypedSelector((state) => state.appReducer);
    const [challenges, setChallenges] = useState([])
    const [Draft, setDraft] = useState([])
    const [brand, setBrand] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [offset, setOffset] = useState(0)
    const [page, setPage] = useState(1)
    const [userLocation, setUserLocation] = useState<any[]>([])
    
    useEffect(() => {
        dispatch({
            type: ActionType.UPDATE_LAYOUT,
            payload: "brand"
        })
    }, [])
    useEffect(() => {
        if (!loggedInBrand || loggedInBrand == "") {
            // nobody is logged in
            navigate("/brand/login");
        }
    }, [loggedInBrand])

    // get brand profile
    useEffect(() => {
        (
            async()=>{
                const userLoc = await getUserLocation()
                setUserLocation(userLoc)
                const headers = {
                    'Authorization': `Bearer ${loggedInBrand}`
                }
                var paramsid = params.id
                instance({
                    method: "POST",
                    url: `/challenge/single/${paramsid}/0/0`,
                    headers: headers
                }).then((response) => {
                    try {
                        var cha = response.data.data.challenge;
                        var totalbudget = cha.totalPrizeBudget;
                        var b = parseInt(cha.totalPrizeBudget);
                        var char = 10 / 100 * b
                        var status = cha.paymentStatus;
                        if (status == "incomplete" || status == "pending") {
                            var url = "";
                            if (window.location.host.includes("localhost")) {
                                url = "http://beentos.com" + `/verify-challenge-payment/${paramsid}`
                            } else {
        
                                url = "https://" + window.location.host + `/verify-challenge-payment/${paramsid}`
                            }
        
        
                            var newData = {
                                "currency": "usd",
                                "pricePerUnit": Number(((char + b) * 100).toFixed(2)),
                                "quantity": 1,
                                "productType": "challenge",
                                "productId": paramsid,
                                "productName": cha.title,
                                "localCurrency": userLoc[3] ?? "USD",
                                 //"localCurrency": userLoc[1] == "NG" ? "NGN" : "USD",
                                "returnUrl": url
                            }
                            instance({
                                method: "POST",
                                url: `/payment`,
                                headers: headers,
                                data: newData
                            }).then((response) => {
                                console.log(response)
                                window.location.href = response.data.data.paymentLink;
                            }).catch((error) => {
                                toast.error("Something went wrong")
                                setTimeout(() => {
                                    navigate('/brand/dashboard');
                                }, 2000);
                            })
                        } else {
                            console.log("paid")
                        }
                    } catch (error) {
                        toast.error("Something went wrong1")
                        setTimeout(() => {
                            navigate('/brand/dashboard');
                        }, 2000);
                    }
                }).catch((err) => {
                    if (err.code == "ERR_NETWORK") {
                        alert("No internet connection")
                    }
                    if (err.response.data.statusCode === 403) {
                        navigate('/brand/login');
                        return false;
                    }
                    toast.error(err.response.data.message)
                    console.log(err)
                    setTimeout(() => {
                        // navigate('/brand/dashboard');
                    }, 2000);
                })

            }
        )()
    }, [])


    // /api/challenge/brand/challenges/:creationStatus/:offset/:limit
    useDocumentTitle('BRAND Generate Campaign Invoice')
    // const a = []
    return (
        <div>
            <div className="max-w-[1445px]">
                <ToastContainer />
                {/* wrapper */}
                {
                    isLoading ? <Loader /> :
                        <div className="flex"></div>
                }

            </div>
        </div>
    )
}

export default GenerateInvoice