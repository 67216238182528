import React, { useState, useEffect, useRef } from 'react'
import { FiSearch } from 'react-icons/fi'
import { VscCloseAll } from 'react-icons/vsc'
import ButtonLoader from '../../helpers/button_loader'
import { iCreate } from '../../models/model'
import Button from '../button'
import { ai, ai_big, beentos_ai, brandToggle, checkBox, checkbox_white, copy_icon, edit_ai, Logo, notChecked } from '../images'
import Input from '../input'
import '../../helpers/ai_modal.css'
import { BsSend } from 'react-icons/bs'
import { Configuration, OpenAIApi } from "openai"
import { openai_api_key } from '../../config'
import ls from 'localstorage-slim';
import instance from '../../helpers/axios'
import { useTypedSelector } from '../../hooks/useSelector'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

interface iAiChat {
    sender: number;
    message: string;
    tiedTo?: string;
}

function AiModal({ close, newChat = "" }: iCreate) {
    const navigate = useNavigate();
    const { pathname, search } = useLocation();
    const [loading, setLoading] = useState(false)
    const [askai, setAskai] = useState('')
    const [avatar, setAvatar] = useState('')
    const [chatMessages, setChatMessages] = useState<iAiChat[]>([])
    const [chats1, setChats] = useState<any>([])
    const [gettingChat, setGettingChat] = useState(false)
    const endofChatRef = useRef<HTMLDivElement>(null);
    const [lastMsg, setLastMsg] = useState("")
    const [challenge, setChallenge] = useState("")


    // openai config 
    const config = new Configuration({
        apiKey: openai_api_key
    })

    useEffect(() => {
        // ls.remove("chats")
        const search_ = search.replace("?", "").split("&");
        if (search_.length > 0) {
            search_.forEach((s) => {
                if (s.includes("challenge=") !== false) {
                    const challengeId = s.replace("challenge=", "");
                    setChallenge(challengeId);
                }
            })
        }

        if (newChat !== "") {
            // setChatMessages([]);

            setAskai(newChat)
            setTimeout(() => {
                chatAI("send", newChat.trim());
            }, 500);
        }
        if (chats1.length == 0) {
            // var localChats = ls.get("chats", { decrypt: true })
            // if (localChats) setChats(localChats)
        }
    }, [newChat])



    const { loggedInBrand } = useTypedSelector((state) => state.appReducer);

    const headers = {
        'Authorization': `Bearer ${loggedInBrand}`
    }
    const chatAI = async (type = "send", chatMessage = "") => {
        if (gettingChat) return false;
        var chats = chats1;
        let chatLocal = ls.get("chats", { decrypt: true })
        chatLocal = chatLocal ?? [];

        if (askai === "" && chatMessage === "") {
            return false;
        }
        setGettingChat(true)
        var new_chat: any = {
            content: chatMessage === "" ? askai : chatMessage,
            role: "user"
        }
        setLastMsg(new_chat.content)
        instance({
            method: "POST",
            url: `/challenge/create-suggestion`,
            data: {
                request: new_chat.content
            },
            headers: headers
        }).then((response) => {
            let data = response.data.data;
            let description = data.description
            let musicUrl = data.musicUrl
            let price = data.prize
            let guidelines = data.guidelines

            var c = <>
                <div>
                    <b>Title :</b>
                    <p>{data.title}</p>
                    <br />
                </div>

                <div>
                    <b>Description :</b>
                    <p>{description}</p>
                    <br />
                </div>
                <div>
                    <b>Price :</b>
                    <p>{price}</p>
                    <br />
                </div>
                <div>
                    <b>Background Music :</b>
                    <p>{musicUrl}</p>
                    <br />
                </div>
                <div>
                    <b>Guidelines :</b>
                    <ul>
                        {
                            guidelines.map((item: any, i: number) => (
                                <li>{item}</li>
                            ))
                        }
                    </ul>
                </div>
            </>

            chats = [...chats, {
                role: "assistant",
                content: c,
                data: data
            }];
            // chatLocal = [...chatLocal, newChat, {
            //     role: "assistant",
            //     data: data
            // }]
            try {
                // const completion = await openai.createChatCompletion({
                //     model: 'gpt-3.5-turbo',
                //     max_tokens: 512,
                //     temperature: 0.5,
                //     // prompt: askai,
                //     messages: chats,
                // })
                // new_chat = completion.data.choices[0].message
                // chats = [...chats, new_chat];

                setTimeout(() => {
                    ls.set("chats", chats, { encrypt: true })
                    setChats(chats);
                    setGettingChat(false)
                    setAskai("")
                }, 1000);
                endofChatRef.current?.scrollIntoView({ behavior: 'smooth' });

            } catch (error) {
                setGettingChat(false)
            }
        }).catch((err) => {
            setGettingChat(false)
        }).finally(() => {
        })
    }
    const useChat = (index: number, type = "new") => {
        let c = chats1[index];
        if (c.data) {
            let data = c.data;
            setLoading(true)

            let description = data.description
            let musicUrl = data.musicUrl
            let price = data.prize
            let guidelines = data.guidelines
            let gString = (guidelines.join("~end-of-a-step~")).toString()
            var payload: any = {
                title: data.title,
                description: description,
                price: price,
                guidelines: gString,
                backgroundMusicUrl: musicUrl,
                creationStatus: "draft",
                lastStep: 1
            }

            if (challenge !== "") {
                payload.challengeId = challenge
            }
            instance({
                url: type == "replace" ? "/challenge/update" : '/challenge/create',
                method: type == "replace" ? "PUT" : "POST",
                headers: headers,
                data: payload
            })
                .then((response) => {
                    console.log("respon response", response)
                    if (type == "replace") {
                        toast.success("Campaign updated")
                    } else {
                        toast.success("New Campaign created")
                    }
                    window.location.assign(`/brand/challenge/create?challenge=${response.data.data._id}`)
                }).catch((error) => {
                    setLoading(false)

                }).finally(() => {
                })
        }
    }
    return (
        <div>
            <div className='fixed top-0 left-0 flex items-center w-full z-[999] h-[100vh]'>
                <div className="max-h-[80vh] min-h-[300px] w-full flex items-center justify-center">
                    <div onClick={() => close()} className='back_drop backdrop-blur fixed top-0 left-0 w-full bg-blue/30 h-[100vh] bg-blue'></div>
                    <div className='bg-white shadow-lg rounded-[15px] w-[95%] lg:w-[760px] p-2 relative max-h-[90vh]'>
                        <div className="mt-[3px] p-2 h-[80vh] min-h-[300px]">
                            <div className="flex h-100">
                                <div className="flex-initial w-full p-1 h-[100%]">
                                    <div className="h-[89%] ai_modal_chat p-3 rounded-[10px] overflow-y-scroll" ref={endofChatRef}>
                                        {
                                            chats1.length === 0 ?
                                                <div className='flex mt-[100px] justify-center flex-col items-center'>
                                                    <img src={ai_big} className='object-contain rotate-scale-up rounded-[100%] h-[100px] w-[100px] opacity-30' />
                                                    <br />
                                                    <h6 className="text-blue text-[18px] font-primary">Ask Me &#128519;</h6>
                                                </div>
                                                : ''
                                        }
                                        {
                                            chats1.map((chat: any, i: number) => (
                                                chat.role === "assistant" ?
                                                    <div className="bot_chat" key={i}>
                                                        <div className="chat_wrapper bg-graylight rounded-[10px] mt-[30px] p-[8px] flex">
                                                            <div className="flex-4nitial w-[40px]">
                                                                <img src={beentos_ai} className='object-contain rounded-[100%] h-[30px] w-[30px]' />
                                                            </div>
                                                            <div className="chat_content flex-initial w-full leading-relaxed text-[13px] px-[10px]">
                                                                <p>

                                                                    <div />
                                                                    {chat.content}
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div className="my-2 flex items-center justify-end">
                                                            {/* <img src={copy_icon} style={{ cursor: 'pointer' }} alt="" className='object-contain h-[25px]' /> <div className="mx-2"></div> */}
                                                            {challenge && <Button
                                                                loading={loading}
                                                                text='Use response' onclick={() => {
                                                                    useChat(i, "replace")
                                                                }} containerClass='bg-yellow w-[150px] text-blue font-bold text-[13px]' h='35px' />}
                                                            <Button
                                                                loading={loading}
                                                                text='Create new Campaign' onclick={() => {
                                                                    useChat(i, "new")
                                                                }} containerClass='bg-yellow w-[150px] text-blue font-bold text-[13px]' h='35px' />
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="user_chat" key={i}>

                                                        <div className="chat_wrapper bg-graylight rounded-[10px] p-3 flex">
                                                            <div className="flex-4nitial w-[40px]">
                                                                <img src={avatar === "" ? brandToggle : avatar} className='object-contain rounded-[100%] h-[40px] w-[40px]' />
                                                            </div>
                                                            <div className="chat_content flex-initial w-full text-[13px] px-[10px] pt-[10px]">
                                                                {(chat.content).replace("\n", "\r\n")}
                                                            </div>
                                                            <div className="flex-initial w-[20px] pt-[10px]">
                                                                <img src={edit_ai} className='object-contain h-[20px] w-[20px]' />
                                                            </div>
                                                        </div>
                                                    </div>
                                            ))
                                        }


                                    </div>
                                    {/* <div className="end_of_chat" ref={endofChatRef}></div> */}
                                    <div>
                                        {
                                            gettingChat ? <p className='text-center'>loading...</p> :
                                                <div className={`control bg-white mb-3 mt-3 relative border flex items-center border-blue pl-2 h-[50px] rounded-[45px] w-full`}>
                                                    <div className="ml-3 mr-2">
                                                        <img src={ai} alt="" className='object-contain' />
                                                    </div>
                                                    <input onKeyUp={(e) => {
                                                        if (e.keyCode === 13) chatAI();
                                                    }} placeholder="Ask AI" type='text' value={askai} onChange={(e) => {
                                                        setAskai(e.target.value)
                                                    }
                                                    } className={`bg-transparent font-primary font-semibold ${askai === '' ? 'font-medium text-blue opacity-50' : 'dark:text-white  text-blue'} placeholder:font-medium  border-0 outline-0 bg-none w-full px-2 h-[50px] is-dirty peer`} />
                                                    <div style={{ cursor: 'pointer' }} className="absolute right-[15px] top-[15px] text-blue opacity-80" onClick={() => chatAI()}>
                                                        <BsSend />
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AiModal