import axios from "axios";
import CountriesJson from "./countriesArray.json";

const editor_url = window.location.href.includes("localhost") ? "http://localhost:5173" : "https://editor.beentos.com";
const ScrollToDiv = (id : string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
};
export function slugify(str: string):string {
  return str
      .toLowerCase()                     // Convert to lowercase
      .replace(/\s+/g, '-')              // Replace spaces with dashes
      .replace(/[^\w\-]+/g, '')          // Remove non-word characters (excluding dashes)
      .replace(/\-\-+/g, '-')            // Replace consecutive dashes with a single dash
      .replace(/^-+/, '')                // Remove leading dashes
      .replace(/-+$/, '');  
  }             // Remove trailing dashes
const navigateToEditor = (brand:string)=> { 
  // alert(brand)
  window.location.href = editor_url+"?t="+brand
}
const getUserLocation = async () => {
  try {
    let response = await axios.get("https://geolocation-db.com/json/");
    // console.log(response);
    var country = response.data.country_name.replace(" ", "-").toLowerCase();
    var countryCode = response.data.country_code;
    var currency = ""
    var symbol = ""
    CountriesJson.map((aCountry) => {
      if (aCountry.cc == countryCode) {
        currency = aCountry.currency;
        symbol = aCountry.symbol;
      }
    });
    return [country, countryCode, symbol,currency]
    
  } catch (error) {
    return ["US", "US", "$","USD"]
  }
}
const numberFormat = (number: number) =>{
  return (number).toLocaleString("en-US", {style:"decimal", maximumFractionDigits : 2})
}

const validateEmail = (e:string) => {
  return String(e)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};


function ValidDate(UserDate : string) {
    var ToDate = new Date();
    if (new Date(UserDate).getTime() <= ToDate.getTime()) {
          return false;
     }
    return true;
}


const isValidUrl = (urlString : string)=> {
  // urlString = urlString.includes("http") ? urlString : "http://"+urlString
  console.log(urlString)

  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(urlString);

  try { 
    return Boolean(new URL(urlString)); 
  }
  catch(e){ 
    return false; 
  }
}

const ucwords = (str : string) => {
  str = str.toLowerCase().replace(/\b[a-z]/g, function(letter) {
      return letter.toUpperCase();
  });
  return str;
}
const generateToken = (length : number) : string => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}


const formatDate = (date : string, type = 1, fromDb = false) => {
  // date.ex
  try {
    
  
  var date_array = date.split("-");
  var y = date_array[0], m = date_array[1], d = date_array[2];
  if(type == 3) m = String(Number(m)-1);
  if(type == 2 || type ==3){
    d = Number(d) < 10 ? `0${d}` : d;
    if(fromDb){
      m = utcToMonth(Number(m)-1);
    }else{
      m = utcToMonth(Number(m));
    }
    if(String(d).includes("00")) d = String(d).replace("00", "0");
    return `${m} ${d}, ${y}`
  }
  return `${d}/${m}/${y}`;
} catch (error) {
  return "";
}
}

function sortByKey (array : any, key : string, sortBy : string) {
        // if(sort == "All") return array;
        return array.sort(function(a : any, b : any) {
            var x = a[key]; var y = b[key];
            if(sortBy == "Highest"){
                return ((x > y) ? -1 : ((x < y) ? 1 : 0));
            }

            if(sortBy == "Lowest") {
                return ((x < y) ? -1 : ((x > y) ? 1 : 0));
            }

            if(sortBy == "Oldest") {
                var keyA = new Date(a.createdAt),
                    keyB = new Date(b.createdAt);
                // Compare the 2 dates
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
            }

            if(sortBy == "Newest" || sortBy == "All") {
                var keyA = new Date(a.createdAt),
                    keyB = new Date(b.createdAt);
                // Compare the 2 dates
                if (keyA > keyB) return -1;
                if (keyA < keyB) return 1;
            }
        });
    }
const sharePrize = (prize: number, noOfUsers : number, toList = 0)=> {
    const share = [];
    let sumUsers = 0;
    for (let i = 1; i <= noOfUsers; i++) {
      sumUsers += i;
    }
    for (let i = 1; i <= noOfUsers; i++) {
      share[(noOfUsers - i) + 1 ] = Number(((i / sumUsers) * prize).toFixed(2));
    }

    if(toList !== 0){
      return share.splice(1, toList);
    }
    return share;
  }

  const position = (i : number) => {
    switch (i+1) {
        case 1:
            return '1st'
            break;
        case 2:
            return '2nd'
            break;

        case 3:
            return '3rd'
            break;
    
        default:
            break;
    }
}

const getToday = (date = new Date) => {
  try {
    
    return date.toISOString().split("T")[0]
  } catch (error) {
    return ""
    
  }
}
const formatCreatedAtDate = (createdAt : any) : string => {
  var dd = new Date(createdAt);
  return getToday(dd);
}

const getPreviousDate = (start: string, day: number) => {
  if(start === ""){
    var date = new Date();
  }else{
    var date = new Date(start);
  }
    day = date.getTime() - (day * 24 * 60 * 60 * 1000);
    var minus = date.setTime(day);
    var minusday = new Date(minus);

    const year = minusday.getFullYear()
    const month = minusday.getMonth() < 10 ? `0${minusday.getMonth() + 1}` : minusday.getMonth() + 1
    const day_ = minusday.getDate() < 10 ? `0${minusday.getDate()}` : minusday.getDate();
    return `${year}-${month}-${day_}`
}

const findPercentageDiff = (a : number, b : number, rounded = 2) => {
  if(a === 0 && b ===0) return 0;
  var diff = a - b

  var average = (a + b)/2 

  return ((diff/average) * 100).toFixed(rounded)
}


const findPercentage = (a : number, b : number, rounded = 2) =>{
  if(a === 0 && b ===0) return 0;
  return ((a/b)*100).toFixed(0)
}

const shuffle = (array: any[]) => { 
  for (let i = array.length - 1; i > 0; i--) { 
    const j = Math.floor(Math.random() * (i + 1)); 
    [array[i], array[j]] = [array[j], array[i]]; 
  } 
  return array; 
}; 
const utcToDay = (day : number) : string => {
  switch (day) {
    case 0:
      return "Sun"
    case 1:
      return "Mon"
    case 2:
      return "Tue"
    case 3:
      return "Wed"
    case 4:
      return "Thu"
    case 5:
        return "Fri"
    case 6:
      return "Sat"
    default:
      return "Sun"
      break;
  }
  return "Sun"
}


const utcToMonth = (day : number) : string => {
  switch (day) {
    case 0:
      return "January"
    case 1:
      return "February"
    case 2:
      return "March"
    case 3:
      return "April"
    case 4:
      return "May"
    case 5:
        return "June"
    case 6:
      return "July"
    case 6:
      return "August"
    case 7:
      return "September"
    case 8:
      return "October"
    case 9:
      return "October"
    case 10:
      return "November"
    case 11:
      return "December"

    default:
      return ""
      break;
  }
}
function capitalizeFirstLetter(string : string) {
  if(!string) return ""
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const isEdit = () => {
  return window.location.href.includes("edit") ? true : false;
}
const isAdmin = () => {
  return window.location.href.includes("admin") ? true : false;
}
export function containsSpecialChars(str:string) {
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  return specialChars.test(str);
}
export function containsNumber(str:string) {
  return /\d/.test(str);
}

export function containsCapitalLetter(str:string) {
  var strArray = str.split("");
  for (let i = 0; i < strArray.length; i++) {
    const strr = strArray[i];
    const isUpperCase = /^[A-Z]*$/.test(strr);
    if (isUpperCase) return true;
  }

  return false;
}

export {editor_url, isAdmin, navigateToEditor, shuffle, isEdit, capitalizeFirstLetter, sortByKey, ScrollToDiv, validateEmail, isValidUrl, generateToken, formatDate, formatCreatedAtDate, ucwords, utcToDay, findPercentage, findPercentageDiff, getToday, getPreviousDate, ValidDate, sharePrize, position, getUserLocation, numberFormat}