import React, { useEffect, useState } from 'react'
import { AiOutlineEyeInvisible } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { SettingsAside, SettingsHeader } from '../../../components/brand';
import Button from '../../../components/button';
import { cardPayment, checked, notChecked, paypal, radio, radioActive, stripe, who } from '../../../components/images';
import Input from '../../../components/input';
import { filterInsight, monthsList } from '../../../data';
import useDocumentTitle from '../../../helpers/pageTitle';
import { useTypedSelector } from '../../../hooks/useSelector';
import { ActionType } from '../../../store/actions';

import ls from "localstorage-slim"
import { CoreDropdown, Hr } from '../../../components';
import axios from 'axios';
import Loader from '../../../helpers/loader';
import IGCard from '../../../components/brand/apps/ig_card';
import { capitalizeFirstLetter, formatCreatedAtDate, formatDate } from '../../../helpers/helpers';
import InsightTable from '../../../components/brand/apps/insight_table';
import InstagramUpload from '../../../components/brand/upload_ig';
import { ToastContainer, toast } from 'react-toastify';
import ButtonLoader from '../../../helpers/button_loader';

const ThirdPartyApps: React.FC = () => {
    const { loggedInBrand } = useTypedSelector((state) => state.appReducer);
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [value, setValue] = useState('')
    const [login, setLogin] = useState(false);
    const [loading, setLoading] = useState(false);
    const [publishing, setPublishing] = useState(false);
    const [livedToken, setLivedToken] = useState("");
    const [insightPeriod, setInsightPeriod] = useState("Day");
    const [paging, setPaging] = useState<any>(null);
    const [loadingIG, setLoadingIG] = useState(true);
    const [showUpload, setShowUpload] = useState(false);

    const [currentAccount, setCurrentAccount] = useState<any>(null)
    const [instagramAccounts, setInstagramAccounts] = useState<any[]>([])
    useEffect(() => {
        dispatch({
            type: ActionType.UPDATE_LAYOUT,
            payload: "brand"
        })
        if (!loggedInBrand) navigate("/brand/login")
        responseFacebook()
    }, [])
    const responseFacebook = () => {
        let token = ls.get("bt_long_lived_token", { decrypt: true })
        if (token) {
            setLogin(true)
            setLivedToken(token.toString())
            setTimeout(() => {
                let t = token || "";
                loadIGAccounts(t.toString())
            }, 400);
        } else {
            setLoadingIG(false)
        }
    }

    const postToIG = (url: string) => {
        let userid = currentAccount.account.instagram_business_account.id
        setLoading(true)
        let u = `https://graph.facebook.com/v19.0/${userid}/media?video_url=${url}&media_type=REELS&share_to_feed=1&access_token=${livedToken}`
        axios.post(u).then((res) => {
            setPublishing(true)
            setTimeout(async () => {
                publishCreation(res.data.id)
            }, 5000);
        }).catch(() => {
            toast.error("Error posting your video, please try again");
            setLoading(false)
        }).finally(() => {
            setLoading(false)
        })

    }

    const publishCreation = (creation_id: string, tries = 1) => {
        let userid = currentAccount.account.instagram_business_account.id
        let u = `https://graph.facebook.com/v19.0/${userid}/media_publish?creation_id=${creation_id}&access_token=${livedToken}`
        axios.post(u).then((res) => {
            setPublishing(false)
            toast.success("Reels posted successfully")
        }).catch(() => {
            if (tries < 5) {
                setTimeout(() => {
                    publishCreation(creation_id, 2)
                }, 5000);
            } else {
                toast.error("Error publishing your video, please try again");
                setPublishing(false)
            }
        }).finally(() => {
        })
    }
    interface iPayload {
        impressions?: any,
        reach?: any,
        profile_views?: any,
    }
    const resetApp = (type = 'ig') => {
        ls.remove("bt_long_lived_token")
        ls.remove("bt_access_token")
        setLogin(false)
    }
    const loadIGAccounts = async (token: any) => {
        setLoadingIG(true)
        let accounts: any[] = []
        let url = `https://graph.facebook.com/v19.0/me/accounts?access_token=${token}&fields=id%2Cpicture%2Cprofile%2Cname%2Caccess_token%2Cinstagram_business_account`
        axios.get(url).then((res) => {
            let d = res.data.data;
            d.forEach((ig: any) => {
                if (ig.instagram_business_account) {
                    accounts = [...accounts, ig]
                }
            });

            setInstagramAccounts(accounts)
        }).catch(() => {

        }).finally(() => {
            setLoadingIG(false)
        })
    }
    const loadInsight = async (igaccount: any, filter = "day", fetchUrl = "") => {
        // igaccount = igaccount ? igaccount : currentAccount.account;
        let igId = igaccount?.instagram_business_account?.id
        let acct: any = {
            account: igaccount,
        }
        let payload: iPayload = {};
        // setLoading(true)
        let metric = filter == 'day' ? 'impressions,reach,profile_views' : 'impressions,reach'
        // let url = `https://graph.facebook.com/v19.0/${igId}/insights?period=${filter}&access_token=${livedToken}ZD&metric=${metric}`
        // axios.get(fetchUrl == "" ? url : fetchUrl).then((res) => {
        //     let d = res.data.data;
        //     let p = res.data.paging;
        //     setCurrentAccount(acct)
        //     setPaging(p)
        //     if (d.length == 0) return false;
        //     for (let index = 0; index < d.length; index++) {
        //         const data = d[index];
        //         if (data.name == "impressions") payload.impressions = data
        //         if (data.name == "reach") payload.reach = data
        //         if (data.name == "profile_views") payload.profile_views = data
        //     }

        //     acct.payload = payload;
        // }).catch((err) => {
        // }).finally(() => {
        //     setLoading(false)

        // })
        acct.payload = null
        setCurrentAccount(acct)
    }
    useEffect(() => {
        if (currentAccount) loadInsight(currentAccount.account, insightPeriod.toLowerCase())
    }, [insightPeriod])
    useDocumentTitle('THIRD PARTY APPS')

    return (
        <div className='w-full'>
            <ToastContainer />
            <div className="px-[15px] mb-[80px] pb-[10px] h-100 relative font-primary flex max-w-[1440]">
                <SettingsAside />
                {
                    loading && <Loader />}
                <div className='lg:container sm:mx-auto md:mx-auto flex-inital lg:px-[15px] px-[5px] py-[25px] w-full'>
                    <SettingsHeader title='Apps' sub_title='Connect Third Party Apps' />
                    {
                        currentAccount
                        && !publishing && <div className=' mt-[50px] text-right text-blue'>
                            <Link to={"#?"} onClick={() => setShowUpload(true)} >Publish video</Link>
                        </div>
                    }
                    {
                        showUpload && <InstagramUpload close={() => {
                            // publishCreation();

                            setShowUpload(false)
                        }
                        } callback={(v: string) => {
                            if (v) postToIG(v)
                            setShowUpload(false)
                        }} />
                    }

                    {
                        publishing
                            ?
                            <>
                                <div className='h-[10vh]'></div>
                                <div className="min-h-[100px] mx-auto flex justify-center flex-col items-center bg-blue text-center shadow w-[95%] rounded shadow max-w-[200px]">
                                    <ButtonLoader />
                                    <p className="text-center text-white">Publishing your video...</p>
                                </div>
                                <div className="text-center">
                                    <p className='text-[12px] text-muted'>This may take up to 2mins</p>
                                </div>
                            </>
                            :
                            <div className="shadow-lg rounded-[15px] mt-2 p-[15px]  py-[40px] bg-white">
                                {
                                    login
                                        ?
                                        <div>
                                            {
                                                currentAccount ?
                                                    <>
                                                        <div>
                                                            <div className=' flex items-center justify-between'>
                                                                <Link to={"#?"} onClick={() => setCurrentAccount(null)} >go back</Link>
                                                                <div>
                                                                    <CoreDropdown value={"Filter : " + insightPeriod} placeholder='Filter'
                                                                        extendedClasses='dark:text-white text-yellow'
                                                                        containerClass="mt-[-5px] w-full h-[45px] border-graylight border-2 shadow-sm text-[0.8rem] mt-0 bg-blue text-yellow"
                                                                        showError={false} err={""} items={filterInsight} setValue={setInsightPeriod} />
                                                                </div>

                                                            </div>
                                                            <div className='h-[8px]'></div>
                                                            <IGCard item={currentAccount?.account} onClick={(v: string) => { }} />
                                                            {
                                                                !currentAccount.payload
                                                                    ?
                                                                    <div>
                                                                        <p>No record found</p>
                                                                    </div>
                                                                    :
                                                                    <>
                                                                        {
                                                                            currentAccount.payload?.impressions &&
                                                                            <InsightTable item={currentAccount.payload?.impressions} insightPeriod={insightPeriod} />
                                                                        }

                                                                        {
                                                                            currentAccount.payload?.reach && <InsightTable item={currentAccount.payload?.reach} insightPeriod={insightPeriod} />
                                                                        }
                                                                        {
                                                                            currentAccount.payload?.profile_views && <InsightTable item={currentAccount.payload?.profile_views} insightPeriod={insightPeriod} />
                                                                        }
                                                                    </>
                                                            }
                                                            {/* <div className='flex justify-center gap-3 mt-3'>
                                                                <a onClick={() => {
                                                                    if (paging.previous) loadInsight(currentAccount?.account, insightPeriod, paging.previous)
                                                                }} className={`text-blue ${paging.previous ? '' : 'opacity-[0.4]'}`} href='#?'>Prev</a>
                                                                <a onClick={() => {
                                                                    if (paging.next) loadInsight(currentAccount?.account, insightPeriod, paging.next)
                                                                }} className={`text-blue ${paging.next ? '' : 'opacity-[0.4]'}`} href='#?'>Next</a>
                                                            </div> */}
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div>
                                                            <h3 className='font-primarybold text-blue text-[16px]'>INSTAGRAM ACCOUNTS</h3>
                                                            <small><a href='#?' onClick={() => resetApp('ig')}>reset</a></small>
                                                        </div>
                                                        <Hr />
                                                        <div className='h-[10px]'></div>
                                                        {
                                                            loadingIG
                                                                ?
                                                                <h3 className='font-primarybold text-blue text-[16px]'>Loading data...</h3>
                                                                :
                                                                <div className=''>
                                                                    {
                                                                        instagramAccounts.map((item, i) => (
                                                                            <IGCard onClick={(v: string) => loadInsight(item)} key={i} item={item} />
                                                                        ))
                                                                    }

                                                                    {
                                                                        instagramAccounts.length == 0 && <div>
                                                                            <h3 className='font-primarybold text-blue text-[16px]'>No IG Account found</h3>
                                                                        </div>
                                                                    }
                                                                    <br />
                                                                </div>

                                                        }
                                                    </>
                                            }
                                        </div>
                                        :
                                        <div>
                                            <h3 className='font-primarybold text-blue text-[16px]'>Connect Facebook/Instagram</h3>
                                            <div className='grid grid-cols-2 lg:grid-cols-2'>
                                                <div>
                                                {/* https://www.facebook.com/v19.0/dialog/oauth?response_type=token&display=popup&client_id=689239116692839&redirect_uri=https://beentos.com&auth_type=rerequest&scope=publish_video%2Cpages_show_list%2Cbusiness_management%2Cinstagram_basic%2Cinstagram_manage_comments%2Cinstagram_manage_insights%2Cinstagram_content_publish%2Cpages_read_engagement%2Cpages_read_user_content */}
                                                    <a href="https://www.facebook.com/v19.0/dialog/oauth?response_type=token&display=popup&client_id=689239116692839&redirect_uri=https://beentos.com&auth_type=rerequest&scope=pages_show_list%2Cinstagram_basic%2Cinstagram_content_publish" className="flex items-center justify-center w-full px-4 py-2 mt-2 space-x-3 text-sm text-center bg-[#1877F2] text-white transition-colors duration-200 transform border rounded-lg dark:text-gray-300 dark:border-gray-300 hover:bg-gray-600 dark:hover:bg-gray-700">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-facebook" viewBox="0 0 16 16">
                                                            <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                                                        </svg>
                                                        <span className="text-sm text-white dark:text-gray-200">Login with Facebook</span></a>
                                                </div>
                                            </div>
                                        </div>
                                }
                                <br />
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default ThirdPartyApps;