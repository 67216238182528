import { PDFDownloadLink } from '@react-pdf/renderer';
import React, { useState, useEffect, useRef } from 'react'
import { AiOutlineShareAlt } from 'react-icons/ai'
import { BiShare } from 'react-icons/bi'
import { toast, ToastContainer } from 'react-toastify';
import ButtonLoader from '../../../helpers/button_loader';
import { iPdf } from '../../../models/model';
import ReportTemplate from '../../../pages/brands/pdf';
import AdminReportTemplate from '../../../pages/brands/admin_pdf_downloader';
import ChallengeResultPDFTemplate from './pdfTemplates/admin_pdf_downloader';




function ChallengeResultPdf(data: iPdf) {
    return (
        <div>
            <ToastContainer />
            <div className='fixed top-0 left-0 w-full z-[999] h-[100vh]'>
                <div className="max-h-[80vh] min-h-[200px] w-full flex justify-center">
                    <div onClick={() => data.close()} className='back_drop backdrop-blur fixed top-0 left-0 w-full bg-blue/30 h-[100vh] bg-blue'></div>
                    <div className='bg-white shadow-lg rounded-[15px] w-[95%] lg:w-[860px] p-3 relative'>
                        <div className="mt-[10px] px-3 font-primary ">
                            {
                                data.loaded ?
                                    <div className="" id="">
                                        <h3 className="text-blue text-center text-uppercase text-[22px] font-bold mb-3">Download Report</h3>
                                        <div className="text-center">
                                            <PDFDownloadLink className="bg-blue text-yellow rounded-[10px] font-bold font-primary text-yellow h-[45px] mb-3 w-[150px] mx-auto flex items-center justify-center px-[10px] text-[14px]" style={{color : 'yellow'}} document={
                                                <ChallengeResultPDFTemplate challenge={data.challenge} title={data.challenge?.title} challengePosts={data.challengePosts} data={data.data} />
                                            } fileName={`${data.challenge?.title}.pdf`}>
                                                {(blog) =>
                                                    blog.loading ? 'Loading document...' : 'Export'
                                                }
                                            </PDFDownloadLink>
                                        </div>
                                    </div> :
                                    <div className='text-center'>
                                        <ButtonLoader />
                                        <p>Generating document, please wait...</p>
                                    </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}


export default ChallengeResultPdf;