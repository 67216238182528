import axios from 'axios';
import React, { useEffect } from 'react'
import { HomeBanner, Companies, About, HowItWorks, Brands, Blog, AppSection, BeentosForBrands } from '../components/home'
import useDocumentTitle from '../helpers/pageTitle'
import FeaturedBrands from '../components/home/featured_brands';
import SuccessStories from '../components/home/SuccessStories';
import InfluencerSuccess from '../components/home/faqs';
import Faqs from '../components/home/faqs';
import ls from 'localstorage-slim'
import { useNavigate } from 'react-router-dom';
import { useTypedSelector } from '../hooks/useSelector';
import BeentosFeatures from '../components/home/features';
import CoreFeatures from '../components/home/core_features';

export default function Home() {
  const { loggedInBrand } = useTypedSelector((state) => state.appReducer);

  const navigate = useNavigate()
  useEffect(() => {
    let href: any = window.location.href;
    href = href.split("#")
    if (href[1]) {
      let href1 = href[1];
      if (href1.includes("access_token")) {
        if (!loggedInBrand) {
          alert("Please login first");

        } else {
          navigate("/brand/login")
          let facebookcallbook = href1;
          facebookcallbook = facebookcallbook.split("&")
          let access_token, long_lived_token;
          access_token = facebookcallbook[0].replace("access_token=", "")
          long_lived_token = facebookcallbook[3].replace("long_lived_token=", "")
          ls.set("bt_access_token", access_token, { encrypt: true })
          ls.set("bt_long_lived_token", long_lived_token, { encrypt: true })
          navigate("/brand/settings/apps")
        }
      }
    }
  }, [])
  // const getAuthTokenFromSecondaryApi = (data : any) => {
  //   getAuthTokenFromSecondar()
  //   // console.log("called...", data)
  //   var apiUrl2 = "https://admin.fricassa.com/api";
  //   axios
  //     .post(`${apiUrl2}/auth/login`, data)
  //     .then((res) => {
  //       console.log("result from secondary token", res.data);
  //     })
  //     .catch((error) => {
  //       console.log("error from secondary token", error.response);
  //     });
  // };

  // const getAuthTokenFromSecondar = () => {
  //   // console.log("called...", data)
  //   var apiUrl2 = "https://admin.fricassa.com/api";
  //   axios
  //     .get(`${apiUrl2}/general`)
  //     .then((res) => {
  //       console.log("result from secondary token", res.data);
  //     })
  //     .catch((error) => {
  //       console.log("error from secondary token", error.response);
  //     });
  // };

  useDocumentTitle('HOME ')
  return (
    <div>
      <div className="mx-auto max-w-[1440]">
        <HomeBanner />
        <Companies />
        <About />
        {/* <BeentosFeatures /> */}
        <CoreFeatures />
        <BeentosForBrands />
        {/* <HowItWorks /> */}
        <FeaturedBrands />
        <SuccessStories />
        <Faqs />
        <Brands />
        <Blog />
        <AppSection />
      </div>
    </div>
  )
}
