import React from 'react'
import Image3 from '../../assets/img/image3.png';

function About() {
  return (
    <div className='mx-auto bg-white mt-[0px] py-[60px]' id='about_page'>
      <div className="container mx-auto flex flex-col-reverse lg:flex-row items-center">
        <div className='flex-1 lg:pr-[20px]'>
          <h3 className="text-[35px] font-primarybold text-blue dark:text-white">About Us</h3>
          <p className="text-blue mb-4 mt-3 font-primary dark:text-white text-[18px]">Beentos is an innovative digital platform that helps businesses tap into the power of user-generated content to build their brand and engage with their audience. By leveraging the creativity of their users, brands can unlock a new level of engagement and drive authentic social proof that leads to increased brand loyalty and customer acquisition.</p>
          <p className="text-blue dark:text-white font-primary text-[18px]">Our platform offers a user-friendly and intuitive interface that enables businesses to easily launch and manage UGC campaigns, track metrics, and gain valuable insights into their audience. With Beentos, businesses of all sizes can unlock the power of user-generated content to drive growth and build a strong online presence.</p>
        </div>
        <div className='flex-1'>
          <img src={Image3} className="h-[245px] mb-4 lg:mb-0 lg:h-[453px] object-contain w-full" alt="" />
        </div>
      </div>
    </div>
  )
}
export default About