import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useDocumentTitle from '../../helpers/pageTitle';
import { useTypedSelector } from '../../hooks/useSelector';
import { ActionType } from '../../store/actions';
import instance from '../../helpers/axios';
import { toast, ToastContainer } from 'react-toastify';
import ScrollToTop from '../../helpers/scrollToTop';
import Loader from '../../helpers/loader';
import Button from '../../components/button';
import { calendar, checkBox, checkBoxChecked, who } from '../../components/images';
import { graph1, graph2, trendup } from '../../components/super_images';
import { CChart } from '@coreui/react-chartjs';
import { BsCircleFill } from 'react-icons/bs';
import { BiDotsVertical } from 'react-icons/bi';
import { AiOutlineCaretDown, AiOutlineCheckSquare, AiOutlineEye } from 'react-icons/ai';
import { formatDate, getPreviousDate, getToday, ValidDate } from '../../helpers/helpers';
import Pagination from '../../components/pagination';
import { DateRangePicker } from 'rsuite';
// import { ShareModal } from '../../components/brand';

const Dashboard: React.FC = () => {
    const { loggedInAdmin } = useTypedSelector((state) => state.appReducer);
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { search } = useLocation()
    const [user, setUser] = useState('')
    const [selected, setSelected] = useState<number[]>([])
    const { acctSideBarActive } = useTypedSelector((state) => state.appReducer);
    const [isLoading, setIsLoading] = useState(false)
    const [c, setC] = useState([])
    const [allList, setAllList] = useState([])
    const [newUser, setnewUser] = useState(0)
    const [totalUsers, setTotalUsers] = useState(0)
    const [totalBrands, setTotalBrands] = useState(0)
    const [activeChallenge, setActiveChallenge] = useState<any[]>([])
    const [today, setToday] = useState(getToday(new Date()))
    const [prevDate, setPrevDate] = useState(getToday(new Date()))
    const [statDate, setStatDate] = useState(getPreviousDate(getToday(), 90))
    const [totalSubscription, setTotalSubscription] = useState(0)
    const [totalUserActivity, setTotalUserActivity] = useState(0)
    const [isDateChanged, setIsDateChanged] = useState(false)
    const [isCalenderOpen, setIsCalenderOpen] = useState(false)
    useEffect(() => {
        dispatch({
            type: ActionType.UPDATE_LAYOUT,
            payload: "admin"
        })

    }, [])

    useEffect(() => {
        if (!loggedInAdmin || loggedInAdmin == "") {
            // nobody is logged in
            navigate("/admin/login");
        }
    }, [loggedInAdmin])
    const chartlegends = [
        { title: "Running", className: "text-[#0B0149]" },
        { title: "Not Running", className: "text-[#0B01491F]" },
    ]




    useEffect(() => {
        const headers = {
            'Authorization': `Bearer ${loggedInAdmin}`
        }
        const startTimeCount = new Date().getTime() - (30 * 86400000)
        const startingDate = (new Date(startTimeCount).toISOString().split("T")[0])
        instance({
            method: "GET",
            url: `/user/admin/users-brands-counts?from=${startingDate}&to=${today}`,
            headers: headers
        }).then((response) => {
            // console.log("response", response)
            setTotalUsers(response.data.data.totalUsers)
            setTotalBrands(response.data.data.totalBrands)
            setnewUser(response.data.data.newUsers)

        }).catch((err) => {
            // alert(JSON.stringify(err))
        }).finally(() => {
            // setIsLoading(false);
        })
    }, [])

    useEffect(() => {
        const headers = {
            'Authorization': `Bearer ${loggedInAdmin}`
        }
        instance({
            method: "GET",
            url: `/subscription/analytics/?from=${statDate}&to=${today}`,
            headers: headers
        }).then((response) => {
            console.log("response", response)
            setTotalSubscription(response.data.data.allTotalSubscriptionCost)
        }).catch((err) => {

        }).finally(() => {
            // setIsLoading(false);
        })
    }, [])

    useEffect(() => {
        const headers = {
            'Authorization': `Bearer ${loggedInAdmin}`
        }
        instance({
            method: "GET",
            url: `/challenge/all?closingDate=>${getToday(new Date())}`,
            headers: headers
        }).then((response) => {
            // console.log("response", response)
            setActiveChallenge(response.data.data.docs)
        }).catch((err) => {

        }).finally(() => {
            // setIsLoading(false);
        })
    }, [])
    const [running, setRunning] = useState([0, 0, 0, 0, 0, 0, 0])
    const [notRunning, setNotRunning] = useState([0, 0, 0, 0, 0, 0, 0])
    useEffect(() => {
        const headers = {
            'Authorization': `Bearer ${loggedInAdmin}`
        }
        instance({
            method: "GET",
            url: `/challenge/all??openingDate=>=${statDate}&openingDate=<=${today}&limit=10000`,
            headers: headers
        }).then((response) => {
            // console.log("response1", response)
            var data = response.data.data.docs;
            setRunning(getData(data, "running"))
            setNotRunning(getData(data, "not-running"))

        }).catch((err) => {

        }).finally(() => {
            // setIsLoading(false);
        })
    }, [])

    const getData = (data: any, source: string) => {
        var responseData: number[] = [];

        var sun = 0, mon = 0, tue = 0, wed = 0, thu = 0, fri = 0, sat = 0;

        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            var dataDate = new Date(element.openingDate);
            console.log(element.closingDate)
            if (source == "running") {
                if (!ValidDate(element.closingDate)) {
                    continue;
                }
            }


            if (source == "not-running") {
                if (ValidDate(element.closingDate)) {
                    continue;
                }
            }
            if (dataDate.getUTCDay() === 0) {
                if (responseData[6] == null || responseData[6] == undefined) {
                    responseData[6] = 1;
                } else {
                    responseData[6] = responseData[6] + 1;
                }
            } else {
                if (responseData[dataDate.getUTCDay() - 1] == null || responseData[dataDate.getUTCDay() - 1] == undefined) {
                    responseData[dataDate.getUTCDay() - 1] = 1;
                } else {
                    responseData[dataDate.getUTCDay() - 1] = responseData[dataDate.getUTCDay() - 1] + 1;
                }
            }
        }
        // console.log(responseData)
        return responseData;
    }

    useEffect(() => {
        const headers = {
            'Authorization': `Bearer ${loggedInAdmin}`
        }
        instance({
            method: "GET",
            url: `/auth/allUserActivity?from=${statDate}&to=${today}`,
            headers: headers
        }).then((response) => {
            setTotalUserActivity(response.data.data.totalDocs)
        }).catch((err) => {

        }).finally(() => {
            // setIsLoading(false);
        })
    }, [])

    useEffect(() => {
        const search_ = (search.replace('?', '')).split("&")
        if (search_.length > 0) {
            search_.forEach(s => {
                if (s.includes('page=') !== false) {
                    const scrollTo = s.replace('page=', '')
                    setPage(parseInt(scrollTo))
                    return false;
                }
            });
        }
    }, [search])
    const handleRangeChanged = (date: any) => {
        // console.log(date)
        setStatDate(getToday(date[0]))
        setToday(getToday(date[1]))
        setIsDateChanged(true);
    }

    const [allPosts, setAllPosts] = useState([])
    const [allPostCount, setAllPostCount] = useState(0)
    const [page, setPage] = useState(1)
    useEffect(() => {
        const headers = {
            'Authorization': `Bearer ${loggedInAdmin}`
        }
        instance({
            method: "GET",
            url: `challenge-post?_orderBy=noOfVoters&_page=${page}`,
            headers: headers
        }).then((response) => {
            console.log("response", response)
            setAllPostCount(response.data.data.totalDocs)
            setAllPosts(response.data.data.docs)
        }).catch((err) => {

        }).finally(() => {
        })
    }, [page])

    const addSelected = (value: number) => {
        var selected_ = selected;
        if (selected_.includes(value)) {
            selected_.splice(selected_.indexOf(value), 1)
        } else {
            selected_.push(value)
        }
        setSelected(selected_);
        setTimeout(() => {
            setAllList(c)
        }, 500);
    }

    const formatNumber = (value: number) => {
        try {
            return `${value.toString()}`

        } catch (error) {
            return `0`
        }
        // return `${value.toString()}k`
    }
    useDocumentTitle('ADMIN - DASHBOARD')
    // const a = []
    return (
        <div>
            <div className="max-w-[1445px] font-primary">
                <ToastContainer />
                {/* wrapper */}
                {
                    isLoading ? <Loader /> :
                        <div className='container font-primary min-h-[95vh] flex-inital lg:px-[15px] px-[5px] py-[25px] w-full'>
                            {/* filter buttons */}
                            <div>
                                {/* <div className="flex max-w-[350px] shadow-sm bg-white rounded-[10px]" >
                                    <Button containerClass='w-full bg-blue rounded text-white' text='Days' />
                                    <Button containerClass='w-full bg-white rounded text-blue opacity-50 border-0' text='Months' />
                                    <Button containerClass='w-full bg-white rounded text-blue opacity-50 border-0' text='Years' />
                                </div> */}
                                <div className="calendar relative w-full max-w-[500px]">
                                    <div className="rounded-[10px] items-center shadow bg-white p-4 flex">
                                        <div>
                                            <img src={calendar} className="object-contain" alt="" />
                                        </div>
                                        <div className='flex-1 px-4'>
                                            <h3 className="text-[18px] text-blue font-bold">Change Period</h3>
                                            {
                                                isDateChanged ?
                                                    <p className='text-gray2 text-[13px]'>{formatDate(statDate, 3)} - {formatDate(today, 3)}</p>
                                                    :
                                                    <p className='text-gray2 mt-1 text-[13px]'>Last 90days</p>
                                            }
                                        </div>
                                        <div>
                                            <AiOutlineCaretDown className='text-blue' style={{ cursor: 'pointer' }} onClick={() => setIsCalenderOpen(!isCalenderOpen)} />
                                        </div>
                                    </div>
                                    <div className="absolute left-0 bottom-0">
                                        <DateRangePicker
                                            open={isCalenderOpen}
                                            format="yyyy-MM-dd"
                                            defaultCalendarValue={[new Date(statDate), new Date(today)]}
                                            onChange={handleRangeChanged}
                                            editable={false}
                                            // defaultOpen={true}
                                            ranges={[]}
                                            onOk={() => setIsCalenderOpen(false)}
                                        // cancelable={false}
                                        />
                                    </div>
                                </div>
                            </div>


                            {/* overview data */}
                            <div className="grid gap-4 lg:grid-cols-2 mt-4">
                                <div>
                                    <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-4">
                                        <div className="rounded-[14px] shadow bg-white h-[140px] flex justify-center flex-col items-center">
                                            <img src={who} alt="" />
                                            <div className="mt-2">
                                                <p className='text-gray2 text-[14px]'>New Users</p>
                                                <h3 className='text-blue text-[26px] mt-1 font-primarybold text-center font-[700]'>{formatNumber(newUser)}</h3>
                                            </div>
                                        </div>
                                        <div className="rounded-[14px] shadow bg-white h-[140px] flex justify-center flex-col items-center">
                                            <img src={who} alt="" />
                                            <div className="mt-2">
                                                <p className='text-gray2 text-[14px]'>Total Users</p>
                                                <h3 className='text-blue text-[26px] mt-1 font-primarybold font-[700] text-center'>{formatNumber(totalUsers)}</h3>
                                            </div>
                                        </div>
                                        <div className="rounded-[14px] shadow bg-white h-[140px] flex justify-center flex-col items-center">
                                            <img src={who} alt="" />
                                            <div className="mt-2">
                                                <p className='text-gray2 text-[14px]'>Total Brands</p>
                                                <h3 className='text-blue text-[26px] mt-1 font-primarybold text-center font-[700]'>{formatNumber(totalBrands)}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="grid lg:grid-cols-2 gap-4">
                                        <div className="rounded-[14px] shadow bg-white h-[140px] flex justify-between p-3 gap-2  items-center">
                                            <img src={graph1} className="w-[40%] flex-initial" alt="" />
                                            <div className="mt-2">
                                                <p className='text-gray2 text-[14px]'>Total Subscription</p>
                                                <h3 className='text-blue text-[26px] mt-1 font-primarybold font-[700]'>{formatNumber(totalSubscription)}</h3>
                                                <p className='text-[14px] text-gray2 '> <span className='text-green-700'>23%</span> more than last month</p>
                                            </div>
                                        </div>
                                        <div className="rounded-[14px] shadow bg-white h-[140px] flex justify-between p-3 gap-2  items-center">
                                            <img src={graph2} className="w-[40%] flex-initial" alt="" />
                                            <div className="mt-2 flex-initial w-full">
                                                <p className='text-gray2 text-[14px]'>User activity</p>
                                                <h3 className='text-blue text-[26px] mt-1 font-primarybold font-[700]'>{formatNumber(totalUserActivity)}</h3>
                                                <p className='text-[14px] text-gray2 '> <span className='text-green-700'>23%</span> more than last month</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* brand activity */}
                            <div className="mt-[30px] flex gap-4 lg:flex-row flex-col">
                                <div className="p-[20px] flex-initial lg:w-[75%] bg-white shadow rounded-[14px]">
                                    <div className="mb-3">
                                        <div className="flex items-start lg:items-center gap-5 flex-row justify-between">
                                            <div className='w-full flex lg:flex-row flex-col justify-between'>
                                                <div className='w-full flex justify-between items-center lg:w-initial'>
                                                    <h3 className='text-blue font-bold'>Brand Activity</h3>
                                                </div>
                                                <ul className='flex gap-2'>
                                                    {
                                                        chartlegends.map((legend, i) => (
                                                            <li key={i} className='flex items-center gap-2'>
                                                                <BsCircleFill className={`text-[14px] ${legend.className}`}></BsCircleFill>
                                                                <span className='text-gray2 text-[12px]'>{legend.title}</span>
                                                            </li>
                                                        ))
                                                    }

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <CChart
                                        options={{

                                            plugins: {
                                                legend: {
                                                    display: false,
                                                },
                                            }
                                        }}
                                        type="bar"
                                        data={{
                                            labels: ['Mon', 'Tue', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
                                            datasets: [
                                                {
                                                    label: 'Running campaign',
                                                    backgroundColor: '#2712A9',
                                                    data: running,
                                                    borderRadius: 10,
                                                    barThickness: 10,
                                                },
                                                {
                                                    label: 'Not Running',
                                                    backgroundColor: '#0B01491F',
                                                    data: notRunning,
                                                    borderRadius: 10,
                                                    barThickness: 10,
                                                },
                                            ],
                                        }}
                                    // labels="months"
                                    />
                                </div>
                                <div className="lg:w-[25%] bg-white rounded-[14px] p-3 shadow flex-initial">
                                    <div className="my-3">
                                        <h3 className="text-blue font-bold text-[18px]">Active Challenge</h3>
                                    </div>
                                    <hr />
                                    <div className="flex py-3">
                                        <div className="flex-1 w-full">
                                            <p className='text-[#0B0149BF] text-[13px]'>Campaign</p>
                                        </div>
                                        <div className="">
                                            <p className='text-[#0B0149BF] text-[13px]'>Growth</p>
                                        </div>
                                    </div>
                                    <hr />
                                    <div>
                                        {
                                            (activeChallenge.splice(0, 5)).map((item, i) => (


                                                <div className="flex mt-[25px]">
                                                    <div className="flex-1 flex items-center w-full">
                                                        <img src={item.brand ? (item.brand.avatar ? item.brand.avatar : who) : who} className="h-[20px] object-contain" alt="" /> &nbsp;
                                                        <h6 className='text-[#0B014980]'>{item.title}</h6>
                                                    </div>
                                                    <div className="flex items-center ">
                                                        <img src={trendup} alt="" className="h-[14px] object-contain" /> &nbsp;
                                                        <p className='text-[#0B014980]'>30%</p>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>

                            {/* Top voted users */}
                            <div className="py-3 px-3 mt-[30px] rounded shadow bg-white " style={{ overflow: 'scroll' }}>
                                <div className="search flex justify-between items-center mb-4">
                                    <h3 className="font-primaary font-bold text-blue">Top Voted</h3>
                                </div>

                                <div className="shadow-[10px] rounded-[10px] w-[800px] md:w-full lg:w-full border-graylight border-2" >
                                    <table className="border-collapse text-sm table-auto w-[800px] md:w-full lg:w-full">
                                        <thead className='text-blue p-2 h-[45px] border-b-graylight border-b-2'>
                                            <tr>
                                                <th></th>
                                                <th>Username</th>
                                                <th className='text-left'>Brand</th>
                                                <th className='text-left'>Details</th>
                                                <th className="text-left"><BiDotsVertical /></th>
                                            </tr>
                                        </thead>
                                        <tbody className='p-2'>
                                            {
                                                allPosts.map((item: any, i) => (
                                                    <tr key={i} className='p-2 text-[#0B014980] border-spacing-2' style={{ height: '70px' }}>
                                                        <td className='w-[120px] text-center pr-2'>
                                                            <div className=" flex items-center justify-end">
                                                                {
                                                                    selected.includes(item) ?
                                                                        <img onClick={() => addSelected(item)} style={{ cursor: 'pointer' }} src={checkBoxChecked} alt="" />
                                                                        :
                                                                        <img onClick={() => addSelected(item)} style={{ cursor: 'pointer' }} src={checkBox} alt="" />
                                                                }
                                                                <div className="mx-2"></div>
                                                                <div className="mx-2"></div>
                                                                <img className='h-[40px] w-[40px] rounded-[100%] object-cover' src={item.user ? (item.user.avatar ? item.user.avatar : who) : who} alt="" />

                                                            </div>
                                                        </td>
                                                        <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''}`}>
                                                            <div className="flex items-center font-bold font-primary">
                                                                <div className='pl-3 min-w-[200px]'>
                                                                    <h3 className='text-blue'>{item.user ? item.user.fullName : ""}</h3>
                                                                    <p>{item.noOfVoters} votes</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''} min-w-[250px]`}>{"Brand Name"}</td>
                                                        <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''}`}>{item.description}</td>
                                                        <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''} w-[50px]`}><BiDotsVertical /></td>
                                                    </tr>
                                                ))
                                            }

                                        </tbody>

                                        {
                                            allPosts.length === 0 ?
                                                <p>No record found</p>
                                                :
                                                ''
                                        }
                                    </table>
                                </div>
                            </div>
                            <Pagination length={allPostCount} page={page} />
                        </div>
                }

            </div>
        </div>
    )
}

export default Dashboard