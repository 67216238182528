import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useDocumentTitle from '../../helpers/pageTitle';
import { useTypedSelector } from '../../hooks/useSelector';
import { ActionType } from '../../store/actions';
import instance from '../../helpers/axios';
import { toast, ToastContainer } from 'react-toastify';
import ScrollToTop from '../../helpers/scrollToTop';
import Loader from '../../helpers/loader';
import Button from '../../components/button';
import { checkBox, checkBoxChecked, checked, exporticon, notChecked, paypal, who } from '../../components/images';
import { flutter, gmail, graph1, graph2, trendup } from '../../components/super_images';
import { CChart } from '@coreui/react-chartjs';
import { BsCaretDownSquare, BsCircleFill } from 'react-icons/bs';
import { BiCaretDown, BiDotsVertical } from 'react-icons/bi';
import { AiOutlineCheckSquare, AiOutlineEye, AiOutlinePlusCircle } from 'react-icons/ai';
import { formatDate } from '../../helpers/helpers';
import { CoreDropdown } from '../../components';
import { resultSort } from '../../data';
import { FiSearch } from 'react-icons/fi';
import SettingsAside from '../../components/admin/settings_aside';
// import { ShareModal } from '../../components/brand';

const SystemIntegration: React.FC = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { search } = useLocation()
    const [user, setUser] = useState('')
    const [selected, setSelected] = useState<number[]>([])
    const { acctSideBarActive } = useTypedSelector((state) => state.appReducer);
    const [isLoading, setIsLoading] = useState(false)
    const [c, setC] = useState([])
    const [search_, setSearch_] = useState("");
    const [allList, setAllList] = useState<any[]>([])
    useEffect(() => {
        dispatch({
            type: ActionType.UPDATE_LAYOUT,
            payload: "admin"
        })

    }, [])
    const chartlegends = [
        { title: "Running", className: "text-[#0B0149]" },
        { title: "Not Running", className: "text-[#0B01491F]" },
    ]

    const addSelected = (value: number) => {
        var selected_ = selected;
        if (selected_.includes(value)) {
            selected_.splice(selected_.indexOf(value), 1)
        } else {
            selected_.push(value)
        }
        setSelected(selected_);
        setTimeout(() => {
            setAllList(c)
        }, 500);
    }
    const [sort, setSort] = useState("All")
    const [page, setPage] = useState(1)
    function dropChanged(value: any) {
        setPage(1)
        setSort(value)
    }
    const searchChanged = (value: string) => {
        if (value === "" || value === undefined || value === null) {
            setSearch_("")
            return setAllList(c)
        };
        console.log("value", value)
        setSearch_(value)
        var filtered: any[] = [];
        allList.map((list: any) => {
            if (list.user) {
                var fname: string = list.user.fullName;

                if ((fname.toLowerCase()).includes(value.toLowerCase())) filtered = [...filtered, list];
            }
        })

        setAllList(filtered)

    }
    useDocumentTitle('ADMIN - Campaign Management')
    // const a = []
    return (
        <div>
            <div className="max-w-[1445px] font-primary">
                <ToastContainer />
                {/* wrapper */}
                {
                    isLoading ? <Loader /> :
                        <div className="px-[15px] h-100 relative font-primary flex max-w-[1440]">
                            <SettingsAside />
                            <div className='lg:container sm:mx-auto md:mx-auto flex-inital lg:px-[15px] px-[5px] py-[25px] w-full'>
                                <h6 className="text-blue mb-[5px] font-primarybold text-[18px]">System Integration</h6>
                                <p className="text-blue opacity-75">Choose which notification you would like users to recieve</p>

                                <div className="my-3 shadow lg:items-center rounded-[10px] w-full justify-between gap-3 bg-white p-[20px] flex">
                                    <div className="">
                                        <img src={gmail} alt="" />
                                    </div>
                                    <div className="flex-1 flex flex-col lg:justify-between lg:flex-row gap-3">
                                        <div className=' '>
                                            <h3 className='text-[14px] text-blue'>Email</h3>
                                            <p className="text-blue opacity-75">Integrate Email</p>
                                        </div>
                                        <div>
                                            <Link to={'/brand/challenge/create'} className='bg-yellow rounded-[45px] font-bold font-primary w-[50px] h-[35px] text-blue w-full lg:w-[120px] text-blue  lg:h-[35px] flex items-center justify-center px-[10px] text-[14px]'> <AiOutlinePlusCircle className='font=bold' /> <div className="mx-1"></div> <span className=''>Add Email</span> </Link>
                                        </div>
                                    </div>
                                </div>


                                <div className="my-3 shadow lg:items-center rounded-[10px] w-full bg-white p-[20px]">
                                    <div className="mt-3 w-full justify-between gap-3 bg-white p-[10px] flex">
                                        <div className="">
                                            <img src={paypal} alt="" />
                                        </div>
                                        <div className="flex-1 flex lg:items-center flex-col lg:justify-between lg:flex-row gap-3">
                                            <div className=''>
                                                <h3 className='text-[14px] text-blue'>Paypal</h3>
                                            </div>
                                            <div>
                                            <Link to={'/brand/challenge/create'} className='bg-yellow rounded-[45px] font-bold font-primary w-[50px] h-[35px] text-blue w-full lg:w-[120px] text-blue  lg:h-[35px] flex items-center justify-center px-[10px]  lg:text-[14px]'> <AiOutlinePlusCircle className='font=bold' /> <div className=" mx-1"></div> <span className=''>Add</span> </Link>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="mt-3 w-full justify-between gap-3 bg-white p-[10px] flex">
                                        <div className="">
                                            <img src={flutter} alt="" />
                                        </div>
                                        <div className="flex-1 lg:items-center flex flex-col lg:justify-between lg:flex-row gap-3">
                                            <div className=''>
                                                <h3 className='text-[14px] text-blue'>Stripe</h3>
                                            </div>
                                            <div>
                                            <Link to={'/brand/challenge/create'} className='bg-yellow rounded-[45px] font-bold font-primary w-[50px] h-[35px] text-blue w-full lg:w-[120px] text-blue  lg:h-[35px] flex items-center justify-center px-[10px] tex tlg:-[14px]'> <AiOutlinePlusCircle className='font=bold' /> <div className="mx-1"></div> <span className=''>Add</span> </Link>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-3 w-full justify-between gap-3 bg-white p-[10px] flex">
                                        <div className="">
                                            <img src={flutter} alt="" />
                                        </div>
                                        <div className="flex-1 flex flex-col lg:items-center lg:justify-between lg:flex-row gap-3">
                                            <div className=''>
                                                <h3 className='text-[14px] text-blue'>Flutterwave</h3>
                                            </div>
                                            <div>
                                            <Link to={'/brand/challenge/create'} className='bg-yellow rounded-[45px] font-bold font-primary w-[50px] h-[35px] text-blue w-full lg:w-[120px] text-blue  lg:h-[35px] flex items-center justify-center px-[10px]  lg:text-[14px]'> <AiOutlinePlusCircle className='font=bold' /> <div className=" mx-1"></div> <span className=''>Add</span> </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                }

            </div>
        </div>
    )
}

export default SystemIntegration