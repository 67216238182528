import React from 'react'
import ExcelExportHelper from '../../components/excelHelpers';
// import { DownloadExcel } from "react-excel-export";

function ExcelExport() {

    const multiDataSet = [
        {
          columns: [
            { value: "Name", widthPx: 50 }, // width in pixels
            { value: "Salary", widthCh: 20 }, // width in charachters
            { value: "Sex", widthPx: 60, widthCh: 20 }, // will check for width in pixels first
          ],
          data: [
            ["Johnson", 30000, "Male"],
            ["Monika", 355000, "Female"],
            ["Konstantina", 20000, "Female"],
            ["John", 250000, "Male"],
            ["Josef", 450500, "Male"],
          ],
        },
        {
          xSteps: 1, // Will start putting cell with 1 empty cell on left most
          ySteps: 5, //will put space of 5 rows,
          columns: ["Name", "Department"],
          data: [
            ["Johnson", "Finance"],
            ["Monika", "IT"],
            ["Konstantina", "IT Billing"],
            ["John", "HR"],
            ["Josef", "Testing"],
          ],
        },
      ];

    const books = [
        {
            author: "Chinua Achebe",
        },
        {
          author: "Chinua Achebe",
          country: "Nigeria",
          language: "English",
          pages: 209,
          title: "Things Fall Apart",
          year: 1958,
        },
        {
          author: "Hans Christian Andersen",
          country: "Denmark",
          language: "Danish",
          pages: 784,
          title: "Fairy tales",
          year: 1836,
        },
        {
          author: "Dante Alighieri",
          country: "Italy",
          language: "Italian",
          pages: 928,
          title: "The Divine Comedy",
          year: 1315,
        },
      ];

      const DATA = [
        {
          STUDENT_DETAILS: {
            id: 101,
            name: "Suman Kumar",
            parentName: "Suresh Kumar",
            classroom: "12th",
            subject: "Non Medical",
            division: "1st",
            status: "Pass",
          },
          MARKS: {
            maths: 75,
            physics: 65,
            chemistry: 72,
            english: 62,
            computerScience: 80,
          },
        },
        {
          STUDENT_DETAILS: {
            id: 102,
            name: "Rahul Kumar",
            parentName: "Aatma Ram",
            classroom: "12th",
            subject: "Non Medical",
            division: "1st",
            status: "Pass",
          },
          MARKS: {
            maths: 70,
            physics: 75,
            chemistry: 82,
            english: 72,
            computerScience: 60,
          },
        },
        {
          STUDENT_DETAILS: {
            id: 103,
            name: "Anuj Kumar",
            parentName: "Ashok Kumar",
            classroom: "12th",
            subject: "Non Medical",
            division: "1st",
            status: "Pass",
          },
          MARKS: {
            maths: 60,
            physics: 65,
            chemistry: 92,
            english: 77,
            computerScience: 80,
          },
        },
      ];

  return (
    <div>
     

        <ExcelExportHelper data={DATA} />
    </div>
  )
}

export default ExcelExport