import React from 'react'
import BlogCard from '../../components/admin/blog'
import { blog3 } from '../../components/images'
import { iPost } from '../../models/post'
export const links:iPost[] = [
    {
        title : "BLOG",
        image : blog3,
        slug : "/admin/blog"
    },
    {
        title : "FAQs",
        image : blog3,
        slug : "/admin/faqs"
    }
] 
function Others() {
    
    return (
        <>
            <div>
                <div className="max-w-[1445px] font-primary">
                    <div className='container font-primary min-h-[95vh] flex-inital lg:px-[15px] px-[5px] py-[25px] w-full'>
                        <div className="search flex justify-between flex-col lg:flex-row items-center mb-4">
                            <h3 className="font-primaary font-bold text-blue">Others</h3>
                        </div>

                        <div className="flex lg:flex-col-3 gap-3">
                            {
                                links.map((item, i)=>(
                                    <BlogCard key={i} source="admin" title={item.title} image={item.image} slug={item.slug} />
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Others