import axios from "axios";
import { baseUrl } from "./axios";

export const httpGetWithToken = async (token: string, url: string) => {
    //   const token = sessionStorage.getItem("sj_token");
    return await axios
        .get(`${baseUrl}/${url}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((resp) => {
            return resp;
        })
        .catch(function (error) {
            console.log(error)
            if (error.code === "ERR_NETWORK") {
                return { error: "An error occurred, please try again later", status :  error.response.status  };
            }
            else {
                if (error.response.data.statusCode == "401") {

                } else {

                    const msg = error?.response?.data?.message;
                    return { error: msg, status : error.response.status };
                }

            }

        });
};

export const httpDeleteWithToken = async (token: string, url: string) => {
    //   const token = sessionStorage.getItem("sj_token");
    return await axios
        .delete(`${baseUrl}/${url}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((resp) => {
            return resp;
        })
        .catch(function (error) {
            console.log(error)
            if (error.code === "ERR_NETWORK") {
                return { error: "An error occurred, please try again later", status :  error.response.status  };
            }
            else {
                if (error.response.data.statusCode == "401") {

                } else {

                    const msg = error?.response?.data?.message;
                    return { error: msg, status : error.response.status };
                }

            }

        });
};