import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ScrollToDiv } from "./helpers";
import Loader from "./loader";
import {useDispatch} from 'react-redux';
import { ActionType } from "../store/actions";

export default function ScrollToTop() 
{
  const [showLoader, setShowLoader] = useState<boolean>(true)
  const { pathname, search } = useLocation();
  const noLayoutRoutes = ["/brand/login", "/pdf", "/brand/register", "/not-found", "/forgot-password", "/reset-password", "/brand/register-step-2", "/brand/register-step-1", "/brand/register-step-3"];
  const dispatch = useDispatch();
  useEffect(() => {
    if(!pathname.includes("/brand/challenge/create")) setShowLoader(true);
    
    if(noLayoutRoutes.includes(pathname)){
      dispatch({
          type : ActionType.UPDATE_LAYOUT,
          payload : "none"
      })
    }else{
      dispatch({
        type : ActionType.UPDATE_LAYOUT,
        payload : "main"
      })
    }

    window.scrollTo(0, 0);
    const search_ = (search.replace('?', '')).split("&")
    if(search_.length > 0){
      search_.forEach(s => {
        if(s.includes('section=') !== false){
          const scrollTo = s.replace('section=','')
          ScrollToDiv(scrollTo);
        }
      });
    }
    setTimeout(() => {
        setShowLoader(false);
    }, 1000);
  }, [pathname]);

  return showLoader ? <Loader /> : null;
}