import React, { useEffect, useState } from 'react'
import { BiDotsVertical, BiWallet } from 'react-icons/bi'
import { Link, useNavigate } from 'react-router-dom';
import { budgetIcon, challengeTypeIcon, musicIcon, needHelp, videoLength, winnersIcon } from '../../components/images';
import { capitalizeFirstLetter, formatDate, position, sharePrize, slugify, ValidDate } from '../../helpers/helpers';
import Button from '../button';
import ShareModal from './share_modal';
import { BsCodeSlash, BsEye } from 'react-icons/bs';
import { Hr } from '..';
import QRCode from 'react-qr-code';
import html2canvas from 'html2canvas';
import { Qr } from '../QrContainer';
import IntegrateModal from './integrate_modal';
import { FiCode, FiCopy } from 'react-icons/fi';
import { ToastContainer, toast } from 'react-toastify';
import DownloadQR from './downloadqr';

interface iChallenge {
    data: any,
    source?: string
}
const ChallengeCard: React.FC<iChallenge> = ({ data, source = "main" }) => {
    const [showOptions, setShowOptions] = useState(false)
    const [showIntegration, setShowIntegration] = useState(false)
    const [showDownloadQr, setShowDownloadQR] = useState(false)
    // console.log(data.title)
    const navigate = useNavigate();
    const { challengeCode, title, challengeVisibility, prizeNarration, currency, totalPrizeBudget, noOfWinners, description, challengeType, closingDate, backgroundMusic, _id, brandId } = data;
    const brand = data.brand;
    const brandName = brand ? brand.brandName : ""
    const shares = sharePrize(totalPrizeBudget, noOfWinners, 3)
    var remaining = Number(totalPrizeBudget - shares.reduce(function (a, b) { return a + b; }, 0)).toFixed(2);
    const ref = React.useRef<HTMLInputElement>(null);
    const [copied, setCopied] = useState(true)
    const [toCopy, SettoCopy] = useState('')

    const downloadCanvas = (id: string) => {
        var input = document.getElementById(id);
        html2canvas(input!)
            .then((canvas) => {
                var imgData = canvas.toDataURL('image/jpg');
                console.log(imgData)
            })
    }
    const clickToCopy = (content: string) => {
        if (content == "link") {
            SettoCopy(`https://${window.location.host}/${slugify(brandName)}/${challengeCode}`)
        }
    }
    useEffect(() => {
        if (toCopy === "") return;
        else {
            if (ref.current) {
                ref.current?.select();
                try {
                    document.execCommand('copy');
                    ref.current?.blur();
                    SettoCopy("")
                    toast.success("Copied to clipboard")
                } catch (err) {
                    alert('please press Ctrl/Cmd+C to copy');
                }
            }
        }
    }, [toCopy])
    return (
        <div className={`bg-white p-3 ${source == 'result' ? 'mt-4 rounded-t-[15px]' : 'my-4 rounded-[15px]'} relative lg:p-[20px]`}>
            <ToastContainer />
            {
                toCopy === "" ? '' :
                    <input className='absolute' ref={ref} onChange={() => { }} type="text" value={toCopy} />
            }
            {
                showIntegration &&
                <IntegrateModal close={() => {
                    setShowIntegration(false)
                }} challenge={data} />
            }
            {showDownloadQr && <DownloadQR brandName={brandName} challengeCode={challengeCode} close={()=> {
                setShowDownloadQR(false)
            }} />
                }
            {
                showOptions ?
                    <div className="rounded-[10px] z-[4444] absolute top-[40px] right-[10px] text-blue font-primary bg-white p-3 shadow-blue shadow-md">
                        <div className='my-2'>
                            <a onClick={() => navigate("/brand/challenge/update-banner/" + _id)} href="#?" className='my-'>Upload Ads Banner</a>
                        </div>
                        {
                            source !== "pending" ?
                                <>

                                    <div className='my-2'>
                                        <a href="#?" className='my-'>Share Campaign</a>
                                    </div>
                                </>
                                : ''
                        }
                        <div className='my-2'>
                            <Link to={'/brand/challenge/edit?challenge=' + data._id} className='my-2'>Edit Campaign</Link>
                        </div>
                        {
                            challengeCode &&
                            <div className='my-2'>
                                <Link to={'#?'} onClick={() => {
                                    setShowIntegration(true)
                                    setShowOptions(false)

                                }} className='my-2 flex items-center gap-2'>Integrate </Link>
                            </div>
                        }
                    </div>
                    : ''
            }

            <div className="header flex justify-between items-center" >
                <h3 className='text-blue font-bold font-primary' onClick={() => setShowOptions(false)}>{title}</h3>
                {source == "main" || source == "pending" ?
                    <BiDotsVertical onClick={() => setShowOptions(!showOptions)} />
                    : ""}
            </div>
            <div className="sub_header mt-3 bg-graylight rounded-[10px] py-3" onClick={() => setShowOptions(false)}>
                <div className="grid grid-cols-2 gap-y-3 lg:grid-cols-4">
                    <div className="col text-center">
                        <div className="px-3 flex items-center justify-center font-primary text-blue opacity-75">
                            <img src={budgetIcon} alt="" className='h-[14px] object-contain' /> &nbsp;
                            <p className='text-[14px]'>Total Budget</p>
                        </div>
                        <div className="h-[1px] my-2 bg-[#7E799F80] w-full"></div>
                        <h3 className="text-blue font-bold font-primary">${totalPrizeBudget}</h3>
                    </div>
                    <div className="col text-center">
                        <div className="px-3 flex items-center justify-center font-primary text-blue opacity-75">
                            <img src={videoLength} alt="" className='h-[14px] object-contain' /> &nbsp;
                            <p className='text-[14px]'>Max. Video length</p>
                        </div>
                        <div className="h-[1px] my-2 bg-[#7E799F80] w-full"></div>
                        <h3 className="text-blue font-bold font-primary">10</h3>
                    </div>
                    <div className="col text-center">
                        <div className="px-3 flex items-center justify-center font-primary text-blue opacity-75">
                            <img src={winnersIcon} alt="" className='h-[14px] object-contain' /> &nbsp;
                            <p className='text-[14px]'>Number of Winner</p>
                        </div>
                        <div className="h-[1px] my-2 bg-[#7E799F80] w-full"></div>
                        <h3 className="text-blue font-bold font-primary">{noOfWinners}</h3>
                    </div>
                    <div className="col text-center">
                        <div className="px-3 flex items-center justify-center font-primary text-blue opacity-75">
                            <img src={challengeTypeIcon} alt="" className='h-[14px] object-contain' /> &nbsp;
                            <p className='text-[14px]'>Campaign Type</p>
                        </div>
                        <div className="h-[1px] my-2 bg-[#7E799F80] w-full"></div>
                        <h3 className="text-blue font-bold font-primary">{challengeType}</h3>
                    </div>
                </div>
            </div>
            {source == "main" || source == "pending" ?
                <>
                    <div className="flex" onClick={() => setShowOptions(false)}>
                        <div className="flex-inial lg:w-[70%] w-full">
                            <div className="flex my-[15px]">
                                <div className="flex items-center justify-center font-primary mr-[20px] text-blue ">
                                    <p className='text-[14px] flex opacity-75'>Valid <span className='text-white'>.</span>thru: </p> &nbsp;
                                    <p className={`text-[14px] ${ValidDate(closingDate) ? '' : 'text-red-700'}`}>{closingDate ? formatDate(closingDate) : ''}</p>
                                </div>
                                {backgroundMusic && <div className="px-3 flex items-center justify-center font-primary text-blue opacity-75">
                                    <img src={musicIcon} alt="" className='h-[14px] object-contain' /> &nbsp;
                                    <p className='text-[14px]'>{backgroundMusic}</p>
                                </div>}
                                {currency && <div className="px-3 flex items-center justify-center font-primary text-blue opacity-75">
                                    <BiWallet /> &nbsp;
                                    <p className='text-[14px]'>{currency}</p>
                                </div>}
                            </div>
                            <div>
                                <div className='my-4  flex items-center font-primary text-blue opacity-75'>
                                    <BsEye /> &nbsp;
                                    <p className='text-[14px]'>{capitalizeFirstLetter(challengeVisibility)}</p>
                                </div>
                            </div>
                            <div className="mt-4">
                                <p className='font-bold font-primary'>About Campaign</p>
                                <p className='font-primary text-blue text-[12px]'>
                                    {description}
                                </p>
                                {
                                    source == 'main' ?


                                        <div className="mt-3 flex justify-between mx-1 lg:px-5">
                                            <Button onclick={() => {
                                                navigate(`/brand/challenge/clips/${_id}`)
                                            }} text='View Posts' containerClass="w-[150px] lg:w-[200px] bg-white border-blue border-2 text-blue mt-[30px]" />
                                            <Button onclick={() => {
                                                navigate(`/brand/challenge/result/${_id}`)
                                            }} text='View Results' containerClass="w-[150px] lg:w-[200px] bg-blue text-yellow mt-[30px]" />
                                        </div>
                                        :
                                        <></>
                                }
                            </div>
                        </div>
                        <div className="flex-inial lg:w-[30%] hidden lg:block w-full">
                            <div className="my-[15px]">
                                <p className='font-bold text-blue font-primary'>Distrubtion</p>
                                <div className="mt-3 bg-white p-2 text-[#7E799F] text-sm shadow-lg shadow-t rounded-[10px]">
                                    <p className='font-primary text-blue text-[14px]'>{prizeNarration ? prizeNarration : 'None'}</p>
                                </div>
                                {
                                    challengeCode &&
                                    <div className='flex justify-end'>

                                        <div onClick={()=> setShowDownloadQR(true)} className='text-center w-[100px] mt-3'>
                                            <Qr qrCode={challengeCode} brandName={brandName} />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    {
                        challengeCode &&
                        <div onClick={() => clickToCopy("link")} style={{ backgroundColor: "rgb(248 250 252)" }} className='mt-2 rounded-[10px] p-3'>
                            <p className='font-primary flex gap-2 items-center cursor-[pointer] font-[600]'>
                                {`https://${window.location.host}/${slugify(brandName)}/${challengeCode}`}
                                <span className='cursor-[pointer]' >
                                    <FiCopy />
                                </span>
                            </p>
                        </div>
                    }
                </> : ''}
        </div>
    )
}

export default ChallengeCard