import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../components/button';
import { bgWhite, arrowBack, bgYellow } from "../../components/images";
import Input from '../../components/input';
import useDocumentTitle from '../../helpers/pageTitle';
import { industries } from '../../data';
import { useTypedSelector } from '../../hooks/useSelector';
import { useDispatch } from 'react-redux';
import { ActionType } from '../../store/actions';
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';    
import instance from '../../helpers/axios';
import { generateToken, validateEmail } from '../../helpers/helpers';
import Steps from '../../components/signup/steps';

const BrandRegister4 = () => {
  const [industry, setIndustry] = useState <string>('')
  const [showIndustryErr, setShowIndustryErr] = useState(false)
  const [iindustrErr, setIndustryErr] = useState <string>('')
  const [password, setPassword] = useState('')
  const [passwordErr, setPasswordErr] = useState('')
  const [showPasswordErr, setShowPasswordErr] = useState(false)
  const [repassword, setRepassword] = useState('')
  const [repasswordErr, setRepasswordErr] = useState('')
  const [loading, setLoading] = useState(false)
  const [checked, setChecked] = useState(false)
  const [showTermsErr, setShowTermsErr] = useState(false)
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const [brandEmail, setBrandEmail] = useState <string>('')
  const [brandEmailErr, setBrandEmailErr] = useState <string>('')
  const [showBrandEmailErr, setShowBrandEmailErr] = useState(false)


  const [showRePasswordErr, setShowRePasswordErr] = useState(false)
  
  const { brandData } : any = useTypedSelector((state) => state.appReducer);

  useEffect(() => {
    dispatch({
      type : ActionType.UPDATE_LAYOUT,
      payload : "none"
  })
    if(!brandData) {
      navigate("/brand/register");
      return;
    }
  }, [brandData, navigate, dispatch])
  
  const login = () =>{
    let passed = true;
    setLoading(true)
    setShowRePasswordErr(false)
    setShowBrandEmailErr(false)
    setShowPasswordErr(false)
    setRepasswordErr("")
    setShowTermsErr(false)
    
    if(password === ''){
      passed = false
      setPasswordErr('Please enter password')
      setShowPasswordErr(true)
    }else if(password.length < 8 ){
      setPasswordErr('Password must be at least 8 digits')
      setShowPasswordErr(true)
      passed = false
    }else if(password !== repassword){
      setPasswordErr('Password not matched')
      setShowPasswordErr(true)
      passed = false
    }

    if(brandEmail === ""){
      // passed = false
      // setBrandEmailErr('Please enter a valid email address')
      // setShowBrandEmailErr(true)
    }
    else{
      if(!validateEmail(brandEmail)){
        setBrandEmailErr("Please enter a valid email address");
        setShowBrandEmailErr(true)
        passed = false;
      }
    }

    setTimeout(() => {
      if(!passed){
        setLoading(false)
        return false;
      }

      if(!checked){
        setLoading(false)
        setShowTermsErr(true)
        return false;
      }
      var data = JSON.parse(brandData);
      data = {...data, brand_email : brandEmail, password : password, repassword : repassword}

      var postData = {
        "brandName": data.brand,
        "brandCategory": (data.category.toLowerCase()).includes('personal') ? 'personal' : (data.category).toLowerCase(),
        "website": data.website ? data.website : "beentos.com",
        "email": data.brand_email,
        "country": data.country,
        "city": data.city,
        "state": data.state,
        "areaOfIndustry": [data.industry],
        "contactName": data.name,
        "contactPhoneNumber": data.phone,
        "corporateRegistrationNumber": data.reg,
        "socialMedia" : data.socialMedia,
        "contactEmail": data.brand_email,
        "password": data.password,
        "about": "",
        "address": `${data.city}, ${data.country}`,
      }

      // console.log(brandData)
      // console.log(postData)
      // return false;
      instance({
        url : '/brand/create',
        method : "POST",
        data : postData
      })
      .then((response)=>{
        console.log(response)
        toast.success("Registration successful, please login");
        setTimeout(() => {
          navigate(`/otp/brand?token${generateToken(20)}=&u=${postData.email}`)
        }, 1000);
      }).catch((err)=>{
        // show error
        console.log(err)
        toast.error(err.response.data.message);
      }).finally(()=>{
        // unset variables
        setLoading(false)
      })
    }, 1000);

  }
  
  useDocumentTitle('BRAND SIGN UP - STEP 4')
  return (
    <div className='h-[100vh] relative'>
      <ToastContainer />
      <img src={bgYellow} className="h-[100vh] w-full object-fill" alt="" />
      <div className="h-[100vh] absolute top-0 left-0 flex w-full justify-center items-center">
        <div className="login-container shadow-lg w-[95%] max-w-[520px] p-[40px] px-[30px] lg:px-[60px] text-center bg-white rounded-[20px] relative">
          <div className="absolute top-[10px] left-[10px]">
            <img src={arrowBack} className="h-[30px] object-contain" style={{cursor:'pointer'}} onClick={()=>navigate("/brand/register-step-2")} alt="back" />
          </div>
          <h3 className="text-blue font-primarybold  text-[26px] ">Create an account</h3>
          <div className="flex items-center justify-center my-[20px]">
            <Steps s={1} iscurrent={false} islast={false} />
            <Steps s={2} iscurrent={false} islast={false} />
            <Steps s={3} iscurrent={true} islast={true} />
          </div>
        
          <div className='w-full text-left'>
            <h3 className="text-blue font-primarybold text-[16px] ">Sign Up details</h3>
          </div>
          <form onSubmit={(e)=>e.preventDefault()}>
          <Input showError={showBrandEmailErr} err={brandEmailErr} placeholder="Brand's email" value={brandEmail} setValue={setBrandEmail} containerClass="mt-[10px] text-[1rem]" />
          <Input type='password' prevErr={showBrandEmailErr} showError={showPasswordErr} err={passwordErr} placeholder="Enter your password" value={password} setValue={setPassword} containerClass={` ${showBrandEmailErr ? 'mt-[30px]' : 'mt-[15px]' } text-[1rem]`} />
          <Input type='password' showError={showRePasswordErr} err={repasswordErr} placeholder="Re-enter password" value={repassword} setValue={setRepassword} containerClass={` ${showPasswordErr ? 'mt-[25px]' : 'mt-[15px]' } text-[1rem]`} />
          
          <div className="flex justify-start items-center p-1 mt-[12px] text-[10px] lg:text-[14px]">
          {
                                                !checked ?
                                                    <div onClick={() => {
                                                        setChecked(true)
                                                    }} className='border-2 cursor-[pointer] border-blue h-[18px] w-[18px]'></div>
                                                    :
                                                    <div onClick={() => {
                                                        setChecked(false)
                                                    }} className='border-2 cursor-[pointer] flex rounded-[100%] items-center justify-center border-blue  h-[18px] w-[18px] checkedBoss'>
                                                        {/* <div className='bg-blue  h-[8px] w-[8px] checkedBoss'>
                                                       </div>  */}
                                                        <span className='font-bold'> &#10003; </span>
                                                    </div>
                                            }
            {/* <label htmlFor="remember" className='flex items-center'> */}
              {/* <input defaultChecked={true} onChange={()=>setChecked(!checked)} type="checkbox" className='border-0 peer outline-0 bg-transparent' name="remember" id="remember" value={1} /> &nbsp; */}
             &nbsp;
              <span className="font-bold text-blue  opacity-50">By signing up, You agree to our &nbsp; </span> 
            {/* </label> &nbsp; */}
            <Link target={'_blank'} className='font-bold font-primary text-blue' to={'/terms'}>Terms & Conditions</Link> &nbsp;
          </div>
          {
            showTermsErr ? 
          <div className='px-2 w-full flex justify-start'>
              <p className="text-red-700"> <small>You must accept terms and conditions!</small> </p>
            </div> : ''
          }

          <div className="lg:flex lg:justify-end pt-[10px]">
          <Button onclick={login} text="Next" loading={loading} containerClass='w-full lg:w-[200px] bg-blue text-yellow font-bold hover:opacity-75 mt-[15px]' />
          </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default BrandRegister4