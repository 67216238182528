import React from "react";
import { PopupButton } from "react-calendly";
import { useNavigate } from "react-router-dom";
import { Icon } from '@iconify/react';

function BeentosForBrands() {
  const navigate = useNavigate();
  const beentos = [
    {
      name: "Amplify Brand Influence",
      icon : "logos:aws-amplify",
      description:
        "Beentos empowers your brand to amplify its influence by connecting you with the right influencers who resonate with your target audience.",
    },
    {
      name: "Boost Sales through Partnerships",
      icon : "solar:graph-new-up-bold",

      description:
        "Collaborate with influencers on Beentos to boost your sales. Leverage their authentic connections with followers to drive conversions and increase revenue",
    },
    {
      name: "Enhanced Visibility",
      icon : "logos:aws-amplify",

      description:
        "Increase your brand's visibility with Beentos. Our platform connects you with influencers who can showcase your products or services to a wider audience, boosting brand awareness.",
    },
    {
      name: "Expand Reach and Engagement",
      icon : "logos:aws-amplify",

      description:
        "Discover influencers on Beentos to expand your brand's reach and foster meaningful engagement with a wider audience.",
    },
  ];
  return (
    <div
      className="bg-blue dark:bg-black py-[60px]"
      id="BeentosForBrands_section"
    >
      <div className="container mx-auto">
        <div className="text-center">
          <h3 className="text-[36px] font-primarybold text-white">
            See How Beentos Helps Your Brand At Every Step
          </h3>
        </div>

        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4 justify-between my-[50px]">
          {beentos.map((item, i) => {
            return (
              <div className="p-3 pb-[10px] text-center shadow bg-graylight rounded">
                <div className="flex justify-center text-center">
                {/* <Icon icon={item.icon} width={100} color="#0b0149" /> */}
                </div>
                <h3 className="text-[22px] my-2 text-black font-bold font-primary">
                  {item.name}
                </h3>
                <div>
                  <p>{item.description}</p>
                </div>
              </div>
            );
          })}
        </div>

        <div className="flex lg:flex-row justify-center flex-col gap-4">
          <PopupButton
            className="
            button h-[50px] rounded-[15px] px-2 font-primarybold w-full lg:w-[300px] text-blue font-primary
               bg-yellow"
            url="https://calendly.com/beentos/beentos-demo"
            rootElement={document.getElementById("root")}
            text="Book a Demo"
          />

          <button
            onClick={() => navigate("/brand/register")}
            className={`button h-[50px] rounded-[15px] px-2 font-primarybold w-full lg:w-[300px] text-blue font-primary bg-yellow`}
          >
            {" "}
            Sign up for Free
          </button>
        </div>
      </div>
    </div>
  );
}

export default BeentosForBrands;
