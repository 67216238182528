import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useDocumentTitle from '../../../helpers/pageTitle';
import { useTypedSelector } from '../../../hooks/useSelector';
import { ActionType } from '../../../store/actions';
import { toast, ToastContainer } from 'react-toastify'
import Input from '../../../components/input';
import Button from '../../../components/button';
import { challengeTypes, rewardTypes, votingCategories, locations } from '../../../data';
import { Choose } from '../../../components/brand';
import { capitalizeFirstLetter, position, sharePrize } from '../../../helpers/helpers';
import instance from '../../../helpers/axios';
import { Divider } from 'rsuite';
// import ls from 'localstorage-slim';

const AdminCreateChallenge5: React.FC = () => {
    const params = useParams()
    const { loggedInAdmin } = useTypedSelector((state) => state.appReducer);
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [title, setTitle] = useState('')
    const [showTitleErr, setShowTitleErr] = useState(false)
    const [titleERr, setTitleERr] = useState('')

    const [loading, setLoading] = useState(false)
    const [budget, setBudget] = useState('')

    const [location_, setLocation] = useState('')
    const [locationErr, setLocationErr] = useState('')
    const [showLocationErr, setShowLocationErr] = useState(false)

    const [winner, setWinner] = useState('')
    const [maxLength, setMaxLength] = useState('')
    const [category, setCategory] = useState('')
    const { challengeData }: any = useTypedSelector((state) => state.appReducer);
    const [ctype, setCtype] = useState('')
    const [showCtypeErr, setShowCtypeErr] = useState(false)
    const [ctypeErr, setCtypeErr] = useState('')

    const [valid, setValid] = useState('')
    const [reward, setReward] = useState('')
    const [backgroundMusic, setBackgroundMusic] = useState('')
    const [description, setDescription] = useState('')
    const [isSaved, setIsSaved] = useState(false)
    const [shares, setShares] = useState<any[]>([])
    const [remaining, setRemaining] = useState(0)
    const [criteria, setCriteria] = useState("")
    const [isBudget, setIsBudget] = useState(true)
  const [rewardValues, setRewardValues] = useState("");
  const [brand, setBrand] = useState<any>({})
  const [currency, setCurrency] = useState("")
  const [userSub, setUserSub] = useState<any>({})
    const headers = {
        'Authorization': `Bearer ${loggedInAdmin}`
    }

    useEffect(() => {
        var brandId = params.brandid;

        instance({
            method: "GET",
            url: `/brand/all?_searchBy[]=brandId&_keyword=${brandId}`,
            headers: headers
        }).then((response) => {
            console.log("response1", response)
            var docs = response.data.data.docs;
            if (docs.length == 0) {
                navigate("/admin/")
            } else {
                setBrand(docs[0]);
            }
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
        })
    }, [])

    useEffect(() => {
        if (!loggedInAdmin || loggedInAdmin === "") {
            // nobody is logged in
            navigate("/brand/login");
        }
    }, [loggedInAdmin, navigate])

    useEffect(() => {
        dispatch({
            type: ActionType.UPDATE_LAYOUT,
            payload: "admin"
        })

    }, [])
    const [guidelinesArray, setGuidelinesArray] = useState<any[]>([]);

    const { pathname, search } = useLocation();
    const [challengeId, setChallengeId] = useState('')
    const [challenge, setChallenge] = useState<any>({})
    const [source, setSource] = useState<any>("")
    const [prizeNarration, setPrizeNarration] = useState("")

    useEffect(() => {
        const headers = {
            'Authorization': `Bearer ${loggedInAdmin}`
        }
        setLoading(true)
        const search_ = (search.replace('?', '')).split("&")
        if (search_.length > 0) {
            console.log(search_)
            search_.forEach(s => {
                if (s.includes('challenge=') !== false) {
                    const cha = s.replace('challenge=', '')
                    let s1 = "";
                    if (search_[1]) {
                        if (search_[1].includes("source")) {
                            s1 = search_[1].replace('source=', '')
                            setSource(s1)
                        }
                    }
                    // alert(s1)
                    setChallengeId(cha)

                    let url = s1 == "" ? `/challenge/draft/single/${cha}` : `/challenge/single/${cha}/1/10`;
                    instance({
                        method: s1 == "" ? "GET" : "POST",
                        url: url,
                        headers: headers
                    }).then((response) => {
                        var cha = response.data.data;
                        if(s1 != ""){
                          cha = response.data.data.challenge;
                        }
                        setChallenge(cha)
                        setLocation(cha.audienceLocation ? cha.audienceLocation : "Select")
                        setCtype(cha.challengeType ? capitalizeFirstLetter(cha.challengeType) : "Select")
                        setWinner(cha.noOfWinners)
                        setBudget(cha.totalPrizeBudget);
                        setValid(cha.closingDate)
                        setTitle(cha.title);

                        // setMaxLength();
                        const bud = cha.totalPrizeBudget;
                        const win = cha.noOfWinners;
                        const shares_ = sharePrize(Number(bud), Number(win), 3)
                        var remaining_ = Number(Number(bud) - shares_.reduce(function (a, b) { return a + b; }, 0)).toFixed(2);
                        setShares(shares_);
                        setRemaining(Number(remaining_))

                        setDescription(cha.description)
                        setCriteria(cha.judgingCriteria)
                        setPrizeNarration(cha.prizeNarration)
                        if (cha.currency) setCurrency(cha.currency)


                        if (cha.winnerSelectionType) {
                            var sel = cha.winnerSelectionType;
                            if (sel.length == 1) {
                                setCategory("Audience Choice")
                            } else {
                                setCategory("Brand Choice");
                            }

                        }
                        setBackgroundMusic(cha.backgroundMusic);

                        const r = cha.rewardType
                        var r_value = ""
                        r.forEach((element: any) => {
                            if (r_value === "") {
                                r_value = element;
                            } else {
                                r_value = r_value + "," + element;
                            }
                        });

                        if (r_value == "deals" || r_value == "deal") {
                            setIsBudget(false)
                        }
                        setReward(r_value);
                        if (cha.rewardType) {
                            setReward(cha.rewardType);
                            var rewar = "";
                            cha.rewardType.forEach((r: string) => {
                              rewar =
                                rewar === ""
                                  ? capitalizeFirstLetter(r)
                                  : `${capitalizeFirstLetter(r)},`;
                            });
                            setRewardValues(rewar);
                          }
                        setCriteria(cha.guidelines);
                        setGuidelinesArray(cha.guidelines.split("~end-of-a-step~"));

                    }).catch((err) => {
                        if (err.code == "ERR_NETWORK") {
                            alert("No internet connection")
                        }
                        if (err.response.data.statusCode === 403) {
                            navigate('/brand/login');
                            return false;
                        }

                        toast.error(err.response.data.message)
                    }).finally(() => {
                        setLoading(false)
                    })
                }
            });
        } else {
            navigate('/brand/dashboard');
        }

    }, []);
    useEffect(() => {
        // if (!challengeData) {
        //     navigate("/brand/dashboard");
        // } else {
        //     var data = JSON.parse(challengeData);


        //     const r = data.rewardType[0]
        //     var r_value = ""
        //     r.forEach((element: any) => {
        //         if (r_value === "") {
        //             r_value = element.value;
        //         } else {
        //             r_value = r_value + "," + element.value;
        //         }
        //     });
        //     setReward(r_value);

        //     if(r.length === 1){
        //         if(r[0].label === "Deal"){
        //             setIsBudget(false)
        //         }
        //     }

        // }
    }, [challengeData])
    const setChallengeTitle = (value: string) => {
        if (value.length <= 40) {
            setTitle(value);
        } else {
            setShowTitleErr(true)
            setTitleERr("Tittle cannot be more than 40 characters");
        }
    }

    useDocumentTitle('CREATE CHALLENGE ')

    const proceed = () => {
        // var ispassed = true;
        // setLoading(true)
        // if(title == ""){
        //     setShowTitleErr(true);
        //     setTitleERr("Title cannot be empty");
        //     ispassed = false;
        // }


        setLoading(true)
        setIsSaved(true)
        // navigate("/brand/challenge/create-step-payment");
        setLoading(false)
        return false;

    }
    const unProceed = () => {
        setIsSaved(false)
    }
    // const a = []
    return (
        <div className='px-[5px] py-[50px]'>
            <div className="container mx-auto">
                <div className="text-blue font-primarybold text-[18px]">{source != "" ? "VIEW CHALLENGE" : "Create Challenge"} : {brand.brandName}</div>
                <ToastContainer />
                {isSaved ? <Choose brandId={params.brandid?.toString()} challenge={challengeId} close={unProceed} /> : ''}
                <div className="py-[20px] bg-white shadow-lg rounded ">
                    <div className="text-censter font-primary">
                        <div className="mt-3 w-full">
                            <h3 className='text-blue  text-center text-[24px] m-0 font-bold font-primary'>{ source != "" ? "VIEW CHALLENGE" : "One last check!"}</h3>
                            <p className="text-center text-gray2 mt-4">Take one more glance at the information you’ve provided and edit where necessary</p>
                        </div>
                        <div className="mt-4 w-[80%] mx-auto">
              <div className="my-2">
                <label className="font-primary text-blue" htmlFor="">
                  What will you call this campaign?
                </label>
                <Input
                  isDisabled={true}
                  showError={showTitleErr}
                  err={titleERr}
                  value={title}
                  setValue={setTitle}
                  containerClass="h-[50px] bg-[#F0F0F4] border-graylight shadow-0 shadow-none border-0"
                  placeholder="Title"
                />
              </div>
              <div className="my-2 mt-4">
                <label className="font-primary text-blue mb-0" htmlFor="">
                  Tell People more about your campaign
                </label>
                {/* <Input isDisabled={true} err='' textareaH={"100px"} placeholder="Give more details or additional instructions" type='textarea' value={description} setValue={setDescription} containerClass="bg-[#F0F0F4] p-3 border-graylight border-0 shadow-0" /> */}
                <div className="min-h-[100px] rounded font-600 text-[14px] text-blue p-3 mt-[12px] bg-[#F0F0F4] border-graylight border-0">
                  {description}
                </div>
              </div>

              <div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
                <div className="my-2">
                  <label className="font-primary text-blue" htmlFor="">
                    Challenge type
                  </label>
                  {/* <Input isDisabled pos='top-[50%]' posErr='top-[30%]' prevErr={false} showError={showCtypeErr} err={ctypeErr} isDropDown={true} items={challengeTypes} placeholder="Select and option" value={ctype} setValue={setCtype} containerClass="mt-[4px] border-graylight border-0 shadow-0 mt-0 bg-[#F0F0F4]" /> */}
                  <Input
                    isDisabled={true}
                    showError={showTitleErr}
                    err={titleERr}
                    value={ctype}
                    setValue={setCtype}
                    containerClass="mt-[4px] h-[50px] bg-[#F0F0F4] border-graylight shadow-0 shadow-none border-0"
                    placeholder="Title"
                  />
                </div>
                {/* <div className="my-2">
                                    <label className='font-primary text-blue' htmlFor="">Reward Type</label>
                                    <Input pos='top-[40%]' posErr='top-[30%]' prevErr={false} isDropDown={true} items={rewardTypes} placeholder="Selec an option" type="readonly" value={reward} setValue={setReward} containerClass="mt-[0px] border-graylight border-0 shadow-0 mt-0 bg-[#F0F0F4]" />
                                </div> */}
                <div className="my-2">
                  <label
                    className="text-blue flex items-center gap-3 justify-between"
                    htmlFor=""
                  >
                    {" "}
                    <span>Reward type*</span>{" "}
                  </label>
                  <Input
                    isDisabled={true}
                    showError={showTitleErr}
                    err={titleERr}
                    value={rewardValues}
                    setValue={setRewardValues}
                    containerClass="mt-[4px] h-[50px] bg-[#F0F0F4] border-graylight shadow-0 shadow-none border-0"
                    placeholder="Title"
                  />

                  {/* {
                                        rewardValues === "" ?
                                        <DropDown showError={showRewardErr} err={rewardErr} isMulti={true} options={rewardTypes} handleChange={handleChange2} handleInputChange={handleInputChange2} />
                                        :
                                        <div >
                                            <Input isDisabled={true} showError={false} err={""} placeholder="" items={challengeTypes} value={rewardValues} setValue={()=>{}} containerClass="border-graylight border-2 shadow-sm mt-[5px] bg-[#F0F0F4]" />
                                        </div>
                                    } */}
                </div>
              </div>
              {isBudget ? (
                <div className="my-[15px]">
                  <p className="font-bold text-blue font-primary">
                    Distribution
                  </p>
                  <div className="mt-3 p-2 text-blue bg-[#F0F0F4] text-sm shadow-0 shadow-t rounded-[10px]">
                    <p>{challenge.prizeNarration}</p>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
                <div className="my-2">
                  <label className="font-primary text-blue" htmlFor="">
                    Winner determination*
                  </label>
                  {/* <Input pos='top-[40%]' posErr='top-[30%]' prevErr={false} isDropDown={true} items={votingCategories} placeholder="Selec an option" type="text" value={category} setValue={setCategory} containerClass="mt-[0px] border-graylight border-0 shadow-0 mt-0 bg-[#F0F0F4]" />
                   */}
                  <Input
                    isDisabled={true}
                    showError={showTitleErr}
                    err={titleERr}
                    value={category}
                    setValue={setCategory}
                    containerClass="mt-[4px] h-[50px] bg-[#F0F0F4] border-graylight shadow-0 shadow-none border-0"
                    placeholder="Title"
                  />
                </div>

                {/* <div className="my-2 hidden">
                  <label className="text-blue" htmlFor="">
                    Audience Location*
                  </label>
                  <Input pos='top-[40%]' posErr='top-[30%]' prevErr={false} showError={showLocationErr} err={locationErr} isDropDown={true} items={locations} placeholder="Select and option" type="text" value={location_} setValue={setLocation} containerClass="mt-[0px] border-graylight border-0 shadow-0 mt-0 bg-[#F0F0F4]" />
                  <Input
                    isDisabled={true}
                    showError={showTitleErr}
                    err={titleERr}
                    value={location_}
                    setValue={setLocation}
                    containerClass="mt-[4px] h-[50px] bg-[#F0F0F4] border-graylight shadow-0 shadow-none border-0"
                    placeholder="Title"
                  />
                </div> */}

                {/* <div className="my-2">
                                    <label className='font-primary text-blue mb-2' htmlFor="">Total Budget</label>
                                    <Input value={budget} setValue={setBudget} containerClass="h-[50px] bg-[#F0F0F4] border-graylight shadow-0 border-0" placeholder='Title' />
                                </div> */}

                {/* <div className="my-2">
                                    <label className='font-primary text-blue' htmlFor="">Numbers of winners</label>
                                    <Input value={winner} setValue={setWinner} containerClass="h-[50px] bg-[#F0F0F4] border-graylight shadow-0 border-0" placeholder='Title' />
                                </div> */}

                <div className="my-2">
                  <label className="font-primary text-blue" htmlFor="">
                    Valid Through
                  </label>
                  {/* <Input value={valid} type="date" setValue={setValid} containerClass="h-[50px] bg-[#F0F0F4] border-graylight shadow-0 border-0" placeholder='12/12/2024' /> */}
                  <Input
                    isDisabled={true}
                    showError={showTitleErr}
                    err={titleERr}
                    value={valid}
                    setValue={setValid}
                    containerClass="mt-[4px] h-[50px] bg-[#F0F0F4] border-graylight shadow-0 shadow-none border-0"
                    placeholder="Title"
                  />
                </div>

                <div className="my-2">
                  <label className="font-primary text-blue" htmlFor="">
                    Music
                  </label>
                  {/* <Input value={backgroundMusic} setValue={setBackgroundMusic} containerClass="h-[50px] bg-[#F0F0F4] border-graylight shadow-0 border-0" placeholder='Select a file' /> */}
                  <Input
                    isDisabled={true}
                    showError={showTitleErr}
                    err={titleERr}
                    value={backgroundMusic}
                    setValue={setBackgroundMusic}
                    containerClass="mt-[4px] h-[50px] bg-[#F0F0F4] border-graylight shadow-0 shadow-none border-0"
                    placeholder="Title"
                  />
                </div>

                <div className="my-2">
                  <label className="font-primary text-blue" htmlFor="">
                    Prize Narration
                  </label>
                  {/* <Input value={backgroundMusic} setValue={setBackgroundMusic} containerClass="h-[50px] bg-[#F0F0F4] border-graylight shadow-0 border-0" placeholder='Select a file' /> */}
                  <Input
                    isDisabled={true}
                    showError={showTitleErr}
                    err={titleERr}
                    value={prizeNarration}
                    setValue={setBackgroundMusic}
                    containerClass="mt-[4px] h-[50px] bg-[#F0F0F4] border-graylight shadow-0 shadow-none border-0"
                    placeholder="Title"
                  />
                </div>


                {currency && <div className="my-2">
                  <label className="font-primary text-blue" htmlFor="">
                    Prize Narration
                  </label>
                  {/* <Input value={backgroundMusic} setValue={setBackgroundMusic} containerClass="h-[50px] bg-[#F0F0F4] border-graylight shadow-0 border-0" placeholder='Select a file' /> */}
                  <Input
                    isDisabled={true}
                    showError={showTitleErr}
                    err={titleERr}
                    value={currency}
                    setValue={setBackgroundMusic}
                    containerClass="mt-[4px] h-[50px] bg-[#F0F0F4] border-graylight shadow-0 shadow-none border-0"
                    placeholder="Title"
                  />
                </div>}
              </div>

              <div className="my-2">
                <label className="font-primary text-blue" htmlFor="">
                  Guidelines
                </label>
                <div className="mt-[12px] bg-[#F0F0F4] border-graylight border-2 p-3 rounded-[10px] shadow-sm">
                  {guidelinesArray.map((item, i) => (
                    <>
                      <div className="text-blue font-bold flex gap-3" key={i}>
                        <div className="flex-initial w-full">
                          <p>{item}</p>
                        </div>
                        <div className="flex-initial w-[80px]"></div>
                      </div>
                      <Divider />
                    </>
                  ))}
                </div>
              </div>
              <div className="justify-center items-center flex gap-4 flex-col lg:flex-row">
                {
                  source !== "" ? 
                  <>
                   <Button
                  loading={loading}
                  text="Go back"
                  onclick={() => navigate("/admin/campaign-management")}
                  containerClass="w-full max-w-[250px] bg-blue text-yellow mt-[30px]"
                  />

                <Button
                  loading={loading}
                  text="Edit"
                  onclick={() => navigate(`/admin/edit-challenge/${challenge?.brandId}?challenge=${challenge?._id}`)}
                  containerClass="w-full max-w-[250px] bg-yellow text-blue mt-[30px]"
                  />
                  </>
                  :
                <Button
                  loading={loading}
                  text="Looks Good"
                  onclick={() => proceed()}
                  containerClass="w-full max-w-[250px] bg-blue text-yellow mt-[30px]"
                  />
                }
              </div>
            </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminCreateChallenge5