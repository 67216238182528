import React from 'react'
import { Link } from 'react-router-dom'
import  useDocumentTitle from '../helpers/pageTitle'

function Terms() {
    useDocumentTitle('Terms and Conditions')
    return (
        <div className="mx-auto max-w-[1200]">
            <div className="container mx-auto mt-[50px] dark:text-graylight">
                <div className="container mx-auto p-3 font-primary">
                    <div className="title my-3">
                        <h3 className="text-[32px] font-primarybold text-blue dark:text-graylight">
                            Terms and Conditions
                        </h3> 
                    </div>
                <p><strong>Locator being linked to; or</strong></p>
<p><span className='fw-400'>By use of any other description of our Website being linked to that makes sense within the context and format of content on the linking party&rsquo;s site.</span></p>
<p><span className='fw-400'>No use of Beentos's logo or other artwork will be allowed for linking absent a trademark license agreement.</span></p>
<p>&nbsp;</p>
<p><strong>iFrames</strong></p>
<p><span className='fw-400'>Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website.</span></p>
<p>&nbsp;</p>
<p><strong>Content Liability</strong></p>
<p><span className='fw-400'>We shall not be hold responsible for any content that appears on your Website. You agree to protect and defend us against all claims that is rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.</span></p>
<p>&nbsp;</p>
<p><strong>Your Privacy</strong></p>
<p><span className='fw-400'> <Link to='/privacy-policy' className='text-blue font-primary dark:text-graylight'>Please read Privacy Policy</Link></span></p>
<p>&nbsp;</p>
<p><strong>Reservation of Rights</strong></p>
<p><span className='fw-400'>We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amen these terms and conditions and it&rsquo;s linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.</span></p>
<p>&nbsp;</p>
<p><strong>Removal of links from our website</strong></p>
<p><span className='fw-400'>If you find any link on our Website that is offensive for any reason, you are free to contact and inform us any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly.</span></p>
<p>&nbsp;</p>
<p><span className='fw-400'>We do not ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.</span></p>
<p>&nbsp;</p>
<p><strong>Disclaimer</strong></p>
<p><span className='fw-400'>To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website. Nothing in this disclaimer will:</span></p>
<p><span className='fw-400'>limit or exclude our or your liability for death or personal injury;</span></p>
<p><span className='fw-400'>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</span></p>
<p><span className='fw-400'>limit any of our or your liabilities in any way that is not permitted under applicable law; or</span></p>
<p><span className='fw-400'>exclude any of our or your liabilities that may not be excluded under applicable law.</span></p>
<p><span className='fw-400'>The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort and for breach of statutory duty.</span></p>
<p>&nbsp;</p>
<p><span className='fw-400'>As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.</span></p>
                </div>
            </div>

        </div>
    )
}

export default Terms 