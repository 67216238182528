import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useLocation, useParams } from 'react-router-dom'
import { SuperSEO } from 'react-super-seo'
import instance from '../helpers/axios'
import { useTypedSelector } from '../hooks/useSelector'
import { ActionType } from '../store/actions'
import VideoThumbnail from 'react-video-thumbnail'; // use npm published version
import { video } from '../components/images'
import Input from '../components/input'
import Button from '../components/button'
import styles from './styles.module.css'
import ClipCaard2 from '../components/brand/clip_card2'


const VerifyPost = () => {
    const dispatch = useDispatch();
    const params = useParams()
    const { search } = useLocation();
    const [value, setValue] = useState<string>('')
    const [title, setTitle] = useState<string>('')
    const [description, setDescription] = useState('')
    const [mediaUrl, setMediaUrl] = useState('')
    const [image, setImage] = useState('')
    const [post, setPost] = useState<any>(null)
    const [data, setData] = useState<any>(null)
    const [user, setUser] = useState<any>(null)
    const [notfound, setNotFound] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    

    const { loggedInBrand } = useTypedSelector((state) => state.appReducer);
    const headers = {
        'Authorization': `Bearer ${loggedInBrand}`
    }
    useEffect(() => {
        dispatch({
            type: ActionType.UPDATE_LAYOUT,
            payload: loggedInBrand ? "brand" : "main"
        })

    }, [])
    const fetchWithCode = async () => {
        if(isLoading) return false; 
        setIsLoading(true)
        setPost(null)
        setUser(null)
        setData(null)
        instance({
            method: "GET",
            url: `challenge-post/view-with-code/${value}`,
            headers: headers
        }).then((response) => {
            if(response.data.data.post) {
                setPost(response.data.data.post);
                setUser(response.data.data.user);
                setData(response.data);
            }else{
                setNotFound(true)
            }
        }).catch((err) => {
            setNotFound(true)
        }).finally(()=> {
            setIsLoading(false)
        })
    }

    return (
        <>
            <div  className='container mx-auto my-3'>
                <Link to={"/brand/dashboard"}>back to dashboard</Link>
            </div>
            <div className='mx-auto max-w-[350px] flex flex-col md:flex-row gap-2 my-3'>
                <Input placeholder='Enter Post Unique Code' value={value} setValue={setValue} />
                <div className="mx-auto text-center">
                    <Button loading={isLoading} onclick={()=> {
                        fetchWithCode()
                    }} text='Verify' containerClass='bg-blue text-yellow w-[100px] mx-auto pr-2' />
                </div>
            </div>
            <div className='mx-auto max-w-[400px] w-[95%]'>
            {
                post && <ClipCaard2 source={"view"} item={post} loggedInBrand={loggedInBrand}/>
            }
            {
                notfound && <div className='text-center'>Post not found</div>
            }
            {
                isLoading && <div className='text-center text-blue'>Loading...</div>
            }
            </div>
        </>
    )
}

export default VerifyPost