import React, {useState, useEffect, useRef} from 'react'
import { AiOutlineShareAlt } from 'react-icons/ai'
import { BiShare } from 'react-icons/bi'
import { toast, ToastContainer } from 'react-toastify';
import instance from '../../helpers/axios';
import ButtonLoader from '../../helpers/button_loader';
import { useTypedSelector } from '../../hooks/useSelector';
import { facebookSVG, instagramSVG, linkedinSVG, telegramSVG, twitterSVG, whatsappSVG } from '../images'
import { Qr } from '../QrContainer';
import Input from '../input';
import Button from '../button';


interface iConfirm {
    challengeCode : string;
    brandName? : string;
    close : any;
    callback? : any;
}

function DownloadQR(data : iConfirm) {
    const [isLoading, setIsLoading] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [title, setTitle] = useState("📢 Attention Amazing Customers! 📢");
    const [dtitle, setDTitle] = useState("📢 Attention Amazing Customers! 📢");
    const [description, setDescription] = useState("Scan this QR Code to participate in the Challenge");
    const [ddescription, setDDescription] = useState("Scan this QR Code to participate in the Challenge");

    const edit = (type = "") => {
        if(type ==  "revert") {
            setDescription(ddescription)
            setTitle(dtitle)
            setIsEdit(false)
            setIsEdit(false)
        }else{
            setDDescription(description)
            setDTitle(title)
            setIsEdit(true)
        }
    }

    return (
        <div>
            <div className='fixed top-0 left-0 w-full z-[999] h-[100vh]'>
                <div className="max-h-[700px] min-h-[100px] w-full flex justify-center">
                    <div onClick={()=>data.close()} className='back_drop backdrop-blur fixed top-0 left-0 w-full bg-blue/30 h-[100vh] bg-blue'></div>
                    <div className='bg-white shadow-lg rounded-[15px] w-[95%] lg:w-[560px] p-3 relative'>
                        <div className="mt-[10px] mb-[15px] px-3 font-primary ">
                            <div className="flex items-center flex-col" id="">
                                <h3 className="text-blue text-center text-uppercase text-[20px] font-bold mb-3">Download Qr Code</h3>
                                {
                                    isEdit ?
                                    
                                <div className="p-2 w-full my-3 ">
                                    <label>Title</label>
                                <Input placeholder='Title' value={title} setValue={setTitle} />
                                <div className='h-[10px]' />
                                <label>Description</label>
                                <Input placeholder='Description' value={description} setValue={setDescription} />
                                </div>
                                :
                                <Qr title={title} description={description} source={2} qrCode={data.challengeCode} brandName={data.brandName} />
                            }
                            </div>
                        </div>
                    <div className="mt-4 text-center">
                        {
                            isEdit 
                            ?
                            <div className="flex items-center justify-center gap-3">

                            <small>
                            <a className="font-bold text-red-700" onClick={() => {
                                   setIsEdit(false)
                                }} href='#?' >Save</a>
                            </small>
                            <small>
                           <Button onclick={()=>{
                             edit("revert")
                           }} text='Cancel' containerClass='bg-blue w-[100px] pr-2 text-yellow' />
                            </small>
                            </div>

                            :
                        <small>
                            <a className="font-bold text-red-700" onClick={() => {
                                edit()
                            }} href='#?' >Click to edit title and description</a>
                        </small>
                        }


                    </div>
                        <div className='h-[30px]' ></div>
                    </div>

                </div>
            </div>
        </div>

    )
}


export default DownloadQR;