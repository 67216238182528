import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import Slider from "react-slick";
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import useDocumentTitle from '../../../helpers/pageTitle';
import { decryptData } from '../../../helpers/security';
import { useTypedSelector } from '../../../hooks/useSelector';
import { ActionType } from '../../../store/actions';
import instance from '../../../helpers/axios';
import { toast, ToastContainer } from 'react-toastify';
import Loader from '../../../helpers/loader';
import { checkBox, checkBoxChecked, Clipimg, needHelp, playIcon, exporticon, who } from '../../../components/images';
import { BiCaretLeft, BiDotsHorizontal, BiDotsVertical, BiSearch, BiVerticalBottom } from 'react-icons/bi';
import "../../../helpers/slick.css";
import { ClipCard, PdfModal } from '../../../components/brand';
import Input from '../../../components/input';
import { FiSearch } from 'react-icons/fi';
import ChallengeCard from '../../../components/brand/challengeCard';
import { resultSort } from '../../../data';
import { RxCaretLeft, RxCaretRight } from 'react-icons/rx';
import Pagination from '../../../components/pagination';
import { formatCreatedAtDate, formatDate, getToday, sortByKey } from '../../../helpers/helpers';
import { CoreDropdown } from '../../../components';
import Button from '../../../components/button';
import ChallengeResultPdf from '../../../components/brand/pdf/challenge_result';


const Result: React.FC = () => {
    const { loggedInBrand , loggedInAdmin} = useTypedSelector((state) => state.appReducer);
    const navigate = useNavigate()
    const { search } = useLocation()
    const dispatch = useDispatch();
    const [user, setUser] = useState('')
    const [brand, setBrand] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [sort, setSort] = useState('All')
    const [challenges, setChallenges] = useState([])
    const { acctSideBarActive } = useTypedSelector((state) => state.appReducer);
    const params = useParams()
    const [selected, setSelected] = useState<number[]>([])
    const [challenge, setChallenge] = useState<any>()
    const [offset, setOffset] = useState(0)
    const [limit, setLimit] = useState(1000000)
    const [posts, setPosts] = useState([])
    const [allList, setList] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21])
    const [c, setC] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21])
    const [showPdfModal, setShowPdfModal] = useState(false)
    const [isLoadingPdf, setIsLoadingPdf] = useState(false)
    const [page, setPage] = useState(1)

    var isAdmin  = window.location.href.includes("admin")
    var paramsid = params.id

    const headers = {
        'Authorization': `Bearer ${isAdmin ? loggedInAdmin : loggedInBrand}`
    }

    useEffect(() => {
        dispatch({
            type: ActionType.UPDATE_LAYOUT,
            payload: isAdmin ? "admin" : "brand"
        })

    }, [])

    const addSelected = (value: number) => {
        var selected_ = selected;
        if (selected_.includes(value)) {
            selected_.splice(selected_.indexOf(value), 1)
        } else {
            selected_.push(value)
        }
        setSelected(selected_);
        setList(c)
        setTimeout(() => {
        }, 500);
    }

    var settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2
    };

   
    useEffect(() => {
        if(isAdmin){
            if (!loggedInAdmin || loggedInAdmin == "") {
                // nobody is logged in
                navigate("/admin/login");
            }
        }else{
            if (!loggedInBrand || loggedInBrand == "") {
                // nobody is logged in
                navigate("/brand/login");
            }
        }
    }, [loggedInBrand, loggedInAdmin])


    useEffect(() => {
        const search_ = (search.replace('?', '')).split("&")
        if (search_.length > 0) {
            search_.forEach(s => {
                if (s.includes('page=') !== false) {
                    const scrollTo = s.replace('page=', '')
                    setPage(parseInt(scrollTo))
                    // setOffset()
                    return false;
                }
            });
        }
    }, [search])

    var paramsid = params.id

    // get brand profile
    useEffect(() => {
       if(!isAdmin){

        instance({
            method: "GET",
            url: "/brand/profile",
            headers: headers
        }).then((response) => {
            // console.log(response)
            setChallenges(response.data.data.challenges);
            setBrand(response.data.data.brand);
           

        }).catch((err) => {
            if (err.code == "ERR_NETWORK") {
                alert("No internet connection")
            }
            if (err.response.data.statusCode === 403) {
                navigate('/brand/login');
                return false;
            }

            toast.error(err.response.data.message)
        })

    }

        instance({
            method: "POST",
            url: `/challenge/single/${paramsid}/${offset}/${limit}`,
            headers: headers
        }).then((response) => {
            // console.log("response", response)
            setChallenge(response.data.data.challenge);
            var p = response.data.data.posts;
            p = sortByKey(p, "noOfVoters", sort);
            const mapped = p.map((p: any) => {
                return {
                    _id: p._id,
                    fullname: p.user ? p.user.userName : (p.privateUser ? p.privateUser.fullName : 'User'),
                    avatar: p.user ? p.user.avatar : null,
                    email: p.user ? (p.user.email ? p.user.email : p.privateUser ? p.privateUser.email : "") : (p.privateUser ? p.privateUser.email : 'User'),
                    phone: p.privateUser ? p.privateUser.phoneNumber || "-" : '-',
                    joined: new Date(p.createdAt).toLocaleDateString("EN-US", { month: "long", day: "2-digit", year: "numeric" }),
                    votes: p.noOfVoters
                }
            })
            // console.log(mapped)
            setPosts(mapped);
            setIsLoading(false);
        }).catch((err) => {

        }).finally(() => {
            setIsLoading(false);
        })
    }, [paramsid, sort])


    function dropChanged(value: any) {
        setPage(1)
        setSort(value)
    }
    const handleDownload = () => {
        // getting report sections ===========================================
        setShowPdfModal(true)
        setTimeout(() => {
            setIsLoadingPdf(true)
        }, 1000);
    }
    useDocumentTitle('BRAND RESULT')
    // const a = []
    return (
        <div>
            <div className="max-w-[1445px]">
                <ToastContainer />
                {isLoading ? <Loader /> :
                    <div className="flex">
                        {
                            showPdfModal &&
                            <ChallengeResultPdf loaded={isLoadingPdf} challenge={challenge} challengePosts={posts} close={() => setShowPdfModal(false)} />
                        }
                        {/* main */}
                        <div className='container font-primary  flex-inital lg:px-[15px] px-[5px] py-[25px] w-full'>
                            <div className='flex w-full flex-row items-start justify-between'>
                                {
                                    !isAdmin ?
                                <div className="flex">
                                    <a href="#?" className='text-blue text-sm font-bold border-b-2 border-sky-950 mr-[10px]'>Result</a>
                                    <a href="#?" onClick={() => {
                                        navigate(`/brand/challenge/clips/${challenge._id}`)
                                    }} className='text-[#7E799F] text-sm font-bold'>View Posts</a>
                                </div>
                                :
                                <Link to={`/admin/campaign-management`}>go back</Link>

                                }
                                <div>

                                    <Button text='Export' onclick={handleDownload} containerClass="bg-blue rounded-[10px] font-bold font-primary text-yellow h-[35px] w-[fit-content] mx-auto flex items-center justify-center px-[20px] text-[14px]" h='35px' img={exporticon} />
                                </div>

                            </div>

                            <ChallengeCard source='result' data={challenge} />
                            <div className="py-5 px-3 bg-white">
                                <div className="search flex justify-between items-center mb-4">
                                    <h3 className="font-primaary font-bold text-blue">{challenge.noOfParticipants} Participants</h3>
                                    <div className="max-w-[150px] w-full">
                                        <CoreDropdown value={"Sort : " + sort} placeholder='Sort : All' extendedClasses='dark:text-white text-yellow' containerClass="mt-[-5px] w-full h-[40px] border-graylight border-2 shadow-sm text-[0.8rem] mt-0 bg-blue text-yellow" showError={false} err={""} items={resultSort} setValue={(value: any) => dropChanged(value)} />
                                    </div>
                                </div>

                                <div className="shadow-[10px] rounded-[10px] overflow-hidden border-graylight border-2">
                                    <table className="border-collapse table-auto w-full text-sm ">
                                        <thead className='text-blue p-2 h-[45px] border-b-graylight border-b-2'>
                                            <tr>
                                                <th></th>
                                                <th>Username</th>
                                                <th className='text-left'>Email</th>
                                                <th className='text-left'>Phone</th>
                                                <th className='text-left'>Joined</th>
                                                <th className='text-left'>Votes</th>
                                                <th><BiDotsVertical /></th>
                                            </tr>
                                        </thead>
                                        <tbody className='p-2'>
                                            {
                                                (posts.slice(page == 1 ? 0 : (page - 1) * 10, page == 1 ? 10 : ((page - 1) * 10) + 10)).map((item: any, i) => (
                                                    <tr key={i} className='p-2 text-[#0B014980] border-spacing-2' style={{ height: '70px' }}>
                                                        <td className='w-[50px] lg:w-[100px] text-center pr-2'>
                                                            <div className=" flex items-center justify-end">
                                                                {
                                                                    selected.includes(item._id) ?
                                                                        <img onClick={() => addSelected(item._id)} style={{ cursor: 'pointer' }} src={checkBoxChecked} alt="" />
                                                                        :
                                                                        <img onClick={() => addSelected(item._id)} style={{ cursor: 'pointer' }} src={checkBox} alt="" />
                                                                }
                                                                <div className="mx-2"></div>
                                                                <img className='h-[40px] w-[40px] object-cover hidden lg:block rounded-[100%]' src={item.avatar || who} alt="" />
                                                            </div>

                                                        </td>
                                                        <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''}`}>
                                                            <div className="flex items-center font-bold font-primary">
                                                                <div className='pl-2'>
                                                                    {item.fullname}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''}`}>
                                                            <div className="flex items-center font-bold font-primary">
                                                                <div className='pl-2'>
                                                                    {item.email}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''}`}>
                                                            <div className="flex items-center font-bold font-primary">
                                                                <div className='pl-2'>
                                                                    {item.phone}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''}`}>{item.joined}</td>
                                                        <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''}`}>{item.votes}</td>
                                                        <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''} w-[50px]`}><BiDotsVertical /></td>
                                                    </tr>
                                                ))
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <Pagination length={challenge.noOfChallengePosts} page={page} limit={10} />
                        </div>
                        {!isAdmin && 
                        <aside className={`h-100 min-h-[90vh] z-40 px-[10px] lg:static lg:block py-[20px] ${acctSideBarActive ? 'absolute left-0 top:0' : 'hidden'} flex-initial w-full max-w-[320px] bg-white shadow`}>
                            <div className="table-fixed w-full pr-3 min-h-[50vh]">
                                <div className='flex mb-3'>
                                    <p className='w-[75%] font-primary text-[#7E799F] font=bold ' >Challenges</p>
                                    <p className='font-primary text-[#7E799F] font=bold ' >Participants</p>

                                </div>
                                <div>
                                    {
                                        (challenges.slice(0, 10)).map((item: any, i) => (
                                            <Link key={i} to={`/brand/challenge/result/${item._id}`} className='flex items-center py-[5px]'>
                                                <div className='w-[70%] text-sm text-blue font-primary'>{item.title}</div>
                                                <div className='text-center w-[25%]'> <button className='rounded-[15px] text-[12px] text-[#0B0149] p-2 bg-[#F0F0F4]'>{(item.challengePosts).length}</button> </div>
                                            </Link>
                                        ))
                                    }
                                </div>
                            </div>

                            <div className="h-[1px] my-[20px] bg-[#7E799F] opacity-70 w-full"></div>
                            <div className='text-center w-full flex justify-center'>
                                <img src={needHelp} alt="" />
                            </div>
                        </aside> }
                    </div>
                }
            </div>
        </div>
    )
}
export default Result