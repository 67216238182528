import React, { useState, useEffect } from 'react'
import { FiSearch } from 'react-icons/fi'
import { VscCloseAll } from 'react-icons/vsc'
import { Link } from 'react-router-dom'
import instance from '../../helpers/axios'
import ButtonLoader from '../../helpers/button_loader'
import { useTypedSelector } from '../../hooks/useSelector'
import { iCreate } from '../../models/model'
import { Logo, who } from '../images'
import Input from '../input'

// hassub serves as a check to know if its coming from add coupon ... or
function SelectBrandModal({ close, hasSub = "", setFeature = null }: iCreate) {
    
    const [search, setSearch] = useState('')
    const [result, setResult] = useState([])
    const [brands, setBrands] = useState([])
    const [loading, setLoading] = useState(true)
    const [allList, setAllList] = useState<any[]>([])
    const { loggedInAdmin } = useTypedSelector((state) => state.appReducer);

    useEffect(() => {
        const headers = {
            'Authorization': `Bearer ${loggedInAdmin}`
        }
        // _searchBy[]=text&_keyword=arise
        setLoading(true)
        instance({
            method: "GET",
            url: search == "" ? `/brand/all` : `/brand/all?_searchBy[]=brandName&_keyword=${search}`,
            headers: headers
        }).then((response) => {
            console.log("res", response.data.data.docs)
            var docs = response.data.data.docs;
            setLoading(false)
            setAllList(docs);
        }).catch((err) => {

        }).finally(() => {
            // setIsLoading(false);
        })
    }, [search])
    const selected = (item : any) => {
        console.log(item)
        setFeature(item)
    }

    return (
        <div>
            <div className='fixed top-0 left-0 w-full z-[999] h-[100vh]'>
                <div className="max-h-[80vh] min-h-[300px] w-full flex justify-center">
                    <div onClick={() => close()} className='back_drop backdrop-blur fixed top-0 left-0 w-full bg-blue/30 h-[100vh] bg-blue'></div>
                    <div className='bg-white shadow-lg rounded-[15px] w-[95%] lg:w-[860px] p-3 relative'>
                        <div className="mt-[15px] text-center">
                            <form action="">
                                <header className="flex items-center">
                                    <label className="p-2 DocSearch-MagnifierLabel" htmlFor="docsearch-input" id="docsearch-label">
                                        <FiSearch className='text-gray2 font-bold text-[18px] ' />
                                    </label>
                                    <div className={`control border border-0 pl-2 h-[30px] rounded-[15px] w-full`}>
                                        <input autoFocus={true} arial-aria-label="Search Beentos" placeholder="Search Beentos" type="text" value={search} onChange={(e) => setSearch(e.target.value)} className={`bg-transparent font-primary font-semibold ${search === '' ? 'font-medium text-blue opacity-50' : 'dark:text-white  text-blue'} placeholder:font-medium  border-0 outline-0 bg-none w-full px-2 h-[30px] is-dirty peer`} />
                                    </div>
                                    <button type="reset" title="Clear the query" className="DocSearch-Reset" aria-label="Clear the query"></button>
                                    <button className="DocSearch-Cancel" type="reset" aria-label="Cancel">
                                        <VscCloseAll onClick={() => close()} className='text=blue text-[22px]' />
                                    </button>
                                </header>
                            </form>
                        </div>

                        <div className="mt-[10px] h-[65vh] px-3 overflow-y-scroll">
                           {
                            loading ? 'please wait...' :
                            <div>
                                 <table className="border-collapse text-sm table-auto w-[800px] md:w-full lg:w-full">
                                        <thead className='text-blue p-2 h-[45px] border-b-graylight border-b-2'>
                                            <tr>
                                                <th></th>
                                                <th>Brand Name</th>
                                                <th className='text-left'>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody className='p-2'>
                                            {
                                                allList.map((item: any, i) => (
                                                    <tr key={i} className='p-2 text-[#0B014980] border-spacing-2' style={{ height: '70px' }}>
                                                        <td className='w-[120px] text-center pr-2'>
                                                            <div className=" flex items-center justify-end">
                                                                <div className="mx-2"></div>
                                                                <img className='h-[40px]  w-[40px] rounded-[100%] object-cover' src={who} alt="" />

                                                            </div>
                                                        </td>
                                                        <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''}`}>
                                                            <div className="flex items-center font-bold font-primary">
                                                                <div className='pl-3'>
                                                                    <h3 className='text-blue'>{item.brandName}</h3>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''} `}>
                                                            {
                                                                hasSub == "" ?
                                                                <Link to={`/admin/create-challenge/${item.brandId}`} >Select</Link>
                                                                :
                                                                <a href='#?' onClick={()=>selected(item)} >Select</a>
                                                            }
                                                        </td>
                                                    </tr>
                                                ))
                                            }

                                        </tbody>

                                        {
                                            allList.length === 0 ?
                                                <p>No record found</p>
                                                :
                                                ''
                                        }
                                    </table>
                            </div>
                           }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SelectBrandModal