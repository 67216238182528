import React from 'react'
import { BsCheck } from 'react-icons/bs';

interface iStep {
  s : number,
  iscurrent : boolean,
  islast : boolean,
  className? : string,
  title? : string,
  titleClass? : string,
  isCompleted? : boolean
}
const Steps = ({s=1, iscurrent, islast, className='', title = "", titleClass="", isCompleted = false} : iStep) => {
  let num = s;
  return (
    <div className='flex items-center justify-center' style={{cursor : 'pointer'}}>
      <div className='relative'>
        <div className={`h-[50px] w-[50px] rounded-[100%] ${ iscurrent || isCompleted ? 'bg-blue text-white' : (className == '' ? 'text-blue bg-[#F0F0F4] border' : className)} flex items-center justify-center`}>
          {
            isCompleted ? <BsCheck color='white' className='text-[24px]' /> : s
          }
        </div>
        {
          title == '' ? '' : <h6 className={`hidden mt-3 text-[14px] lg:block font-bold absolute left-0 w-full text-center  ${iscurrent ? 'text-blue font-primary' : 'text-[#7E799F]'} ${titleClass}`}>{title}</h6>
        }
      </div>
      {
        !islast ? <div className="h-[1px] w-[30px] lg:w-[100px] bg-blue rounded"></div>
        : ''
      }
    </div>
  )
}

export default Steps