import { PDFDownloadLink } from '@react-pdf/renderer';
import React, { useState, useEffect, useRef } from 'react'
import { AiFillCrown, AiOutlineShareAlt } from 'react-icons/ai'
import { BiShare } from 'react-icons/bi'
import { toast, ToastContainer } from 'react-toastify';
import ButtonLoader from '../../helpers/button_loader';
import { iPdf, iSubscription } from '../../models/model';
import ReportTemplate from '../../pages/brands/pdf';
import { numberFormat } from '../../helpers/helpers';
import Button from '../button';
import instance from '../../helpers/axios';
import { useTypedSelector } from '../../hooks/useSelector';
import { useNavigate } from 'react-router-dom';
import ls from 'localstorage-slim'

function SubscriptionModal(data: iSubscription) {
    const [selected, setSelected] = useState(30)
  const { loggedInBrand, sideBarActive } = useTypedSelector((state) => state.appReducer);
    const navigate = useNavigate()
    const [showCouponBox, setShowcouponBox] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [couponCode, setCouponCode] = useState("")
    const [useCoupon, setUseCoupon] = useState("")
    const subTypes = [
        {
            days : 30,
            name : "30 days"
        },

        {
            days : 180,
            name : "6 months"
        },

        {
            days : 365,
            name : "1  year"
        },
        {
            days : 730,
            name : "2 year"
        },

    ]

    const headers = {
        'Authorization': `Bearer ${loggedInBrand}`,
    }

    const validateCoupon = () => {
        console.log("coupon", ls.get("bcoupon", {decrypt : true}))
        if(couponCode == "") return false;

        instance({
            method: "GET",
            url: "/coupon/verify-coupon/"+couponCode,
            headers: headers
        }).then((response) => {
            // console.log("response-coupon", response)
            toast.success("Coupon added")
            setUseCoupon(couponCode)
            setShowcouponBox(false)
            setCouponCode("")
            
        }).catch((err) => {
            if (err.response.data.statusCode === 403) {
                navigate('/brand/login');
                return false;
            }
            if(err.response.data.statusCode === 404){
                toast.error("Invalid coupon code")
            }

            if(err.response.data.statusCode === 400){
                toast.error("Invalid coupon code")
            }

            
            console.log("error", err)
            // if(er)
        })
    }
    return (
        <div>
            <ToastContainer />
            <div className='fixed top-0 left-0 w-full z-[999] h-[100vh]'>
                <div className="max-h-[80vh] min-h-[200px] w-full flex justify-center">
                    <div onClick={() => data.close()} className='back_drop backdrop-blur fixed top-0 left-0 w-full bg-blue/30 h-[100vh] bg-blue'></div>
                    <div className='bg-white max-h-[80vh] overflow-y-auto shadow-lg rounded-[15px] w-[95%] lg:w-[860px] p-3 relative'>
                        <div className="mt-[10px] px-3 font-primary ">
                            
                            <div className="font-bold" id="">
                                <h3 className="text-blue text-center text-uppercase text-[22px] font-bold mb-3">{data.subscription}</h3>
                                <p className='text-gray text-center'>Please select your preferred plan duration below.</p>
                                <div className="my-3 gap-4 flex justify-center flex-wrap">
                                    {
                                        subTypes.map((sub, i )=> (
                                            <div
                                            onClick={()=>{
                                                setSelected(sub.days)
                                            }}
                                            style={{cursor : 'pointer'}}
                                             className={`rounded ${sub.days == selected ? 'bg-blue text-white' : 'bg-graylight'}  p-3 text-center`}>
                                                <h6 className='font-[700] text-[30px]'>{data.countryCode == "NG" ? "N" : "$"}{numberFormat(data.selectedPrice * sub.days)}</h6>
                                                <small>{sub.name}</small>
                                            </div>
                                        ))
                                    }
                                </div>
                                {
                                    useCoupon !== "" && <div className='text-center'>
                                        <h3>Coupon : {useCoupon}</h3>
                                    </div>
                                }
                                {
                                    !window.location.href.includes("admin") &&
                                <>
                                {!showCouponBox && <div className="flex justify-center">
                                    <p>You have a Coupon? </p>
                                    <a href="#?" onClick={()=>{setShowcouponBox(true)}} className='flex items-center gap-2 text-[#0000EE]'>  <AiFillCrown className='text-yellow'/> Apply coupon</a>
                                </div>}
                                </>
                                }
                                {
                                    showCouponBox ? 
                                    <div className="mt-[30px] bg-gray py-3 flex items-center flex-column justify-between">
                                        <h3>Enter Coupon Code</h3>
                                        <div className='bg-[#F0F0F4] max-w-[400px]  rounded-[8px] w-full flex items-center py-1 my-3'>
                                            <input value={couponCode} onChange={(e) => setCouponCode(e.target.value)} type="text" className='bg-transparent ml-3 border-0 outline-0 w-full h-[40px]' placeholder='Enter coupon code' />
                                        </div>
                                        <div className='flex gap-3 justify-center'>
                                            <Button onclick={validateCoupon} loading={isLoading} text='Validate' containerClass='bg-blue pr-2 text-yellow border-0 w-[100px] lg:w-[100px]' h='40px' />
                                            <Button text='Cancel' onclick={()=>{setShowcouponBox(false)}} containerClass='text-red-700 border-0 w-[120px]' h='40px' />
                                        </div>

                                    </div>
                                    :
                                <div className="mt-[30px] flex items-center flex-column justify-between">
                                    
                                    <Button text='Proceed' onclick={()=>{
                                        data.proceed(selected, useCoupon)
                                    }} containerClass='bg-blue text-yellow pr-2 w-[120px]' h='45px' />
                                    <Button text='Close' onclick={()=>{data.close()}} containerClass='text-red-700 border-0 w-[120px]' h='40px' />
                                </div>
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}


export default SubscriptionModal;