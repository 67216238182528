import React from 'react'
import CreatableSelect from "react-select/creatable";
import { iChallengeCat, iDropDown } from '../models/model';


function DropDown(data: iDropDown) {
    const { options, showError = false, prevErr = false, err = "", handleChange, handleInputChange, isMulti, placeholder = "Select one or more item", source = "main", value = "", isMenuOpen = false } = data;

    const colorStyles = {

        // control: (styles: any, state : any) => ({ ...styles,  backgroundColor: "#F0F0F4", borderColor: 'transparent', border : "none", borderRadius : "45px", height : "auto", minHeight : '50px', marginTop: "10px" 
        // }),
        control: (styles: any, state: any) => {
            if (source === "main") return { ...styles, backgroundColor: "#F0F0F4", borderColor: 'transparent', border: "none", borderRadius: "45px", height: "auto", minHeight: '50px', marginTop: "10px" }
            return { ...styles, backgroundColor: "transparent", color: 'red', borderColor: state.isFocused ? '#0B014980' : '#0B014980', border: "1", borderRadius: "45px", height: "auto", minHeight: '50px', marginTop: "10px" }
        },
        option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
            return { ...styles, border: 'none' };
        },

        container: (styles: any, state: any) => {
            return { ...styles, maxHeihgt: "300px" }
        },
        // valueContainer : (styles : any) => {
        //     return {...styles, border : "none", marginLeft : '10px' }
        // },
        valueContainer: (styles: any) => {
            if (source == "main") {
                return { ...styles, border: "none", marginLeft: '10px' }
            }
            return { ...styles, textAlign: 'left',  fontWeight: 600, fontFamily: 'fontprimary', opacity: value === "" ? 0.3 : 1, border: "none", marginLeft: '10px' }
        },
        // font-semibold font-primary ${value === '' ? ' text-blue opacity-20' : 'dark:text-white  text-blue'}`
        value: (styles: any) => {
            return { ...styles, color: 'red' }
        },
        singleValue : (styles : any, {data} : any) => {
            return {
                ...styles,
                color: "#0B0149",
                fontWeight: "600",
            };
        },
        multiValue: (styles: any, { data }: any) => {
            return {
                ...styles,
                backgroundColor: "#0B0149",
                color: "#fff",
            };
        },
        multiValueLabel: (styles: any, { data }: any) => {
            return {
                ...styles,
                color: "#fff",
            };
        },
        multiValueRemove: (styles: any, { data }: any) => {
            return {
                ...styles,
                color: "#fff",
                cursor: "pointer",
                ":hover": {
                    color: "#fff",
                },
            };
        },
    };
    return (
        <div className={`${prevErr ? 'mt-[35px]' : ''}`}>
            {
                source === "signup" ?
                    <CreatableSelect
                        options={options}
                        onChange={handleChange}
                        onInputChange={handleInputChange}
                        isMulti={isMulti}
                        styles={colorStyles}
                        placeholder={placeholder}
                        classNames={{
                            control: () => 'font-bold control border text-blue border-blue pl-2 h-[50px] text-[1rem] rounded-[45px]'
                        }}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                primary25: '#F0F0F4',
                                primary: '#0B014980',
                            },
                        })}
                    />
                    :
                    <CreatableSelect
                        options={options}
                        onChange={handleChange}
                        onInputChange={handleInputChange}
                        isMulti={isMulti}
                        styles={colorStyles}
                        placeholder={placeholder}
                        classNames={{
                            control: () => 'control border border-blue pl-2 h-[50px] rounded-[45px]'
                        }}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                primary25: '#F0F0F4',
                                primary: '#0B014980',
                            },
                        })}
                    />

            }

            {
                showError ? <div className='px-2 m-0 w-full flex justify-start'>
                    <p className="m-0 text-red-700"> <small>{err}</small> </p>
                </div>
                    : ''}
        </div>
    )
}

export default DropDown