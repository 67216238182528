import React, { useState } from 'react'
import { BiDotsHorizontal, BiDotsVertical } from 'react-icons/bi'
import { Clipimg, Logo, playIcon, video, who } from '../images'
import ReactPlayer from 'react-player/lazy'


import ButtonLoader from '../../helpers/button_loader';
import instance from '../../helpers/axios';
import ShareModal from './share_modal';
import { toast, ToastContainer } from 'react-toastify';
import ConfirmModal from './ask_modal';
import { httpGetWithToken } from '../../helpers/http_util';
import { editor_url } from '../../helpers/helpers';
import { useNavigate } from 'react-router-dom';


interface iClipCard {
    item: any,
    loggedInBrand? : string,
    deleteCallback : any
}
function EditorCard({ item, loggedInBrand, deleteCallback}: iClipCard) {
    const [isActive, setIsActive] = useState(false)
    const [showPlayer, setShowPlayer] = useState(false)
    const [playerReady, setPlayerReady] = useState(false)
    const [isAdmin, setIsAdmin] = useState(window.location.href.includes("admin") ? true : false)
    const [isFlag, setIsFlag] = useState(false)
    const [showShare, setShowShare] = useState(false)
    const navigate = useNavigate()
    const generateThumb = () => {

    }
    const headers = {
        'Authorization': `Bearer ${loggedInBrand}`
    }

    const editEditor = async () => {
       return window.location.assign(`${editor_url}?t=${loggedInBrand}&p=${item._id}`)
    }

    
    const deleteEditor = async () => {
       
         deleteCallback(item._id)
    }


    return (
        // <div className="relative shadow rounded ">
        <div className="relative shadow rounded">
            <ToastContainer />

            {
                isActive ?
                    <div className="overlay">
                        <div className="bg-white py-2 px-3 shadow z-[4111] right-0 rounded absolute w-[180px]">
                                <ul className="text-[12px]">
                                <li className='mt-1'>
                                    <a onClick={()=>{
                                        setIsActive(false)
                                        editEditor()
                                    }} className="text-blue font-primary" href="#?">Edit</a>
                                </li>
                                <li className='mt-1'>
                                    <a onClick={
                                        ()=>{
                                            setIsActive(false)
                                            deleteEditor()
                                        }
                                    } className="text-blue font-primary" href="#?">Delete </a>
                                </li>
                            </ul>
                        </div>
                        <div onClick={() => setIsActive(false)} className="backgrop bg-black opacity-40 z-40 h-[100%] rounded w-[100%] absolute"></div>
                    </div>
                    : ''
            }

            <div className="flex flex-column items-center py-2">
                <img onClick={() => { }} style={{cursor : 'pointer'}} className='rounded-l-[10px] flex-initial object-contain h-[150px] sm:w-["100%"] md:w-["100%"]' src={`${Logo}`} />
                <p className='font-primary font-bold'>{item.name}</p>
                        <BiDotsVertical className='absolute right-0 top-[10]' onClick={() => setIsActive(true)} style={{ cursor: 'pointer' }} />
            </div>
        </div>
    )
}

export default EditorCard