import {Action, ActionType, State} from './actions'


const initialState = {
    loggedIn : sessionStorage.getItem('loggedIn') ? sessionStorage.getItem("loggedIn") : "",
    loggedInBrand : sessionStorage.getItem('loggedInBrand') ? sessionStorage.getItem('loggedInBrand') : "",
    loggedInAdmin : sessionStorage.getItem('loggedInAdmin') ? sessionStorage.getItem('loggedInAdmin') : "",
    layoutType : "main",
    brandId : "",
    brandData : sessionStorage.getItem('brandData'),
    challengeData : sessionStorage.getItem('challengeData'),
    acctSideBarActive : false,
    sideBarActive : false
}

export const appReducer = (state : State = initialState, action : Action) => {

    switch (action.type) {
        case ActionType.UPDATE_LAYOUT:
            return {
                ...state, layoutType : action.payload
            }
        case ActionType.UPDATE_CHALLENGE:
            sessionStorage.setItem('challengeData', action.payload)
            return {...state, challengeData : action.payload}
            
        case ActionType.UNSET_CHALLENGE:
            try {
                if(sessionStorage.getItem('challengeData')){
                    sessionStorage.removeItem('challengeData')
                }
                return {...state, challengeData : ""}
            } catch (error) {
                return {...state, challengeData : ""}
            }

        case ActionType.GET_LAYOUT:
            return state.layoutType

        case ActionType.UPDATE_BRAND_DATA:
            sessionStorage.setItem('brandData', action.payload)
            return {...state, brandData : action.payload}
        

        case ActionType.LOGIN_BRAND:
            sessionStorage.setItem('loggedInBrand', action.payload)
            return {
                ...state, loggedInBrand : action.payload
            }

        case ActionType.LOGIN:
            sessionStorage.setItem('loggedIn', action.payload)
            return {
                ...state, loggedInBrand : action.payload
            }

        case ActionType.LOGOUT_BRAND:
            try {
                if(sessionStorage.getItem('loggedInBrand')){
                    sessionStorage.removeItem('loggedInBrand')
                }
                return {...state, loggedInBrand : ""}
            } catch (error) {
                return "";
            }

        case ActionType.LOGIN_ADMIN:
            sessionStorage.setItem('loggedInAdmin', action.payload)
            return {
                ...state, loggedInAdmin : action.payload
            }
        case ActionType.LOGOUT_ADMIN:
            try {
                if(sessionStorage.getItem('loggedInAdmin')){
                    sessionStorage.removeItem('loggedInAdmin')
                }
                return {...state, loggedInAdmin : ""}
            } catch (error) {
                return "";
            }
        case ActionType.ACCT_SIDEBAR_ACTIVE:
            try {
                return {...state, acctSideBarActive : action.payload}
            } catch (error) {
                
            }
            return ''
        
        case ActionType.SIDEBAR_TOGGLE:
                try {
                    return {...state, sideBarActive : action.payload}
                } catch (error) {
                    
                }
                return ''
            
        default:
            return state;
        
    }
}

