import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { SettingsAside, SettingsHeader } from '../../../components/brand';
import { checked, notChecked } from '../../../components/images';
import instance from '../../../helpers/axios';
import Loader from '../../../helpers/loader';
import useDocumentTitle from '../../../helpers/pageTitle';
import { useTypedSelector } from '../../../hooks/useSelector';
import { ActionType } from '../../../store/actions';


const Notification: React.FC = () => {
    const { loggedInBrand } = useTypedSelector((state) => state.appReducer);
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [eamilNotification, setEamilNotification] = useState(false)
    const [cahllengeNotification, setCahllengeNotification] = useState('')
    const [loading, setLoading] = useState(true)
    const [reminderNotification, setReminderNotification] = useState('none')
    const [moreActivity, setMoreActivity] = useState('')
    useEffect(() => {
        dispatch({
            type: ActionType.UPDATE_LAYOUT,
            payload: "brand"
        })
    }, [])
    const headers = {
        'Authorization': `Bearer ${loggedInBrand}`,
    }

    // get brand profile
    useEffect(() => {

        instance({
            method: "GET",
            url: "/brand/profile",
            headers: headers
        }).then((response) => {
            console.log(response)
            const c = response.data.data.challenges;
            const b = response.data.data.brand;
            const n = b.brandNotificationSettings;
            setCahllengeNotification(n.challengeNotifications)
            setEamilNotification(n.emailNotifications)
            setReminderNotification(n.reminderNotifications)
        }).catch((err) => {
            if (err.code == "ERR_NETWORK") {
                alert("No internet connection")
            }
            else if (err.response.data.statusCode === 403) {
                navigate('/brand/login');
                return false;
            }
            toast.error(err.response.data.message)
            console.log(err)
        }).finally(()=>setLoading(false))
    }, [])


    // get brand notification
    const updateNotification = (type : string, value : any) => {
        let data;
        if(type === "email"){
            setEamilNotification(value)
            data = {
                emailNotifications : value
            }
        }

        if(type === "challenge"){
            setCahllengeNotification(value)
            data = {
                challengeNotifications : value
            }
        }

        if(type === "reminder"){
            setReminderNotification(value)
            data = {
                reminderNotifications : value
            }
        }


        if(type === "donot"){

            if(value == "none"){
                setReminderNotification("none")
                setCahllengeNotification("none")
                setEamilNotification(false)

                data = {
                    reminderNotifications : value,
                    challengeNotifications : value,
                    emailNotifications : false
                }
            }else{
                setReminderNotification("all")
                setCahllengeNotification("all")
                setEamilNotification(true)

                data = {
                    reminderNotifications : value,
                    challengeNotifications : value,
                    emailNotifications : true
                }
            }
          
        }
        instance({
            method: "POST",
            url: "/brand-settings/notification/update",
            headers: headers,
            data : data,
        }).then((response) => {
            console.log(response)
            toast.success("Settings updated")
        }).catch((err) => {

            if (err.code == "ERR_NETWORK") {
                alert("No internet connection")
            }
            else if (err.response.data.statusCode === 403) {
                navigate('/brand/login');
                return false;
            }
            toast.error(err.response.data.message)
            console.log(err)
        }).finally(()=> {
            // setLoading(false)
        })
    }
    useDocumentTitle('NOTIFICATION SETTINGS')

    return (
        <div className='w-full'>
            <ToastContainer />
            {
                loading ? <Loader /> :
            <div className="px-[15px] h-100 relative font-primary flex max-w-[1440]">
                <SettingsAside />
                <div className='lg:container sm:mx-auto md:mx-auto flex-inital lg:px-[15px] px-[5px] py-[25px] w-full'>
                    <SettingsHeader title='Notification' />
                    <div className="my-[25px] bg-blue rounded-[10px] shadow-lg p-[15px]">
                        <div className='text-white'>
                            <h3 className='mb-1 text-[20px] font-500'>Email Notifications</h3>
                            <p>Get email notifications and keep up to date with what’s going on while you are offline</p>
                        </div>
                        <div>
                        </div>
                    </div>

                    <div className="my-[25px] bg-white rounded-[10px] shadow-lg flex justify-between p-[15px]">
                        <div className=''>
                            <h3 className='mb-1 text-[20px] font-500 text-blue font-primary'>Notifications from us</h3>
                            <p className='text-blue opacity-80'>Receive latest news and updates from us</p>
                        </div>
                        <div>
                            <img onClick={()=>updateNotification('email', !eamilNotification)} src={eamilNotification ? checked : notChecked} style={{ cursor: 'pointer' }} alt="" />
                        </div>
                    </div>

                    <div className="my-[25px] bg-white rounded-[10px] shadow-lg flex justify-between items-end p-[15px]">
                        <div className=''>
                            <h3 className='mb-1 text-[20px] font-500 text-blue font-primary'>Challenge</h3>
                            <p className='text-blue opacity-80 max-w-[390px]'>Notifications for posts, mentions and comments on your challenge</p>
                        </div>
                        <div>
                            <div className='flex justify-between items-center'>
                                <p className='text-blue opacity-80'>All Activites</p>
                                <div className="mx-2"></div>
                                <img onClick={()=>updateNotification('challenge', cahllengeNotification === 'all' ? 'none' : 'all') } className='h-[19px] object-contain' src={cahllengeNotification === 'all' ? checked : notChecked} style={{ cursor: 'pointer' }} alt="" />
                            </div>
                            <div className='flex justify-between items-center'>
                                <p className='text-blue opacity-80'>Mentions</p>
                                <div className="mx-2"></div>
                                <img onClick={()=>updateNotification('challenge', cahllengeNotification === 'post' ? 'none' : 'post') } className='h-[19px] object-contain' src={cahllengeNotification === 'post' ? checked : notChecked} style={{ cursor: 'pointer' }} alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="my-[25px] bg-white rounded-[10px] shadow-lg p-[15px]">
                        <h3 className='mb-1 text-[20px] font-500 text-blue font-primary'>Reminders</h3>
                        <div className="flex justify-between items-start">
                            <div className=''>
                                <p className='text-blue opacity-80 max-w-[390px]'>Reminds you of updates you might have missed or upcoming events</p>
                            </div>
                            <div>
                                <div className='flex justify-end items-center'>
                                    <p className='text-blue opacity-80'>Do not  remind me</p>
                                    <div className="mx-2"></div>
                                    <img onClick={()=>updateNotification('reminder', reminderNotification === 'none' ? 'all' : 'none') } className='h-[19px] object-contain' src={reminderNotification === 'none' ? checked : notChecked} style={{ cursor: 'pointer' }} alt="" />
                                </div>
                                <div className='flex justify-end items-center'>
                                    <p className='text-blue opacity-80'>Important reminders only</p>
                                    <div className="mx-2"></div>
                                    <img onClick={()=>updateNotification('reminder', reminderNotification === 'important' ? 'none' : 'important') } className='h-[19px] object-contain' src={reminderNotification === 'important' ? checked : notChecked} style={{ cursor: 'pointer' }} alt="" />
                                </div>
                                <div className='flex justify-end items-center'>
                                    <p className='text-blue opacity-80 text-end'>All remainder</p>
                                    <div className="mx-2"></div>
                                    <img onClick={()=>updateNotification('reminder', reminderNotification === 'all' ? 'none' : 'all') } className='h-[19px] object-contain' src={reminderNotification === 'all' ? checked : notChecked} style={{ cursor: 'pointer' }} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="my-[25px] bg-white rounded-[10px] shadow-lg p-[15px]">
                        <h3 className='mb-1 text-[20px] font-500 text-blue font-primary'>More Activites</h3>

                        <div className="flex justify-between items-start">

                            <div className=''>
                                <p className='text-blue opacity-80 max-w-[390px]'>Other activities on your profile</p>
                            </div>
                            <div>
                                <div className='flex justify-between items-center'>
                                    <p className='text-blue opacity-80'>Do not remind me</p>
                                    <div className="mx-2"></div>
                                    <img onClick={()=>updateNotification('donot', !eamilNotification && reminderNotification === 'none' && cahllengeNotification == 'none' ? 'all' : 'none') }  className='h-[19px] object-contain' src={!eamilNotification && reminderNotification === 'none' && cahllengeNotification == 'none' ? checked : notChecked} style={{ cursor: 'pointer' }} alt="" />
                                </div>
                                <div className='flex justify-between items-center'>
                                    <p className='text-blue opacity-80'>Mentions Only</p>
                                    <div className="mx-2"></div>
                                    <img onClick={()=>updateNotification('donot', !eamilNotification && reminderNotification === 'none' && cahllengeNotification == 'none' ? 'none' : 'all') } className='h-[19px] object-contain' src={!eamilNotification && reminderNotification === 'none' && cahllengeNotification == 'none' ? notChecked : checked} style={{ cursor: 'pointer' }} alt="" />
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            }

        </div>
    )
}

export default Notification