import { CButton, CTooltip } from "@coreui/react";
import { FiInfo } from "react-icons/fi";

export default function BSTooltip({tooltip} : any) {
    return (
        <CTooltip
        content={tooltip}
        placement="top"
        >
        <CButton style={{border :"0", background : "none", padding :0, paddingLeft : "5px"}} frameBorder={"0"}><FiInfo className='text-blue' /></CButton>
        </CTooltip>
    )
}