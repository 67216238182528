import React from 'react'
import { checked, notChecked } from '../images'

function UserManagement() {
    return (
        <div className="my-[25px]">
            <div className="bg-blue rounded p-[10px]">
                <div className='text-yellow'>
                    <h3 className='text-[14px] m-0 font-500'>User Management</h3>
                </div>
            </div>
            <div className="pl-3">
                <div className="flex justify-between mt-3">
                    <div className='w-[100px] lg:w-[auto]'>

                        <p className='text-gray2 text-[14px]'>Change user role</p>
                    </div>
                    <div className='lg:w-[340px] w-full grid grid-cols-3 '>
                        <p className='text-blue flex justify-center font-bold text-[14px]'>
                            <img className='h-[22px]' src={notChecked} style={{ cursor: 'pointer' }} alt="" />
                        </p>
                        <p className='text-blue flex justify-center font-bold text-[14px]'>
                            <img className='h-[22px]' src={notChecked} style={{ cursor: 'pointer' }} alt="" />
                        </p>
                        <p className='text-blue flex justify-center font-bold text-[14px]'>
                            <img className='h-[22px]' src={checked} style={{ cursor: 'pointer' }} alt="" />
                        </p>
                    </div>
                </div>


                <div className="flex justify-between mt-3">
                    <div className='w-[100px] lg:w-[auto]'>

                        <p className='text-gray2 text-[14px]'>Edit user details</p>
                    </div>
                    <div className='lg:w-[340px] w-full grid grid-cols-3 '>
                        <p className='text-blue flex justify-center font-bold text-[14px]'>
                            <img className='h-[22px]' src={notChecked} style={{ cursor: 'pointer' }} alt="" />
                        </p>
                        <p className='text-blue flex justify-center font-bold text-[14px]'>
                            <img className='h-[22px]' src={notChecked} style={{ cursor: 'pointer' }} alt="" />
                        </p>
                        <p className='text-blue flex justify-center font-bold text-[14px]'>
                            <img className='h-[22px]' src={checked} style={{ cursor: 'pointer' }} alt="" />
                        </p>
                    </div>
                </div>
                <div className="flex justify-between mt-3">
                    <div className='w-[100px] lg:w-[auto]'>

                        <p className='text-gray2 text-[14px]'>Block user</p>
                    </div>
                    <div className='lg:w-[340px] w-full grid grid-cols-3 '>
                        <p className='text-blue flex justify-center font-bold text-[14px]'>
                            <img className='h-[22px]' src={notChecked} style={{ cursor: 'pointer' }} alt="" />
                        </p>
                        <p className='text-blue flex justify-center font-bold text-[14px]'>
                            <img className='h-[22px]' src={notChecked} style={{ cursor: 'pointer' }} alt="" />
                        </p>
                        <p className='text-blue flex justify-center font-bold text-[14px]'>
                            <img className='h-[22px]' src={checked} style={{ cursor: 'pointer' }} alt="" />
                        </p>
                    </div>
                </div>

                <div className="flex justify-between mt-3">
                    <div className='w-[100px] lg:w-[auto]'>
                        <p className='text-gray2 text-[14px]'>Delete user</p>
                    </div>
                    <div className='lg:w-[340px] w-full grid grid-cols-3 '>
                        <p className='text-blue flex justify-center font-bold text-[14px]'>
                            <img className='h-[22px]' src={notChecked} style={{ cursor: 'pointer' }} alt="" />
                        </p>
                        <p className='text-blue flex justify-center font-bold text-[14px]'>
                            <img className='h-[22px]' src={notChecked} style={{ cursor: 'pointer' }} alt="" />
                        </p>
                        <p className='text-blue flex justify-center font-bold text-[14px]'>
                            <img className='h-[22px]' src={checked} style={{ cursor: 'pointer' }} alt="" />
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserManagement