import React, { useRef, useState } from "react";
import Image1 from "../../assets/img/image1.png";
import instance from "../../helpers/axios";
import ButtonLoader from "../../helpers/button_loader";
import { validateEmail } from "../../helpers/helpers";
import { PopupButton } from "react-calendly";
import { useNavigate } from "react-router-dom";

function HomeBanner() {
  const navigate = useNavigate();
  return (
    <div className="container mx-auto">
      {/* banner */}
      <div className="flex items-center justify-between flex-col-reverse mt-3 lg:flex-row">
        <div className="h-100 flex gap-[3px] flex-col text-blue justify-center">
          <h1 className="font-primarybold font-black text-blue dark:text-white text-[45px] lg:block mb-2">
            Unlock your Brand's Potential.
          </h1>
          <h3 className="max-w-[628px] font-primarybold leading-tight mb-2 font-bold m-0 p-0 text-blue dark:text-white text-[22px] lg:text-[30px]">
            Create your account today <br className="m-0 p-0" />
            to Unleash the Power of User-Generated Content.
          </h3>
          <p className="max-w-[628px] text-[16px] lg:text-[18px] dark:text-white font-primary">
            {/* Join now for exclusive influencer collaborations. Elevate your brand
            like never before. */}
            Elevate Your Engagement, Boost Authenticity, and Connect with Your
            Audience in Extraordinary Ways. Sign Up Now to Ignite a New Era of
            Branding Excellence!
          </p>
          <br />
          <div className="flex lg:flex-row flex-col gap-4">
            <PopupButton
              className="
            button h-[50px] rounded-[15px] px-2 font-primarybold w-full lg:w-[300px] text-yellow font-primary
               bg-blue"
              url="https://calendly.com/beentos/beentos-demo"
              rootElement={document.getElementById("root")}
              text="Book a Demo"
            />
            <button
              onClick={() => navigate("/brand/register")}
              className={`button h-[50px] rounded-[15px] px-2 font-primarybold w-full lg:w-[300px] text-blue font-primary
               bg-yellow
              }`}
            >
              Sign Up
            </button>
          </div>
        </div>
        <div>
          <img
            src={Image1}
            className="h:265 my-5 lg:my-2 lg:h-[445px] object-contain w-full"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default HomeBanner;
