import React from 'react'
import {Logo1, Logo2, Logo3, Logo4, Logo5, Logo6} from '../images'
import {Link} from 'react-router-dom'

function Brands() {
  return (
    // <div className='mx-auto bg-gray dark:bg-[#262527] py-[60px]' id='brands_section'>
    //     <div className="container mx-auto">
    //       <div className="text-center">
    //           <h3 className='text-[36px] font-primarybold mb-[20px] lg:mb-[50px] text-blue dark:text-gray2'>Partners</h3>
    //       </div>

    //       <div className="grid grid-cols-2 lg:grid-cols-3 gap-x-8 grid-flow-row gap-y-4">
    //         <div className='mb-[30px] lg:mb-[100px] flex items-center justify-center'>
    //           <Link to={'https://anutio.com/'} target="_blank">
    //             <img src={Logo1} alt="" className='w-full object-contain h-[150px] max-w-150px' />
    //           </Link>
    //         </div>
    //         <div className='mb-[30px] lg:mb-[100px] flex items-center justify-center'>
    //           <Link to={'https://www.blackchamber.ca/'} target="_blank">
    //             <img src={Logo2} alt="" className='w-full object-contain h-[150px] max-w-150px' />
    //           </Link>
    //         </div>
    //         <div className='mb-[30px] lg:mb-[100px] flex items-center justify-center'>
    //           <Link to={'https://yspace.yorku.ca/'} target="_blank">
    //             <img src={Logo3} alt="" className='w-full object-contain h-[150px] max-w-150px' />
    //           </Link>
    //         </div>
    //         <div className='mb-[30px] lg:mb-[100px] flex items-center justify-center'>
    //           <Link to={'https://www.thebea.co/our-dna'} target="_blank">
    //             <img src={Logo4} alt="" className='w-full object-contain h-[150px] max-w-150px' />
    //           </Link>
    //         </div>
    //         <div className='mb-[30px] lg:mb-[100px] flex items-center justify-center'>
    //           <Link to={'https://gustoholdings.ca/'} target="_blank">
    //             <img src={Logo5} alt="" className='w-full object-contain h-[150px] max-w-120px' />
    //           </Link>
    //         </div>
    //         <div className='mb-[30px] lg:mb-[100px] flex items-center justify-center'>
    //           <Link to={'https://www.taltisfoods.com/'} target="_blank">
    //             <img src={Logo6} alt="" className='w-full object-contain h-[150px] max-w-150px' />
    //           </Link>
    //         </div>
    //       </div>
    //     </div>
    // </div>
    <></>
  )
}

export default Brands