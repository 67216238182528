import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useDocumentTitle from '../../helpers/pageTitle';
import { useTypedSelector } from '../../hooks/useSelector';
import { ActionType } from '../../store/actions';
import instance from '../../helpers/axios';
import { toast, ToastContainer } from 'react-toastify';
import ScrollToTop from '../../helpers/scrollToTop';
import Loader from '../../helpers/loader';
import Button from '../../components/button';
import { findPercentage, findPercentageDiff, formatDate, getPreviousDate, getToday } from '../../helpers/helpers';
import { checkBox, checkBoxChecked, exporticon, who } from '../../components/images';
import { graph1, graph2, trendup } from '../../components/super_images';
import { CChart, CChartDoughnut } from '@coreui/react-chartjs';
import { BsCaretDownSquare, BsCircleFill } from 'react-icons/bs';
import { BiCaretDown, BiDotsHorizontalRounded, BiDotsVertical } from 'react-icons/bi';
import { AiOutlineArrowUp, AiOutlineCaretDown, AiOutlineCaretUp, AiOutlineCheckSquare, AiOutlineEye, AiOutlinePlusCircle } from 'react-icons/ai';
import { CoreDropdown } from '../../components';
import { resultSort } from '../../data';
import { FiSearch } from 'react-icons/fi';
// import { ShareModal } from '../../components/brand';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';

const SubscriptionPayments: React.FC = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { search } = useLocation()
    const [user, setUser] = useState('')
    const [selected, setSelected] = useState<number[]>([])
    const { acctSideBarActive, loggedInAdmin } = useTypedSelector((state) => state.appReducer);
    const [isLoading, setIsLoading] = useState(false)
    const [c, setC] = useState([])
    const [search_, setSearch_] = useState("");
    const [allList, setAllList] = useState<any[]>([])
    const [previous, setPrevious] = useState(getPreviousDate("", 90));
    const [endDate, setEndDate] = useState(getToday());
    const [currentSubscribers, setCurrentSubscribers] = useState(0)
    const [currentTotalSubscriptions, setCurrentTotalSubscriptions] = useState(0)
    const [previousSubscribers, setPreviousSubscribers] = useState(0)
    const [previousTotalSubscriptions, setPreviousTotalSubscriptions] = useState(0)
    const [currentSubscriberData, setCurrentSubscriberData] = useState([])
    const [overallSubscriptions, setOverallSubscriptions] = useState(19)
    const [overallSubscribers, setOverallSubscribers] = useState(19)
    var lineData = [
        {name: 'Jan', uv: 400, pv: 200, Amount: 400},
        {name: 'Feb', uv: 300, pv: 200, Amount: 600},
        {name: 'Mar', uv: 200, pv: 100, Amount: 200},
        {name: 'Apr', uv: 100, pv: 400, Amount: 240},
        {name: 'May', uv: 190, pv: 40, Amount: 110},
        {name: 'Jun', uv: 190, pv: 40, Amount: 300},
        {name: 'Jul', uv: 18, pv: 40, Amount: 200},
        {name: 'Aug', uv: 15, pv: 40, Amount: 100},
        {name: 'Sep', uv: 100, pv: 40, Amount: 280},
        {name: 'Oct', uv: 200, pv: 40, Amount: 320},
        {name: 'Nov', uv: 100, pv: 40, Amount: 100},
        {name: 'Dev', uv: 300, pv: 40, Amount: 490},
    ]
    const chartoptions = {
        maintainAspectRatio: false,
        elements: {
            line: {
                tension: 0.4,
            },
            point: {
                radius: 0,
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                display: false,
            },
            y: {
                display: false,
            },
        },
    }

    useEffect(() => {
        dispatch({
            type: ActionType.UPDATE_LAYOUT,
            payload: "admin"
        })

    }, [])
    const chartlegends = [
        { title: "Running", className: "text-[#0B0149]" },
        { title: "Not Running", className: "text-[#0B01491F]" },
    ]

    const addSelected = (value: number) => {
        var selected_ = selected;
        if (selected_.includes(value)) {
            selected_.splice(selected_.indexOf(value), 1)
        } else {
            selected_.push(value)
        }
        setSelected(selected_);
        setTimeout(() => {
            setAllList(c)
        }, 500);
    }
    const [sort, setSort] = useState("All")
    const [page, setPage] = useState(1)
    function dropChanged(value: any) {
        setPage(1)
        setSort(value)
    }
    useEffect(() => {
        const headers = {
            'Authorization': `Bearer ${loggedInAdmin}`
        }
        instance({
            method: "GET",
            url: `/subscription/comparison/?from=${previous}&to=${endDate}`,
            headers: headers,
        }).then((response) => {
            var data = response.data.data;
            setCurrentSubscribers(data.currentSubscribers)
            setCurrentTotalSubscriptions(data.currentTotalSubscriptions)
            setPreviousSubscribers(data.previousSubscribers)
            setPreviousTotalSubscriptions(data.previousTotalSubscriptions)
            setCurrentSubscriberData(data.currentSubscriberData)
            console.log(response)
        }).catch((error) => {
            toast.error("Something went wrong")
            setTimeout(() => {
                // navigate('/brand/dashboard');
            }, 2000);
        })
    }, [])

    const searchChanged = (value: string) => {
        if (value === "" || value === undefined || value === null) {
            setSearch_("")
            return setAllList(c)
        };
        console.log("value", value)
        setSearch_(value)
        var filtered: any[] = [];
        allList.map((list: any) => {
            if (list.user) {
                var fname: string = list.user.fullName;

                if ((fname.toLowerCase()).includes(value.toLowerCase())) filtered = [...filtered, list];
            }
        })

        setAllList(filtered)

    }
    const CustomizedDot = (props : any) => {
        const { cx, cy, stroke, payload, value } = props;
      
        return (
        <svg x={cx - 10} y={cy - 10} width={20} height={20} fill="red" viewBox="0 0 1024 1024">
            <path d="M512 1009.984c-274.912 0-497.76-222.848-497.76-497.76s222.848-497.76 497.76-497.76c274.912 0 497.76 222.848 497.76 497.76s-222.848 497.76-497.76 497.76zM340.768 295.936c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM686.176 296.704c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM772.928 555.392c-18.752-8.864-40.928-0.576-49.632 18.528-40.224 88.576-120.256 143.552-208.832 143.552-85.952 0-164.864-52.64-205.952-137.376-9.184-18.912-31.648-26.592-50.08-17.28-18.464 9.408-21.216 21.472-15.936 32.64 52.8 111.424 155.232 186.784 269.76 186.784 117.984 0 217.12-70.944 269.76-186.784 8.672-19.136 9.568-31.2-9.12-40.096z" />
        </svg>
        );
    }
    useDocumentTitle('ADMIN - Subscription Payments')
    // const a = []
    return (
        <div>
            <div className="max-w-[1445px] font-primary">
                <ToastContainer />
                {/* wrapper */}
                {
                    isLoading ? <Loader /> :
                        <div className='container font-primary min-h-[95vh] flex-inital lg:px-[15px] px-[5px] py-[25px] w-full'>
                            <div className="search flex justify-between items-center mb-4">
                                <h3 className="font-primaary font-bold text-blue">Subscription Payment Management</h3>
                            </div>

                            <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 lg:w-[80%]">
                                <div className={`w-full font-primary bg-white p-3  rounded-[10px] flex items-end justify-between shadow`}>
                                    <div className='flex flex-col justify-between' >
                                        <h3 className='text-blue text-[32px] font-primarybold font-bold'>{currentSubscribers}</h3>
                                        <div className='my-2'>
                                            <p className='text-blue opacity-90 text-[14px]'>Subscribers</p>
                                        </div>
                                        <div className='flex items-center'>
                                            {
                                                currentSubscribers > previousSubscribers ? <AiOutlineCaretUp className='text-blue' /> : <AiOutlineCaretDown className='text-blue' />
                                            }
                                            <p className='text-[11px] m-0'>
                                                <strong className={`text-[13px] ${currentSubscribers > previousSubscribers ? 'text-green-700' : 'text-red-700'}`}>
                                                    {findPercentageDiff((currentSubscribers > previousSubscribers ? currentSubscribers : previousSubscribers), (currentSubscribers > previousSubscribers ? previousSubscribers : currentSubscribers), 0)}% </strong> {currentSubscribers > previousSubscribers ? 'More than ' : 'Less than '}
                                                last month</p>
                                        </div>
                                    </div>
                                    <div className='relative'>
                                        {/* <img src={circlingpregress} className="h-[85px] object-contain" alt="" /> */}
                                        <h3 className='absolute top-[31px] left-[25px] text-blue text-[13px] font-primarybold font-bold'>{findPercentage(currentSubscribers, overallSubscribers, 0)}%</h3>


                                        <CChartDoughnut
                                            className="mx-auto"
                                            style={{ height: '83px', width: '83px' }}
                                            options={chartoptions}
                                            data={{
                                                labels: ['New', 'All',],
                                                datasets: [
                                                    {
                                                        backgroundColor: ['#F6E701', '#F0F0F4'],
                                                        data: [Number(findPercentage(currentSubscribers, overallSubscribers, 0)), 100 - Number(findPercentage(currentSubscribers, overallSubscribers, 0))],

                                                    },
                                                ],
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className={`w-full font-primary bg-white p-3  rounded-[10px] flex items-end justify-between shadow`}>
                                    <div className='flex flex-col justify-between' >
                                        <h3 className='text-blue text-[32px] font-primarybold font-bold'>{currentTotalSubscriptions}</h3>
                                        <div className='my-2'>
                                            <p className='text-blue opacity-90 text-[14px]'>Total Subscriptions</p>
                                        </div>
                                        <div className='flex items-center'>
                                            {
                                                currentTotalSubscriptions > previousTotalSubscriptions ? <AiOutlineCaretUp className='text-blue' /> : <AiOutlineCaretDown className='text-blue' />
                                            }
                                            <p className='text-[11px] m-0'>
                                                <strong className={`text-[13px] ${currentTotalSubscriptions > previousTotalSubscriptions ? 'text-green-700' : 'text-red-700'}`}>
                                                    {findPercentageDiff((currentTotalSubscriptions > previousTotalSubscriptions ? currentTotalSubscriptions : previousTotalSubscriptions), (currentTotalSubscriptions > previousTotalSubscriptions ? previousTotalSubscriptions : currentTotalSubscriptions), 0)}% </strong> {currentTotalSubscriptions > previousTotalSubscriptions ? 'More than ' : 'Less than '}
                                                last month</p>
                                        </div>
                                    </div>
                                    <div className='relative'>
                                        {/* <img src={circlingpregress} className="h-[85px] object-contain" alt="" /> */}
                                        <h3 className='absolute top-[31px] left-[25px] text-blue text-[13px] font-primarybold font-bold'>0{findPercentage(currentTotalSubscriptions, overallSubscriptions, 0)}%</h3>


                                        <CChartDoughnut
                                            className="mx-auto"
                                            style={{ height: '83px', width: '83px' }}
                                            options={chartoptions}
                                            data={{
                                                labels: ['New', 'All',],
                                                datasets: [
                                                    {
                                                        backgroundColor: ['#F6E701', '#F0F0F4'],
                                                        data: [Number(findPercentage(currentTotalSubscriptions, overallSubscriptions, 0)), 100 - Number(findPercentage(currentTotalSubscriptions, overallSubscriptions, 0))],
                                                    },
                                                ],
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="py-4 px-3 mt-[30px] rounded shadow bg-white ">
                            <div className="text-[22px] text-[#0B0149] font-[600]">
                                <h3>Subscription Overview</h3>
                                <div className="flex mt-1">
                                    <p className='text-[#7E799F] text-[16px]'>$10,875</p> &nbsp; <p className='text-[#10B981] text-[16px]'>17%</p> &nbsp; <AiOutlineArrowUp color='#10B981' className='text-[16px]' />
                                </div>
                            </div>
                            <br />
                            <LineChart width={window.innerWidth - 300} height={300} data={lineData} padding={{left : 10}} margin={{ top: 5, right: 20, bottom: 5, left: 10 }}>
                                <Line background="red" type="monotone" dataKey="Amount" color="red" stroke="#8884d8"
                                dot={<CustomizedDot />}
                                 />
                                <XAxis padding={{left : 10}} dataKey="name" />
                                {/* <YAxis /> */}
                                <Tooltip dataKey="amt" />
                            </LineChart>
{/*                             
                            <CChart
                                type="line"
                                color='red'
                                data={{
                                    labels: ["January", "February", "March", "April", "May", "June", "July"],
                                    datasets: [
                                        {
                                            label: "Subscription",
                                            borderColor: "rgba(220, 220, 220, 1)",
                                            pointBackgroundColor: "blue",
                                            pointBorderColor: "#FE718E",
                                            backgroundColor : "red",
                                            pointBorderWidth: 5,
                                            data: [40, 20, 12, 39, 10, 40, 39, 80, 40]
                                        },
                                 
                                    ],
                                }}
                                options={{
                                    plugins: {
                                        legend: {
                                            display : false,
                                            labels: {
                                                color: "blue",
                                            }
                                        }
                                    },
                                    scales: {
                                        x: {
                                            grid: {
                                                color:  "transparent",
                                            },
                                            ticks: {
                                                color: "gray",
                                            },
                                            
                                        },
                                        y: {
                                            grid: {
                                                color:  "transparent",
                                            },
                                            ticks: {
                                                color: "gray",
                                            },
                                            
                                        },
                                    },
                                }}
                            /> */}
                            </div>


                            <div className="py-3 px-3 mt-[30px] rounded shadow bg-white " style={{ overflow: 'scroll' }}>
                                <div className="search flex justify-between items-center mb-4">
                                    <h3 className="font-primaary font-bold text-blue">Top Voted</h3>
                                </div>

                                <div className="shadow-[10px] rounded-[10px] w-[800px] md:w-full lg:w-full border-graylight border-2" >
                                    <table className="border-collapse text-sm table-auto w-[800px] md:w-full lg:w-full">
                                        <thead className='text-blue p-2 h-[45px] border-b-graylight border-b-2'>
                                            <tr>
                                                <th></th>
                                                <th>Invoice Number</th>
                                                <th className='text-left'>Brand</th>
                                                <th className='text-left'>Date</th>
                                                <th className='text-left'>Amount</th>
                                                <th className="text-left"><BiDotsVertical /></th>
                                            </tr>
                                        </thead>
                                        <tbody className='p-2'>
                                            {
                                                currentSubscriberData.map((item: any, i) => (
                                                    <tr key={i} className='p-2 text-[#0B014980] border-spacing-2' style={{ height: '70px' }}>
                                                        <td className='w-[120px] text-center pr-2'>
                                                            <div className=" flex items-center justify-end">
                                                                {
                                                                    selected.includes(item._id) ?
                                                                        <img onClick={() => addSelected(item)} style={{ cursor: 'pointer' }} src={checkBoxChecked} alt="" />
                                                                        :
                                                                        <img onClick={() => addSelected(item)} style={{ cursor: 'pointer' }} src={checkBox} alt="" />
                                                                }
                                                                <div className="mx-2"></div>
                                                                <div className="mx-2"></div>
                                                            </div>
                                                        </td>
                                                        <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''} min-w-[250px]`}>{item._id}</td>
                                                        <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''} min-w-[250px]`}>Marvel</td>
                                                        <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''} min-w-[250px]`}>{formatDate(item.lastPaymentUpdate, 3)}</td>
                                                        <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''}`}>{item.amount}</td>
                                                        <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''} w-[50px]`}><BiDotsVertical /></td>
                                                    </tr>
                                                ))
                                            }

                                        </tbody>

                                        {
                                            currentSubscriberData.length === 0 ?
                                                <p>No record found</p>
                                                :
                                                ''
                                        }
                                    </table>
                                </div>
                            </div>
                        </div>
                }

            </div>
        </div>
    )
}

export default SubscriptionPayments