import React, { useState } from 'react'
import { FiSearch } from 'react-icons/fi'
import { VscCloseAll } from 'react-icons/vsc'
import { toast, ToastContainer } from 'react-toastify'
import { brandCategories, superAdminUsersTypes } from '../../data'
import instance from '../../helpers/axios'
import ButtonLoader from '../../helpers/button_loader'
import { useTypedSelector } from '../../hooks/useSelector'
import { iCreate } from '../../models/model'
import Button from '../button'
import CoreDropdown from '../core_dropdown'
import { checkBox, checkBoxChecked, copy_icon1, Logo } from '../images'
import Input from '../input'

function AddUserModal({ close }: iCreate) {
    const [search, setSearch] = useState('')
    const [result, setResult] = useState([])
    const [loading, setLoading] = useState(true)
    const [selectedUser, setSelectedUser] = useState('')
    const { loggedInAdmin } = useTypedSelector((state) => state.appReducer);
    const [isLoading, setIsLoading] = useState(false)
    const [email, setEmail] = useState("")
    const [allRoles, setAllRoles] = useState([
        {title : "Super Admin", value : "super-admin"},
        {title : "Brand Admin", value : "brand-admin"},
    ])
    const [selectedRoles, setSelectedRoles] = useState<any[]>([])

    const addMember = () => {
        if (email == "") return;
        setIsLoading(true)
        const headers = {
            'Authorization': `Bearer ${loggedInAdmin}`
        }
        instance({
            method: "PUT",
            url: "/auth/update-admin-roles/add",
            headers: headers,
            data : {
                adminRoles : ["super-admin"],
                userNameOrEmail : email
            }
        }).then((response) => {
            toast.success("User is now an Admin");
            setEmail("");
        }).catch((e) => {
            toast.error(e.response.data.message);
        }).finally(() => {
            setIsLoading(false)
        })
    }
    const selectARole = (value : string) =>{

    }
    return (
        <div>
            <ToastContainer />
            <div className='fixed top-0 left-0 w-full flex items-center justify-center z-[999] h-[100vh]'>
                <div className="max-h-[80vh] min-h-[300px] w-full flex justify-center">
                    <div onClick={() => close()} className='back_drop backdrop-blur fixed top-0 left-0 w-full bg-blue/30 h-[100vh] bg-blue'></div>
                    <div className='bg-white shadow-lg rounded-[15px] w-[95%] lg:w-[670px] p-3 relative'>
                        <h3 className='text-[#1E2124] text-[20px]'>Share Super Admin Access</h3>
                        <p className='text-[#1E2124] mt-2'>Add team members </p>
                        <div className="h-[1px] my-3 bg-[#CDE1FF] w-full"></div>

                        <div className='bg-[#F0F0F4] rounded-[8px] w-full flex items-center py-1'>
                            <input value={email} onChange={(e) => setEmail(e.target.value)} type="text" className='bg-transparent ml-3 border-0 outline-0 w-full h-[40px]' placeholder='Enter emails' />
                            <Button onclick={addMember} loading={isLoading} text='Add' containerClass='bg-blue text-yellow border-0 w-[100px] lg:w-[150px]' h='40px' />
                        </div>
                        {/* <div className="px-3">
                            <div className='flex my-3 gap-4'>{
                            allRoles.map((item : any, i) => (
                                <div className='flex-initial flex items-center' onClick={()=>{selectARole(item.vlaue)}}>
                                    <img src={checkBox} alt="" className='h-[20px]' /> &nbsp;
                                    {selectedRoles.includes(item.value) ?
                                        <img style={{cursor:'pointer'}} src={checkBoxChecked} alt="" />
                                        :
                                        <img style={{cursor:'pointer'}} src={checkBox} alt="" />
                                    }
                                    <div className='flex-initial w-full flex justify-between'>
                                        <div className='flex-initial w-full'>
                                            <p className='text-[13px] text-gray2'>{item.title}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            </div>
                        </div> */}

                        {/* <div className="mt-4 px-3">
                            {
                                [1, 2, 3].map((item, i) => (
                                    <div key={i} className='flex my-3 gap-4'>
                                        <img src={checkBox} alt="" className='h-[20px]' />
                                        <div className='flex-initial w-full flex justify-between'>
                                            <div className='flex-initial w-full'>
                                                <p className='text-[13px] text-gray2'>zinohzain@gmail.com</p>
                                            </div>
                                            <div className='w-[100px]'>
                                                <CoreDropdown showError={false} err={""} items={superAdminUsersTypes} containerClass="control justify-between border border-blue pl-2 h-[25px] text-sm rounded mt-0" placeholder="Admin" type="text" value={selectedUser} setValue={setSelectedUser} />
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div> */}
                        <div className="h-[1px] my-4 bg-[#CDE1FF] w-full"></div>
                        <div className="px-3">
                            <p className='text-[13px] text-gray2'>Anyone added have admin access</p>
                            <div className="mt-3 flex justify-between">
                                {/* <button className={`control border border-blue pl-2 flex justify-center items-center rounded-[45px] bg-yellow text-blue w-[120px]`} style={{ height: '40px' }}>
                                    <span className="mr-3">
                                    </span>
                                    <div className="flex gap-2">
                                        Copy
                                        <img src={copy_icon1} alt="" />
                                    </div>
                                </button> */}
                                <Button text='Cancel' containerClass='border-blue border-1 w-[120px]' h='40px' />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddUserModal