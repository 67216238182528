import React from 'react'
import { RxDropdownMenu } from 'react-icons/rx';
import { MdMenuOpen } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../hooks/useSelector';
import { ActionType } from '../../store/actions';
interface header {
    title : string,
    sub_title?:string,
}
function SettingsHeader({title, sub_title} : header) {
  const { acctSideBarActive } = useTypedSelector((state) => state.appReducer);
    const dispatch = useDispatch();
  return (
    <div className="flex justify-between">
        <div>
            <h6 className="text-blue font-primarybold text-[18px]">{title}</h6>
            <p className="text-blue opacity-75">{sub_title}</p>
        </div>
        <div className='block lg:hidden'>
            <button onClick={()=>{
                dispatch({
                    type : ActionType.ACCT_SIDEBAR_ACTIVE,
                    payload : !acctSideBarActive
                })
            }}> {
                acctSideBarActive ? <MdMenuOpen className='text-blue text-[30px]' /> :
                <RxDropdownMenu className='text-blue text-[30px]' />
            } 
            </button>
        </div>
    </div>
  )
}

export default SettingsHeader