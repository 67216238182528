import React from 'react'
import { BiCog, BiLogOut, BiUserCircle } from 'react-icons/bi';
import { BsGridFill } from 'react-icons/bs';
import { RiBankLine, RiLockPasswordLine, RiShieldUserLine } from 'react-icons/ri';
import { VscShield } from 'react-icons/vsc';
import { IoMdHelp } from 'react-icons/io';
import { FiBell } from 'react-icons/fi';
import { Link, useLocation } from 'react-router-dom';
import { sidebarItems } from "../../models/model";
import { useTypedSelector } from '../../hooks/useSelector';

const items:sidebarItems[] = [
    {title : 'Password', icon : <BiUserCircle />, link : '/admin/settings'},
    {title : 'Payment', icon : <FiBell />, link : '/admin/settings/notification'},
    {title : 'User Authorization', icon : <RiBankLine />, link : '/admin/settings/payment'},
    {title : 'Notification Management', icon : <RiShieldUserLine />, link : '/admin/settings/privacy'},
    {title : 'Integration', icon : <VscShield />, link : '/admin/settings/security'},
    // {title : 'OTP Verification', icon : <RiLockPasswordLine />, link : '/admin/settings/otp'},
    {title : 'Help', icon : <IoMdHelp />, link : '/admin/settings/help'}
]
    
function SettingsAside() {
  const path = useLocation().pathname
  const { acctSideBarActive } = useTypedSelector((state) => state.appReducer);

  return (
    <aside className={`h-100 min-h-[100vh] z-40 px-[10px] ${acctSideBarActive ? 'absolute left-0 top:0' : 'hidden'} lg:static lg:block py-[20px] flex-initial w-full max-w-[320px] bg-white shadow`}>
          <div >
              {
                items.map((item, i)=> {
                    const isActive = item.link === path;
                    return <Link to={item.link} key={i} className={`w-full flex items-center justify-start my-[10px] p-2 ${isActive ? 'bg-blue text-yellow rounded ' : 'text-blue opacity-75 hover:opacity-100'}`}>
                                <div className={`text-[15px] rounded-[50%] ${isActive ? 'bg-yellow text-blue' : 'bg-blue text-white'} p-2`}>
                                    {item.icon}
                                </div>
                                <div className="ml-2 text-[14px]">
                                    {item.title}
                                </div>
                            </Link> 
                })
              }
          </div> 
    </aside>
  )
}

export default SettingsAside