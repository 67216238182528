import React, { Fragment, useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useDocumentTitle from '../../helpers/pageTitle';
import { useTypedSelector } from '../../hooks/useSelector';
import { ActionType } from '../../store/actions';
import instance from '../../helpers/axios';
import { toast, ToastContainer } from 'react-toastify';
import Loader from '../../helpers/loader';
import { calendar, checkBox, checkBoxChecked, circlingpregress, exporticon, graph, lineGraph, who } from '../../components/images';
import { AiOutlineCaretDown, AiOutlineCaretUp, AiOutlineCheckSquare, AiOutlineEye } from 'react-icons/ai';
import Button from '../../components/button';
import { Hr } from '../../components';
import { CChart, CChartBar, CChartLine, CChartDoughnut } from '@coreui/react-chartjs';
import { BsCircleFill } from 'react-icons/bs';
import Pagination from '../../components/pagination';
import { BiDotsVertical } from 'react-icons/bi';
import { findPercentage, findPercentageDiff, formatDate, getPreviousDate, getToday, utcToDay } from '../../helpers/helpers';
import { DateRangePicker } from 'rsuite';
import '../../helpers/rssuite.css';
import GenericPdfDownloader from '../../helpers/convert_to_pdf';
import jsPDF from 'jspdf';
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import ReportTemplate from './pdf';
import html2canvas from 'html2canvas';
import { PdfModal } from '../../components/brand';
import ButtonLoader from '../../helpers/button_loader';

const Analytics: React.FC = () => {
    const { loggedInBrand } = useTypedSelector((state) => state.appReducer);
    const navigate = useNavigate()
    const { search } = useLocation()
    const dispatch = useDispatch();
    const [brand, setBrand] = useState([])
    const [previous, setPrevious] = useState(getPreviousDate("", 30));
    const [endDate, setEndDate] = useState(getToday());
    const [selected, setSelected] = useState<number[]>([])
    const [page, setPage] = useState(1)
    const [allList, setAllList] = useState<any[]>([])
    const [c, setC] = useState<any[]>([])
    const [startDate, setStartDate] = useState('')

    const [totalPreviousChallenge, setTotalPreviousChallenge] = useState(0)

    const [isAnalytics, setIsAnalytics] = useState(true);
    const [reached, setReached] = useState(0)
    const [reachedAll, SetreachedAll] = useState(0)
    const [reachedPrev, setreachedPrev] = useState(0)
    const [allUniques, setAllUniques] = useState([])
    const [reachedArray, setReachedArray] = useState([0, 0, 0, 0, 0, 0, 0])
    const [clicks, setClicks] = useState(0)
    const [clicksArray, setClicksArray] = useState([0, 0, 0, 0, 0, 0, 0])
    const [sharesArray, setsharesArray] = useState([0, 0, 0, 0, 0, 0, 0])
    const [impressionArr, setImpressionArr] = useState([0, 0, 0, 0, 0, 0, 0])
    const [reachedByHour, setReachedByHour] = useState([0, 0, 0, 0, 0, 0, 0, 0])
    const [showPdfModal, setShowPdfModal] = useState(false)

    const [socialShares, setSocialShares] = useState([0, 0, 0])
    const [challenges, setChallenges] = useState([])
    const [totalChallengeCreated, setTotalChallengeCreated] = useState(0)
    const [engagements, setEngagements] = useState([0, 0, 0, 0, 0, 0, 0])
    const [engagementcount, setEngagementcount] = useState<number>(0)
    const [engagementcountLife, setEngagementcountLife] = useState<number>(0)
    const [engagementcountprev, setEngagementcountprev] = useState(0)
    const [isDateChanged, setIsDateChanged] = useState(false)
    const [finalLoading, setFinalLoading] = useState(true)
    const [isCalenderOpen, setIsCalenderOpen] = useState(false)

    const [challengeTypes, setChallengeTypes] = useState([
        { id: 1, count: 0, title: "Video" },
        // { id: 2, count: 0, title: "Picture" },
    ])
    const [isLoading, setIsLoading] = useState(true)
    const [isNextToInitialDataLoading, setIsNextToInitialDataLoading] = useState(true);
    const chartoptions = {
        maintainAspectRatio: false,
        elements: {
            line: {
                tension: 0.4,
            },
            point: {
                radius: 0,
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                display: false,
            },
            y: {
                display: false,
            },
        },
    }


    const chartlegends = [
        { title: "Impression", className: "text-[#2712A9]" },
        { title: "Reached", className: "text-[#F6E701]" },
        { title: "Clicks", className: "text-[#0B01491F]" },
        { title: "Shares", className: "text-[#FE718E]" },
    ]
    useEffect(() => {
        dispatch({
            type: ActionType.UPDATE_LAYOUT,
            payload: "brand"
        })

    }, [])


    const addSelected = (value: number) => {
        var selected_ = selected;
        if (selected_.includes(value)) {
            selected_.splice(selected_.indexOf(value), 1)
        } else {
            selected_.push(value)
        }
        setSelected(selected_);
        setTimeout(() => {
            setAllList(c)
        }, 500);
    }

    useEffect(() => {
        if (!loggedInBrand || loggedInBrand == "") {
            // nobody is logged in
            navigate("/brand/login");
        }
    }, [loggedInBrand])

    // get brand profile
    useEffect(() => {
        const headers = {
            'Authorization': `Bearer ${loggedInBrand}`
        }
        instance({
            method: "GET",
            url: "/brand/profile",
            headers: headers
        }).then((response) => {
            setBrand(response.data.data.brand);
            const c = response.data.data.challenges;
            if (c == null) setChallenges([])
            else if (c.length === 0) setChallenges([])
            else setChallenges(c.reverse());
            setIsLoading(false)
        }).catch((err) => {
            if (err.response.data.statusCode === 403) {
                navigate('/brand/login');
                return false;
            }
            toast.error(err.response.data.message)
        })
    }, [])



    // get period based analytics 
    useEffect(() => {
        var prev = previous;
        // setPrevious(prev)
        var previousEndDate = getPreviousDate(prev, 1)
        var previousStartDate = getPreviousDate(prev, 30)

        const headers = {
            'Authorization': `Bearer ${loggedInBrand}`
        }
        instance({
            method: "GET",
            url: `/challenge/analytics?from=${previous}&to=${endDate}`,
            headers: headers
        }).then((response) => {
            setIsLoading(false)
            if (response.data.data.length == 0) {
                setIsAnalytics(false);
                return false;
            } else {
                var data = response.data.data.analyticsData;
                setIsNextToInitialDataLoading(false);
                var data = response.data.data.analyticsData;
                var uniqueUsers = response.data.data.uniquePosts;
                uniqueUsers = Object.keys(uniqueUsers).map((key) => [key, uniqueUsers[key]] ).sort((a, b) => Number(a[1]?.impressions) - Number(b[1]?.impressions));
                setAllUniques(uniqueUsers.reverse())
                getEngagement(data, "life");
                SetreachedAll(Number(getReached(data, "count")));
    
                //var uniqueUsers = response.data.data.allUniquePost;
                 //setAllUniques(uniqueUsers.reverse())
                setReached(Number(getReached(data, "count")));
                setReachedArray(getReached(data, "breakdown"));
                setClicksArray(getClicks(data, "breakdown"));
                setsharesArray(getSharedSocial(data, "breakdown"));
                getEngagement(data);
                getuserReachedByhr(data)
                setImpressionArr(getImpressions(data));
                setSocialShares(getSharedSocial(data))

                // for previous month campaign analytics
                setTimeout(() => {
                    instance({
                        method: "GET",
                        url: `/challenge/analytics?from=${previousStartDate}&to=${previousEndDate}`,
                        headers: headers
                    }).then((response) => {
                        var data = response.data.data.analyticsData;
                        getEngagement(data, "prev");
                        setreachedPrev(Number(getReached(data, "count")));
                    }).catch((err) => {
                        console.log(err)
                    })
                }, 100);
            }
        }).catch((err) => {
            console.log(err)
        })
    }, [endDate, previous])

    // get challenge analytics
    useEffect(() => {
        setIsLoading(true)
        // setFinalLoading(true)
        var prev = previous;
        // setPrevious(prev)
        var previousEndDate = getPreviousDate(prev, 1)
        var previousStartDate = getPreviousDate(prev, 30)
        const headers = {
            'Authorization': `Bearer ${loggedInBrand}`
        }
        instance({
            method: "GET",
            url: `/challenge/analytics/challenges?from=${prev}&to=${endDate}`,
            headers: headers
        }).then((response) => {
            var data = response.data.data;
            getChallengesAnalytics(data)
            getChallengeTypeAnalytics(data)

            // for previous month campaign analytics
            //setTimeout(() => {
                setIsLoading(false)
                instance({
                    method: "GET",
                    url: `/challenge/analytics/challenges?from=${previousStartDate}&to=${previousEndDate}`,
                    headers: headers
                }).then((response) => {
                    var data = response.data.data;
                    getChallengesAnalytics(data, "prev")
                }).catch((err) => {
                    console.log(err)
                })
            //}, 100);

        }).catch((err) => {
            console.log(err)
        })
    }, [endDate, previous])

    const getSharedSocial = (data: any, type = "") => {

        var facebook = 0;
        var ig = 0;
        var resultArr: number[] = [];
        var tiktok = 0;
        let whatsapp = 0;

        for (let index = 0; index < data.length; index++) {
            const analytic = data[index];
            var shared = analytic.shared;
            // if (shared === 0) shared = 1;
            var sharedDate = new Date(analytic.date);

            var dday = sharedDate.getUTCDay();
            if (dday === 0) {
                if (resultArr[6] == null || resultArr[6] == undefined) {
                    resultArr[6] = shared;
                } else {
                    resultArr[6] = resultArr[6] + shared;
                }
            } else {
                if (resultArr[dday] == null || resultArr[dday] == undefined) {
                    resultArr[dday] = shared
                } else {
                    resultArr[dday] = resultArr[dday] + shared;
                }
            }


            // if(sharedDate.getUTCDay() === 0){
            //     resultArr[6] = shared;
            // }else{
            //     resultArr[sharedDate.getUTCDay()-1] = shared;
            // }
            facebook = facebook + (analytic.socialMediaShares.Facebook == undefined ? 0 : analytic.socialMediaShares.Facebook);
            ig = ig + (analytic.socialMediaShares.Instagram ? analytic.socialMediaShares.Instagram : 0);
            tiktok = tiktok + (analytic.socialMediaShares.Tiktok ? analytic.socialMediaShares.Tiktok : 0);
            whatsapp = whatsapp + (analytic.socialMediaShares.Whatsapp ? analytic.socialMediaShares.Whatsapp : 0);
        }
        if (type === "breakdown") return resultArr;
        return [facebook, ig, whatsapp];
    }

    const getEngagement = (data: any, source = "main") => {
        var engagements: number[] = [];
        var totalEngagements = 0;
        var sun = 0, mon = 0, tue = 0, wed = 0, thu = 0, fri = 0, sat = 0;
        for (let index = 0; index < data.length; index++) {
            const analytic = data[index];
            var clicks = analytic.clicks, impressions = analytic.impressions, shared = analytic.shared, reached = analytic.reached.userReached;
            var newEngagements = clicks + impressions + shared + reached;
            totalEngagements = totalEngagements + newEngagements;
            // if(newEngagements === 0) newEngagements = 1;
            if (source == "main") {
                var engagementDate = new Date(analytic.date);
                if (engagementDate.getUTCDay() === 0) {
                    if (engagements[6] == null || engagements[6] == undefined) {
                        engagements[6] = newEngagements;
                    } else {
                        engagements[6] = engagements[6] + newEngagements;
                    }
                } else {
                    if (engagements[engagementDate.getUTCDay() - 1] == null || engagements[engagementDate.getUTCDay() - 1] == undefined) {
                        engagements[engagementDate.getUTCDay() - 1] = newEngagements;
                    } else {
                        engagements[engagementDate.getUTCDay() - 1] = engagements[engagementDate.getUTCDay() - 1] + newEngagements;
                    }
                }
            }
        }

        if (source === "life") {
            setEngagementcountLife(totalEngagements)
        }
        if (source === "prev") {
            setEngagementcountprev(totalEngagements)
        }
        if (source === "main") {
            setEngagementcount(totalEngagements)
            setEngagements(engagements)
        }
    }

    const getImpressions = (data: any) => {
        const resultArr: number[] = [];
        for (let index = 0; index < data.length; index++) {
            const analytic = data[index];
            var impressions = analytic.impressions;
            // if(impressions === 0) impressions = 0.1;
            var engagementDate = new Date(analytic.date);


            if (engagementDate.getUTCDay() === 0) {
                if (resultArr[6] == null || resultArr[6] == undefined) {
                    resultArr[6] = impressions;
                } else {
                    resultArr[6] = resultArr[6] + impressions;
                }
            } else {
                if (resultArr[engagementDate.getUTCDay() - 1] == null || resultArr[engagementDate.getUTCDay() - 1] == undefined) {
                    resultArr[engagementDate.getUTCDay() - 1] = impressions;
                } else {
                    resultArr[engagementDate.getUTCDay() - 1] = resultArr[engagementDate.getUTCDay() - 1] + impressions;
                }
            }

        }
        setFinalLoading(false)
        return resultArr;
    }
    const getReached = (data: any, type: string): any => {
        var total_reached = 0;
        var resultArr: number[] = [];
        for (let index = 0; index < data.length; index++) {
            const analytic = data[index];
            var reached = analytic.reached.userReached;
            total_reached = total_reached + analytic.reached.userReached;
            var engagementDate = new Date(analytic.date);
            // if(reached === 0) reached = 0.1;
            var dday = engagementDate.getUTCDay();
            if (dday === 0) {
                if (resultArr[6] == null || resultArr[6] == undefined) {
                    resultArr[6] = reached;
                } else {
                    resultArr[6] = resultArr[6] + reached;
                }
            } else {
                if (resultArr[dday] == null || resultArr[dday] == undefined) {
                    resultArr[dday] = reached
                } else {
                    resultArr[dday] = resultArr[dday] + reached;
                }
            }

            // if(engagementDate.getUTCDay() === 0){
            //     resultArr[6] = reached;
            // }else{
            //     resultArr[engagementDate.getUTCDay()-1] = reached;
            // }
        }

        if (type == "breakdown") return resultArr;
        return total_reached;
    }


    const getClicks = (data: any, type: string): any => {
        var total_clicks = 0;
        var resultArr: number[] = [];
        for (let index = 0; index < data.length; index++) {
            const analytic = data[index];
            var click = analytic.clicks;
            total_clicks = total_clicks + click;
            var engagementDate = new Date(analytic.date);
            // if(click === 0) click = 0.3;

            var dday = engagementDate.getUTCDay();
            if (dday === 0) {
                if (resultArr[6] == null || resultArr[6] == undefined) {
                    resultArr[6] = click;
                } else {
                    resultArr[6] = resultArr[6] + click;
                }
            } else {
                if (resultArr[dday] == null || resultArr[dday] == undefined) {
                    resultArr[dday] = click
                } else {
                    resultArr[dday] = resultArr[dday] + click;
                }
            }
        }

        if (type == "breakdown") return resultArr;
        return total_clicks;
    }

    const getChallengeTypeAnalytics = (data: any) => {
        var videos = 0;
        var pictures = 0;

        for (let index = 0; index < data.length; index++) {
            const analytic = data[index];
            var ctypes = analytic.statistics.challengeTypes;
            if (ctypes.picture) pictures = ctypes.picture + pictures;

            if (ctypes.video) videos = ctypes.video + videos;
        }

        setChallengeTypes([
            { id: 1, count: videos, title: "Video" },
            // { id: 2, count: pictures, title: "Picture" },
        ])
    }
    const getuserReachedByhr = (data: any) => {
        var userReachedByhr: any[] = [0, 0, 0, 0, 0, 0, 0, 0];
        // console.clear()
        try {
            for (let index = 0; index < data.length; index++) {
                const analytic = data[index];
                var reached = analytic.reached.userReachedPerHour
                reached = Object.keys(reached).map((key) => [key, reached[key]]);
                reached.map((r: any) => {
                    if (r.length > 0) {
                        var i = getReachedGraphindex(Number(r[0]))
                        userReachedByhr[i] = userReachedByhr[i] + Number(r[1])
                    }
                })
            }
        } catch (error) {
        }
        setReachedByHour(userReachedByhr)
    }
    const getReachedGraphindex = (time: any): any => {
        if ((time == 0 && time <= 2)) {
            return 0;
        }
        if ((time >= 3 && time <= 5)) {
            return 1;
        }
        if ((time >= 6 && time <= 8)) {
            return 2;
        }
        if ((time >= 9 && time <= 11)) {
            return 3;
        }
        if ((time >= 12 && time <= 14)) {
            return 4;
        }
        if ((time >= 15 && time <= 17)) {
            return 5;
        }
        if ((time >= 18 && time <= 20)) {
            return 6;
        }
        if ((time >= 21 && time <= 23)) {
            return 7;
        }
        return 0;
    }
    const [overallViews, setOverallViews] = useState(0)
    const getChallengesAnalytics = (data: any, type = "") => {
        var allChallenges_: any[] = [];
        var OverallViews = 0;
        try {
            for (let index = 0; index < data.length; index++) {
                const analytic = data[index];
                var challengeArr = analytic.statistics.allChallenges;
                challengeArr.map((item: any) => {
                    var v = convertPositive(item.noOfViews);
                    OverallViews = OverallViews + v;
                    item.noOfViews1 = convertPositive(item.noOfViews)
                    allChallenges_ = [...allChallenges_, item]
                })

                if(index === data.length -1){
                    if (type == "prev") {
                        setTotalPreviousChallenge(allChallenges_.length)
                    } else {
                        setOverallViews(OverallViews)
                        setPage(1)
                        setAllList(allChallenges_)
                        
                        setTotalChallengeCreated(allChallenges_.length)
                        setC(allChallenges_)
                    }
                }

            }
            // console.log(allChallenges_)
        } catch (error) {
        }



    }


    // handle date range picker change
    const handleRangeChanged = (date: any) => {
        // console.log(date)
        setPrevious(getToday(date[0]))
        setEndDate(getToday(date[1]))
        setIsDateChanged(true);
    }
    const generatePDF = () => {

        const report = new jsPDF('landscape', 'pt', 'a3');
        var element: HTMLElement = document.querySelector('#pageToDownload')!;
        report.html(element).then(() => {
            report.save('report.pdf');
        });
    }

    const pdfRef = useRef(null);
    const [report1, setReport1] = useState("/report1.png")
    const [report2, setReport2] = useState("/report2.png")
    const [report3, setReport3] = useState("/report3.png")
    const [report4, setReport4] = useState("/report4.png")
    const [report5, setReport5] = useState("/report5.png")
    const [loaded, setLoaded] = useState(false)
    const [isLoadingPdf, setIsLoadingPdf] = useState(false)
    const handleDownload = () => {
        // getting report sections ===========================================
        setLoaded(false)
        setShowPdfModal(true)
        setIsLoadingPdf(true)

        setTimeout(() => {
            // section 1, overview
        var input = document.getElementById('report1');
        html2canvas(input!)
        .then((canvas) => {
        var imgData = canvas.toDataURL('image/jpg');
        setReport1(imgData)
        // section 2
        var input2 = document.getElementById('report2');
        html2canvas(input2!)
        .then((canvas) => {
        var imgData2 = canvas.toDataURL('image/jpg');
            setReport2(imgData2)
            // section 3
            var input3 = document.getElementById('report3');
            html2canvas(input3!)
            .then((canvas) => {
            var imgData3 = canvas.toDataURL('image/png');
                setReport3(imgData3)
                // section 4
                var input4 = document.getElementById('report4');
                html2canvas(input4!)
                .then((canvas) => {
                    var imgData4 = canvas.toDataURL('image/png');
                    setReport4(imgData4)
                    // section 5
                    var input5 = document.getElementById('report5');
                    html2canvas(input5!)
                    .then((canvas) => {
                    var imgData5 = canvas.toDataURL('image/png');
                        setReport5(imgData5)
                        setLoaded(true)
                        setIsLoadingPdf(false)
                    })
                })
            })
        })
      })
        }, 2000);
        
    ;
    };

    
    // when the challenge pagination changed
    useEffect(() => {
        const search_ = (search.replace('?', '')).split("&")
        if (search_.length > 0) {
            search_.forEach(s => {
                if (s.includes('page=') !== false) {
                    const scrollTo = s.replace('page=', '')
                    setPage(parseInt(scrollTo))
                    return false;
                }
            });
        }
    }, [search])
    const styles = StyleSheet.create({
        page: {
            // flexDirection: 'row',
            // backgroundColor: '#E4E4E4'
        },
        section: {
            margin: 10,
            padding: 10,
            // height: 'fit-content'
        }
    });
    const convertPositive = (value : any):number => {
        if(Number(value) < 0){
            return Number(value) * -1;
        }
        return Number(value)
    }
    useDocumentTitle('BRAND ANALYTICS')
    // const a = []
    return (
        <div>
            <div className="max-w-[1445px] overflow-hidden">
                <ToastContainer />
                {/* wrapper */}
                {
                    isLoading ? <Loader /> :
                        <div className="flex" >
                            {
                                showPdfModal ?
                                <PdfModal overallviews={overallViews} challenges={allList} loaded={loaded} startDate={formatDate(previous, 3)} endDate ={formatDate(endDate, 3)} close={()=>setShowPdfModal(false)} report1={report1} report2={report2} report3={report3} report4={report4} report5={report5} />
                                : ''
                            }
                            {
                                finalLoading ?
                                <div className="min-h-[100px] mt-[20vh] mx-auto flex justify-center items-center bg-blue text-center shadow w-[95%] rounded shadow max-w-[200px]">
                                    <ButtonLoader />
                                    <p className="text-center text-white">Please wait</p>
                                </div>
                                : 
                            <div className='container mx-auto font-primary min-h-[95vh] flex-inital lg:px-[15px] px-[5px] py-[25px] w-full'>
                                <div className={`${isLoadingPdf ? 'w-[1058px]' : 'w-full'}`} id='pageToDownload' >
                                    <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center">
                                        <div className="calendar relative">
                                            <div className="rounded-[10px] items-center shadow bg-white p-4 flex">
                                                <div>
                                                    <img src={calendar} className="object-contain" alt="" />
                                                </div>
                                                <div className='flex-1 px-4'>
                                                    {isNextToInitialDataLoading ? <p className='text-gray2 mt-1 text-[13px]'>Some data is loading on background ...</p> : <span></span>}
                                                    <h3 className="text-[18px] text-blue font-bold">Change Period</h3>
                                                    {
                                                        isDateChanged ? 
                                                        <p className='text-gray2 text-[13px]'>{formatDate(previous, 3)} - {formatDate(endDate, 3)}</p>
                                                        :
                                                        <p className='text-gray2 mt-1 text-[13px]'>Last 30days</p>
                                                    }
                                                </div>
                                                <div>
                                                    <AiOutlineCaretDown className='text-blue' style={{ cursor: 'pointer' }} onClick={() => setIsCalenderOpen(!isCalenderOpen)} />
                                                </div>
                                            </div>
                                            <div className="absolute left-0 bottom-0">
                                                <DateRangePicker
                                                    open={isCalenderOpen}
                                                    format="yyyy-MM-dd"
                                                    defaultCalendarValue={[new Date(previous), new Date(endDate)]}
                                                    onChange={handleRangeChanged}
                                                    editable={false}
                                                    // defaultOpen={true}
                                                    ranges={[]}
                                                    onOk={() => setIsCalenderOpen(false)}
                                                // cancelable={false}
                                                />
                                            </div>
                                        </div>
                                        <div className="calendar mt-3 lg:mt-0">
                                            <Button text='Export' onclick={handleDownload} containerClass="bg-blue rounded-[10px] font-bold font-primary text-yellow h-[45px] mb-3 w-[150px] mx-auto flex items-center justify-center px-[10px] text-[14px]" img={exporticon} />
                                        </div>
                                    </div>

                                    {/* analytics, graph cards */}
                                    <div className="flex flex-col gap-3 lg:flex-row mt-4">
                                        <div className='flex-1' id='report1' >
                                            <div className='flex gap-4 flex-col lg:flex-row'>
                                                <div className={`w-full font-primary bg-white p-3  rounded-[10px] flex items-end justify-between ${isLoadingPdf ? 'border-1' : 'shadow'}`}>
                                                    <div className='flex flex-col justify-between' >
                                                        <h3 className='text-blue text-[32px] font-primarybold font-bold'>{totalChallengeCreated}</h3>
                                                        <div className='my-2'>
                                                            <p className='text-blue opacity-90 text-[14px]'>Campaign Created</p>
                                                        </div>
                                                        <div className='flex items-center'>
                                                            {
                                                                totalChallengeCreated > totalPreviousChallenge ? <AiOutlineCaretUp className='text-blue' /> : <AiOutlineCaretDown className='text-blue' />
                                                            }
                                                            <p className='text-[11px] m-0'> <strong className={`text-[13px] ${totalChallengeCreated > totalPreviousChallenge ? 'text-green-700' : 'text-red-700'}`}>
                                                                {findPercentageDiff((totalChallengeCreated > totalPreviousChallenge ? totalChallengeCreated : totalPreviousChallenge), (totalChallengeCreated > totalPreviousChallenge ? totalPreviousChallenge : totalChallengeCreated), 0)}% </strong> {totalChallengeCreated > totalPreviousChallenge ? 'More than ' : 'Less than '}
                                                                previous period</p>
                                                        </div>
                                                    </div>
                                                    <div className='relative'>
                                                        {/* <img src={circlingpregress} className="h-[85px] object-contain" alt="" /> */}
                                                        <h3 className='absolute top-[31px] left-[25px] text-blue text-[13px] font-primarybold font-bold'>{findPercentage(totalChallengeCreated, challenges.length, 0)}%</h3>

                                                        <CChartDoughnut
                                                            className="mx-auto"
                                                            style={{ height: '83px', width: '83px' }}
                                                            options={chartoptions}
                                                            data={{
                                                                labels: ['New', 'All',],
                                                                datasets: [
                                                                    {
                                                                        backgroundColor: ['#F6E701', '#F0F0F4'],
                                                                        data: [Number(findPercentage(totalChallengeCreated, challenges.length, 0)), 100 - Number(findPercentage(totalChallengeCreated, challenges.length, 0))],
                                                                    },
                                                                ],
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className={`w-full font-primary bg-white p-3 rounded-[10px] flex items-end justify-between ${isLoadingPdf ? 'border-1' : 'shadow'}`}>
                                                    <div className='flex flex-col justify-between'>
                                                        <h3 className='text-blue text-[32px] font-primarybold font-bold'>{reached}</h3>
                                                        <div className='my-2'>
                                                            <p className='text-blue opacity-90 text-[14px]'>Users Reached</p>
                                                        </div>
                                                        <div className='flex items-center'>
                                                            {
                                                                reached > reachedPrev ? <AiOutlineCaretUp className='text-blue' /> : <AiOutlineCaretDown className='text-blue' />
                                                            }

                                                            <p className='text-[11px] m-0'> <strong className='text-[13px] text-green-700'>
                                                                {findPercentageDiff((reached > reachedPrev ? reached : reachedPrev), (reached > reachedPrev ? reachedPrev : reached), 0)}% </strong> {reached > reachedPrev ? 'More than ' : 'Less than '}
                                                                previous period</p>
                                                        </div>
                                                    </div>
                                                    <div className='h-[85px]'>
                                                        {/* <img src={lineGraph} className="h-[85px] object-contain" alt="" /> */}
                                                        <CChartLine
                                                            className="mx-auto"
                                                            style={{ height: '85px', width: '85px' }}
                                                            data={{
                                                                labels: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
                                                                datasets: [
                                                                    {
                                                                        backgroundColor: 'transparent',
                                                                        borderColor: '#321fdb',
                                                                        borderWidth: 2,
                                                                        data: reachedArray,
                                                                    },
                                                                ],
                                                            }}
                                                            options={chartoptions}

                                                        />
                                                    </div>
                                                </div>

                                                <div className={`w-full font-primary bg-white p-3 rounded-[10px] flex items-end justify-between ${isLoadingPdf ? 'border-1' : 'shadow'}`}>
                                                    <div className='flex flex-col justify-between'>
                                                        <h3 className='text-blue text-[32px] font-primarybold font-bold'>{findPercentage(engagementcount, engagementcountLife, 0)}%</h3>
                                                        <div className='my-2'>
                                                            <p className='text-blue opacity-90 text-[14px]'>Engagement</p>
                                                        </div>
                                                        <div className='flex items-center'>
                                                            {
                                                                engagementcount > engagementcountprev ? <AiOutlineCaretUp className='text-blue' /> : <AiOutlineCaretDown className='text-blue' />
                                                            }
                                                            <p className='text-[11px] m-0'> <strong className='text-[13px] text-green-700'>
                                                                {findPercentageDiff((engagementcount > engagementcountprev ? engagementcount : engagementcountprev), (engagementcount > engagementcountprev ? engagementcountprev : engagementcount), 0)}% </strong> {engagementcount > engagementcountprev ? 'More than ' : 'Less than '}
                                                                previous period</p>
                                                        </div>
                                                    </div>

                                                    <div className='h-[85px]'>
                                                        {/* <img src={lineGraph} className="h-[85px] object-contain" alt="" /> */}
                                                        <CChartBar
                                                            className="mx-auto"
                                                            style={{ height: '85px', width: '85px' }}
                                                            title="&1 psti"
                                                            data={{
                                                                labels: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
                                                                datasets: [
                                                                    {
                                                                        backgroundColor: '#321fdb',
                                                                        borderColor: 'transparent',
                                                                        borderWidth: 1,
                                                                        data: engagements,
                                                                    },
                                                                ],
                                                            }}
                                                            options={chartoptions}
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div>
                                            <div className="p-3 w-full lg:w-[300px]  bg-white shadow-xs rounded">
                                                <div className="py-3 flex justify-between">
                                                    <h3 className='text-gray2 text-bold'>Active Challenges</h3>
                                                    <Link to={'/brand/dashboard'} >
                                                        <AiOutlineCaretDown className='text-blue' />
                                                    </Link>
                                                </div>
                                                <Hr />
                                                <table className="border-collapse table-auto w-full text-sm ">
                                                    <tbody className='p-2'>
                                                        {
                                                            (challenges.slice(0, 3)).map((item: any, i) => (
                                                                <tr key={i} className='p-1 text-blue border-spacing-2' style={{ height: '25px' }}>
                                                                    <td className='pr-2 text-[14px]'>
                                                                        <Link className='text-primary font-primary' to={`/brand/challenge/result/${item._id}`} >
                                                                            {item.title}
                                                                        </Link>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }

                                                    </tbody>
                                                </table>
                                                {
                                                    challenges.length === 0 ? "You have not created any Campaign yet." : ""
                                                }
                                            </div>
                                        </div>
                                    </div>



                                    {/* engaement comparism */}
                                    <div className="mt-[30px] ">
                                        <div className="flex flex-col lg:flex-row gap-4">
                                            <div className={`flex-initial lg:w-[60%] bg-white rounded p-[20px] ${isLoadingPdf ? 'border-1' : 'shadow'}`} id='report2'>
                                                <div className="mb-3">
                                                    <div className="flex items-start lg:items-center gap-5 flex-row justify-between">
                                                        <div className='w-full flex lg:flex-row flex-col justify-between'>
                                                            <div className='w-full flex justify-between items-center lg:w-initial'>
                                                                <h3 className='text-blue font-bold'>Engagement Comparison</h3>
                                                                <div className='flex lg:hidden gap-2 items-center'>
                                                                    <h6 className="text-blue font-bold text-[12px]">Week</h6>
                                                                    <AiOutlineCaretDown className='text-blue' />
                                                                </div>
                                                            </div>
                                                            <ul className='flex gap-2'>
                                                                {
                                                                    chartlegends.map((legend, i) => (
                                                                        <li key={i} className='flex items-center gap-2'>
                                                                            <BsCircleFill className={`text-[14px] ${legend.className}`}></BsCircleFill>
                                                                            <span className='text-gray2 text-[12px]'>{legend.title}</span>
                                                                        </li>
                                                                    ))
                                                                }

                                                            </ul>
                                                        </div>
                                                        <div className='hidden lg:flex gap-2 items-center'>
                                                            <h6 className="text-blue font-bold text-[12px]">Week</h6>
                                                            <AiOutlineCaretDown className='text-blue' />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mt-3">
                                                    <CChart
                                                        options={{
                                                            plugins: {
                                                                legend: {
                                                                    display: false,
                                                                },
                                                            }
                                                        }}
                                                        type="bar"
                                                        data={{
                                                            labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                                                            datasets: [
                                                                {
                                                                    label: 'Impression',
                                                                    backgroundColor: '#2712A9',
                                                                    data: impressionArr,
                                                                    borderRadius: 20,
                                                                    barThickness: 10,
                                                                    offset: 0,
                                                                },
                                                                {
                                                                    label: 'Reached',
                                                                    backgroundColor: '#F6E701',
                                                                    data: reachedArray,
                                                                    borderRadius: 20,
                                                                    barThickness: 10,
                                                                    offset: 0,
                                                                },
                                                                {
                                                                    label: 'Clicks',
                                                                    backgroundColor: '#0B01491F',
                                                                    data: clicksArray,
                                                                    borderRadius: 20,
                                                                    barThickness: 10,
                                                                    offset: 0,
                                                                },
                                                                {
                                                                    label: 'Shares',
                                                                    backgroundColor: '#FE718E',
                                                                    data: sharesArray,
                                                                    borderRadius: 20,
                                                                    barThickness: 10,
                                                                    offset: 0,
                                                                },
                                                            ],
                                                        }}
                                                    // labels="months"
                                                    />
                                                </div>
                                            </div>

                                            <div className={`flex-initial lg:w-[40%] bg-white rounded p-[20px] ${isLoadingPdf ? 'border-1' : 'shadow'}`} id='report3'>
                                                <h3 className="text-blue font-bold">Social Media Shares</h3>
                                                <div className="flex justify-center flex-col items-center my-[15px]">
                                                    <CChartDoughnut
                                                        className="mx-auto"
                                                        style={{ height: '200px', width: '200px' }}
                                                        options={chartoptions}
                                                        data={{
                                                            labels: ['Facebook', 'Instagram', 'Whatsapp'],
                                                            datasets: [
                                                                {
                                                                    backgroundColor: ['#FE718E', '#F6E701', '#2712A9'],
                                                                    data: socialShares,
                                                                },
                                                            ],
                                                        }}
                                                    />
                                                    <div className="w-[200px]">
                                                        <div className="flex mt-4 justify-between">
                                                            <div>
                                                                <li className='flex items-center mt-1 gap-2'>
                                                                    <BsCircleFill className={`text-[14px] text-[#FE718E]`}></BsCircleFill>
                                                                    <span className='text-gray2 text-[14px]'>Facebook</span>
                                                                </li>
                                                                <li className='flex items-center mt-1 gap-2'>
                                                                    <BsCircleFill className={`text-[14px] text-[#F6E701]`}></BsCircleFill>
                                                                    <span className='text-gray2 text-[14px]'>Instagram</span>
                                                                </li>
                                                                <li className='flex items-center mt-1 gap-2'>
                                                                    <BsCircleFill className={`text-[14px] text-[#2712A9]`}></BsCircleFill>
                                                                    <span className='text-gray2 text-[14px]'>Tiktok</span>
                                                                </li>
                                                            </div>
                                                            <div>
                                                                <div className='text-blue font-bold mt-1 text-[14px] items-center gap-2'>{socialShares[0]}</div>
                                                                <div className='text-blue font-bold mt-1 text-[14px] items-center gap-2'>{socialShares[1]}</div>
                                                                <div className='text-blue font-bold mt-1 text-[14px] items-center gap-2'>{socialShares[2]}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-[30px] flex gap-4 lg:flex-row flex-col">
                                        <div className={`p-[20px] flex-initial lg:w-[50%] bg-white rounded ${isLoadingPdf ? 'border-1' : 'shadow'}`} id='report4'>
                                            <div className="mt-3 flex justify-between">
                                                <h3 className='text-blue font-bold'>User Reached</h3>
                                                <span className='text-gray2 font-bold text-[14px]'>Up to <span className='text-green-500'>24hrs</span></span>
                                            </div>
                                            <CChart
                                                options={{
                                                    plugins: {
                                                        legend: {
                                                            display: false,
                                                        },
                                                    }
                                                }}
                                                type="bar"
                                                data={{
                                                    labels: ['12am-2am', '3am-5am', '6am-8am', '9am-11am', '12pm-2pm', '3pm-5pm', '6pm-8pm', '9pm-11pm'],
                                                    datasets: [
                                                        {
                                                            label: 'Impression',
                                                            backgroundColor: '#2712A9',
                                                            data: reachedByHour,
                                                            borderRadius: 5,
                                                            barThickness: 30,
                                                        },
                                                    ],
                                                }}
                                            // labels="months"
                                            />
                                        </div>
                                        <div className="lg:w-[50%] flex-initial" id='report5'>
                                            <div className="flex flex-col h-[100%] lg:flex-row gap-4">
                                                <div className={`bg-white flex-initial lg:w-[50%] p-[20px] rounded ${isLoadingPdf ? 'border-1' : 'shadow'}`}>
                                                    <h3 className='text-blue font-bold'>Campagin Types</h3>

                                                    <div className="mt-[50px] flex justify-center gap-4">
                                                        {
                                                            challengeTypes.map((item, i) => (
                                                                <div className={`flex flex-col items-center justify-center rounded-[100%] ${i === 0 ? 'h-[85px] bg-yellow w-[85px]' : 'bg-blue text-white h-[120px] w-[120px]'}`}>
                                                                    <div>{item.title}</div>
                                                                    <div>
                                                                        {item.count}
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                                <div className={`bg-white flex-initial lg:w-[50%] p-[20px] rounded ${isLoadingPdf ? 'border-1' : 'shadow'}`}>
                                                    <div className="flex items-center justify-between">
                                                        <h3 className='text-blue font-bold'>Top Participants</h3>
                                                        {/* <Link className='text-gray2 text-sm' to={'#?'} >View all</Link> */}
                                                    </div>

                                                    <div className="mt-4 flex flex-col gap-4">
                                                        {
                                                            (allUniques.slice(0, 5)).map((item: any, i) => (
                                                                <div key={i} className="flex">
                                                                    <img className='h-[30px] object-cover w-[30px] rounded-[100%]' src={item[1].userDetails ? item[1].userDetails?.avatar : who} alt="" />
                                                                    <div className="flex items-center font-bold font-primary">
                                                                        <div className='pl-2'>
                                                                            <div className="text-[14px]">{item[1].userDetails ? item[1].userDetails?.fullName : '-'}</div>
                                                                            <div className="text-[12px] text-gray2">{ item[1]?.impressions ? "User Traction Generated:": ""} {item[1]?.impressions ?? "NA"}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                        {
                                                            allUniques.length === 0 ? <p>Records are on their way ...  </p> : ''
                                                        }
                                                    </div>
                                                </div>



                                            </div>
                                        </div>
                                    </div>



                                    <div className="py-5 px-3 mt-[30px] rounded shadow bg-white " style={{ overflow: 'scroll' }}>
                                        <div className="search flex justify-between items-center mb-4">
                                            <h3 className="font-primaary font-bold text-blue">All Campaign</h3>
                                        </div>

                                        <div className="shadow-[10px] rounded-[10px] w-[800px] md:w-full lg:w-full border-graylight border-2" >
                                            <table className="border-collapse text-sm table-auto w-[800px] md:w-full lg:w-full">
                                                <thead className='text-blue p-2 h-[45px] border-b-graylight border-b-2'>
                                                    <tr>
                                                        <th></th>
                                                        <th>Title</th>
                                                        <th className='text-left'>Start</th>
                                                        <th className='text-left'>End</th>
                                                        <th className='text-left'>Engagement</th>
                                                        <th className='text-left'>Participants</th>
                                                        <th className="text-left"><BiDotsVertical /></th>
                                                    </tr>
                                                </thead>
                                                <tbody className='p-2'>
                                                    {
                                                        (allList.slice(page == 1 ? 0 : (page - 1) * 10, page == 1 ? 10 : ((page - 1) * 10) + 10)).map((item : any, i) => (
                                                            <tr key={i} className='p-2 text-[#0B014980] border-spacing-2' style={{ height: '70px' }}>
                                                                <td className='w-[100px] text-center pr-2'>
                                                                    <div className=" flex items-center justify-end">
                                                                        {
                                                                            selected.includes(item) ?
                                                                                <img onClick={() => addSelected(item)} style={{ cursor: 'pointer' }} src={checkBoxChecked} alt="" />
                                                                                :
                                                                                <img onClick={() => addSelected(item)} style={{ cursor: 'pointer' }} src={checkBox} alt="" />
                                                                        }
                                                                        <div className="mx-2"></div>
                                                                        {/* <div className="mx-2"></div>
                                                                    <img className='h-[40px]  w-[40px] rounded-[100%] object-cover' src={who} alt="" />
                                                                    */}
                                                                    </div>
                                                                </td>
                                                                <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''}`}>
                                                                    <div className="flex items-center font-bold font-primary">
                                                                        <div className='pl-2'>
                                                                            {item.title}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''}`}>{item.openingDate ? formatDate(item.openingDate, 2, true) : ''}</td>
                                                                <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''}`}>{item.closingDate ? formatDate(item.closingDate, 2, true) : ''}</td>
                                                                <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''}`}>
                                                                    <div className="flex gap-2">
                                                                        <span className='flex items-center'> <AiOutlineEye /> &nbsp; {findPercentage(convertPositive(item.noOfViews), overallViews, 0)}% </span>
                                                                        <span className='flex items-center'> <AiOutlineCheckSquare /> &nbsp; 23% </span>
                                                                    </div>
                                                                </td>
                                                                <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''}`}>{(item.participants).length}</td>
                                                                <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''}`}><BiDotsVertical /></td>
                                                            </tr>
                                                        ))
                                                    }

                                                </tbody>

                                                {
                                                    allList.length === 0 ?
                                                        <p>No record found</p>
                                                        :
                                                        ''
                                                }
                                            </table>
                                        </div>
                                    </div>
                                    <Pagination limit={10} length={allList.length} page={page} />
                                </div>
                            </div>
                            }

                        </div>
                }

            </div>
        </div>
    )
}

export default Analytics