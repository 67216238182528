import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useDocumentTitle from '../../helpers/pageTitle';
import { useTypedSelector } from '../../hooks/useSelector';
import { ActionType } from '../../store/actions';
import instance from '../../helpers/axios';
import { toast, ToastContainer } from 'react-toastify';
import ScrollToTop from '../../helpers/scrollToTop';
import Loader from '../../helpers/loader';
import Button from '../../components/button';
import { checkBox, checkBoxChecked, exporticon, who } from '../../components/images';
import { graph1, graph2, trendup } from '../../components/super_images';
import { CChart } from '@coreui/react-chartjs';
import { BsCaretDownSquare, BsCircleFill } from 'react-icons/bs';
import { BiCaretDown, BiDotsVertical } from 'react-icons/bi';
import { AiOutlineCheckSquare, AiOutlineEye } from 'react-icons/ai';
import { formatCreatedAtDate, formatDate } from '../../helpers/helpers';
import { CoreDropdown } from '../../components';
import { resultSort } from '../../data';
import { FiSearch } from 'react-icons/fi';
import AppLink from '../../components/link';
import Pagination from '../../components/pagination';
// import { ShareModal } from '../../components/brand';

const AdminFaqs: React.FC = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { search } = useLocation()
    const [user, setUser] = useState('')
    const [selected, setSelected] = useState<number[]>([])
    const { acctSideBarActive } = useTypedSelector((state) => state.appReducer);
    const [isLoading, setIsLoading] = useState(false)
    const [c, setC] = useState([])
    const [search_, setSearch_] = useState("");
    const [allList, setAllList] = useState<any[]>([])
    const { loggedInAdmin } = useTypedSelector((state) => state.appReducer);
    const [dropdown_, setDropDown] = useState("")
    const [allRecords, setAllRecords] = useState(0)
    useEffect(() => {
        dispatch({
            type: ActionType.UPDATE_LAYOUT,
            payload: "admin"
        })

    }, [])
    const chartlegends = [
        { title: "Running", className: "text-[#0B0149]" },
        { title: "Not Running", className: "text-[#0B01491F]" },
    ]
    useEffect(() => {
        if (!loggedInAdmin || loggedInAdmin == "") {
            // nobody is logged in
            navigate("/admin/login");
        }
    }, [loggedInAdmin])
    useEffect(() => {
        const headers = {
            'Authorization': `Bearer ${loggedInAdmin}`
        }
        instance({
            method: "GET",
            url: `/knowledge-base/all?_orderBy=createdAt&_order=desc`,
            headers: headers
        }).then((response) => {
            console.log("response", response)
            var docs = response.data.data.docs;
            setAllList(docs);
            setC(docs);
            setAllRecords(response.data.data.totalDocs)
        }).catch((err) => {

        }).finally(() => {
            setIsLoading(false);
        })
    }, [])
    
    useEffect(() => {
        const search_ = (search.replace('?', '')).split("&")
        if(search_.length > 0){
          search_.forEach(s => {
            if(s.includes('page=') !== false){
              const scrollTo = s.replace('page=','')
                setPage(parseInt(scrollTo))
              return false;
            }
          });
        }
    }, [search])

    const addSelected = (value: number) => {
        var selected_ = selected;
        if (selected_.includes(value)) {
            selected_.splice(selected_.indexOf(value), 1)
        } else {
            selected_.push(value)
        }
        setSelected(selected_);
        setTimeout(() => {
            setAllList(c)
        }, 500);
    }
    const [sort, setSort] = useState("All")
    const [page, setPage] = useState(1)

    // dropdownchanged
    function dropChanged (value : any){
        setPage(1)
        setSort(value)
    }
    // search
    const searchChanged = (value : string) => {
        if(value === "" || value === undefined || value === null) {
            setSearch_("")
            return setAllList(c)
        };
    //   console.log("value", value)
      setSearch_(value)
      var filtered:any[] = [];
      allList.map((list : any) => {
            var fname:string = list.userName;
            if((fname.toLowerCase()).includes(value.toLowerCase())) filtered = [...filtered, list];
      })

      setAllList(filtered)
    
    }
    useDocumentTitle('ADMIN - FAQs')
    // const a = []
    return (
        <div>
            <div className="max-w-[1445px] font-primary">
                <ToastContainer />
                {/* wrapper */}
                {
                    isLoading ? <Loader /> :
                        <div className='container font-primary min-h-[95vh] flex-inital lg:px-[15px] px-[5px] py-[25px] w-full'>
                            <div className="search flex justify-between flex-col lg:flex-row items-center mb-4">
                                <h3 className="font-primaary font-bold text-blue">FAQs</h3>
                                <AppLink href='/admin/faqs/add'  text='Add' containerClass='bg-blue text-yellow w-[200px]' />
                            </div>
                            {/* Top voted users */}
                            <div className="py-[15px] px-3 rounded shadow bg-white " style={{overflow : 'scroll'}}>
                                    

                                    <div className="shadow-[10px] rounded-[10px] w-[800px] md:w-full lg:w-full border-graylight border-2" >
                                     <table className="border-collapse text-sm table-auto w-[800px] md:w-full lg:w-full">
                                            <thead className='text-blue p-2 h-[45px] border-b-graylight border-b-2'>
                                                <tr>
                                                    <th></th>
                                                    <th>Subject</th>
                                                    <th className='text-left'>Description</th>
                                                    <th className='text-left'>Status</th>
                                                    <th className="text-left"><BiDotsVertical /></th>
                                                </tr>
                                            </thead>
                                            <tbody className='p-2'>
                                                {
                                                    allList.map((item : any, i) => (
                                                        <tr key={i} onClick={()=>{
                                                            if(dropdown_ !== "") setDropDown("")
                                                        }} className='p-2 text-[#0B014980] border-spacing-2' style={{ minHeight: '70px',height:'70px' }}>
                                                            <td className='w-[120px] text-center pr-2'>
                                                               {i+1}
                                                            </td>
                                                            <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''} `}>{item.subject}</td>
                                                            <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''} `}>{item.detail}</td>
                                                            <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''} `}>{item.status}</td>
                                                            <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''} w-[50px]`}>
                                                                <div className='relative'>
                                                                {
                                                                    dropdown_ == item ?
                                                                        <div className="rounded-[10px] z-[4444] absolute min-w-[150px] top-[-20px] right-[10px] text-blue font-primary bg-white p-3 shadow-blue shadow-md">
                                                                            <div className='my-2'>
                                                                                <a href="#?" className='my-'>Edit</a>
                                                                            </div>
                                                                            <div className='my-2'>
                                                                                <a href="#?" className='my-2'>Publish</a>
                                                                            </div>
                                                                            <div className='my-2'>
                                                                                <a href="#?" className='my-2'>Delete</a>
                                                                            </div>
                                                                        </div>
                                                                        : ''
                                                                }
                                                                    <BiDotsVertical onClick={()=>{
                                                                        setDropDown(item)
                                                                    }} />

                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }

                                            </tbody>

                                            {
                                                allList.length === 0 ?
                                                    <p>No record found</p>
                                                    :
                                                    ''
                                            }
                                        </table>
                                    </div>
                                </div>
                                <Pagination limit={10} length={allRecords} page={page} />
                        </div>
                }

            </div>
        </div>
    )
}

export default AdminFaqs