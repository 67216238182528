import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useDocumentTitle from '../../helpers/pageTitle';
import { ActionType } from '../../store/actions';
const BrandLogout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    sessionStorage.removeItem('otp')
    try {
    dispatch({
      type : ActionType.LOGOUT_BRAND,
    })
    } catch (error) {
      
    }
    navigate("/brand/login")
  }, [])
  
  
  useDocumentTitle('BRAND LOGOUT ')
  return (
    <div className='h-[100vh] relative'>
    </div>
  )
}

export default BrandLogout