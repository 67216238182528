import React from 'react'
import {socialMedias} from '../social_medias'

function Socials() {
  return (
    <div className="flex justify-evenly lg:justify-start gap-3 p-2 lg:mt-0">
      {
        socialMedias.map((s, i) => (
          <a key={i} href={s.link} target='blank'>
              <img src={s.icon} className='object-contain h-[45px]' alt={s.name} />
          </a>
        ))
      }
  
  </div>
  )
}

export default Socials