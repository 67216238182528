import React, {useState, useEffect, useRef} from 'react'
import { AiOutlineShareAlt } from 'react-icons/ai'
import { BiShare } from 'react-icons/bi'
import { toast, ToastContainer } from 'react-toastify';
import instance from '../../helpers/axios';
import ButtonLoader from '../../helpers/button_loader';
import { useTypedSelector } from '../../hooks/useSelector';
import { facebookSVG, instagramSVG, linkedinSVG, telegramSVG, twitterSVG, whatsappSVG } from '../images'

interface iSocialMedia {
    id : number;
    title : string;
    image : any;
    code  : string;
}
interface iShareModal {
    id : string;
    title? : string;
    type : string;
    close : any;
}

function ShareModal(data : iShareModal) {
    const { loggedInBrand } = useTypedSelector((state) => state.appReducer);
    const [clicked, setClicked] = useState("")
    const ref = React.useRef<HTMLInputElement>(null);
    const [copied, setCopied] = useState(true)
    
    const [toCopy, SettoCopy] = useState('')
    const socialMedias:iSocialMedia[] = [
        {id : 1, title : "LinkedIn" , image : linkedinSVG, code : 'link'},
        {id : 2, title : "Whatsapp" , image : whatsappSVG, code : 'what'},
        {id : 3, title : "Facebook" , image : facebookSVG, code : 'fb'},
        {id : 4, title : "Twitter" , image : twitterSVG, code : 'twi'},
        {id : 5, title : "Instagram" , image : instagramSVG, code : 'inst'},
    ]
    useEffect(() => {
      if(toCopy  === "") return;
      else{
        // alert(toCopy)
        // useClipboard(toCopy);
        if(ref.current){
            ref.current?.select();
            try {
                // copy text
                document.execCommand('copy');
                ref.current?.blur();
                SettoCopy("")
                // setCopied()
            } catch (err) {
                //fallback in case exexCommand doesnt work
                alert('please press Ctrl/Cmd+C to copy');
            } 
        }
      }
    }, [toCopy])
    
    const copyLink = (code : string, title : string)=> {
        var siteurl = window.location.href.includes("localhost") ? 'https://beentos.herokuapp.com' : 'https://api.beentos.com'

        if(clicked !== "") return false;
        setClicked(code)
        const headers = {
            'Authorization': `Bearer ${loggedInBrand}`
          }
      instance({
        method : "GET",
        url : `challenge-post/share/${data.id}?platform=${code}`,
        headers : headers
      }).then((response)=>{
        var buildUrl = `${siteurl}/${response.data.data}`
        SettoCopy(buildUrl)
        toast.success("Link copied to clipboard "+buildUrl)
        setClicked("")
      }).catch((err)=>{
        if (err.code == "ERR_NETWORK") {
            alert("No internet connection")
        }else{
            toast.error("Something went wrong")
        }
        setClicked("")
        console.log(err)
      })
    }

    function copyToClipboard() {
        // alert('this function was triggered');
        // find target element
        // make the input visible
        // select the input .select()

        // inp.select();
        // // check if input element exist and if it's selectable
        // if (inp && inp.select) {
        //     // select text
        //     inp.select();
        //     try {
        //         // copy text
        //         document.execCommand('copy');
        //         inp.blur();
        //     } catch (err) {
        //         //fallback in case exexCommand doesnt work
        //         alert('please press Ctrl/Cmd+C to copy');
        //     }

        }
    return (
        <div>
            <ToastContainer />
            <div className='fixed top-0 left-0 w-full z-[999] h-[100vh]'>
                <div className="max-h-[80vh] min-h-[300px] w-full flex justify-center">
                    <div onClick={()=>data.close()} className='back_drop backdrop-blur fixed top-0 left-0 w-full bg-blue/30 h-[100vh] bg-blue'></div>
                    <div className='bg-white shadow-lg rounded-[15px] w-[95%] lg:w-[860px] p-3 relative'>
                        <div className="mt-[10px] mb-[15px] px-3 font-primary ">
                            <div className="" id="">
                                {
                                    data.title ? 
                                    <h3 className="text-blue text-center text-uppercase text-[22px] font-bold mb-3">{data.title}</h3>
                                    :
                                    ''
                                }

                                <div className="text-center">
                                    {/* <i data-feather="share-2" width="50" height="50" className="webinar-icon"></i> */}
                                    <div className="flex justify-center">
                                        <AiOutlineShareAlt className='h-[70px] w-[70px]' />
                                    </div>

                                    <div className="flex justify-center my-[20px]">
                                        <p className="text-[18]">Share {data.type === "challenge" ? "Campaign" : "Post"} with your friends</p>
                                    </div>

                                    <div className="mt-2 mt-lg-40 grid gap-y-4 justify-center grid-cols-2 lg:grid-cols-5 items-center">
                                        {
                                            socialMedias.map((social, i)=> (
                                            <a key={i} href="#?" onClick={()=>copyLink(social.code, social.title)} className="flex flex-col justify-center items-center relative text-center">
                                                <img src={social.image} width="50" height="50" className={`${clicked === "" ? '' : 'opacity-50'}`} alt="telegram" />
                                                {
                                                clicked === social.code ?
                                                <div className='w-[100%] flex justify-center h-[40px] absolute top-[5px] left-0'>
                                                    <ButtonLoader />
                                                </div>
                                                : ''
                                                }
                                                <span className="mt-4 d-block">{social.title}</span>
                                            </a>
                                            ))
                                        }
                                    </div>
                                </div>
                                {
                                    toCopy === "" ? '' :
                                    <input ref={ref} onChange={()=>{}} type="text" value={toCopy}  />
                                }

                                <div className="mt-5 flex items-center justify-content-end">
                                    <button type="button" onClick={()=>data.close()} className="btn bg-red-700 text-white ml-10 close-swl">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}


export default ShareModal;