import React from 'react'
import {iInput} from '../models/model'
import DatalistInput from 'react-datalist-input';
import "../helpers/dropdown_css.css"
import { BsChevronDown } from 'react-icons/bs';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

const InputB = (data : iInput) => {
  const { placeholder, items, setValue, toggleEye = null, value, containerClass, className, type="text", isDropDown, passwordType="", showError = false, err = "", prevErr, hasPt =true, posErr = "", pos="", isText = false} = data;
  return (
    <div>
      <div className='mb-[2px] my-[15px]'>
        <label htmlFor="" className='text-blue opacity-90'>{placeholder}</label>
      </div>
      {
      isDropDown ? 
      <div className={`relative ${prevErr === true ? (hasPt ? 'pt-[30px]' : 'pt-[10px]') : 'pt-[5px]'} `}>
        <DatalistInput
            value={value}
            placeholder=""
            label="Select ice cream flavor"
            showLabel={false}
            className={`control bg-white shadow border-white pl-2 h-[50px] rounded-[45px] `}
            inputProps={{
              className:`bg-transparent font-primary font-semibold dark:text-white  text-blue  border-0 outline-0 bg-none w-full px-2 h-[50px] is-dirty peer ${className}`,
              readOnly: false,
            }}
            onSelect={(item : {id : string, value : string}) => setValue(item.value)}
            items={items}
          />
        <BsChevronDown style={{cursor : 'pointer'}} className={`absolute right-[15px] ${showError ? posErr : pos}`} />
          {
          showError ? <div className='px-2 w-full flex justify-start'>
              <p className="text-red-700"> <small>{err}</small> </p>
            </div>
            : ''
        }
        </div>
      :
    <div className={`control relative w-full outline-0 border-0 bg-white shadow pl-2 h-[50px] rounded-[45px] ${containerClass}`}>
        <input type={type} value={value} onChange={(e)=>setValue(e.target.value)} className={`bg-transparent font-primary font-semibold dark:text-white  text-blue placeholder:font-medium  border-0 outline-0 bg-none w-full px-2 h-[50px] is-dirty peer ${className}`} />
        {
          type === "password" ?
          <AiOutlineEyeInvisible onClick={()=>toggleEye(passwordType)} style={{cursor : 'pointer'}} className='absolute text-[#7E799F] top-[35%] right-[15px]' />
          : ''
        }
        {
          isText ? 
          <AiOutlineEye onClick={()=>toggleEye(passwordType)} style={{cursor : 'pointer'}} className='absolute text-[#7E799F] top-[35%] right-[15px]' />
          : ''
        }
        {
          showError ? <div className='w-full flex justify-start '>
                <p className="text-red-700"> <small>{err}</small> </p>
            </div>
            : ''
        }
    </div>
    }
    </div>
  )
}

export default InputB