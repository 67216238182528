import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useDocumentTitle from '../../helpers/pageTitle';
import { useTypedSelector } from '../../hooks/useSelector';
import { ActionType } from '../../store/actions';
import { toast, ToastContainer } from 'react-toastify'
import Input from '../../components/input';
import Button from '../../components/button';
import { challengeTypes, rewardTypes, votingCategories, locations, couponTypes } from '../../data';
import { Choose } from '../../components/brand';
import { capitalizeFirstLetter, position, sharePrize } from '../../helpers/helpers';
import instance from '../../helpers/axios';
import AddProductModal from '../../components/admin/add_package_modal';
import { BiDotsVertical, BiPencil } from 'react-icons/bi';
import AppLink from '../../components/link';
import { CoreDropdown } from '../../components';
import SelectBrandModal from '../../components/admin/select_brand_modal';
import DropDown from '../../components/dropdown';
import Loader from '../../helpers/loader';

const AdminAddCoupon: React.FC = () => {
    const params = useParams()
    const { loggedInAdmin } = useTypedSelector((state) => state.appReducer);
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [title, setTitle] = useState('')
    const [challengePerDay, setChallengePerDay] = useState("")
    const [maxChallengePerDay, setMaxChallengePerDay] = useState("")
    const [rateperDay, setRateperDay] = useState("")
    const [loading, setLoading] = useState(false)
    const [feature, setFeature] = useState("")
    const [features, setFeatures] = useState<string[]>([])
    const [showAddModal, setShowAddModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [editIndex, setEditIndex] = useState(0)
    const [couponType, setCouponType] = useState("")
    const [showSelectBrandModal, setShowSelectBrandModal] = useState(false)
    const [brand, setBrand] = useState<any>(null)
    const [expiry, setExpiry] = useState("")
    const [couponTypess, setCouponTypes] = useState<any[]>([])
    const [toUpdate, setToUpdate] = useState(false)
    const [showLoadd, setShowLoadd] = useState(true)
    const headers = {
        'Authorization': `Bearer ${loggedInAdmin}`
    }
    useEffect(() => {
        console.log(params.id)
        if (params.id) {
            const headers = {
                'Authorization': `Bearer ${loggedInAdmin}`
            }
            // _searchBy[]=text&_keyword=arise
            setLoading(true)
            instance({
                method: "GET",
                url: `/coupon/all?couponCode=${params.id}`,
                headers: headers
            }).then((response) => {
                var coup = response.data.data.docs
                if (coup.length == 0) {
                    navigate("/admin/coupons")
                } else {
                    setBrand(coup[0].brand)
                    setLoading(false)
                    setToUpdate(true)
                }
            }).catch((err) => {

            }).finally(() => {
                setShowLoadd(false)
                // setIsLoading(false);
            })
        } else {
            setShowLoadd(false)
        }
    }, [])


    // useEffect(() => {
    //     if (!loggedInAdmin || loggedInAdmin === "") {
    //         // nobody is logged in
    //         navigate("/admin/login");
    //     }
    // }, [loggedInAdmin, navigate])

    useEffect(() => {
        dispatch({
            type: ActionType.UPDATE_LAYOUT,
            payload: "admin"
        })
    }, [])

    const { pathname, search } = useLocation();
    useEffect(() => {
        const headers = {
            'Authorization': `Bearer ${loggedInAdmin}`
        }

    }, []);

    useDocumentTitle('CREATE CHALLENGE ')

    const proceed = () => {
        if (loading) return false;
        // navigate("/brand/challenge/create-step-payment");
        setLoading(true)
        var passed = true;
        if (!brand) {
            setLoading(false)
            toast.error("Please select a Brand")
            return false;
        }

        if (expiry === "" || expiry == "0") {
            toast.error("Please enter coupon expiry in days")
            passed = false;
        }

        if (couponTypess.includes("Cash")) {
            if (rateperDay === "" || rateperDay == "0") {
                toast.error("Please enter coupon rate")
                passed = false;
            }
            if (Number(rateperDay) > 100) {
                toast.error("Rate cannot be more than 100%")
                passed = false;
            }
        }
        if (couponTypess.includes("Challenge")) {
            // if(challengePerDay === "" || challengePerDay == "0"){
            //     toast.error("Please enter No. of Challenge per day")
            //     passed = false;
            // }

            if (maxChallengePerDay === "" || maxChallengePerDay == "0") {
                toast.error("Please enter Max No. of Campaign per day")
                passed = false;
            }
        }



        if (!passed) {
            setLoading(false)
            toast.error("All fields required")
            return false;
        }
        var data = {
            brandId: brand.brandId,
            expiresIn: Number(expiry),
            couponType: couponTypess,
            noOfChallenges: Number(challengePerDay),
            discountRate: parseFloat(rateperDay),
            maxNoOfChallenges: Number(maxChallengePerDay),
            isValid: true,
        }

        const headers = {
            'Authorization': `Bearer ${loggedInAdmin}`
        }
        instance({
            method: "POST",
            url: `/coupon/create`,
            headers: headers,
            data: data
        }).then((response) => {
            // window.location.reload();
            if (toUpdate) {
                toast.success("Coupon updated")
            } else {
                toast.success("New Coupon added")
            }
            console.log(response)
            setRateperDay("")
            setChallengePerDay("")
            setBrand(null)
            setMaxChallengePerDay("0")
            setChallengePerDay("0")
            setExpiry("0")
            setCouponTypes([])
            setCouponType("")
            setTimeout(() => {
                navigate("/admin/coupons")
            }, 2000);

        }).catch((err) => {
            toast.error("Something went wrong")
        }).finally(() => {
            setLoading(false)
        })
    }
    const newFeature = (value: string) => {
        var f = features;
        f = [...f, value];

        setFeatures(f)
        setShowAddModal(false)
    }
    const handleChange2 = (selectedOption: any, actionMeta: any) => {

        setCouponType(selectedOption)
        var sel: any[] = []
        selectedOption.map((item: any) => {
            sel = [...sel, item.label];
        })

        setCouponTypes(sel)

    }
    // const a = []
    return (
        <div className='px-[5px] py-[50px]'>
            {
                showLoadd ?
                    <Loader /> :
                    <div className="container mx-auto">
                        <ToastContainer />

                        {
                            showSelectBrandModal ?
                                <SelectBrandModal
                                    setFeature={(item: any) => {
                                        setBrand(item)
                                        setShowSelectBrandModal(false)
                                    }}
                                    hasSub="coupon"
                                    close={() => {
                                        setShowSelectBrandModal(false)
                                    }} /> : ''
                        }


                        <AppLink href='/admin/coupons' containerClass='font-bold font-primary w-[120px] text-blue flex items-center justify-center px-[10px] text-[14px]' text='go back' />

                        <div className="py-[20px] bg-white shadow-lg rounded ">
                            <div className="text-censter font-primary">
                                <div className="mt-3 w-full">
                                    <h3 className='text-blue  text-center text-[24px] m-0 font-bold font-primary'> {toUpdate ? "Edit " : "Add "} Coupon</h3>
                                    {
                                        toUpdate ?
                                            <h3 className='text-blue  text-center text-[18px] m-0 font-bold font-primary'> Coupon : {params.id}</h3>
                                            : ""
                                    }
                                </div>

                                <div className="mt-4 w-[80%] mx-auto">

                                    <div className="my-2">
                                        <label className='font-primary text-blue' htmlFor="">Type</label>
                                        {/* <CoreDropdown source="challenge" showError={false} err={""} items={couponTypes} containerClass="control justify-between border border-blue pl-3 h-[50px] rounded-[45px] w-full mt-[7px] bg-[#F0F0F4]" placeholder="Select Coupon Type" type="text" value={couponType} setValue={setCouponType} /> */}
                                        <DropDown showError={false} err={""} isMulti={true} options={couponTypes} handleChange={handleChange2} handleInputChange={() => { return "" }} />

                                    </div>
                                    {
                                        !params.id ?
                                            <div className="my-2">
                                                <label className='font-primary text-blue' htmlFor="">Select Brand</label>
                                                <div onClick={() => setShowSelectBrandModal(true)}>
                                                    <Input value={brand ? brand.brandName : ""} type="text" setValue={setBrand} containerClass="h-[50px] bg-[#F0F0F4] border-graylight shadow-0 shadow-none border-0" placeholder='Select brand' />
                                                </div>
                                            </div> : ""
                                    }
                                    <div className="my-2">
                                        <label className='font-primary text-blue' htmlFor="">Expiry (days)</label>
                                        <Input value={expiry} type="number" setValue={setExpiry} containerClass="h-[50px] bg-[#F0F0F4] border-graylight shadow-0 shadow-none border-0" placeholder='No. of Days' />
                                    </div>
                                    {
                                        couponTypess.includes("Cash") ?
                                            <div className="my-2">
                                                <label className='font-primary text-blue' htmlFor="">Rate (%)</label>
                                                <Input value={rateperDay} type="number" setValue={setRateperDay} containerClass="h-[50px] bg-[#F0F0F4] border-graylight shadow-0 shadow-none border-0" placeholder='Enter coupon rate' />
                                            </div>
                                            : ""
                                    }
                                    {
                                        couponTypess.includes("Challenge") ?
                                            <>
                                                <div className="my-2">
                                                    <label className='font-primary text-blue' htmlFor="">Max. No of Challenge</label>
                                                    <Input value={maxChallengePerDay} type="number" setValue={setMaxChallengePerDay} containerClass="h-[50px] bg-[#F0F0F4] border-graylight shadow-0 shadow-none border-0" placeholder='Max. No. of Challenge' />
                                                </div>
                                            </>
                                            : ""
                                    }

                                    <div className="text-center">
                                        <Button loading={loading} text="Submit" onclick={() => proceed()} containerClass="w-full max-w-[250px] bg-blue text-yellow mt-[30px]" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }

        </div>
    )
}

export default AdminAddCoupon