import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AiOutlinePlusCircle } from "react-icons/ai";
import { needHelp } from '../../components/images';
import useDocumentTitle from '../../helpers/pageTitle';
import { decryptData } from '../../helpers/security';
import { useTypedSelector } from '../../hooks/useSelector';
import { ActionType } from '../../store/actions';
import { BiDotsVertical } from 'react-icons/bi';
import Button from '../../components/button';
import ChallengeCard from '../../components/brand/challengeCard';
import instance from '../../helpers/axios';
import { toast, ToastContainer } from 'react-toastify';
import Pagination from '../../components/pagination';
import ScrollToTop from '../../helpers/scrollToTop';
import Loader from '../../helpers/loader';
// import { ShareModal } from '../../components/brand';

const PendingChallenges: React.FC = () => {
    const { loggedInBrand, loggedIn } = useTypedSelector((state) => state.appReducer);
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const {search} = useLocation()
    const [user, setUser] = useState('')
    const { acctSideBarActive } = useTypedSelector((state) => state.appReducer);
    const [challenges, setChallenges] = useState([])
    const [brand, setBrand] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [page, setPage] = useState(1)
    const [pendings, Setpendings] = useState([])
    useEffect(() => {
        dispatch({
            type: ActionType.UPDATE_LAYOUT,
            payload: "brand"
        })
     
    }, [])
    useEffect(() => {
        if (!loggedInBrand || loggedInBrand == "") {
            // nobody is logged in
            navigate("/brand/login");
        }
    }, [loggedInBrand])

    useEffect(() => {
        const search_ = (search.replace('?', '')).split("&")
        if(search_.length > 0){
          search_.forEach(s => {
            if(s.includes('page=') !== false){
              const scrollTo = s.replace('page=','')
                setPage(parseInt(scrollTo))
                // ScrollToTop()
                setIsLoading(true)
                window.scrollTo(0, 0);
                setTimeout(() => {
                    setIsLoading(false)
                    return false;
                }, 500);
            }
          });
        }
    }, [search])


    // get brand profile
    
    const [allRecords, setAllRecords] = useState(0)
    useEffect(() => {
        const headers = {
            'Authorization': `Bearer ${loggedInBrand}`
          }
      instance({
        method : "GET",
        url : "/brand/profile",
        headers : headers
      }).then((response)=>{
        var brandid = response.data.data.brand.brandId
        instance({
            method : "GET",
            url : `/challenge/all?brandId=${brandid}&published=false`,
            headers : headers
          }).then((response)=>{
            console.log("pendng", response.data)
            setAllRecords(response.data.data.totalDocs)
            const c = response.data.data.docs;
            if(c == null) Setpendings([])
            else if(c?.length === 0) Setpendings([])
            else Setpendings(c.reverse());
        // console.log("c", c)
            setIsLoading(false)
          })
        instance({
            method : "GET",
            url : `/challenge/all?brandId=${brandid}&published=true`,
            headers : headers
          }).then((response)=>{
            // console.log(response.data)
            const c = response.data.data.docs;
            if(c == null) setChallenges([])
            else if(c?.length === 0) setChallenges([])
            else setChallenges(c.reverse());
            // setIsLoading(false)
          })
        var brand = response.data.data.brand;
        // console.log(brand._id)
        setBrand(response.data.data.brand);
      }).catch((err)=>{
        if (err.code == "ERR_NETWORK") {
            alert("No internet connection")
        }
        if(err.response.data.statusCode === 403){
            navigate('/brand/login');
            return false;
        }

        toast.error(err.response.data.message)
        console.log(err)
      })
    }, [])
    

    useDocumentTitle('BRAND Pending Challenges')
    // const a = []
    return (
        <div>
            <div className="max-w-[1445px]">
                <ToastContainer />
                {/* wrapper */}
                {
                isLoading ? <Loader /> :
                <div className="flex">
                    {/* main */}
                    <div className='container font-primary min-h-[95vh] flex-inital lg:px-[15px] px-[5px] py-[25px] w-full'>
                        <div className="flex">
                            <Link to="/brand/dashboard" className='text-[#7E799F] text-sm font-bold mr-[10px]'>Active</Link>
                            <a href="#?" className='text-blue text-sm font-bold border-b-2 border-sky-950 mr-[10px]'>Pending</a>
                            <Link to={'/brand/challenges/draft'} className='text-[#7E799F] text-sm font-bold'>Draft</Link>
                        </div>

                        {
                            pendings.length == 0 ?
                            <div className='text-center flex flex-col items-center mt-[25vh]'>
                                <h3 className='text-[30px] font-primarybold text-blue text-center'>Looks like it’s your first time here</h3>
                                <p className='text-[#7E799F80]'>Click on the  button below to create your first challenge</p>
                                <div className="mt-[10px]">
                                    <Link to={'/brand/challenge/create'} className='bg-yellow rounded-[45px] font-bold font-primary text-blue h-[35px] flex items-center justify-center px-[10px] text-[14px]'> <AiOutlinePlusCircle /> <div className="hidden lg:block mx-1"></div> <span className=''>Create a Campaign</span> </Link>
                                </div>
                            </div>
                            :
                            <div>
                                <div className="mt-4">
                                    {
                                        pendings.map((challenge, i) => (
                                            <ChallengeCard source='pending' key={i} data={challenge} />
                                        ))
                                    }
                                    
                                </div>
                                <div className="mt-3">
                                    <Pagination limit={10} length={pendings.length} page={page} />
                                </div>

                            </div>
                        }

                    </div>
                    {/* sidebar */}
                    <aside className={`h-100 min-h-[90vh] z-40 px-[10px] lg:static lg:block py-[20px] ${acctSideBarActive ? 'absolute left-0 top:0' : 'hidden'} flex-initial w-full max-w-[320px] bg-white shadow`}>
                        <div className="table-fixed w-full pr-3 min-h-[50vh]">
                            <div className='flex mb-3'>
                                <p className='w-[75%] font-primary text-[#7E799F] font=bold ' >Challenges</p>
                                <p className='font-primary text-[#7E799F] font=bold ' >Participants</p>
                           
                            </div>
                            <div>
                                {
                                   (challenges.slice(0, 10)).map((item : any, i)=> (
                                    <Link key={i} to={`/brand/challenge/result/${item._id}`} className='flex items-center py-[5px]'>
                                        <div className='w-[70%] text-sm text-blue font-primary'>{item.title}</div>
                                        <div className='text-center w-[25%]'> <button className='rounded-[15px] text-[12px] text-[#0B0149] p-2 bg-[#F0F0F4]'>{ (item.challengePosts).length }</button> </div>
                                    </Link>
                                   ))
                                }
                            </div>
                        </div>

                        <div className="h-[1px] my-[20px] bg-[#7E799F] opacity-70 w-full"></div>
                        <div className='text-center w-full flex justify-center'>
                            <img src={needHelp} alt="" />
                        </div>
                    </aside>
                </div>
                }

            </div>
        </div>
    )
}

export default PendingChallenges