import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import useDocumentTitle from '../../helpers/pageTitle';
import { useTypedSelector } from '../../hooks/useSelector';
import { ActionType } from '../../store/actions';
import instance from '../../helpers/axios';
import { toast, ToastContainer } from 'react-toastify';
import ScrollToTop from '../../helpers/scrollToTop';
import Loader from '../../helpers/loader';
import Button from '../../components/button';
import { checkBox, checkBoxChecked, checked, exporticon, notChecked, who } from '../../components/images';
import { graph1, graph2, trendup } from '../../components/super_images';
import { CChart } from '@coreui/react-chartjs';
import { BsCaretDownSquare, BsCircleFill } from 'react-icons/bs';
import { BiCaretDown, BiDotsVertical } from 'react-icons/bi';
import { AiOutlineCheckSquare, AiOutlineEye, AiOutlinePlusCircle } from 'react-icons/ai';
import { formatDate } from '../../helpers/helpers';
import { CoreDropdown } from '../../components';
import { resultSort } from '../../data';
import { FiSearch } from 'react-icons/fi';
// import { ShareModal } from '../../components/brand';

const TransactionDetailsAdmin: React.FC = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { search } = useLocation()
    const params = useParams()
    const { loggedInAdmin } = useTypedSelector((state) => state.appReducer);
    const { acctSideBarActive } = useTypedSelector((state) => state.appReducer);
    const [isLoading, setIsLoading] = useState(false)
    const [allList, setAllList] = useState<any[]>([])
    const [challenge, setChallenge] = useState<any>({})
    useEffect(() => {
        dispatch({
            type: ActionType.UPDATE_LAYOUT,
            payload: "admin"
        })

    }, [])
    useEffect(() => {
        const headers = {
            'Authorization': `Bearer ${loggedInAdmin}`
        }
        instance({
            method: "GET",
            url: `/challenge/all?_id=${params.id}`,
            headers: headers,
        }).then((response) => {
            var data = response.data.data;
            setChallenge(data.docs[0])
            // setAllList(data.docs)
            // setAllRecord(data.totalDocs)
            console.log(response)
        }).catch((error) => {
            toast.error("Something went wrong")
            setTimeout(() => {
                // navigate('/brand/dashboard');
            }, 2000);
        })
    }, [])

    useEffect(() => {
        const headers = {
            'Authorization': `Bearer ${loggedInAdmin}`
        }
        instance({
            method: "GET",
            url: `/challenge/winnerBoard/${params.id}`,
            headers: headers,
        }).then((response) => {
            var data = response.data.data;
            // setChallenge(data.docs[0])
            setAllList(data.winningPosts)
            console.log(response)
        }).catch((error) => {
            toast.error("Something went wrong")
            setTimeout(() => {
                // navigate('/brand/dashboard');
            }, 2000);
        })
    }, [])

    useEffect(() => {
        if (!loggedInAdmin || loggedInAdmin == "") {
            // nobody is logged in
            navigate("/admin/login");
        }
    }, [loggedInAdmin])

    useDocumentTitle('ADMIN - Transaction Management')
    // const a = []
    return (
        <div>
            <div className="max-w-[1445px] font-primary">
                <ToastContainer />
                {/* wrapper */}
                {
                    isLoading ? <Loader /> :
                        <div className='container font-primary min-h-[95vh] flex-inital lg:px-[15px] px-[5px] py-[25px] w-full'>

                            <div className="mb-[15px]">
                                <h6 className="text-blue mb-[15px] font-primarybold text-[18px]">Transaction Details</h6>
                                <div >
                                    <Link to={'#?'} className='bg-yellow rounded-[45px] w-[fit-content] font-bold font-primary h-[35px] text-blue   text-blue  lg:h-[35px] flex items-center justify-center px-[15px] text-[14px]'> {challenge.brand?challenge.brand.brandName: ''} </Link>
                                </div>
                            </div>


                            <div className="my-[25px] bg-blue rounded-[10px] shadow-lg flex justify-between p-[15px]">
                                <div className=''>
                                    <h3 className='mb-1 text-[16px] font-500 text-white font-primary'>Campaign : {challenge.title} </h3>
                                </div>
                            </div>


                            <div className="my-3 shadow lg:items-center rounded-[10px] w-full bg-white p-[20px]">
                                <div className='flex lg:flex-row gap-3 flex-col lg:justify-between'>
                                    <div>
                                        <div className="flex flex-col gap-4 mb-3 lg:items-center lg:flex-row lg:justify-between ">
                                            <h3 className='text-[16px] font-bold text-blue'>Total number of winners: <span className='font-normal'>{challenge.noOfWinners}</span></h3>
                                            <div></div>
                                        </div>
                                        <div className="flex flex-col gap-4 mb-3 lg:flex-row lg:justify-between ">
                                            <h3 className='text-[16px] font-bold text-blue'>Total reward budget: <span className='font-normal'>${challenge.totalPrizeBudget}</span></h3>
                                            <div></div>
                                        </div>
                                        <div className="flex flex-col gap-4 mb-3 lg:flex-row lg:justify-between ">
                                            <h3 className='text-[16px] font-bold text-blue'>Prize per winner: <span className='font-normal'>${challenge.totalPrizeBudget ? challenge.totalPrizeBudget/challenge.noOfWinners : 0}</span></h3>
                                            <div></div>
                                        </div>

                                        <div className="flex flex-col gap-4 lg:flex-row lg:justify-between ">
                                            <Button text={`Sum of charges $${challenge.amountPaid - challenge.totalPrizeBudget}`} containerClass='bg-black w-[fit-content] text-yellow rounded px-3' h='35px' />
                                            <div></div>
                                        </div>
                                    </div>
                                    {/* <Link to={'#?'} className='bg-yellow rounded-[45px] w-[fit-content] font-bold font-primary h-[35px] text-blue text-blue flex items-center justify-center px-[15px] text-[14px]'> See Details</Link> */}
                                </div>
                            </div>


                            <div className="mt-[25px]">
                                <h3 className="text-blue">Payment Breakdown</h3>
                            </div>
                            <div className="my-3 shadow lg:items-center rounded-[10px] w-full bg-white p-[20px]">
                                <div className='flex lg:flex-row gap-3 flex-col lg:justify-between'>
                                    <div>
                                        <div className="flex flex-col gap-4 mb-3 lg:items-center lg:flex-row lg:justify-between ">
                                            <h3 className='text-[16px] font-bold text-blue'>Total Payment: <span className='font-normal'>${challenge.amountPaid}</span></h3>
                                            <div></div>
                                        </div>
                                        <div className="flex flex-col gap-4 mb-3 lg:flex-row lg:justify-between ">
                                            <h3 className='text-[16px] font-bold text-blue'>Total disposable: <span className='font-normal'>${challenge.totalPrizeBudget}</span></h3>
                                            <div></div>
                                        </div>

                                        <div className="flex flex-col gap-4 lg:flex-row lg:justify-between ">
                                            <Button text='Disbursed Amount: $450' containerClass='bg-yellow w-[fit-content] text-blue rounded px-3' h='35px' />
                                            <div></div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="bg-white p-3">
                            <div className="shadow-[10px] rounded-[10px] w-[800px] md:w-full lg:w-full" >
                                <table className="border-collapse text-sm table-auto w-[800px] md:w-full lg:w-full">
                                    <thead className='text-blue p-2 h-[45px] border-b-graylight border-b-2'>
                                        <tr>
                                            {/* unique id, username, amount, status */}
                                            <th>Unique ID</th>
                                            <th className='text-left'>Username</th>
                                            <th className='text-left'>Amount</th>
                                            <th className='text-left'>State</th>
                                        </tr>
                                    </thead>
                                    <tbody className='p-2'>
                                        {
                                            allList.map((item: any, i) => (
                                                <tr key={i} className='p-2 text-[#0B014980] border-spacing-2' style={{ height: '70px' }}>
                                                    <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''}`}>
                                                        <div className="flex items-center font-bold font-primary">
                                                            <div className='pl-3'>
                                                                <h3 className='text-blue'>{item.user? item.user.userId : ''}</h3>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''} `}>{item.user.userName}</td>
                                                    <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''} `}>${item.prizeWon}</td>
                                                    <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''} `}> <span className="bg-yellow p-2 px-3 text-blue rounded text-[10px]">{item.won? 'paid': 'pending'}</span> </td>
                                                </tr>
                                            ))
                                        }

                                    </tbody>
                                </table>
                            </div>
                            </div>

                        </div>
                }

            </div>
        </div>
    )
}

export default TransactionDetailsAdmin