import { capitalizeFirstLetter, formatCreatedAtDate, formatDate } from "../../../helpers/helpers";

export default function InsightTable({ item, insightPeriod }: any) {
    return (
        <div className="my-5">
            <h3 className='font-primarybold text-blue text-[16px] text-uppercase'>{item.title}</h3>
            <p className="text-[13px]">{item.description}</p>
            <table className="max-w-[500px] border-collapse table-auto w-full text-sm ">
                <thead className='text-blue p-2 h-[45px] border-b-graylight border-b-2'>
                    <tr>
                        <th>Date</th>
                        <th>Value</th>
                    </tr>
                </thead>

                <tbody>
                    {
                        item.values.map((item: any, i: any) => (
                            <tr>
                                <td>{formatDate(formatCreatedAtDate(item.end_time), 2, true)}</td>
                                <td>{item.value}</td>
                            </tr>
                        ))
                    }

                </tbody>
            </table>
        </div>
    )
}