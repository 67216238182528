import React, { useEffect, useState } from 'react'
import { AiOutlineEyeInvisible } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SettingsAside, SettingsHeader } from '../../../components/brand';
import Button from '../../../components/button';
import { cardPayment, checked, notChecked, paypal, radio, radioActive, stripe } from '../../../components/images';
import Input from '../../../components/input';
import { monthsList } from '../../../data';
import useDocumentTitle from '../../../helpers/pageTitle';
import { useTypedSelector } from '../../../hooks/useSelector';
import { ActionType } from '../../../store/actions';


const PaymentMethod: React.FC = () => {
    const { loggedInBrand } = useTypedSelector((state) => state.appReducer);
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [value, setValue] = useState('')
    useEffect(() => {
        dispatch({
            type: ActionType.UPDATE_LAYOUT,
            payload: "brand"
        })
    }, [])

    useDocumentTitle('PAYMENT METHOD SETTINGS')

    return (
        <div className='w-full'>
            <div className="px-[15px] mb-[80px] pb-[10px] h-100 relative font-primary flex max-w-[1440]">
                <SettingsAside />
                <div className='lg:container sm:mx-auto md:mx-auto flex-inital lg:px-[15px] px-[5px] py-[25px] w-full'>
                    <SettingsHeader title='Payment Method' sub_title='Manage your payment & plan details' />
                   <div className="shadow-lg rounded-[15px] mt-[50px] p-[15px] py-[40px] bg-white">
                        <h3 className='font-primary text-blue text-[16px]'>Select payment method</h3>
                        <div className="my-[15px] mb-[25px] flex items-center">
                            <div className="card flex mr-[25px] items-center">
                                <img src={radioActive} className='object-contain h-[20px]' alt="" /> <div className="mx-1"></div>
                                <img src={cardPayment} className='object-contain h-[30px]' alt="" />
                            </div>
                            <div className="card flex mr-[25px] items-center">
                                <img src={radio} className='object-contain h-[20px]' alt="" /> <div className="mx-1"></div>
                                <img src={stripe} className='object-contain h-[20px]' alt="" />
                            </div>
                            <div className="card flex  items-center">
                                <img src={radio} className='object-contain h-[20px]' alt="" /> <div className="mx-1"></div>
                                <img src={paypal} className='object-contain h-[20px]' alt="" />
                            </div>
                        </div>
                        <h3 className='font-primary text-blue mt-[13px] font-bold text-[16px]'>Payment Details</h3>
                        <div className="mt-5">
                            <label className='font-primary text-blue' htmlFor="">Card Holder's Name</label>
                            <Input value={value} setValue={setValue} containerClass="h-[50px] border-graylight shadow-sm border-2" placeholder='Name on Card' />
                        </div>

                        <div className="mt-5">
                            <label className='font-primary text-blue' htmlFor="">Card Number</label>
                            <Input value={value} setValue={setValue} containerClass="h-[50px] border-graylight shadow-sm border-2" placeholder='Enter card number' />
                        </div>
                        <div className="flex items-center lg:flex-row justify-between flex-col">
                            <div className="mt-5">
                                <label className='font-primary text-blue' htmlFor="">Valid Thru</label>
                                <div className="flex">
                                <Input pos="top-[45%]" items={monthsList} isDropDown={true} value={value} setValue={setValue} containerClass="h-[50px] border-graylight shadow-sm border-2" placeholder='Month' />
                                <div className="mx-3"></div>
                                <Input pos="top-[45%]" items={monthsList} isDropDown={true} value={value} setValue={setValue} containerClass="h-[50px] border-graylight shadow-sm border-2" placeholder='Year' />
                                </div>
                            </div>
                            <div className="mt-5 relative">
                                <label className='font-primary text-blue' htmlFor="">CVV</label>
                                <div className="mt-2"></div>
                                <Input value={value} type="password" setValue={setValue} containerClass="h-[50px] border-graylight shadow-sm border-2" placeholder='CVV' />
                                <span>
                                    <AiOutlineEyeInvisible className='opacity-75 absolute top-[40px] right-[15px]' />
                                </span>
                            </div>
                        </div>
                        <div className="mt-[30px]">
                            <Button text="Pay" containerClass='bg-blue text-yellow w-[180px]' />
                        </div>
                   </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentMethod;