import React from 'react'

import { Swiper, SwiperSlide } from 'swiper/react';
import {Logo1, Logo2, Logo3, Logo4, Logo5, Logo6} from '../images'

// Import Swiper styles
import 'swiper/css';
function FeaturedBrands() {
  const brands = [
    Logo1, Logo2,Logo3, Logo4, Logo5, Logo6
  ]
  const beentos = [
    {
      name: "Amplify Brand Influence",
      description: "Beentos empowers your brand to amplify its influence by connecting you with the right influencers who resonate with your target audience."
    },
    {
      name: "Boost Sales through Partnerships",
      description: "Collaborate with influencers on Beentos to boost your sales. Leverage their authentic connections with followers to drive conversions and increase revenue"
    },
    {
      name: "Enhanced Visibility",
      description: "Increase your brand's visibility with Beentos. Our platform connects you with influencers who can showcase your products or services to a wider audience, boosting brand awareness."
    },
    {
      name: "Expand Reach and Engagement",
      description: "Discover influencers on Beentos to expand your brand's reach and foster meaningful engagement with a wider audience."
    },

  ]
  return (
    <div className='py-[60px] bg-graylight' id='FeaturedBrands_section'>
      <div className="container mx-auto">
        <div className="text-center mb-5">
          <h3 className='text-[36px] font-primarybold text-blue'>Featured Brands</h3>
        </div>



        <div className="">
          <Swiper
            navigation={{ enabled: true, nextEl: ">", prevEl: "<" }}
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            spaceBetween={50}
            slidesPerView={window.innerWidth < 800 ? 1 : 3}
            onSlideChange={() => console.log('slide change')}
          >
            {
             brands.map((item, i) => (
                <SwiperSlide key={i}>
                  <img style={{
                    height : "120px",
                    width : "100%",
                    objectFit : "contain"
                  }} src={item} />
                </SwiperSlide>
              ))
            }

          </Swiper>
        </div>


      </div>
    </div>
  )
}

export default FeaturedBrands