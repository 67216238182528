import React from "react";
import { PopupButton } from "react-calendly";
import { useNavigate } from "react-router-dom";
import { Icon } from '@iconify/react';
import { analyticsBeentos, apiBeentos, buyPower, content, customerImage, markeTi } from "../images";

function CoreFeatures() {
  const navigate = useNavigate();
    const beentos = [
    {
      image : apiBeentos,
      description:
        "API integration, Ecommerce/website integration.",
    },
    {
      image : buyPower,
      description:
        "Customized branded web page for campaign (for simplified review upload)",
    },
    {
      image : content,
      description:
        "Access to Content",
    },
    {
      image : markeTi,
      description:
        "Intellectual property rights for the marketing materials",
    },
    
    {
      image : analyticsBeentos,
      description:
        "Valuable Insights",
    },
    
    {
      image : customerImage,
      description:
        "Support on how to utilize video reviews for marketing",
    },
    
  ];
  return (
    <div
      className="bg-graylight dark:bg-black py-[60px]"
      id="features"
    >
      <div className="container mx-auto">
        <div className="text-center">
          <h3 className="text-[36px] font-primarybold text-blue">
Features          </h3>
          <p>
          Seamlessly collect and store video reviews from customers on a regular basis
          </p>
        </div>

        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 justify-between my-[50px]">
          {beentos.map((item, i) => {
            return (
              <div className="text-center shadow bg-white rounded">
                <div className="flex justify-center h-[250px] text-center">
                  <img src={item.image} className="w-full cover h-full" />
                </div>
                <div className="p-3">
                <h3 className="text-[18px] mt-2 text-black font-bold font-primary">
                  {item.description}
                </h3>
              </div>
              </div>
            );
          })}
        </div>

      </div>
    </div>
  );
}

export default CoreFeatures;
