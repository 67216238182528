import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../components/button';
import { Logo, bgWhite, bgYellow } from "../../components/images";
import Input from '../../components/input';
import Steps from '../../components/signup/steps';
import useDocumentTitle from '../../helpers/pageTitle';
import { brandCategories } from '../../data';
import { isValidUrl, validateEmail } from '../../helpers/helpers';
import { useDispatch } from 'react-redux';
import { ActionType } from '../../store/actions';
import { useTypedSelector } from '../../hooks/useSelector';
import { CoreDropdown } from '../../components';
import instance from '../../helpers/axios';



const BrandRegisterMain = () => {
  const [brand, setBrand] = useState<string>('')
  const [brandErr, setBrandErr] = useState<string>('')
  const [showBrandErr, setShowBrandErr] = useState(false)
  const [website, setWebsite] = useState<string>('')
  const [websiteErr, setWebsiteErr] = useState<string>('')
  const [showWebsiteErr, setShowWebsiteErr] = useState(false)

  const [category, setCategory] = useState('')
  const [categoryErr, setCategoryErr] = useState('')
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordErr, setPasswordErr] = useState('')
  const [showPasswordErr, setShowPasswordErr] = useState(false)
  //const [brandCategories, setBrandCategories] = useState<any[]>([])
  // hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showCategoryErr, setShowCategoryErr] = useState(false)

  const { brandData }: any = useTypedSelector((state) => state.appReducer);

  useEffect(() => {
    sessionStorage.removeItem('otp')
    try {
      dispatch({
        type: ActionType.LOGOUT_BRAND,
      })
    } catch (error) {

    }
  }, [])


  useEffect(() => {
    dispatch({
      type: ActionType.LOGOUT_BRAND,
    })


  }, [dispatch])

  useEffect(() => {
    if (!brandData) {
    } else {
      var data = JSON.parse(brandData);
      setBrand(data.email)
      setPassword(data.password)
      setCategory(data.category)
    }
  }, [brandData, dispatch])

  const login = () => {
    let passed = true;
    setLoading(true)
    setShowCategoryErr(false)
    setShowWebsiteErr(false)
    setShowBrandErr(false)
    setShowPasswordErr(false)

    if (brand === "") {
      setShowBrandErr(true)
      setBrandErr("Email cannot be empty");
      passed = false;
    }
    if (!validateEmail(brand)) {
      setShowBrandErr(true)
      setBrandErr("Invalid email address");
      passed = false;
    }

    if (category === "") {
      setShowCategoryErr(true)
      setCategoryErr("Brand category cannot be empty");
      passed = false;
    }
    if (password === "") {
      setShowPasswordErr(true)
      setPasswordErr("password cannot be empty");
      passed = false;
    }


    // if (website === "") {
    //   // setShowWebsiteErr(true)
    //   // setWebsiteErr("Please input your official website.");
    //   // passed = false;
    // } else if (!isValidUrl(website)) {
    //   setShowWebsiteErr(true)
    //   setWebsiteErr("Please a valid url e.g example.com.");
    //   passed = false;
    // }

    setTimeout(() => {
      if (!passed) {
        setLoading(false)
        return false;
      }
      const data = JSON.stringify({
        email: brand,
        category: category,
        password: password
      });


      dispatch({
        type: ActionType.UPDATE_BRAND_DATA,
        payload: data
      })
      // write logic
      navigate('/brand/register-step-1');
    }, 1000);

  }
  useDocumentTitle('BRAND SIGN UP ')
  return (
    <div className='h-[100vh] relative'>
      <img src={bgYellow} className="h-[100vh] w-full object-fill" alt="" />
      <div className="h-[100vh] absolute top-0 left-0 flex w-full justify-center items-center">
        <div className="login-container shadow-lg w-[95%] max-w-[520px] p-[40px] px-[30px] lg:px-[60px] text-center bg-white rounded-[20px]">
          <div className='bg-yellow mx-auto w-[85px] h-[75px] rounded-[8px] mb-[20px] flex items-center justify-center p-2'>
            <Link to={'/'}>
              <img src={Logo} className="h-[62px]  object-contain" alt="" />
            </Link>
          </div>
          <h3 className="text-blue font-primarybold  text-[26px] ">Create an account</h3>
          <div className="flex items-center justify-center my-[20px]">
            <Steps s={1} iscurrent={true} islast={false} />
            {/* <Steps s={2} iscurrent={false} islast={false} /> */}
            <Steps s={2} iscurrent={false} islast={true} />
          </div>
          <div className='w-full text-left'>
            <h3 className="text-blue font-primarybold text-[16px] ">Sign Up for Free</h3>
          </div>
          <Input showError={showBrandErr} err={brandErr} placeholder="someone@example.com" value={brand} setValue={setBrand} containerClass="mt-[10px] text-[1rem]" />
          {showBrandErr && <div className='opacity-0'>s</div>}
          <Input type='password' prevErr={showBrandErr} showError={showPasswordErr} err={passwordErr} placeholder="Enter your password" value={password} setValue={setPassword} containerClass={`mt-[15px] text-[1rem]`} />

          <CoreDropdown source='main' prevErr={showBrandErr} showError={showCategoryErr} err={categoryErr} items={brandCategories} containerClass="control justify-between border border-blue pl-4 h-[50px] rounded-[45px] w-full mt-3" placeholder="Brand category" type="text" value={category} setValue={setCategory} />


          <div className="lg:flex lg:justify-end pt-[10px]">
            <Button onclick={login} text="Next" loading={loading} containerClass='w-full lg:w-[200px] bg-blue text-yellow font-bold hover:opacity-75 mt-[20px]' />
          </div>

          <div className="flex justify-center p-2 mt-[22px]">
            <span className="font-bold text-[16px] text-blue opacity-50">Already have an account? </span> &nbsp;
            <Link to={'/brand/login'} className='text-[16px] font-bold font-primary text-blue hover:opacity-75'>Login</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BrandRegisterMain