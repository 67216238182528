import React, { useState } from 'react'
import { BiDotsHorizontal } from 'react-icons/bi'
import { Clipimg, playIcon, video, who, winnersIcon } from '../images'
import ReactPlayer from 'react-player/lazy'


import ButtonLoader from '../../helpers/button_loader';
import instance from '../../helpers/axios';
import ShareModal from './share_modal';
import { toast, ToastContainer } from 'react-toastify';
import ConfirmModal from './ask_modal';


interface iClipCard {
    item: any,
    loggedInBrand : string,
    reported ?: any,
    reload ?: any ,
    selectWinners? : boolean,
    setChecked ? : any,
    selectedWinners? : any[],
    winners? : any[]
}
function ClipCaard({winners, setChecked, selectWinners = false, selectedWinners, item, loggedInBrand, reported = null, reload }: iClipCard) {
    const [isActive, setIsActive] = useState(false)
    const [showPlayer, setShowPlayer] = useState(false)
    const [playerReady, setPlayerReady] = useState(false)
    const [isAdmin, setIsAdmin] = useState(window.location.href.includes("admin") ? true : false)
    const [isFlag, setIsFlag] = useState(false)
    const [showShare, setShowShare] = useState(false)
    const generateThumb = () => {

    }
    const [isDelete, setIsDelete] = useState(false)
    const {_id, comments, user, noOfVoters, noOfViews, media, thumbnailUrl } = item;
    const headers = {
        'Authorization': `Bearer ${loggedInBrand}`
    }
    const pinpost = () => {
        instance({
            method: "POST",
            url: `/brand/challenge-post/pin/${_id}`,
            headers: headers
        }).then((response) => {
            console.log("response", response)
        }).catch((err) => {

        }).finally(() => {
        })
    }
    const flagPost = () => {
        instance({
            method: "POST",
            data : {
                flag : true
            },
            url: `/challenge-post/admin/flag/${_id}`,
            headers: headers
        }).then((response) => {
            // console.log("response", response)
            toast.success("Post flagged")
            setIsDelete(false)
            setIsFlag(false)
        }).catch((err) => {
            setIsDelete(false)
            alert("Unable to flag post")
        }).finally(() => {
        })
    }

    const deletePost = () => {
        instance({
            method: "DELETE",
            url: `/challenge-post/admin/delete-reported-posts`,
            headers: headers,
            data: {property: "id", value: reported ? [reported._id]: [_id]}
        }).then((response) => {
            // console.log("response", response)
        setIsDelete(false)

            toast.success("Post deleted")
            window.location.href = "./reported-contents";
        }).catch((err) => {
            setIsDelete(false)
            alert("Unable to delete"+ err.message)
            alert(JSON.stringify(err))
            

        }).finally(() => {
        })
    }

    const showDelete = () => {
        setIsDelete(true)
    }
    return (
        // <div className="relative shadow rounded ">
        <div className="relative shadow rounded max-w-[420px]">
            <ToastContainer />
            {
                isDelete ? 
                <ConfirmModal
                callback={()=>deletePost()}
                 close={()=>setIsDelete(false)} title="Delete Post?" description='This action cannot be revert' />
                : ""
            }


            {
                isFlag ? 
                <ConfirmModal
                callback={()=>flagPost()}
                 close={()=>setIsFlag(false)} title="Flag Post?" description='Are you sure to flag this post' />
                : ""
            }


            {
                showShare ?
                <ShareModal close={()=> setShowShare(false)} type='post' id={_id} /> : ''
            }

            {
                showPlayer
                    ?
                    <div className='fixed flex items-center  top-0 left-0 w-full z-[99999] h-[100vh]'>
                        <div className="max-h-[80vh] min-h-[300px] w-full flex justify-center">
                            <div onClick={() => setShowPlayer(false)} className='back_drop backdrop-blur fixed top-0 left-0 w-full bg-blue/30 h-[100vh] bg-blue'></div>
                            <div className='relative'>
                                {
                                    playerReady ? ''
                                    : 
                                    <div className='absolute top-[48%] z-[3px] left-[45%]'>
                                        <ButtonLoader />
                                    </div>
                                }
                                
                                <div className={`${playerReady ? 'bg-white' : ''} left-[0] z-[49999px]`}>
                                    <ReactPlayer
                                        controls={true}
                                        
                                        onReady={()=>{setPlayerReady(true)}}
                                        // url={media.mediaSecureUrl} 
                                        url={item.media.mediaSecureUrl}
                                     />
                                </div>

                            </div>
                        </div>
                    </div>
                    : ''
            }

            {
                isActive ?
                    <div className="overlay">
                        <div className="bg-white py-2 px-3 shadow z-[4111] right-0 rounded absolute w-[180px]">
                            {
                                isAdmin ?
                                <ul className="text-[12px]">
                                <li className='mt-1'>
                                    <a onClick={()=>{
                                        setIsActive(false)
                                        setIsDelete(false)
                                        setIsFlag(true)
                                    }} className="text-blue font-primary" href="#?">Flag</a>
                                </li>
                                <li className='mt-1'>
                                    <a onClick={
                                        ()=>{
                                            setIsActive(false)
                                            setIsDelete(true)
                                        }
                                    } className="text-blue font-primary" href="#?">Delete Post</a>
                                </li>
                            </ul>
                                :
                            <ul className="text-[12px]">
                                <li className='mb-1'>
                                    <a onClick={pinpost} className="text-blue font-primary" href="#?">Pin Post</a>
                                </li>
                                <li className='my-1'>
                                    <a onClick={()=>{setIsActive(false); setShowShare(true)}} className="text-blue font-primary" href="#?">Share Post</a>
                                </li>
                                <li className='my-1'>
                                    <a className="text-blue font-primary" href="#?">Flag as inappropriate</a>
                                </li>

                                <li className='mt-1'>
                                    <a className="text-blue font-primary" href="#?">Delete Post</a>
                                </li>
                            </ul>
                            }

                        </div>
                        <div onClick={() => setIsActive(false)} className="backgrop bg-black opacity-40 z-40 h-[100%] rounded w-[100%] absolute"></div>
                    </div>
                    : ''
            }

            <img src={playIcon} style={{cursor : 'pointer'}} onClick={() => { setShowPlayer(true) }} className="absolute top-[20%]  left-[48%]" alt="" />
            <div className="flex flex-column md:flex-row ">
                <img onClick={() => { setShowPlayer(true) }} style={{cursor : 'pointer'}} className='rounded-l-[10px] flex-initial object-cover avatar_kora h-[150px] w-["100%"]' src={thumbnailUrl} />

                <div className="flex-initial w-full">
                        <div className="mt-3 flex justify-end pr-3">
                            <BiDotsHorizontal onClick={() => setIsActive(true)} style={{ cursor: 'pointer' }} />
                        </div>
                    <div className="p-3">
                        <div className="flex items-center">
                            <img src={user? user.avatar : who} className="h-[40px] object-cover w-[40px] rounded-[100%]" alt="" />
                            <div className="mx-2">
                                <h3 className="text-[#727373]">{user ? (user.userName).substr(0, 10) : 'User'}</h3>
                                {
                                    item.privateUser && 
                              <>  <small>{item.privateUser.email}</small>
                                <small>{item.privateUser.phoneNumbeer}</small>
                                </>
                                }
                            </div>
                        </div>
                        <div className="grid mt-3 grid-cols-3">
                            <div className="text-center">
                                <h3 className="text-[14px] text-[#727373]">Comment</h3>
                                <h3 className="text-blue">{comments.length}</h3>
                            </div>
                            <div className="text-center">
                                <h3 className="text-[14px] text-[#727373]">Like</h3>
                                <h3 className="text-blue">{noOfVoters}</h3>
                            </div>
                            <div className="text-center">
                                <h3 className="text-[14px] text-[#727373]">Impression</h3>
                                <h3 className="text-blue">{noOfViews}</h3>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    selectWinners && !winners?.includes(item._id) &&
                <div className='absolute top-[20px] right-[10px]'>
                {
                !selectedWinners?.includes(item._id) ?
                    <div onClick={() => {
                        setChecked(item._id, true)
                    }} className='border-2 cursor-[pointer] border-blue h-[18px] w-[18px]'></div>
                    :
                    <div onClick={() => {
                        // on checked
                        setChecked(item._id,false)
                    }} className='border-2 cursor-[pointer] flex rounded-[100%] items-center justify-center border-blue  h-[18px] w-[18px] checkedBoss'>
                        {/* <div className='bg-blue  h-[8px] w-[8px] checkedBoss'>
                        </div>  */}
                        <span className='font-bold'> &#10003; </span>
                    </div>
                }
                </div>

                }
            </div>
        </div>
    )
}

export default ClipCaard