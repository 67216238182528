import React, {useEffect, useState} from 'react'
import { Link, useLocation } from 'react-router-dom'
import {Logo, lightModeIcon, darkModeIcon} from './images'
import {BiMenu} from 'react-icons/bi'
import appMode from '../helpers/mode'
import { ScrollToDiv } from '../helpers/helpers'

export default function Navbar() {
  const [isActive, setisActive] = useState(false)
  const path = useLocation().pathname
  const [mode, setMode] = useState('')
  const activeClass = 'text-white lg:text-yellow lg:p-3 lg:rounded lg:bg-navactive';
  const loginClass = 'text-blue p-2 rounded bg-yellow';
  const hover = 'hover:text-white';
  const toggleNav = () =>{
    setisActive(!isActive)
  }
  
  useEffect(() => {
    setMode(appMode.initMode());
  }, [])

  useEffect(() => {
    setisActive(false);
  }, [path]);

  const changeMode = ()=> {
    setMode(appMode.toggleMode(mode === 'light' ? 'dark' : 'light'))
  }
  return (
    <div className='bg-blue'>
      <nav className='font-primary flex justify-between items-center h-[51px] lg:h-[90px] '>
          {/* logo */}
          <div >
            <Link to="/" className="brand flex justify-center items-center bg-yellow w-[100px] lg:w-[187px] h-[53px] lg:h-[90px]"> 
                <img src={Logo} className="object-contain h-[53px] lg:h-[90px]" alt="" />
            </Link>
          </div>
          <div className='hidden lg:block px-[20px] lg:pr-[100px]'>
            <ul className="navbar text-yellow flex items-center font-semibold font-primary list-none">
              <li className="font-primary mr-[35px]">
                <Link className={`${hover} ${path === '/' ? activeClass : ''}`} to='/'>Home</Link>
              </li>
              <li className="font-primary mr-[35px]">
                <Link to={path === '/' ?'#?' : '/?section=about_page'} className={`${hover} ${path === '/about' ? activeClass : ''}`} onClick={()=>ScrollToDiv("about_page")} >About Us</Link>
              </li>
              <li className="font-primary mr-[35px]">
                <Link to={path === '/' ?'#?' : '/?section=features'} className={`${hover} ${path === '/features' ? activeClass : ''}`} onClick={()=>ScrollToDiv("features")} >Features</Link>
              </li>

              <li className="font-primary mr-[35px] ">
                <Link to={path === '/' ?'#?' : '/?section=footer'} className={`${hover} ${path === '/contact' ? activeClass : ''}`} onClick={()=>ScrollToDiv("footer")}>Contact</Link>
              </li>

              <li className="font-primary mr-[35px] ">
                <Link to="/blog" className={`${hover} ${path === '/blog' ? activeClass : ''}`}>Blog</Link>
              </li>

              <li className="font-primary">
                <Link to="/brand/login" className={`${loginClass}`}>Login</Link>
              </li>
            
            </ul>
          </div>
         <div className='flex items-center  lg:hidden'>
            <button onClick={toggleNav} className='block lg:hidden px-[10px]'>
                <BiMenu className='text-[30px] hover:text-[32px] transition text-yellow' />
            </button>

            <a href='#?' className='cursor-pointer lg:hidden pr-[10px] text-[20px] text-yellow' onClick={()=> {changeMode();appMode.initMode();}}>
              <img className='w-[25px] object-contain' src={mode === 'dark' ? darkModeIcon : lightModeIcon } alt="" />
            </a>

         </div>

      </nav>


      {/* nav for mobile */}
      <div className={`bg-blue absolute w-full pb-4 lg:pr-[158px]' ${isActive ? 'block' : 'hidden'} lg:hidden`}>
        <ul className="navbar text-yellow flex flex-col  items-end mt-5 font-semibold font-primary list-none">
          <li className="font-primary my-2 text-end text-end mr-[20px]">
            <Link  className={path === '/' ? activeClass : ''} to='/'>Home</Link>
          </li>
          <li className="font-primary my-2 text-end mr-[20px]">
            <Link className={path === '/about' ? activeClass : ''} to="#about_page">About Us</Link>
          </li>

          <li className="font-primary my-2 text-end mr-[20px]">
            <Link to={'#?'} className={path === '/contact' ? activeClass : ''}>Contact</Link>
          </li>

          <li className="font-primary my-2 text-end mr-[20px]">
            <Link className={path === '/blog' ? activeClass : ''} to='/blog'>Blog</Link>
          </li>
        </ul>
      </div>
    </div>
  )
}
