import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useDocumentTitle from '../../helpers/pageTitle';
import { useTypedSelector } from '../../hooks/useSelector';
import { ActionType } from '../../store/actions';
import instance from '../../helpers/axios';
import { toast, ToastContainer } from 'react-toastify';
import ScrollToTop from '../../helpers/scrollToTop';
import Loader from '../../helpers/loader';
import Button from '../../components/button';
import { checkBox, checkBoxChecked, exporticon, who } from '../../components/images';
import { graph1, graph2, trendup } from '../../components/super_images';
import { CChart } from '@coreui/react-chartjs';
import { BsCaretDownSquare, BsCircleFill } from 'react-icons/bs';
import { BiCaretDown, BiDotsVertical } from 'react-icons/bi';
import { AiOutlineCheckSquare, AiOutlineEye, AiOutlinePlusCircle } from 'react-icons/ai';
import { formatCreatedAtDate, formatDate, getToday } from '../../helpers/helpers';
import { CoreDropdown } from '../../components';
import { resultSort, resultSort1 } from '../../data';
import { FiSearch } from 'react-icons/fi';
import Pagination from '../../components/pagination';
import SelectBrandModal from '../../components/admin/select_brand_modal';
import ConfirmModal from '../../components/brand/ask_modal';
import AdminPdfModal from '../../components/brand/adminPdfModal';
// import { ShareModal } from '../../components/brand';

const CampaignManagement: React.FC = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { search } = useLocation()
    const [user, setUser] = useState('')
    const [selected, setSelected] = useState<number[]>([])
    const { loggedInAdmin } = useTypedSelector((state) => state.appReducer);

    const { acctSideBarActive } = useTypedSelector((state) => state.appReducer);
    const [isLoading, setIsLoading] = useState(false)
    const [c, setC] = useState([])
    const [search_, setSearch_] = useState("");
    const [allList, setAllList] = useState<any[]>([])
    const [allListPdf, setAllListPdf] = useState<any[]>([])
    const [dropdown_, setDropdown] = useState("")
    const [totalPages, setTotalPages] = useState(0)
    const [sort, setSort] = useState("Active")
    const [page, setPage] = useState(1)
    const [allRecords, setAllRecords] = useState(1)
    const [showPdfModal, setShowPdfModal]  = useState(false)
    const [loaded, setLoaded]  = useState(false)
    const headers = {
        'Authorization': `Bearer ${loggedInAdmin}`
    }
    useEffect(() => {
        dispatch({
            type: ActionType.UPDATE_LAYOUT,
            payload: "admin"
        })

    }, [])
    const chartlegends = [
        { title: "Running", className: "text-[#0B0149]" },
        { title: "Not Running", className: "text-[#0B01491F]" },
    ]
    useEffect(() => {
        const search_ = (search.replace('?', '')).split("&")
        if (search_.length > 0) {
            search_.forEach(s => {
                if (s.includes('page=') !== false) {
                    const scrollTo = s.replace('page=', '')
                    setPage(parseInt(scrollTo))
                    return false;
                }
            });
        }
    }, [search])
    useEffect(() => {
        fetchConstants();
    }, [page, sort])
    const buildUrl = (source = "main") => {
        var s = sort;
        s = s.toLowerCase()
        var url = "";
        switch (s) {
            case "active":
                url = `/challenge/all?_page=${page}&published=true`
                break;
            case "in active":
                    url = `/challenge/all?_page=${page}&published=false`
                    break;
            case "oldest" : 
                    url = `/challenge/all?_page=${page}&_order=ASC`
                break;
            case "newest" : 
                    url = `/challenge/all?_page=${page}&_order=DESC`
                break;
            default:
                url = `/challenge/all?_page=${page}`
                break;
        }
        if(source == "download") url = `${url}&_limit=${allRecords}`
        // alert(url)
        return url;
    }
    const fetchConstants = () => {
        setIsLoading(true)
        
        instance({
            method: "GET",
            url: buildUrl(),
            headers: headers
        }).then((response) => {
            console.log("response", response)
            var docs = response.data.data.docs;
            setAllList(docs);
            setC(docs);
            setAllRecords(response.data.data.totalDocs)
        }).catch((err) => {

        }).finally(() => {
            setIsLoading(false);
        })
    }
    const addSelected = (value: number) => {
        var selected_ = selected;
        if (selected_.includes(value)) {
            selected_.splice(selected_.indexOf(value), 1)
        } else {
            selected_.push(value)
        }
        setSelected(selected_);
        setTimeout(() => {
            setAllList(c)
        }, 500);
    }

    function updateChallengeStatus(challengeId: string, status: any) {
        instance({
            method: "PUT",
            url: `/challenge/admin/update-publication-status/${status.published ? "publish" : "unPublish"}`,
            data: { challengeIds: [challengeId] },
            headers,
        })
            .then((response) => {
                const data = response.data.data;
                toast.success("campaign updated successfully")
                window.location.href = "./campaign-management";
            })
            .catch((err) => {
                setIsLoading(false);
                alert(err.message)
                alert(JSON.stringify(err))
            })

    }

    function dropChanged(value: any) {
        setPage(1)
        setSort(value)
    }
    const searchChanged = (value: string) => {
        if (value === "" || value === undefined || value === null) {
            setSearch_("")
            return setAllList(c)
        };
        console.log("value", value)
        setSearch_(value)
        var filtered: any[] = [];
        allList.map((list: any) => {
            if (list.user) {
                var fname: string = list.user.fullName;

                if ((fname.toLowerCase()).includes(value.toLowerCase())) filtered = [...filtered, list];
            }
        })

        setAllList(filtered)

    }
    const [isDelete, setIsDelete] = useState(false)
    const [toDelete, setToDelete] = useState("")
    const deleteChallenge = () => {
        const headers = {
            'Authorization': `Bearer ${loggedInAdmin}`
        }
        instance({
            method: "DELETE",
            url: `/challenge/delete/`,
            data: {
                property: "_id",
                value: [toDelete]
            },
            headers: headers
        }).then((response) => {
            // console.log("response", response)
            setIsDelete(false)
            toast.success("Challenge Delete")
            fetchConstants();
        }).catch((err) => {
            setIsDelete(false)
            toast.error("Unable to delete  Challenge");

        }).finally(() => {
        })
    }
    function downloadReport () {
        setShowPdfModal(true)
        // setIsLoading(true)
        instance({
            method: "GET",
            url: buildUrl("download"),
            headers: headers
        }).then((response) => {
            console.log("response", response)
            var docs = response.data.data.docs;
            setAllListPdf(docs);
                setLoaded(true)
        }).catch((err) => {
            setShowPdfModal(false)
        }).finally(() => {
        })
      
    }
    useDocumentTitle('ADMIN - Campaign Management')
    const [showSelectBrandModal, setShowSelectBrandModal] = useState(false)
    return (
        <div>
            <div className="max-w-[1445px] font-primary">
                <ToastContainer />
                {/* wrapper */}
                {
                    isLoading ? <Loader /> :
                        <div className='container font-primary min-h-[95vh] flex-inital lg:px-[15px] px-[5px] py-[25px] w-full'>
                             {
                                showPdfModal &&
                                <AdminPdfModal title='Campaigns Report' overallviews={1} data={allListPdf} loaded={loaded} close={()=>{
                                    setLoaded(false)
                                    setShowPdfModal(false)
                                }} />
                            }
                            {
                                showSelectBrandModal ?
                                    <SelectBrandModal close={() => {
                                        setShowSelectBrandModal(false)
                                    }} /> : ''
                            }

                            {
                                isDelete ?
                                    <ConfirmModal
                                        callback={() => deleteChallenge()}
                                        close={() => setIsDelete(false)} title="Delete Challenge?" description='Are you sure you want to delete this challenge, this action cannot be revert' />
                                    : ""
                            }

                           
                            <div className="search flex justify-between items-center mb-4">
                                <h3 className="font-primaary font-bold text-blue">Campaign Management</h3>
                                <Link to={'#?'} onClick={() => {
                                    setShowSelectBrandModal(true)
                                }} className='bg-yellow rounded-[45px] font-bold font-primary w-[50px] lg:w-[150px] text-blue h-[35px] flex items-center justify-center px-[10px] text-[14px]'> <AiOutlinePlusCircle className='font=bold' /> <div className="hidden lg:block mx-1"></div> <span className='hidden lg:flex'>Create</span> </Link >
                            </div>
                            {/* filter buttons */}
                            <div className='mb-4'>
                                <div className="flex max-w-[350px] shadow-sm bg-white rounded-[10px]" >
                                    <Button containerClass='w-full bg-blue rounded text-white' text='All Campaigns' />
                                    <Button onclick={() => navigate('/admin/campaign-management-beentos')} containerClass='w-full bg-white rounded text-blue opacity-50 border-0' text='Created by Beentos' />
                                </div>
                            </div>
                            {/* Top voted CampaignManagement */}
                            <div className="py-[15px] px-3 rounded shadow bg-white " style={{ overflow: 'scroll' }}>
                                <div className="flex flex-col items-center lg:flex-row justify-between">
                                    {/* filter button */}
                                    <div className="flex-initial w-full max-w-[150px]">
                                        <CoreDropdown value={"Sort : " + sort} placeholder='Sort : All' extendedClasses='dark:text-white text-yellow' containerClass="flex justify-center w-[full] h-[45px] border-graylight border-2 shadow-sm text-[0.8rem] mt-0 bg-blue text-yellow" showError={false} err={""} items={resultSort1} setValue={(value: any) => dropChanged(value)} />
                                    </div>

                                    {/* export and search */}
                                    <div className="flex gap-4">
                                        {/* search */}
                                        <div className={`flex items-center control border border-blue pl-2 h-[45px] rounded-[45px] w-[250px] mt-[5px] bg-[#F0F0F4] border-graylight shadow-sm border-2`}>
                                            <FiSearch className='text-[22px] text-[#7E799F]' /> <div className="mr-1"></div>
                                            <input placeholder="Search" type="text" value={search_} onChange={(e) => searchChanged(e.target.value)} className={`bg-transparent font-primary font-semibold ${search_ === '' ? 'font-medium text-blue opacity-50' : 'dark:text-white  text-blue'} placeholder:font-medium  border-0 outline-0 bg-none w-full px-2 h-[40px] is-dirty peer `} />
                                        </div>
                                        {/* export */}
                                        <Button onclick={downloadReport} text='Report' containerClass="mt-[5px] bg-blue rounded-[10px] font-bold font-primary text-yellow h-[45px] mb-3 w-[150px] mx-auto flex items-center justify-center px-[10px] text-[14px]" img={exporticon} h="45px" />
                                    </div>
                                </div>

                                <div className="shadow-[10px] rounded-[10px] w-[800px] md:w-full lg:w-full border-graylight border-2" >
                                    <table className="border-collapse text-sm table-auto w-[800px] md:w-full lg:w-full">
                                        <thead className='text-blue p-2 h-[45px] border-b-graylight border-b-2'>
                                            <tr>
                                                <th></th>
                                                <th>Brand Name</th>
                                                <th className='text-left'>Challenge</th>
                                                <th className='text-left'>Status</th>
                                                <th className='text-left'>Budget</th>
                                                <th className='text-left'>Date</th>
                                                <th className="text-left"><BiDotsVertical /></th>
                                            </tr>
                                        </thead>
                                        <tbody className='p-2'>
                                            {
                                                allList.map((item: any, i) => (
                                                    <tr key={i} onClick={() => {
                                                        if (dropdown_ !== "") setDropdown("")
                                                    }} className='p-2 text-[#0B014980] border-spacing-2' style={{ height: '70px' }}>
                                                        <td className='w-[120px] text-center pr-2'>
                                                            <div className=" flex items-center justify-end">
                                                                {
                                                                    selected.includes(item._id) ?
                                                                        <img onClick={() => addSelected(item._id)} style={{ cursor: 'pointer' }} src={checkBoxChecked} alt="" />
                                                                        :
                                                                        <img onClick={() => addSelected(item._id)} style={{ cursor: 'pointer' }} src={checkBox} alt="" />
                                                                }
                                                                <div className="mx-2"></div>
                                                                <div className="mx-2"></div>
                                                                <img className='h-[40px] w-[40px] object-cover rounded-[100%]' src={item.brand ? (item.brand.avatar ? item.brand.avatar : who) : who} alt="" />

                                                            </div>
                                                        </td>
                                                        <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''}`}>
                                                            <div className="flex items-center font-bold font-primary">
                                                                <div className='pl-3 min-w-[200px]'>
                                                                    <h3 className='text-blue'>{item.brand ? item.brand.brandName : 'Brand'}</h3>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''} `}>{item.title}</td>
                                                        <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''} `}>{item.published ? "Published" : "Not Published"}</td>
                                                        <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''} `}>${item.totalPrizeBudget}</td>
                                                        <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''} `}>{item.createdAt ? new Date(item.closingDate).toLocaleDateString("EN-US", { month: "long", day: "2-digit", year: "numeric" }) : ''}</td>
                                                        <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''} w-[50px]`}>
                                                            <div className='relative'>
                                                                {
                                                                    dropdown_ == item._id ?
                                                                        <div className="rounded-[10px] z-[4444] absolute min-w-[150px] top-[-20px] right-[10px] text-blue font-primary bg-white p-3 shadow-blue shadow-md">
                                                                            <div className='my-2'>
                                                                                <a href="#?" onClick={(e) => { e.preventDefault(); navigate("/admin/create-challenge/" + item.brandId + "/create-step-check?challenge=" + item._id + "&source=publish") }} className='my-'>View</a>
                                                                            </div>
                                                                            <div className='my-2'>
                                                                                <a href="#?" onClick={(e) => { e.preventDefault(); navigate(`/admin/edit-challenge/${item?.brandId}?challenge=${item?._id}`) }} className='my-'>Edit</a>
                                                                            </div>
                                                                            {/* /admin/edit-challenge/${challenge?.brandId}?challenge=${challenge?._id} */}
                                                                            <div className='my-2'>
                                                                                <Link  to={"/admin/campaign-management/view/"+item._id} className='my-'>Posts</Link>
                                                                            </div>
                                                                            <div className='my-2'>
                                                                            <Link  to={"/admin/campaign-management/result/"+item._id} className='my-'>Results</Link>
                                                                            </div>
                                                                            <div className='my-2'>
                                                                                <a onClick={(e) => { e.preventDefault(); updateChallengeStatus(item._id, { published: Boolean(!item.published) }) }} href='#?' >{item.published ? "UnPublsh" : "Publish"}</a>
                                                                            </div>


                                                                            <div className='my-2'>
                                                                                <a href="#?" onClick={() => {
                                                                                    setIsDelete(true)
                                                                                    setToDelete(item._id)
                                                                                }} className='text-red-700 my-2'>Delete</a>
                                                                            </div>
                                                                        </div>
                                                                        : ''
                                                                }
                                                                <BiDotsVertical onClick={() => {
                                                                    setDropdown(item._id)
                                                                }} />

                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            }

                                        </tbody>

                                        {
                                            allList.length === 0 ?
                                                <p>No record found</p>
                                                :
                                                ''
                                        }
                                    </table>
                                </div>
                            </div>
                            <Pagination limit={10} length={allRecords} page={page} />
                        </div>
                }

            </div>
        </div>
    )
}

export default CampaignManagement