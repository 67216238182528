import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useDocumentTitle from '../../helpers/pageTitle';
import { useTypedSelector } from '../../hooks/useSelector';
import { ActionType } from '../../store/actions';
import { toast, ToastContainer } from 'react-toastify'
import Input from '../../components/input';
import Button from '../../components/button';
import { challengeTypes, rewardTypes, votingCategories, locations } from '../../data';
import { Choose } from '../../components/brand';
import { capitalizeFirstLetter, position, sharePrize } from '../../helpers/helpers';
import instance from '../../helpers/axios';
import AddProductModal from '../../components/admin/add_package_modal';
import { BiDotsVertical, BiPencil } from 'react-icons/bi';
import AppLink from '../../components/link';

const AdminCreateChallenge5: React.FC = () => {
    const params = useParams()
    const { loggedInAdmin } = useTypedSelector((state) => state.appReducer);
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [title, setTitle] = useState('')
    
    const [challengePerDay, setChallengePerDay] = useState("")
    const [rateperDay, setRateperDay] = useState("")
    const [rateperDayNGN, setRateperDayNGN] = useState("")
    const [loading, setLoading] = useState(false)
    const [feature, setFeature] = useState("")
    const [features, setFeatures] = useState<string[]>([])
    const [showAddModal, setShowAddModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [editIndex, setEditIndex] = useState(0)
    const [isEdit, setIsEdit] = useState(false)

    const headers = {
        'Authorization': `Bearer ${loggedInAdmin}`
    }
    useEffect(() => {
        if(!params.package_id){
        }else{
            const slug = params.package_id;
            // alert(slug)
            setIsEdit(true)

            const headers = {
                'Authorization': `Bearer ${loggedInAdmin}`
            }
            instance({
                method: "GET",
                url: `/subscription/subscription-type/?_id=${slug}`,
                headers: headers
            }).then((response) => {
                var docs = response.data.data.docs;
                console.log("docs", docs)
                if(docs.length == 0){
                    alert("Package not found")
                }else{
                    const p = docs[0];
                    setTitle(p.name)
                    setFeatures(p.detail.split(";"))
                    setRateperDay(p.priceRatePerDay)
                    setChallengePerDay(p.challengesPerMonth)
                    setRateperDayNGN(p.ngnPriceRatePerDay)
                }
             
            }).catch((err) => {
    
            }).finally(() => {
              
            })
        }
    }, [])


    useEffect(() => {
        if (!loggedInAdmin || loggedInAdmin === "") {
            // nobody is logged in
            navigate("/admin/login");
        }
    }, [loggedInAdmin, navigate])

    useEffect(() => {
        dispatch({
            type: ActionType.UPDATE_LAYOUT,
            payload: "admin"
        })
    }, [])

    const { pathname, search } = useLocation();
    useEffect(() => {
        const headers = {
            'Authorization': `Bearer ${loggedInAdmin}`
        }



    }, []);

    useDocumentTitle('CREATE CHALLENGE ')

    const proceed = () => {
        if(loading) return false;
        // navigate("/brand/challenge/create-step-payment");
        setLoading(true)
        var passed = true;
        if(features.length === 0 ){
            setLoading(false)
            toast.error("Please add at least one feature")
            return false;
        }

        if(title === "" ){
            passed = false;
        }

        if(rateperDay === "" ){
            passed = false;
        }

        if(rateperDayNGN === "" ){
            passed = false;
        }

        if(challengePerDay === "" ){
            passed = false;
        }

        
        if(Number(challengePerDay) == 0 || Number(rateperDay) == 0 || Number(rateperDayNGN) == 0){
            setLoading(false)
            toast.error("Rate and challenge per month must be greater than zero")
            return false;
        }

        var f = "";
        features.forEach(element => {
            if (f === "") {
                f = element
            } else {
                f = f + ";" + element
            }
        });

        if(!passed){
        setLoading(false)
            toast.error("All fields required")
            return false;
        }
        var data = {
            "priceRatePerDay": Number(rateperDay),
            "name": title,
            "detail": f,
            "challengesPerMonth": Number(challengePerDay),
            "ngnPriceRatePerDay" : Number(rateperDayNGN)
        }

        const headers = {
            'Authorization': `Bearer ${loggedInAdmin}`
        }
        instance({
            method: isEdit ? "PUT" : "POST",
            url: isEdit ? `/subscription/subscription-type/${params.package_id}` : `/subscription/subscription-type`,
            headers: headers,
            data : data
        }).then((response) => {
            // window.location.reload();
            toast.success(isEdit ? "Package updated" : "New package added")
            if(!isEdit){
                setTitle("")
                setFeatures([])
                setRateperDay("")
                setChallengePerDay("")
                setRateperDayNGN("")
            }
        }).catch((err) => {
            toast.error("Something went wrong")
        }).finally(() => {
            setLoading(false)
        })
    }
    const newFeature = (value: string) => {
        var f = features;
        f = [...f, value];

        setFeatures(f)
        setShowAddModal(false)
    }
    // const a = []
    return (
        <div className='px-[5px] py-[50px]'>
            <div className="container mx-auto">
                <ToastContainer />
                <AppLink href='/admin/subscription/packages' containerClass='font-bold font-primary w-[120px] text-blue flex items-center justify-center px-[10px] text-[14px]' text='go back' />

                <div className="py-[20px] bg-white shadow-lg rounded ">
                    <div className="text-censter font-primary">
                        <div className="mt-3 w-full">
                            <h3 className='text-blue  text-center text-[24px] m-0 font-bold font-primary'>{isEdit ? "Edit Package" : "Add Package" }</h3>
                        </div>
                        {
                            showAddModal ?
                                <AddProductModal close={() => {
                                    setShowAddModal(false)
                                }} setFeature={newFeature} /> : ''
                        }

                        {
                            showEditModal ?
                                <AddProductModal isEdit={true} text={features[editIndex]} index={editIndex} close={() => {
                                    setShowEditModal(false)
                                }} setFeature={(index: number, text: string) => {
                                    var f = features;
                                    f[index] = text;
                                    setFeatures(f)
                                    setShowEditModal(false)
                                }} /> : ''
                        }

                        <div className="mt-4 w-[80%] mx-auto">
                            <div className="my-2">
                                <label className='font-primary text-blue' htmlFor="">Package name</label>
                                <Input value={title} setValue={setTitle} containerClass="h-[50px] bg-[#F0F0F4] border-graylight shadow-0 shadow-none border-0" placeholder='Title' />
                            </div>
                            <div className="my-2">
                                <label className='font-primary text-blue' htmlFor="">Rate per day</label>
                                <Input value={rateperDay} type="number" setValue={setRateperDay} containerClass="h-[50px] bg-[#F0F0F4] border-graylight shadow-0 shadow-none border-0" placeholder='Enter rate per day' />
                            </div>
                            <div className="my-2">
                                <label className='font-primary text-blue' htmlFor="">Rate per day (NGN)</label>
                                <Input value={rateperDayNGN} type="number" setValue={setRateperDayNGN} containerClass="h-[50px] bg-[#F0F0F4] border-graylight shadow-0 shadow-none border-0" placeholder='Enter rate per day' />
                            </div>
                            <div className="my-2">
                                <label className='font-primary text-blue' htmlFor="">Challenges per month</label>
                                <Input value={challengePerDay} type="number" setValue={setChallengePerDay} containerClass="h-[50px] bg-[#F0F0F4] border-graylight shadow-0 shadow-none border-0" placeholder='Enter challenge per month' />
                            </div>
                            <div className="my-[15px]">
                                <div className="d-flex justify-between">
                                    <p className='font-bold text-blue font-primary'>Added Features</p>
                                    <a href="#?" onClick={() => {
                                        setShowAddModal(true)
                                    }} className='font-bold text-blue text-sm bg-yellow px-2 py-[2px] rounded'>Add New</a>
                                </div>
                                <div className="mt-3 p-2 text-blue bg-[#F0F0F4] text-sm shadow-0 shadow-t rounded-[10px]">
                                    <div className='max-w-[400px]'>
                                        <div className="flex justify-between gap-4">
                                            <p className='text-center '>Position</p>
                                            <p className='text-end'><BiDotsVertical /></p>
                                        </div>
                                    </div>

                                    <div className="h-[1px] my-1 mb-3 bg-[#7E799F80] w-full"></div>
                                    <div className='max-w-[400px]'>
                                        {
                                            features.map((item, index) => (
                                                <div className="flex justify-between gap-4 my-2" key={index}>
                                                    <p className='text-center'>{item}</p>
                                                    <div className="flex items-center gap-2">
                                                        <p onClick={() => {
                                                            setEditIndex(index)
                                                            setShowEditModal(true)
                                                        }} className='text-center'><BiPencil /></p>
                                                        <p onClick={() => {
                                                            if (confirm("Are you sure? You cannot revert this action")) {
                                                                var f = features;
                                                                var nf: string[] = [];
                                                                f.forEach((it: string, i) => {
                                                                    if (i !== index) {
                                                                        nf.push(it)
                                                                    }
                                                                })
                                                                setFeatures(nf)
                                                            }
                                                        }} className='text-center'>x</p>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                        {
                                            features.length === 0 ?
                                                <p>No feature added yet</p>
                                                :
                                                ''
                                        }
                                    </div>
                                </div>

                            </div>

                            <div className="text-center">
                                <Button loading={loading} text="Save" onclick={() => proceed()} containerClass="w-full max-w-[250px] bg-blue text-yellow mt-[30px]" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminCreateChallenge5